@import '../theme/variables.scss';

.theme-dark {

  --lc-font-color: var(--lc-white-color);

  main {
    .main-background {
      background: var(--lc-background-color) url(/assets/img/dark/background-main.jpg) no-repeat top right;
      /*background-size: cover;*/
      background-attachment: fixed;
    }
  }

  body,
  p,
  li,
  td,
  th,
  label,
  input,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  strong,
  .select-text {
    font-family: SourceSansPro-Light, Arial, Helvetica, san-serif;
    color: var(--lc-font-color);
  }
  button,
  a {
    font-family: SourceSansPro-Light, Arial, Helvetica, san-serif;
  }

  .lc-footer {

    li {
      background-position: center;
      background-repeat: no-repeat;

      &.forward {
        background-image: url(/assets/img/dark/icon-arrow-forward.svg);
        background-size: 43px auto;
      }
      &.back {
        background-image: url(/assets/img/dark/icon-arrow-backward.svg);
        background-size: 43px auto;
      }
      &.action {
        background-image: url(/assets/img/dark/icon-action.svg);
        background-size: 38px auto;
      }
      &.action.active {
        background-image: url(/assets/img/dark/icon-action-active.svg);
        background-size: 38px auto;
      }
      &.confirm {
        background-image: url(/assets/img/dark/icon-check-mark.svg);
        background-size: 45px auto;
      }
      &.cancel {
        background-image: url(/assets/img/dark/icon-close.svg);
        background-size: 45px auto;
      }
    }

  }

  .side-menu {
    background-color: var(--lc-background-color);
    background-image: url(/assets/img/dark/hexagon.svg);
    background-size: calc(200px + 3rem) auto;
    background-position: -154px -115px;
    background-repeat: no-repeat;
    -webkit-filter: drop-shadow(15px 0px 15px #000);
    filter: drop-shadow(15px 0px 15px #000);

    h2,
    span,
    a,
    .solution {
      color: var(--lc-white-color);
    }

    a.globe {
      background-image: url(/assets/img/dark/icon-menu-globe.svg);
    }
    a.print {
      background-image: url(/assets/img/dark/icon-menu-printer.svg);
    }
    a.bluetooth {
      background-image: url(/assets/img/dark/icon-menu-bluetooth.svg);
    }
    a.wifi {
      background-image: url(/assets/img/dark/icon-menu-wifi.svg);
    }
    a.watch {
      background-image: url(/assets/img/dark/icon-menu-watch.svg);
    }
    a.glasses {
      background-image: url(/assets/img/dark/icon-menu-glasses.svg);
    }
    a.settings {
      background-image: url(/assets/img/dark/icon-menu-gear.svg);
    }
    a.restart {
      background-image: url(/assets/img/dark/icon-menu-restart.svg);
    }
    a.info {
      background-image: url(/assets/img/dark/icon-menu-info.svg);
    }
    a.debug {
      background-image: url(/assets/img/dark/icon-menu-debug.svg);
    }
  }

  .barcode-drawer-content {
    background: var(--lc-background-color) url(/assets/img/dark/background-main.jpg) no-repeat top right;
    background-attachment: fixed;
  }

  .control-input1 {

    .input {
      background-image: url(/assets/img/dark/icon-bg.svg);
      background-position: -20px center;
      background-repeat: no-repeat;
      background-color: transparent;
    }

    .input:disabled {
      background-color: rgba(255, 255, 255, 0);
      border: none;
    }

    .input.keyboard {
      background-image: url(/assets/img/dark/icon-bg.svg), url(/assets/img/dark/icon-keyboard.svg);
      background-position: -20px center, right center;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid var(--lc-tertiary-color);
    }

    .input.barcode {
      background-image: url(/assets/img/dark/icon-barcode.svg), url(/assets/img/dark/icon-bg.svg);
      background-position: 15px center, left center;
    }

    .input.barcode.keyboard {
      background-image: url(/assets/img/dark/icon-barcode.svg), url(/assets/img/dark/icon-bg.svg), url(/assets/img/dark/icon-keyboard.svg);
      background-position: 15px center, left center, right center;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid var(--lc-tertiary-color);
    }

    .input.nfc {
      background-image: url(/assets/img/dark/icon-nfc.svg), url(/assets/img/dark/icon-bg.svg);
      background-position: 6px center, left center;
    }

    .input.nfc.keyboard {
      background-image: url(/assets/img/dark/icon-nfc.svg), url(/assets/img/dark/icon-bg.svg), url(/assets/img/dark/icon-keyboard.svg);
      background-position: 6px center, left center, right center;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid var(--lc-tertiary-color);
    }

    .input.barcode.nfc {
      background-image: url(/assets/img/dark/icon-barcode.svg), url(/assets/img/dark/icon-nfc.svg), url(/assets/img/dark/icon-bg.svg);
      background-position: 8px 8px, 24px 24px, left center;
      background-size: 26px, 30px, auto;
    }

    .input.barcode.nfc.keyboard {
      background-image: url(/assets/img/dark/icon-barcode.svg), url(/assets/img/dark/icon-nfc.svg), url(/assets/img/dark/icon-bg.svg), url(/assets/img/dark/icon-keyboard.svg);
      background-position: 8px 8px, 24px 24px, left center, right center;
      background-size: 26px, 30px, auto, auto;
      background-color: rgba(255, 255, 255, 1);
      border: solid #a3e4fc 1px;
    }
  }

  .notification {
    &.alert {
      background: #fc6868 url(/assets/img/dark/icon-error.svg) no-repeat 30px 15px;
      background-size: 3.125rem auto;
      border: solid 2px #ff0000;
      text-align: center;
      z-index: 99;

      .button-close {
        background: url(/assets/img/dark/icon-close.svg) no-repeat center;
        background-size: 1rem;
      }

      h3 {
        text-transform: uppercase;
        padding: 15px 65px 30px 65px;
        color: #ffffff;
      }

      p {
        color: #ffffff;
      }
    }
    &.alert {
      animation-name: Alert;
      animation-duration: 0.75s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-out;
    }
    @keyframes Alert {
      to {
        background-color: #fa4f4f;
      }
    }

    &.confirm {
      background-color: green;
      border: solid 2px darkgreen;
      text-align: center;
      z-index: 99;

      .button-close {
        background: url(/assets/img/dark/icon-close.svg) no-repeat center;
        background-size: 15px;
      }

      h3 {
        text-transform: uppercase;
        padding: 15px 65px 30px 65px;
        color: #ffffff;
      }

      p {
        color: #ffffff;
      }
    }
    &.confirm {
      animation-name: Confirm;
      animation-duration: 0.75s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-out;
    }
    @keyframes Confirm {
      to {
        background-color: forestgreen;
      }
    }

    &.critical {
      background: #fc6868 url(/assets/img/dark/icon-error.svg) no-repeat 30px 15px;
      background-size: 3.125rem auto;
      border: solid 2px #ffd000;
      text-align: center;
      z-index: 99;

      .button-close {
        background: url(/assets/img/dark/icon-close.svg) no-repeat center;
        background-size: 1rem;
      }

      h3 {
        text-transform: uppercase;
        padding: 15px 65px 30px 65px;
        color: #ffffff;
      }

      p {
        color: #ffffff;
      }
    }
    &.critical {
      animation-name: Critical;
      animation-duration: 0.75s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-out;
    }
    @keyframes Critical {
      to {
        background-color: #fa4f4f;
      }
    }
  }


  .modal-default {
    --background: var(--lc-background-color);
    --ion-item-color: var(--lc-white-color);
    ion-content {
      --background: var(--lc-background-color);
      --ion-item-color: var(--lc-white-color);
    }
  }


  .popover-container,
  .popover-grid-container {
    background: var(--lc-background-color) url(/assets/img/dark/background-main.jpg) no-repeat top right;

    .button {
      background-color: var(--lc-primary-color);
      color: var(--lc-white-color);
    }
  }

  .popover-confirm-wrapper {
    background-color: var(--lc-background-color);

    .toolbar-background {
      background-color: var(--lc-primary-color);
      color: var(--lc-white-color);
    }

    a {
      background-position: center;
      background-repeat: no-repeat;

      &.confirm {
        background-image: url(/assets/img/dark/icon-check-mark.svg);
        background-size: 45px auto;
      }
      &.cancel {
        background-image: url(/assets/img/dark/icon-close.svg);
        background-size: 45px auto;
      }
    }
  }



  lc-header {
    .a {
      color: var(--lc-light-color);
    }
    .a:hover:not(.disable-hover) {
      color: var(--lc-secondary-color);
    }

    .tab-select {
      a.tm-closed,
      a.tm-open {
        padding-left: 45px;
        background-size: .75rem;
        background-position: 15px center;
        background-repeat: no-repeat;
      }
      a.tm-closed {
        background-image: url(/assets/img/dark/icon-arrow-down.svg);
      }
      a.tm-open {
        background-image: url(/assets/img/dark/icon-arrow-up.svg);
      }
    }

    .status-bar {
      &.online {
        --background: var(--lc-success-color);
        --color: var(--lc-white-color);
      }

      &.offline {
        --background: var(--lc-warning-color);
        --color: var(--lc-black-color);
      }
    }
  }

}
