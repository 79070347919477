.fad.#{$fa-css-prefix}-0::after, .fa-duotone.#{$fa-css-prefix}-0::after { content: "\30\30"; }
.fad.#{$fa-css-prefix}-1::after, .fa-duotone.#{$fa-css-prefix}-1::after { content: "\31\31"; }
.fad.#{$fa-css-prefix}-2::after, .fa-duotone.#{$fa-css-prefix}-2::after { content: "\32\32"; }
.fad.#{$fa-css-prefix}-3::after, .fa-duotone.#{$fa-css-prefix}-3::after { content: "\33\33"; }
.fad.#{$fa-css-prefix}-4::after, .fa-duotone.#{$fa-css-prefix}-4::after { content: "\34\34"; }
.fad.#{$fa-css-prefix}-5::after, .fa-duotone.#{$fa-css-prefix}-5::after { content: "\35\35"; }
.fad.#{$fa-css-prefix}-6::after, .fa-duotone.#{$fa-css-prefix}-6::after { content: "\36\36"; }
.fad.#{$fa-css-prefix}-7::after, .fa-duotone.#{$fa-css-prefix}-7::after { content: "\37\37"; }
.fad.#{$fa-css-prefix}-8::after, .fa-duotone.#{$fa-css-prefix}-8::after { content: "\38\38"; }
.fad.#{$fa-css-prefix}-9::after, .fa-duotone.#{$fa-css-prefix}-9::after { content: "\39\39"; }
.fad.#{$fa-css-prefix}-00::after, .fa-duotone.#{$fa-css-prefix}-00::after { content: "\e467\e467"; }
.fad.#{$fa-css-prefix}-360-degrees::after, .fa-duotone.#{$fa-css-prefix}-360-degrees::after { content: "\e2dc\e2dc"; }
.fad.#{$fa-css-prefix}-a::after, .fa-duotone.#{$fa-css-prefix}-a::after { content: "\41\41"; }
.fad.#{$fa-css-prefix}-abacus::after, .fa-duotone.#{$fa-css-prefix}-abacus::after { content: "\f640\f640"; }
.fad.#{$fa-css-prefix}-accent-grave::after, .fa-duotone.#{$fa-css-prefix}-accent-grave::after { content: "\60\60"; }
.fad.#{$fa-css-prefix}-acorn::after, .fa-duotone.#{$fa-css-prefix}-acorn::after { content: "\f6ae\f6ae"; }
.fad.#{$fa-css-prefix}-address-book::after, .fa-duotone.#{$fa-css-prefix}-address-book::after { content: "\f2b9\f2b9"; }
.fad.#{$fa-css-prefix}-contact-book::after, .fa-duotone.#{$fa-css-prefix}-contact-book::after { content: "\f2b9\f2b9"; }
.fad.#{$fa-css-prefix}-address-card::after, .fa-duotone.#{$fa-css-prefix}-address-card::after { content: "\f2bb\f2bb"; }
.fad.#{$fa-css-prefix}-contact-card::after, .fa-duotone.#{$fa-css-prefix}-contact-card::after { content: "\f2bb\f2bb"; }
.fad.#{$fa-css-prefix}-vcard::after, .fa-duotone.#{$fa-css-prefix}-vcard::after { content: "\f2bb\f2bb"; }
.fad.#{$fa-css-prefix}-air-conditioner::after, .fa-duotone.#{$fa-css-prefix}-air-conditioner::after { content: "\f8f4\f8f4"; }
.fad.#{$fa-css-prefix}-airplay::after, .fa-duotone.#{$fa-css-prefix}-airplay::after { content: "\e089\e089"; }
.fad.#{$fa-css-prefix}-alarm-clock::after, .fa-duotone.#{$fa-css-prefix}-alarm-clock::after { content: "\f34e\f34e"; }
.fad.#{$fa-css-prefix}-alarm-exclamation::after, .fa-duotone.#{$fa-css-prefix}-alarm-exclamation::after { content: "\f843\f843"; }
.fad.#{$fa-css-prefix}-alarm-plus::after, .fa-duotone.#{$fa-css-prefix}-alarm-plus::after { content: "\f844\f844"; }
.fad.#{$fa-css-prefix}-alarm-snooze::after, .fa-duotone.#{$fa-css-prefix}-alarm-snooze::after { content: "\f845\f845"; }
.fad.#{$fa-css-prefix}-album::after, .fa-duotone.#{$fa-css-prefix}-album::after { content: "\f89f\f89f"; }
.fad.#{$fa-css-prefix}-album-circle-plus::after, .fa-duotone.#{$fa-css-prefix}-album-circle-plus::after { content: "\e48c\e48c"; }
.fad.#{$fa-css-prefix}-album-circle-user::after, .fa-duotone.#{$fa-css-prefix}-album-circle-user::after { content: "\e48d\e48d"; }
.fad.#{$fa-css-prefix}-album-collection::after, .fa-duotone.#{$fa-css-prefix}-album-collection::after { content: "\f8a0\f8a0"; }
.fad.#{$fa-css-prefix}-album-collection-circle-plus::after, .fa-duotone.#{$fa-css-prefix}-album-collection-circle-plus::after { content: "\e48e\e48e"; }
.fad.#{$fa-css-prefix}-album-collection-circle-user::after, .fa-duotone.#{$fa-css-prefix}-album-collection-circle-user::after { content: "\e48f\e48f"; }
.fad.#{$fa-css-prefix}-alicorn::after, .fa-duotone.#{$fa-css-prefix}-alicorn::after { content: "\f6b0\f6b0"; }
.fad.#{$fa-css-prefix}-alien::after, .fa-duotone.#{$fa-css-prefix}-alien::after { content: "\f8f5\f8f5"; }
.fad.#{$fa-css-prefix}-alien-8bit::after, .fa-duotone.#{$fa-css-prefix}-alien-8bit::after { content: "\f8f6\f8f6"; }
.fad.#{$fa-css-prefix}-alien-monster::after, .fa-duotone.#{$fa-css-prefix}-alien-monster::after { content: "\f8f6\f8f6"; }
.fad.#{$fa-css-prefix}-align-center::after, .fa-duotone.#{$fa-css-prefix}-align-center::after { content: "\f037\f037"; }
.fad.#{$fa-css-prefix}-align-justify::after, .fa-duotone.#{$fa-css-prefix}-align-justify::after { content: "\f039\f039"; }
.fad.#{$fa-css-prefix}-align-left::after, .fa-duotone.#{$fa-css-prefix}-align-left::after { content: "\f036\f036"; }
.fad.#{$fa-css-prefix}-align-right::after, .fa-duotone.#{$fa-css-prefix}-align-right::after { content: "\f038\f038"; }
.fad.#{$fa-css-prefix}-align-slash::after, .fa-duotone.#{$fa-css-prefix}-align-slash::after { content: "\f846\f846"; }
.fad.#{$fa-css-prefix}-alt::after, .fa-duotone.#{$fa-css-prefix}-alt::after { content: "\e08a\e08a"; }
.fad.#{$fa-css-prefix}-amp-guitar::after, .fa-duotone.#{$fa-css-prefix}-amp-guitar::after { content: "\f8a1\f8a1"; }
.fad.#{$fa-css-prefix}-ampersand::after, .fa-duotone.#{$fa-css-prefix}-ampersand::after { content: "\26\26"; }
.fad.#{$fa-css-prefix}-anchor::after, .fa-duotone.#{$fa-css-prefix}-anchor::after { content: "\f13d\f13d"; }
.fad.#{$fa-css-prefix}-angel::after, .fa-duotone.#{$fa-css-prefix}-angel::after { content: "\f779\f779"; }
.fad.#{$fa-css-prefix}-angle::after, .fa-duotone.#{$fa-css-prefix}-angle::after { content: "\e08c\e08c"; }
.fad.#{$fa-css-prefix}-angle-90::after, .fa-duotone.#{$fa-css-prefix}-angle-90::after { content: "\e08d\e08d"; }
.fad.#{$fa-css-prefix}-angle-down::after, .fa-duotone.#{$fa-css-prefix}-angle-down::after { content: "\f107\f107"; }
.fad.#{$fa-css-prefix}-angle-left::after, .fa-duotone.#{$fa-css-prefix}-angle-left::after { content: "\f104\f104"; }
.fad.#{$fa-css-prefix}-angle-right::after, .fa-duotone.#{$fa-css-prefix}-angle-right::after { content: "\f105\f105"; }
.fad.#{$fa-css-prefix}-angle-up::after, .fa-duotone.#{$fa-css-prefix}-angle-up::after { content: "\f106\f106"; }
.fad.#{$fa-css-prefix}-angles-down::after, .fa-duotone.#{$fa-css-prefix}-angles-down::after { content: "\f103\f103"; }
.fad.#{$fa-css-prefix}-angle-double-down::after, .fa-duotone.#{$fa-css-prefix}-angle-double-down::after { content: "\f103\f103"; }
.fad.#{$fa-css-prefix}-angles-left::after, .fa-duotone.#{$fa-css-prefix}-angles-left::after { content: "\f100\f100"; }
.fad.#{$fa-css-prefix}-angle-double-left::after, .fa-duotone.#{$fa-css-prefix}-angle-double-left::after { content: "\f100\f100"; }
.fad.#{$fa-css-prefix}-angles-right::after, .fa-duotone.#{$fa-css-prefix}-angles-right::after { content: "\f101\f101"; }
.fad.#{$fa-css-prefix}-angle-double-right::after, .fa-duotone.#{$fa-css-prefix}-angle-double-right::after { content: "\f101\f101"; }
.fad.#{$fa-css-prefix}-angles-up::after, .fa-duotone.#{$fa-css-prefix}-angles-up::after { content: "\f102\f102"; }
.fad.#{$fa-css-prefix}-angle-double-up::after, .fa-duotone.#{$fa-css-prefix}-angle-double-up::after { content: "\f102\f102"; }
.fad.#{$fa-css-prefix}-ankh::after, .fa-duotone.#{$fa-css-prefix}-ankh::after { content: "\f644\f644"; }
.fad.#{$fa-css-prefix}-apartment::after, .fa-duotone.#{$fa-css-prefix}-apartment::after { content: "\e468\e468"; }
.fad.#{$fa-css-prefix}-aperture::after, .fa-duotone.#{$fa-css-prefix}-aperture::after { content: "\e2df\e2df"; }
.fad.#{$fa-css-prefix}-apostrophe::after, .fa-duotone.#{$fa-css-prefix}-apostrophe::after { content: "\27\27"; }
.fad.#{$fa-css-prefix}-apple-core::after, .fa-duotone.#{$fa-css-prefix}-apple-core::after { content: "\e08f\e08f"; }
.fad.#{$fa-css-prefix}-apple-whole::after, .fa-duotone.#{$fa-css-prefix}-apple-whole::after { content: "\f5d1\f5d1"; }
.fad.#{$fa-css-prefix}-apple-alt::after, .fa-duotone.#{$fa-css-prefix}-apple-alt::after { content: "\f5d1\f5d1"; }
.fad.#{$fa-css-prefix}-archway::after, .fa-duotone.#{$fa-css-prefix}-archway::after { content: "\f557\f557"; }
.fad.#{$fa-css-prefix}-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-arrow-down::after { content: "\f063\f063"; }
.fad.#{$fa-css-prefix}-arrow-down-1-9::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-1-9::after { content: "\f162\f162"; }
.fad.#{$fa-css-prefix}-sort-numeric-asc::after, .fa-duotone.#{$fa-css-prefix}-sort-numeric-asc::after { content: "\f162\f162"; }
.fad.#{$fa-css-prefix}-sort-numeric-down::after, .fa-duotone.#{$fa-css-prefix}-sort-numeric-down::after { content: "\f162\f162"; }
.fad.#{$fa-css-prefix}-arrow-down-9-1::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-9-1::after { content: "\f886\f886"; }
.fad.#{$fa-css-prefix}-sort-numeric-desc::after, .fa-duotone.#{$fa-css-prefix}-sort-numeric-desc::after { content: "\f886\f886"; }
.fad.#{$fa-css-prefix}-sort-numeric-down-alt::after, .fa-duotone.#{$fa-css-prefix}-sort-numeric-down-alt::after { content: "\f886\f886"; }
.fad.#{$fa-css-prefix}-arrow-down-a-z::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-a-z::after { content: "\f15d\f15d"; }
.fad.#{$fa-css-prefix}-sort-alpha-asc::after, .fa-duotone.#{$fa-css-prefix}-sort-alpha-asc::after { content: "\f15d\f15d"; }
.fad.#{$fa-css-prefix}-sort-alpha-down::after, .fa-duotone.#{$fa-css-prefix}-sort-alpha-down::after { content: "\f15d\f15d"; }
.fad.#{$fa-css-prefix}-arrow-down-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-arrow-up::after { content: "\f883\f883"; }
.fad.#{$fa-css-prefix}-sort-alt::after, .fa-duotone.#{$fa-css-prefix}-sort-alt::after { content: "\f883\f883"; }
.fad.#{$fa-css-prefix}-arrow-down-big-small::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-big-small::after { content: "\f88c\f88c"; }
.fad.#{$fa-css-prefix}-sort-size-down::after, .fa-duotone.#{$fa-css-prefix}-sort-size-down::after { content: "\f88c\f88c"; }
.fad.#{$fa-css-prefix}-arrow-down-from-dotted-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-from-dotted-line::after { content: "\e090\e090"; }
.fad.#{$fa-css-prefix}-arrow-down-from-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-from-line::after { content: "\f345\f345"; }
.fad.#{$fa-css-prefix}-arrow-from-top::after, .fa-duotone.#{$fa-css-prefix}-arrow-from-top::after { content: "\f345\f345"; }
.fad.#{$fa-css-prefix}-arrow-down-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-left::after { content: "\e091\e091"; }
.fad.#{$fa-css-prefix}-arrow-down-left-and-arrow-up-right-to-center::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-left-and-arrow-up-right-to-center::after { content: "\e092\e092"; }
.fad.#{$fa-css-prefix}-arrow-down-long::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-long::after { content: "\f175\f175"; }
.fad.#{$fa-css-prefix}-long-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-long-arrow-down::after { content: "\f175\f175"; }
.fad.#{$fa-css-prefix}-arrow-down-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-right::after { content: "\e093\e093"; }
.fad.#{$fa-css-prefix}-arrow-down-short-wide::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-short-wide::after { content: "\f884\f884"; }
.fad.#{$fa-css-prefix}-sort-amount-desc::after, .fa-duotone.#{$fa-css-prefix}-sort-amount-desc::after { content: "\f884\f884"; }
.fad.#{$fa-css-prefix}-sort-amount-down-alt::after, .fa-duotone.#{$fa-css-prefix}-sort-amount-down-alt::after { content: "\f884\f884"; }
.fad.#{$fa-css-prefix}-arrow-down-small-big::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-small-big::after { content: "\f88d\f88d"; }
.fad.#{$fa-css-prefix}-sort-size-down-alt::after, .fa-duotone.#{$fa-css-prefix}-sort-size-down-alt::after { content: "\f88d\f88d"; }
.fad.#{$fa-css-prefix}-arrow-down-square-triangle::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-square-triangle::after { content: "\f889\f889"; }
.fad.#{$fa-css-prefix}-sort-shapes-down-alt::after, .fa-duotone.#{$fa-css-prefix}-sort-shapes-down-alt::after { content: "\f889\f889"; }
.fad.#{$fa-css-prefix}-arrow-down-to-bracket::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-to-bracket::after { content: "\e094\e094"; }
.fad.#{$fa-css-prefix}-arrow-down-to-dotted-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-to-dotted-line::after { content: "\e095\e095"; }
.fad.#{$fa-css-prefix}-arrow-down-to-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-to-line::after { content: "\f33d\f33d"; }
.fad.#{$fa-css-prefix}-arrow-to-bottom::after, .fa-duotone.#{$fa-css-prefix}-arrow-to-bottom::after { content: "\f33d\f33d"; }
.fad.#{$fa-css-prefix}-arrow-down-to-square::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-to-square::after { content: "\e096\e096"; }
.fad.#{$fa-css-prefix}-arrow-down-triangle-square::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-triangle-square::after { content: "\f888\f888"; }
.fad.#{$fa-css-prefix}-sort-shapes-down::after, .fa-duotone.#{$fa-css-prefix}-sort-shapes-down::after { content: "\f888\f888"; }
.fad.#{$fa-css-prefix}-arrow-down-wide-short::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-wide-short::after { content: "\f160\f160"; }
.fad.#{$fa-css-prefix}-sort-amount-asc::after, .fa-duotone.#{$fa-css-prefix}-sort-amount-asc::after { content: "\f160\f160"; }
.fad.#{$fa-css-prefix}-sort-amount-down::after, .fa-duotone.#{$fa-css-prefix}-sort-amount-down::after { content: "\f160\f160"; }
.fad.#{$fa-css-prefix}-arrow-down-z-a::after, .fa-duotone.#{$fa-css-prefix}-arrow-down-z-a::after { content: "\f881\f881"; }
.fad.#{$fa-css-prefix}-sort-alpha-desc::after, .fa-duotone.#{$fa-css-prefix}-sort-alpha-desc::after { content: "\f881\f881"; }
.fad.#{$fa-css-prefix}-sort-alpha-down-alt::after, .fa-duotone.#{$fa-css-prefix}-sort-alpha-down-alt::after { content: "\f881\f881"; }
.fad.#{$fa-css-prefix}-arrow-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-left::after { content: "\f060\f060"; }
.fad.#{$fa-css-prefix}-arrow-left-from-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-left-from-line::after { content: "\f344\f344"; }
.fad.#{$fa-css-prefix}-arrow-from-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-from-right::after { content: "\f344\f344"; }
.fad.#{$fa-css-prefix}-arrow-left-long::after, .fa-duotone.#{$fa-css-prefix}-arrow-left-long::after { content: "\f177\f177"; }
.fad.#{$fa-css-prefix}-long-arrow-left::after, .fa-duotone.#{$fa-css-prefix}-long-arrow-left::after { content: "\f177\f177"; }
.fad.#{$fa-css-prefix}-arrow-left-long-to-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-left-long-to-line::after { content: "\e3d4\e3d4"; }
.fad.#{$fa-css-prefix}-arrow-left-to-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-left-to-line::after { content: "\f33e\f33e"; }
.fad.#{$fa-css-prefix}-arrow-to-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-to-left::after { content: "\f33e\f33e"; }
.fad.#{$fa-css-prefix}-arrow-pointer::after, .fa-duotone.#{$fa-css-prefix}-arrow-pointer::after { content: "\f245\f245"; }
.fad.#{$fa-css-prefix}-mouse-pointer::after, .fa-duotone.#{$fa-css-prefix}-mouse-pointer::after { content: "\f245\f245"; }
.fad.#{$fa-css-prefix}-arrow-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-right::after { content: "\f061\f061"; }
.fad.#{$fa-css-prefix}-arrow-right-arrow-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-right-arrow-left::after { content: "\f0ec\f0ec"; }
.fad.#{$fa-css-prefix}-exchange::after, .fa-duotone.#{$fa-css-prefix}-exchange::after { content: "\f0ec\f0ec"; }
.fad.#{$fa-css-prefix}-arrow-right-from-bracket::after, .fa-duotone.#{$fa-css-prefix}-arrow-right-from-bracket::after { content: "\f08b\f08b"; }
.fad.#{$fa-css-prefix}-sign-out::after, .fa-duotone.#{$fa-css-prefix}-sign-out::after { content: "\f08b\f08b"; }
.fad.#{$fa-css-prefix}-arrow-right-from-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-right-from-line::after { content: "\f343\f343"; }
.fad.#{$fa-css-prefix}-arrow-from-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-from-left::after { content: "\f343\f343"; }
.fad.#{$fa-css-prefix}-arrow-right-long::after, .fa-duotone.#{$fa-css-prefix}-arrow-right-long::after { content: "\f178\f178"; }
.fad.#{$fa-css-prefix}-long-arrow-right::after, .fa-duotone.#{$fa-css-prefix}-long-arrow-right::after { content: "\f178\f178"; }
.fad.#{$fa-css-prefix}-arrow-right-long-to-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-right-long-to-line::after { content: "\e3d5\e3d5"; }
.fad.#{$fa-css-prefix}-arrow-right-to-bracket::after, .fa-duotone.#{$fa-css-prefix}-arrow-right-to-bracket::after { content: "\f090\f090"; }
.fad.#{$fa-css-prefix}-sign-in::after, .fa-duotone.#{$fa-css-prefix}-sign-in::after { content: "\f090\f090"; }
.fad.#{$fa-css-prefix}-arrow-right-to-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-right-to-line::after { content: "\f340\f340"; }
.fad.#{$fa-css-prefix}-arrow-to-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-to-right::after { content: "\f340\f340"; }
.fad.#{$fa-css-prefix}-arrow-rotate-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-left::after { content: "\f0e2\f0e2"; }
.fad.#{$fa-css-prefix}-arrow-left-rotate::after, .fa-duotone.#{$fa-css-prefix}-arrow-left-rotate::after { content: "\f0e2\f0e2"; }
.fad.#{$fa-css-prefix}-arrow-rotate-back::after, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-back::after { content: "\f0e2\f0e2"; }
.fad.#{$fa-css-prefix}-arrow-rotate-backward::after, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-backward::after { content: "\f0e2\f0e2"; }
.fad.#{$fa-css-prefix}-undo::after, .fa-duotone.#{$fa-css-prefix}-undo::after { content: "\f0e2\f0e2"; }
.fad.#{$fa-css-prefix}-arrow-rotate-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-right::after { content: "\f01e\f01e"; }
.fad.#{$fa-css-prefix}-arrow-right-rotate::after, .fa-duotone.#{$fa-css-prefix}-arrow-right-rotate::after { content: "\f01e\f01e"; }
.fad.#{$fa-css-prefix}-arrow-rotate-forward::after, .fa-duotone.#{$fa-css-prefix}-arrow-rotate-forward::after { content: "\f01e\f01e"; }
.fad.#{$fa-css-prefix}-redo::after, .fa-duotone.#{$fa-css-prefix}-redo::after { content: "\f01e\f01e"; }
.fad.#{$fa-css-prefix}-arrow-trend-down::after, .fa-duotone.#{$fa-css-prefix}-arrow-trend-down::after { content: "\e097\e097"; }
.fad.#{$fa-css-prefix}-arrow-trend-up::after, .fa-duotone.#{$fa-css-prefix}-arrow-trend-up::after { content: "\e098\e098"; }
.fad.#{$fa-css-prefix}-arrow-turn-down::after, .fa-duotone.#{$fa-css-prefix}-arrow-turn-down::after { content: "\f149\f149"; }
.fad.#{$fa-css-prefix}-level-down::after, .fa-duotone.#{$fa-css-prefix}-level-down::after { content: "\f149\f149"; }
.fad.#{$fa-css-prefix}-arrow-turn-down-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-turn-down-left::after { content: "\e2e1\e2e1"; }
.fad.#{$fa-css-prefix}-arrow-turn-down-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-turn-down-right::after { content: "\e3d6\e3d6"; }
.fad.#{$fa-css-prefix}-arrow-turn-up::after, .fa-duotone.#{$fa-css-prefix}-arrow-turn-up::after { content: "\f148\f148"; }
.fad.#{$fa-css-prefix}-level-up::after, .fa-duotone.#{$fa-css-prefix}-level-up::after { content: "\f148\f148"; }
.fad.#{$fa-css-prefix}-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-arrow-up::after { content: "\f062\f062"; }
.fad.#{$fa-css-prefix}-arrow-up-1-9::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-1-9::after { content: "\f163\f163"; }
.fad.#{$fa-css-prefix}-sort-numeric-up::after, .fa-duotone.#{$fa-css-prefix}-sort-numeric-up::after { content: "\f163\f163"; }
.fad.#{$fa-css-prefix}-arrow-up-9-1::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-9-1::after { content: "\f887\f887"; }
.fad.#{$fa-css-prefix}-sort-numeric-up-alt::after, .fa-duotone.#{$fa-css-prefix}-sort-numeric-up-alt::after { content: "\f887\f887"; }
.fad.#{$fa-css-prefix}-arrow-up-a-z::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-a-z::after { content: "\f15e\f15e"; }
.fad.#{$fa-css-prefix}-sort-alpha-up::after, .fa-duotone.#{$fa-css-prefix}-sort-alpha-up::after { content: "\f15e\f15e"; }
.fad.#{$fa-css-prefix}-arrow-up-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-arrow-down::after { content: "\e099\e099"; }
.fad.#{$fa-css-prefix}-sort-up-down::after, .fa-duotone.#{$fa-css-prefix}-sort-up-down::after { content: "\e099\e099"; }
.fad.#{$fa-css-prefix}-arrow-up-big-small::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-big-small::after { content: "\f88e\f88e"; }
.fad.#{$fa-css-prefix}-sort-size-up::after, .fa-duotone.#{$fa-css-prefix}-sort-size-up::after { content: "\f88e\f88e"; }
.fad.#{$fa-css-prefix}-arrow-up-from-bracket::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-from-bracket::after { content: "\e09a\e09a"; }
.fad.#{$fa-css-prefix}-arrow-up-from-dotted-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-from-dotted-line::after { content: "\e09b\e09b"; }
.fad.#{$fa-css-prefix}-arrow-up-from-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-from-line::after { content: "\f342\f342"; }
.fad.#{$fa-css-prefix}-arrow-from-bottom::after, .fa-duotone.#{$fa-css-prefix}-arrow-from-bottom::after { content: "\f342\f342"; }
.fad.#{$fa-css-prefix}-arrow-up-from-square::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-from-square::after { content: "\e09c\e09c"; }
.fad.#{$fa-css-prefix}-arrow-up-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-left::after { content: "\e09d\e09d"; }
.fad.#{$fa-css-prefix}-arrow-up-left-from-circle::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-left-from-circle::after { content: "\e09e\e09e"; }
.fad.#{$fa-css-prefix}-arrow-up-long::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-long::after { content: "\f176\f176"; }
.fad.#{$fa-css-prefix}-long-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-long-arrow-up::after { content: "\f176\f176"; }
.fad.#{$fa-css-prefix}-arrow-up-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-right::after { content: "\e09f\e09f"; }
.fad.#{$fa-css-prefix}-arrow-up-right-and-arrow-down-left-from-center::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-right-and-arrow-down-left-from-center::after { content: "\e0a0\e0a0"; }
.fad.#{$fa-css-prefix}-arrow-up-right-from-square::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-right-from-square::after { content: "\f08e\f08e"; }
.fad.#{$fa-css-prefix}-external-link::after, .fa-duotone.#{$fa-css-prefix}-external-link::after { content: "\f08e\f08e"; }
.fad.#{$fa-css-prefix}-arrow-up-short-wide::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-short-wide::after { content: "\f885\f885"; }
.fad.#{$fa-css-prefix}-sort-amount-up-alt::after, .fa-duotone.#{$fa-css-prefix}-sort-amount-up-alt::after { content: "\f885\f885"; }
.fad.#{$fa-css-prefix}-arrow-up-small-big::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-small-big::after { content: "\f88f\f88f"; }
.fad.#{$fa-css-prefix}-sort-size-up-alt::after, .fa-duotone.#{$fa-css-prefix}-sort-size-up-alt::after { content: "\f88f\f88f"; }
.fad.#{$fa-css-prefix}-arrow-up-square-triangle::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-square-triangle::after { content: "\f88b\f88b"; }
.fad.#{$fa-css-prefix}-sort-shapes-up-alt::after, .fa-duotone.#{$fa-css-prefix}-sort-shapes-up-alt::after { content: "\f88b\f88b"; }
.fad.#{$fa-css-prefix}-arrow-up-to-dotted-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-to-dotted-line::after { content: "\e0a1\e0a1"; }
.fad.#{$fa-css-prefix}-arrow-up-to-line::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-to-line::after { content: "\f341\f341"; }
.fad.#{$fa-css-prefix}-arrow-to-top::after, .fa-duotone.#{$fa-css-prefix}-arrow-to-top::after { content: "\f341\f341"; }
.fad.#{$fa-css-prefix}-arrow-up-triangle-square::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-triangle-square::after { content: "\f88a\f88a"; }
.fad.#{$fa-css-prefix}-sort-shapes-up::after, .fa-duotone.#{$fa-css-prefix}-sort-shapes-up::after { content: "\f88a\f88a"; }
.fad.#{$fa-css-prefix}-arrow-up-wide-short::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-wide-short::after { content: "\f161\f161"; }
.fad.#{$fa-css-prefix}-sort-amount-up::after, .fa-duotone.#{$fa-css-prefix}-sort-amount-up::after { content: "\f161\f161"; }
.fad.#{$fa-css-prefix}-arrow-up-z-a::after, .fa-duotone.#{$fa-css-prefix}-arrow-up-z-a::after { content: "\f882\f882"; }
.fad.#{$fa-css-prefix}-sort-alpha-up-alt::after, .fa-duotone.#{$fa-css-prefix}-sort-alpha-up-alt::after { content: "\f882\f882"; }
.fad.#{$fa-css-prefix}-arrows-cross::after, .fa-duotone.#{$fa-css-prefix}-arrows-cross::after { content: "\e0a2\e0a2"; }
.fad.#{$fa-css-prefix}-arrows-from-dotted-line::after, .fa-duotone.#{$fa-css-prefix}-arrows-from-dotted-line::after { content: "\e0a3\e0a3"; }
.fad.#{$fa-css-prefix}-arrows-from-line::after, .fa-duotone.#{$fa-css-prefix}-arrows-from-line::after { content: "\e0a4\e0a4"; }
.fad.#{$fa-css-prefix}-arrows-left-right::after, .fa-duotone.#{$fa-css-prefix}-arrows-left-right::after { content: "\f07e\f07e"; }
.fad.#{$fa-css-prefix}-arrows-h::after, .fa-duotone.#{$fa-css-prefix}-arrows-h::after { content: "\f07e\f07e"; }
.fad.#{$fa-css-prefix}-arrows-maximize::after, .fa-duotone.#{$fa-css-prefix}-arrows-maximize::after { content: "\f31d\f31d"; }
.fad.#{$fa-css-prefix}-expand-arrows::after, .fa-duotone.#{$fa-css-prefix}-expand-arrows::after { content: "\f31d\f31d"; }
.fad.#{$fa-css-prefix}-arrows-minimize::after, .fa-duotone.#{$fa-css-prefix}-arrows-minimize::after { content: "\e0a5\e0a5"; }
.fad.#{$fa-css-prefix}-compress-arrows::after, .fa-duotone.#{$fa-css-prefix}-compress-arrows::after { content: "\e0a5\e0a5"; }
.fad.#{$fa-css-prefix}-arrows-repeat::after, .fa-duotone.#{$fa-css-prefix}-arrows-repeat::after { content: "\f364\f364"; }
.fad.#{$fa-css-prefix}-repeat-alt::after, .fa-duotone.#{$fa-css-prefix}-repeat-alt::after { content: "\f364\f364"; }
.fad.#{$fa-css-prefix}-arrows-repeat-1::after, .fa-duotone.#{$fa-css-prefix}-arrows-repeat-1::after { content: "\f366\f366"; }
.fad.#{$fa-css-prefix}-repeat-1-alt::after, .fa-duotone.#{$fa-css-prefix}-repeat-1-alt::after { content: "\f366\f366"; }
.fad.#{$fa-css-prefix}-arrows-retweet::after, .fa-duotone.#{$fa-css-prefix}-arrows-retweet::after { content: "\f361\f361"; }
.fad.#{$fa-css-prefix}-retweet-alt::after, .fa-duotone.#{$fa-css-prefix}-retweet-alt::after { content: "\f361\f361"; }
.fad.#{$fa-css-prefix}-arrows-rotate::after, .fa-duotone.#{$fa-css-prefix}-arrows-rotate::after { content: "\f021\f021"; }
.fad.#{$fa-css-prefix}-refresh::after, .fa-duotone.#{$fa-css-prefix}-refresh::after { content: "\f021\f021"; }
.fad.#{$fa-css-prefix}-sync::after, .fa-duotone.#{$fa-css-prefix}-sync::after { content: "\f021\f021"; }
.fad.#{$fa-css-prefix}-arrows-to-dotted-line::after, .fa-duotone.#{$fa-css-prefix}-arrows-to-dotted-line::after { content: "\e0a6\e0a6"; }
.fad.#{$fa-css-prefix}-arrows-to-line::after, .fa-duotone.#{$fa-css-prefix}-arrows-to-line::after { content: "\e0a7\e0a7"; }
.fad.#{$fa-css-prefix}-arrows-up-down::after, .fa-duotone.#{$fa-css-prefix}-arrows-up-down::after { content: "\f07d\f07d"; }
.fad.#{$fa-css-prefix}-arrows-v::after, .fa-duotone.#{$fa-css-prefix}-arrows-v::after { content: "\f07d\f07d"; }
.fad.#{$fa-css-prefix}-arrows-up-down-left-right::after, .fa-duotone.#{$fa-css-prefix}-arrows-up-down-left-right::after { content: "\f047\f047"; }
.fad.#{$fa-css-prefix}-arrows::after, .fa-duotone.#{$fa-css-prefix}-arrows::after { content: "\f047\f047"; }
.fad.#{$fa-css-prefix}-asterisk::after, .fa-duotone.#{$fa-css-prefix}-asterisk::after { content: "\2a\2a"; }
.fad.#{$fa-css-prefix}-at::after, .fa-duotone.#{$fa-css-prefix}-at::after { content: "\40\40"; }
.fad.#{$fa-css-prefix}-atom::after, .fa-duotone.#{$fa-css-prefix}-atom::after { content: "\f5d2\f5d2"; }
.fad.#{$fa-css-prefix}-atom-simple::after, .fa-duotone.#{$fa-css-prefix}-atom-simple::after { content: "\f5d3\f5d3"; }
.fad.#{$fa-css-prefix}-atom-alt::after, .fa-duotone.#{$fa-css-prefix}-atom-alt::after { content: "\f5d3\f5d3"; }
.fad.#{$fa-css-prefix}-audio-description::after, .fa-duotone.#{$fa-css-prefix}-audio-description::after { content: "\f29e\f29e"; }
.fad.#{$fa-css-prefix}-audio-description-slash::after, .fa-duotone.#{$fa-css-prefix}-audio-description-slash::after { content: "\e0a8\e0a8"; }
.fad.#{$fa-css-prefix}-austral-sign::after, .fa-duotone.#{$fa-css-prefix}-austral-sign::after { content: "\e0a9\e0a9"; }
.fad.#{$fa-css-prefix}-avocado::after, .fa-duotone.#{$fa-css-prefix}-avocado::after { content: "\e0aa\e0aa"; }
.fad.#{$fa-css-prefix}-award::after, .fa-duotone.#{$fa-css-prefix}-award::after { content: "\f559\f559"; }
.fad.#{$fa-css-prefix}-award-simple::after, .fa-duotone.#{$fa-css-prefix}-award-simple::after { content: "\e0ab\e0ab"; }
.fad.#{$fa-css-prefix}-axe::after, .fa-duotone.#{$fa-css-prefix}-axe::after { content: "\f6b2\f6b2"; }
.fad.#{$fa-css-prefix}-axe-battle::after, .fa-duotone.#{$fa-css-prefix}-axe-battle::after { content: "\f6b3\f6b3"; }
.fad.#{$fa-css-prefix}-b::after, .fa-duotone.#{$fa-css-prefix}-b::after { content: "\42\42"; }
.fad.#{$fa-css-prefix}-baby::after, .fa-duotone.#{$fa-css-prefix}-baby::after { content: "\f77c\f77c"; }
.fad.#{$fa-css-prefix}-baby-carriage::after, .fa-duotone.#{$fa-css-prefix}-baby-carriage::after { content: "\f77d\f77d"; }
.fad.#{$fa-css-prefix}-carriage-baby::after, .fa-duotone.#{$fa-css-prefix}-carriage-baby::after { content: "\f77d\f77d"; }
.fad.#{$fa-css-prefix}-backpack::after, .fa-duotone.#{$fa-css-prefix}-backpack::after { content: "\f5d4\f5d4"; }
.fad.#{$fa-css-prefix}-backward::after, .fa-duotone.#{$fa-css-prefix}-backward::after { content: "\f04a\f04a"; }
.fad.#{$fa-css-prefix}-backward-fast::after, .fa-duotone.#{$fa-css-prefix}-backward-fast::after { content: "\f049\f049"; }
.fad.#{$fa-css-prefix}-fast-backward::after, .fa-duotone.#{$fa-css-prefix}-fast-backward::after { content: "\f049\f049"; }
.fad.#{$fa-css-prefix}-backward-step::after, .fa-duotone.#{$fa-css-prefix}-backward-step::after { content: "\f048\f048"; }
.fad.#{$fa-css-prefix}-step-backward::after, .fa-duotone.#{$fa-css-prefix}-step-backward::after { content: "\f048\f048"; }
.fad.#{$fa-css-prefix}-bacon::after, .fa-duotone.#{$fa-css-prefix}-bacon::after { content: "\f7e5\f7e5"; }
.fad.#{$fa-css-prefix}-bacteria::after, .fa-duotone.#{$fa-css-prefix}-bacteria::after { content: "\e059\e059"; }
.fad.#{$fa-css-prefix}-bacterium::after, .fa-duotone.#{$fa-css-prefix}-bacterium::after { content: "\e05a\e05a"; }
.fad.#{$fa-css-prefix}-badge::after, .fa-duotone.#{$fa-css-prefix}-badge::after { content: "\f335\f335"; }
.fad.#{$fa-css-prefix}-badge-check::after, .fa-duotone.#{$fa-css-prefix}-badge-check::after { content: "\f336\f336"; }
.fad.#{$fa-css-prefix}-badge-dollar::after, .fa-duotone.#{$fa-css-prefix}-badge-dollar::after { content: "\f645\f645"; }
.fad.#{$fa-css-prefix}-badge-percent::after, .fa-duotone.#{$fa-css-prefix}-badge-percent::after { content: "\f646\f646"; }
.fad.#{$fa-css-prefix}-badge-sheriff::after, .fa-duotone.#{$fa-css-prefix}-badge-sheriff::after { content: "\f8a2\f8a2"; }
.fad.#{$fa-css-prefix}-badger-honey::after, .fa-duotone.#{$fa-css-prefix}-badger-honey::after { content: "\f6b4\f6b4"; }
.fad.#{$fa-css-prefix}-badminton::after, .fa-duotone.#{$fa-css-prefix}-badminton::after { content: "\e33a\e33a"; }
.fad.#{$fa-css-prefix}-bag-shopping::after, .fa-duotone.#{$fa-css-prefix}-bag-shopping::after { content: "\f290\f290"; }
.fad.#{$fa-css-prefix}-shopping-bag::after, .fa-duotone.#{$fa-css-prefix}-shopping-bag::after { content: "\f290\f290"; }
.fad.#{$fa-css-prefix}-bagel::after, .fa-duotone.#{$fa-css-prefix}-bagel::after { content: "\e3d7\e3d7"; }
.fad.#{$fa-css-prefix}-bags-shopping::after, .fa-duotone.#{$fa-css-prefix}-bags-shopping::after { content: "\f847\f847"; }
.fad.#{$fa-css-prefix}-baguette::after, .fa-duotone.#{$fa-css-prefix}-baguette::after { content: "\e3d8\e3d8"; }
.fad.#{$fa-css-prefix}-bahai::after, .fa-duotone.#{$fa-css-prefix}-bahai::after { content: "\f666\f666"; }
.fad.#{$fa-css-prefix}-baht-sign::after, .fa-duotone.#{$fa-css-prefix}-baht-sign::after { content: "\e0ac\e0ac"; }
.fad.#{$fa-css-prefix}-ball-pile::after, .fa-duotone.#{$fa-css-prefix}-ball-pile::after { content: "\f77e\f77e"; }
.fad.#{$fa-css-prefix}-balloon::after, .fa-duotone.#{$fa-css-prefix}-balloon::after { content: "\e2e3\e2e3"; }
.fad.#{$fa-css-prefix}-balloons::after, .fa-duotone.#{$fa-css-prefix}-balloons::after { content: "\e2e4\e2e4"; }
.fad.#{$fa-css-prefix}-ballot::after, .fa-duotone.#{$fa-css-prefix}-ballot::after { content: "\f732\f732"; }
.fad.#{$fa-css-prefix}-ballot-check::after, .fa-duotone.#{$fa-css-prefix}-ballot-check::after { content: "\f733\f733"; }
.fad.#{$fa-css-prefix}-ban::after, .fa-duotone.#{$fa-css-prefix}-ban::after { content: "\f05e\f05e"; }
.fad.#{$fa-css-prefix}-cancel::after, .fa-duotone.#{$fa-css-prefix}-cancel::after { content: "\f05e\f05e"; }
.fad.#{$fa-css-prefix}-ban-bug::after, .fa-duotone.#{$fa-css-prefix}-ban-bug::after { content: "\f7f9\f7f9"; }
.fad.#{$fa-css-prefix}-debug::after, .fa-duotone.#{$fa-css-prefix}-debug::after { content: "\f7f9\f7f9"; }
.fad.#{$fa-css-prefix}-ban-parking::after, .fa-duotone.#{$fa-css-prefix}-ban-parking::after { content: "\f616\f616"; }
.fad.#{$fa-css-prefix}-parking-circle-slash::after, .fa-duotone.#{$fa-css-prefix}-parking-circle-slash::after { content: "\f616\f616"; }
.fad.#{$fa-css-prefix}-ban-smoking::after, .fa-duotone.#{$fa-css-prefix}-ban-smoking::after { content: "\f54d\f54d"; }
.fad.#{$fa-css-prefix}-smoking-ban::after, .fa-duotone.#{$fa-css-prefix}-smoking-ban::after { content: "\f54d\f54d"; }
.fad.#{$fa-css-prefix}-banana::after, .fa-duotone.#{$fa-css-prefix}-banana::after { content: "\e2e5\e2e5"; }
.fad.#{$fa-css-prefix}-bandage::after, .fa-duotone.#{$fa-css-prefix}-bandage::after { content: "\f462\f462"; }
.fad.#{$fa-css-prefix}-band-aid::after, .fa-duotone.#{$fa-css-prefix}-band-aid::after { content: "\f462\f462"; }
.fad.#{$fa-css-prefix}-bangladeshi-taka-sign::after, .fa-duotone.#{$fa-css-prefix}-bangladeshi-taka-sign::after { content: "\e2e6\e2e6"; }
.fad.#{$fa-css-prefix}-banjo::after, .fa-duotone.#{$fa-css-prefix}-banjo::after { content: "\f8a3\f8a3"; }
.fad.#{$fa-css-prefix}-barcode::after, .fa-duotone.#{$fa-css-prefix}-barcode::after { content: "\f02a\f02a"; }
.fad.#{$fa-css-prefix}-barcode-read::after, .fa-duotone.#{$fa-css-prefix}-barcode-read::after { content: "\f464\f464"; }
.fad.#{$fa-css-prefix}-barcode-scan::after, .fa-duotone.#{$fa-css-prefix}-barcode-scan::after { content: "\f465\f465"; }
.fad.#{$fa-css-prefix}-bars::after, .fa-duotone.#{$fa-css-prefix}-bars::after { content: "\f0c9\f0c9"; }
.fad.#{$fa-css-prefix}-navicon::after, .fa-duotone.#{$fa-css-prefix}-navicon::after { content: "\f0c9\f0c9"; }
.fad.#{$fa-css-prefix}-bars-filter::after, .fa-duotone.#{$fa-css-prefix}-bars-filter::after { content: "\e0ad\e0ad"; }
.fad.#{$fa-css-prefix}-bars-progress::after, .fa-duotone.#{$fa-css-prefix}-bars-progress::after { content: "\f828\f828"; }
.fad.#{$fa-css-prefix}-tasks-alt::after, .fa-duotone.#{$fa-css-prefix}-tasks-alt::after { content: "\f828\f828"; }
.fad.#{$fa-css-prefix}-bars-sort::after, .fa-duotone.#{$fa-css-prefix}-bars-sort::after { content: "\e0ae\e0ae"; }
.fad.#{$fa-css-prefix}-bars-staggered::after, .fa-duotone.#{$fa-css-prefix}-bars-staggered::after { content: "\f550\f550"; }
.fad.#{$fa-css-prefix}-reorder::after, .fa-duotone.#{$fa-css-prefix}-reorder::after { content: "\f550\f550"; }
.fad.#{$fa-css-prefix}-stream::after, .fa-duotone.#{$fa-css-prefix}-stream::after { content: "\f550\f550"; }
.fad.#{$fa-css-prefix}-baseball::after, .fa-duotone.#{$fa-css-prefix}-baseball::after { content: "\f433\f433"; }
.fad.#{$fa-css-prefix}-baseball-ball::after, .fa-duotone.#{$fa-css-prefix}-baseball-ball::after { content: "\f433\f433"; }
.fad.#{$fa-css-prefix}-baseball-bat-ball::after, .fa-duotone.#{$fa-css-prefix}-baseball-bat-ball::after { content: "\f432\f432"; }
.fad.#{$fa-css-prefix}-basket-shopping::after, .fa-duotone.#{$fa-css-prefix}-basket-shopping::after { content: "\f291\f291"; }
.fad.#{$fa-css-prefix}-shopping-basket::after, .fa-duotone.#{$fa-css-prefix}-shopping-basket::after { content: "\f291\f291"; }
.fad.#{$fa-css-prefix}-basket-shopping-simple::after, .fa-duotone.#{$fa-css-prefix}-basket-shopping-simple::after { content: "\e0af\e0af"; }
.fad.#{$fa-css-prefix}-shopping-basket-alt::after, .fa-duotone.#{$fa-css-prefix}-shopping-basket-alt::after { content: "\e0af\e0af"; }
.fad.#{$fa-css-prefix}-basketball::after, .fa-duotone.#{$fa-css-prefix}-basketball::after { content: "\f434\f434"; }
.fad.#{$fa-css-prefix}-basketball-ball::after, .fa-duotone.#{$fa-css-prefix}-basketball-ball::after { content: "\f434\f434"; }
.fad.#{$fa-css-prefix}-basketball-hoop::after, .fa-duotone.#{$fa-css-prefix}-basketball-hoop::after { content: "\f435\f435"; }
.fad.#{$fa-css-prefix}-bat::after, .fa-duotone.#{$fa-css-prefix}-bat::after { content: "\f6b5\f6b5"; }
.fad.#{$fa-css-prefix}-bath::after, .fa-duotone.#{$fa-css-prefix}-bath::after { content: "\f2cd\f2cd"; }
.fad.#{$fa-css-prefix}-bathtub::after, .fa-duotone.#{$fa-css-prefix}-bathtub::after { content: "\f2cd\f2cd"; }
.fad.#{$fa-css-prefix}-battery-bolt::after, .fa-duotone.#{$fa-css-prefix}-battery-bolt::after { content: "\f376\f376"; }
.fad.#{$fa-css-prefix}-battery-empty::after, .fa-duotone.#{$fa-css-prefix}-battery-empty::after { content: "\f244\f244"; }
.fad.#{$fa-css-prefix}-battery-0::after, .fa-duotone.#{$fa-css-prefix}-battery-0::after { content: "\f244\f244"; }
.fad.#{$fa-css-prefix}-battery-exclamation::after, .fa-duotone.#{$fa-css-prefix}-battery-exclamation::after { content: "\e0b0\e0b0"; }
.fad.#{$fa-css-prefix}-battery-full::after, .fa-duotone.#{$fa-css-prefix}-battery-full::after { content: "\f240\f240"; }
.fad.#{$fa-css-prefix}-battery::after, .fa-duotone.#{$fa-css-prefix}-battery::after { content: "\f240\f240"; }
.fad.#{$fa-css-prefix}-battery-5::after, .fa-duotone.#{$fa-css-prefix}-battery-5::after { content: "\f240\f240"; }
.fad.#{$fa-css-prefix}-battery-half::after, .fa-duotone.#{$fa-css-prefix}-battery-half::after { content: "\f242\f242"; }
.fad.#{$fa-css-prefix}-battery-3::after, .fa-duotone.#{$fa-css-prefix}-battery-3::after { content: "\f242\f242"; }
.fad.#{$fa-css-prefix}-battery-low::after, .fa-duotone.#{$fa-css-prefix}-battery-low::after { content: "\e0b1\e0b1"; }
.fad.#{$fa-css-prefix}-battery-1::after, .fa-duotone.#{$fa-css-prefix}-battery-1::after { content: "\e0b1\e0b1"; }
.fad.#{$fa-css-prefix}-battery-quarter::after, .fa-duotone.#{$fa-css-prefix}-battery-quarter::after { content: "\f243\f243"; }
.fad.#{$fa-css-prefix}-battery-2::after, .fa-duotone.#{$fa-css-prefix}-battery-2::after { content: "\f243\f243"; }
.fad.#{$fa-css-prefix}-battery-slash::after, .fa-duotone.#{$fa-css-prefix}-battery-slash::after { content: "\f377\f377"; }
.fad.#{$fa-css-prefix}-battery-three-quarters::after, .fa-duotone.#{$fa-css-prefix}-battery-three-quarters::after { content: "\f241\f241"; }
.fad.#{$fa-css-prefix}-battery-4::after, .fa-duotone.#{$fa-css-prefix}-battery-4::after { content: "\f241\f241"; }
.fad.#{$fa-css-prefix}-bed::after, .fa-duotone.#{$fa-css-prefix}-bed::after { content: "\f236\f236"; }
.fad.#{$fa-css-prefix}-bed-bunk::after, .fa-duotone.#{$fa-css-prefix}-bed-bunk::after { content: "\f8f8\f8f8"; }
.fad.#{$fa-css-prefix}-bed-empty::after, .fa-duotone.#{$fa-css-prefix}-bed-empty::after { content: "\f8f9\f8f9"; }
.fad.#{$fa-css-prefix}-bed-front::after, .fa-duotone.#{$fa-css-prefix}-bed-front::after { content: "\f8f7\f8f7"; }
.fad.#{$fa-css-prefix}-bed-alt::after, .fa-duotone.#{$fa-css-prefix}-bed-alt::after { content: "\f8f7\f8f7"; }
.fad.#{$fa-css-prefix}-bed-pulse::after, .fa-duotone.#{$fa-css-prefix}-bed-pulse::after { content: "\f487\f487"; }
.fad.#{$fa-css-prefix}-procedures::after, .fa-duotone.#{$fa-css-prefix}-procedures::after { content: "\f487\f487"; }
.fad.#{$fa-css-prefix}-bee::after, .fa-duotone.#{$fa-css-prefix}-bee::after { content: "\e0b2\e0b2"; }
.fad.#{$fa-css-prefix}-beer-mug::after, .fa-duotone.#{$fa-css-prefix}-beer-mug::after { content: "\e0b3\e0b3"; }
.fad.#{$fa-css-prefix}-beer-foam::after, .fa-duotone.#{$fa-css-prefix}-beer-foam::after { content: "\e0b3\e0b3"; }
.fad.#{$fa-css-prefix}-beer-mug-empty::after, .fa-duotone.#{$fa-css-prefix}-beer-mug-empty::after { content: "\f0fc\f0fc"; }
.fad.#{$fa-css-prefix}-beer::after, .fa-duotone.#{$fa-css-prefix}-beer::after { content: "\f0fc\f0fc"; }
.fad.#{$fa-css-prefix}-bell::after, .fa-duotone.#{$fa-css-prefix}-bell::after { content: "\f0f3\f0f3"; }
.fad.#{$fa-css-prefix}-bell-concierge::after, .fa-duotone.#{$fa-css-prefix}-bell-concierge::after { content: "\f562\f562"; }
.fad.#{$fa-css-prefix}-concierge-bell::after, .fa-duotone.#{$fa-css-prefix}-concierge-bell::after { content: "\f562\f562"; }
.fad.#{$fa-css-prefix}-bell-exclamation::after, .fa-duotone.#{$fa-css-prefix}-bell-exclamation::after { content: "\f848\f848"; }
.fad.#{$fa-css-prefix}-bell-on::after, .fa-duotone.#{$fa-css-prefix}-bell-on::after { content: "\f8fa\f8fa"; }
.fad.#{$fa-css-prefix}-bell-plus::after, .fa-duotone.#{$fa-css-prefix}-bell-plus::after { content: "\f849\f849"; }
.fad.#{$fa-css-prefix}-bell-school::after, .fa-duotone.#{$fa-css-prefix}-bell-school::after { content: "\f5d5\f5d5"; }
.fad.#{$fa-css-prefix}-bell-school-slash::after, .fa-duotone.#{$fa-css-prefix}-bell-school-slash::after { content: "\f5d6\f5d6"; }
.fad.#{$fa-css-prefix}-bell-slash::after, .fa-duotone.#{$fa-css-prefix}-bell-slash::after { content: "\f1f6\f1f6"; }
.fad.#{$fa-css-prefix}-bells::after, .fa-duotone.#{$fa-css-prefix}-bells::after { content: "\f77f\f77f"; }
.fad.#{$fa-css-prefix}-bench-tree::after, .fa-duotone.#{$fa-css-prefix}-bench-tree::after { content: "\e2e7\e2e7"; }
.fad.#{$fa-css-prefix}-bezier-curve::after, .fa-duotone.#{$fa-css-prefix}-bezier-curve::after { content: "\f55b\f55b"; }
.fad.#{$fa-css-prefix}-bicycle::after, .fa-duotone.#{$fa-css-prefix}-bicycle::after { content: "\f206\f206"; }
.fad.#{$fa-css-prefix}-binary::after, .fa-duotone.#{$fa-css-prefix}-binary::after { content: "\e33b\e33b"; }
.fad.#{$fa-css-prefix}-binary-circle-check::after, .fa-duotone.#{$fa-css-prefix}-binary-circle-check::after { content: "\e33c\e33c"; }
.fad.#{$fa-css-prefix}-binary-lock::after, .fa-duotone.#{$fa-css-prefix}-binary-lock::after { content: "\e33d\e33d"; }
.fad.#{$fa-css-prefix}-binary-slash::after, .fa-duotone.#{$fa-css-prefix}-binary-slash::after { content: "\e33e\e33e"; }
.fad.#{$fa-css-prefix}-binoculars::after, .fa-duotone.#{$fa-css-prefix}-binoculars::after { content: "\f1e5\f1e5"; }
.fad.#{$fa-css-prefix}-biohazard::after, .fa-duotone.#{$fa-css-prefix}-biohazard::after { content: "\f780\f780"; }
.fad.#{$fa-css-prefix}-bird::after, .fa-duotone.#{$fa-css-prefix}-bird::after { content: "\e469\e469"; }
.fad.#{$fa-css-prefix}-bitcoin-sign::after, .fa-duotone.#{$fa-css-prefix}-bitcoin-sign::after { content: "\e0b4\e0b4"; }
.fad.#{$fa-css-prefix}-blanket::after, .fa-duotone.#{$fa-css-prefix}-blanket::after { content: "\f498\f498"; }
.fad.#{$fa-css-prefix}-blanket-fire::after, .fa-duotone.#{$fa-css-prefix}-blanket-fire::after { content: "\e3da\e3da"; }
.fad.#{$fa-css-prefix}-blender::after, .fa-duotone.#{$fa-css-prefix}-blender::after { content: "\f517\f517"; }
.fad.#{$fa-css-prefix}-blender-phone::after, .fa-duotone.#{$fa-css-prefix}-blender-phone::after { content: "\f6b6\f6b6"; }
.fad.#{$fa-css-prefix}-blinds::after, .fa-duotone.#{$fa-css-prefix}-blinds::after { content: "\f8fb\f8fb"; }
.fad.#{$fa-css-prefix}-blinds-open::after, .fa-duotone.#{$fa-css-prefix}-blinds-open::after { content: "\f8fc\f8fc"; }
.fad.#{$fa-css-prefix}-blinds-raised::after, .fa-duotone.#{$fa-css-prefix}-blinds-raised::after { content: "\f8fd\f8fd"; }
.fad.#{$fa-css-prefix}-block::after, .fa-duotone.#{$fa-css-prefix}-block::after { content: "\e46a\e46a"; }
.fad.#{$fa-css-prefix}-block-brick::after, .fa-duotone.#{$fa-css-prefix}-block-brick::after { content: "\e3db\e3db"; }
.fad.#{$fa-css-prefix}-wall-brick::after, .fa-duotone.#{$fa-css-prefix}-wall-brick::after { content: "\e3db\e3db"; }
.fad.#{$fa-css-prefix}-block-brick-fire::after, .fa-duotone.#{$fa-css-prefix}-block-brick-fire::after { content: "\e3dc\e3dc"; }
.fad.#{$fa-css-prefix}-firewall::after, .fa-duotone.#{$fa-css-prefix}-firewall::after { content: "\e3dc\e3dc"; }
.fad.#{$fa-css-prefix}-block-question::after, .fa-duotone.#{$fa-css-prefix}-block-question::after { content: "\e3dd\e3dd"; }
.fad.#{$fa-css-prefix}-block-quote::after, .fa-duotone.#{$fa-css-prefix}-block-quote::after { content: "\e0b5\e0b5"; }
.fad.#{$fa-css-prefix}-blog::after, .fa-duotone.#{$fa-css-prefix}-blog::after { content: "\f781\f781"; }
.fad.#{$fa-css-prefix}-blueberries::after, .fa-duotone.#{$fa-css-prefix}-blueberries::after { content: "\e2e8\e2e8"; }
.fad.#{$fa-css-prefix}-bluetooth::after, .fa-duotone.#{$fa-css-prefix}-bluetooth::after { content: "\f293\f293"; }
.fad.#{$fa-css-prefix}-bold::after, .fa-duotone.#{$fa-css-prefix}-bold::after { content: "\f032\f032"; }
.fad.#{$fa-css-prefix}-bolt::after, .fa-duotone.#{$fa-css-prefix}-bolt::after { content: "\f0e7\f0e7"; }
.fad.#{$fa-css-prefix}-zap::after, .fa-duotone.#{$fa-css-prefix}-zap::after { content: "\f0e7\f0e7"; }
.fad.#{$fa-css-prefix}-bolt-auto::after, .fa-duotone.#{$fa-css-prefix}-bolt-auto::after { content: "\e0b6\e0b6"; }
.fad.#{$fa-css-prefix}-bolt-lightning::after, .fa-duotone.#{$fa-css-prefix}-bolt-lightning::after { content: "\e0b7\e0b7"; }
.fad.#{$fa-css-prefix}-bolt-slash::after, .fa-duotone.#{$fa-css-prefix}-bolt-slash::after { content: "\e0b8\e0b8"; }
.fad.#{$fa-css-prefix}-bomb::after, .fa-duotone.#{$fa-css-prefix}-bomb::after { content: "\f1e2\f1e2"; }
.fad.#{$fa-css-prefix}-bone::after, .fa-duotone.#{$fa-css-prefix}-bone::after { content: "\f5d7\f5d7"; }
.fad.#{$fa-css-prefix}-bone-break::after, .fa-duotone.#{$fa-css-prefix}-bone-break::after { content: "\f5d8\f5d8"; }
.fad.#{$fa-css-prefix}-bong::after, .fa-duotone.#{$fa-css-prefix}-bong::after { content: "\f55c\f55c"; }
.fad.#{$fa-css-prefix}-book::after, .fa-duotone.#{$fa-css-prefix}-book::after { content: "\f02d\f02d"; }
.fad.#{$fa-css-prefix}-book-arrow-right::after, .fa-duotone.#{$fa-css-prefix}-book-arrow-right::after { content: "\e0b9\e0b9"; }
.fad.#{$fa-css-prefix}-book-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-book-arrow-up::after { content: "\e0ba\e0ba"; }
.fad.#{$fa-css-prefix}-book-atlas::after, .fa-duotone.#{$fa-css-prefix}-book-atlas::after { content: "\f558\f558"; }
.fad.#{$fa-css-prefix}-atlas::after, .fa-duotone.#{$fa-css-prefix}-atlas::after { content: "\f558\f558"; }
.fad.#{$fa-css-prefix}-book-bible::after, .fa-duotone.#{$fa-css-prefix}-book-bible::after { content: "\f647\f647"; }
.fad.#{$fa-css-prefix}-bible::after, .fa-duotone.#{$fa-css-prefix}-bible::after { content: "\f647\f647"; }
.fad.#{$fa-css-prefix}-book-blank::after, .fa-duotone.#{$fa-css-prefix}-book-blank::after { content: "\f5d9\f5d9"; }
.fad.#{$fa-css-prefix}-book-alt::after, .fa-duotone.#{$fa-css-prefix}-book-alt::after { content: "\f5d9\f5d9"; }
.fad.#{$fa-css-prefix}-book-bookmark::after, .fa-duotone.#{$fa-css-prefix}-book-bookmark::after { content: "\e0bb\e0bb"; }
.fad.#{$fa-css-prefix}-book-circle-arrow-right::after, .fa-duotone.#{$fa-css-prefix}-book-circle-arrow-right::after { content: "\e0bc\e0bc"; }
.fad.#{$fa-css-prefix}-book-circle-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-book-circle-arrow-up::after { content: "\e0bd\e0bd"; }
.fad.#{$fa-css-prefix}-book-copy::after, .fa-duotone.#{$fa-css-prefix}-book-copy::after { content: "\e0be\e0be"; }
.fad.#{$fa-css-prefix}-book-font::after, .fa-duotone.#{$fa-css-prefix}-book-font::after { content: "\e0bf\e0bf"; }
.fad.#{$fa-css-prefix}-book-heart::after, .fa-duotone.#{$fa-css-prefix}-book-heart::after { content: "\f499\f499"; }
.fad.#{$fa-css-prefix}-book-journal-whills::after, .fa-duotone.#{$fa-css-prefix}-book-journal-whills::after { content: "\f66a\f66a"; }
.fad.#{$fa-css-prefix}-journal-whills::after, .fa-duotone.#{$fa-css-prefix}-journal-whills::after { content: "\f66a\f66a"; }
.fad.#{$fa-css-prefix}-book-medical::after, .fa-duotone.#{$fa-css-prefix}-book-medical::after { content: "\f7e6\f7e6"; }
.fad.#{$fa-css-prefix}-book-open::after, .fa-duotone.#{$fa-css-prefix}-book-open::after { content: "\f518\f518"; }
.fad.#{$fa-css-prefix}-book-open-cover::after, .fa-duotone.#{$fa-css-prefix}-book-open-cover::after { content: "\e0c0\e0c0"; }
.fad.#{$fa-css-prefix}-book-open-alt::after, .fa-duotone.#{$fa-css-prefix}-book-open-alt::after { content: "\e0c0\e0c0"; }
.fad.#{$fa-css-prefix}-book-open-reader::after, .fa-duotone.#{$fa-css-prefix}-book-open-reader::after { content: "\f5da\f5da"; }
.fad.#{$fa-css-prefix}-book-reader::after, .fa-duotone.#{$fa-css-prefix}-book-reader::after { content: "\f5da\f5da"; }
.fad.#{$fa-css-prefix}-book-quran::after, .fa-duotone.#{$fa-css-prefix}-book-quran::after { content: "\f687\f687"; }
.fad.#{$fa-css-prefix}-quran::after, .fa-duotone.#{$fa-css-prefix}-quran::after { content: "\f687\f687"; }
.fad.#{$fa-css-prefix}-book-section::after, .fa-duotone.#{$fa-css-prefix}-book-section::after { content: "\e0c1\e0c1"; }
.fad.#{$fa-css-prefix}-book-law::after, .fa-duotone.#{$fa-css-prefix}-book-law::after { content: "\e0c1\e0c1"; }
.fad.#{$fa-css-prefix}-book-skull::after, .fa-duotone.#{$fa-css-prefix}-book-skull::after { content: "\f6b7\f6b7"; }
.fad.#{$fa-css-prefix}-book-dead::after, .fa-duotone.#{$fa-css-prefix}-book-dead::after { content: "\f6b7\f6b7"; }
.fad.#{$fa-css-prefix}-book-sparkles::after, .fa-duotone.#{$fa-css-prefix}-book-sparkles::after { content: "\f6b8\f6b8"; }
.fad.#{$fa-css-prefix}-book-spells::after, .fa-duotone.#{$fa-css-prefix}-book-spells::after { content: "\f6b8\f6b8"; }
.fad.#{$fa-css-prefix}-book-tanakh::after, .fa-duotone.#{$fa-css-prefix}-book-tanakh::after { content: "\f827\f827"; }
.fad.#{$fa-css-prefix}-tanakh::after, .fa-duotone.#{$fa-css-prefix}-tanakh::after { content: "\f827\f827"; }
.fad.#{$fa-css-prefix}-book-user::after, .fa-duotone.#{$fa-css-prefix}-book-user::after { content: "\f7e7\f7e7"; }
.fad.#{$fa-css-prefix}-bookmark::after, .fa-duotone.#{$fa-css-prefix}-bookmark::after { content: "\f02e\f02e"; }
.fad.#{$fa-css-prefix}-bookmark-slash::after, .fa-duotone.#{$fa-css-prefix}-bookmark-slash::after { content: "\e0c2\e0c2"; }
.fad.#{$fa-css-prefix}-books::after, .fa-duotone.#{$fa-css-prefix}-books::after { content: "\f5db\f5db"; }
.fad.#{$fa-css-prefix}-books-medical::after, .fa-duotone.#{$fa-css-prefix}-books-medical::after { content: "\f7e8\f7e8"; }
.fad.#{$fa-css-prefix}-boombox::after, .fa-duotone.#{$fa-css-prefix}-boombox::after { content: "\f8a5\f8a5"; }
.fad.#{$fa-css-prefix}-boot::after, .fa-duotone.#{$fa-css-prefix}-boot::after { content: "\f782\f782"; }
.fad.#{$fa-css-prefix}-boot-heeled::after, .fa-duotone.#{$fa-css-prefix}-boot-heeled::after { content: "\e33f\e33f"; }
.fad.#{$fa-css-prefix}-booth-curtain::after, .fa-duotone.#{$fa-css-prefix}-booth-curtain::after { content: "\f734\f734"; }
.fad.#{$fa-css-prefix}-border-all::after, .fa-duotone.#{$fa-css-prefix}-border-all::after { content: "\f84c\f84c"; }
.fad.#{$fa-css-prefix}-border-bottom::after, .fa-duotone.#{$fa-css-prefix}-border-bottom::after { content: "\f84d\f84d"; }
.fad.#{$fa-css-prefix}-border-bottom-right::after, .fa-duotone.#{$fa-css-prefix}-border-bottom-right::after { content: "\f854\f854"; }
.fad.#{$fa-css-prefix}-border-style-alt::after, .fa-duotone.#{$fa-css-prefix}-border-style-alt::after { content: "\f854\f854"; }
.fad.#{$fa-css-prefix}-border-center-h::after, .fa-duotone.#{$fa-css-prefix}-border-center-h::after { content: "\f89c\f89c"; }
.fad.#{$fa-css-prefix}-border-center-v::after, .fa-duotone.#{$fa-css-prefix}-border-center-v::after { content: "\f89d\f89d"; }
.fad.#{$fa-css-prefix}-border-inner::after, .fa-duotone.#{$fa-css-prefix}-border-inner::after { content: "\f84e\f84e"; }
.fad.#{$fa-css-prefix}-border-left::after, .fa-duotone.#{$fa-css-prefix}-border-left::after { content: "\f84f\f84f"; }
.fad.#{$fa-css-prefix}-border-none::after, .fa-duotone.#{$fa-css-prefix}-border-none::after { content: "\f850\f850"; }
.fad.#{$fa-css-prefix}-border-outer::after, .fa-duotone.#{$fa-css-prefix}-border-outer::after { content: "\f851\f851"; }
.fad.#{$fa-css-prefix}-border-right::after, .fa-duotone.#{$fa-css-prefix}-border-right::after { content: "\f852\f852"; }
.fad.#{$fa-css-prefix}-border-top::after, .fa-duotone.#{$fa-css-prefix}-border-top::after { content: "\f855\f855"; }
.fad.#{$fa-css-prefix}-border-top-left::after, .fa-duotone.#{$fa-css-prefix}-border-top-left::after { content: "\f853\f853"; }
.fad.#{$fa-css-prefix}-border-style::after, .fa-duotone.#{$fa-css-prefix}-border-style::after { content: "\f853\f853"; }
.fad.#{$fa-css-prefix}-bow-arrow::after, .fa-duotone.#{$fa-css-prefix}-bow-arrow::after { content: "\f6b9\f6b9"; }
.fad.#{$fa-css-prefix}-bowl-chopsticks::after, .fa-duotone.#{$fa-css-prefix}-bowl-chopsticks::after { content: "\e2e9\e2e9"; }
.fad.#{$fa-css-prefix}-bowl-chopsticks-noodles::after, .fa-duotone.#{$fa-css-prefix}-bowl-chopsticks-noodles::after { content: "\e2ea\e2ea"; }
.fad.#{$fa-css-prefix}-bowl-hot::after, .fa-duotone.#{$fa-css-prefix}-bowl-hot::after { content: "\f823\f823"; }
.fad.#{$fa-css-prefix}-soup::after, .fa-duotone.#{$fa-css-prefix}-soup::after { content: "\f823\f823"; }
.fad.#{$fa-css-prefix}-bowl-rice::after, .fa-duotone.#{$fa-css-prefix}-bowl-rice::after { content: "\e2eb\e2eb"; }
.fad.#{$fa-css-prefix}-bowl-scoop::after, .fa-duotone.#{$fa-css-prefix}-bowl-scoop::after { content: "\e3de\e3de"; }
.fad.#{$fa-css-prefix}-bowl-shaved-ice::after, .fa-duotone.#{$fa-css-prefix}-bowl-shaved-ice::after { content: "\e3de\e3de"; }
.fad.#{$fa-css-prefix}-bowl-scoops::after, .fa-duotone.#{$fa-css-prefix}-bowl-scoops::after { content: "\e3df\e3df"; }
.fad.#{$fa-css-prefix}-bowl-soft-serve::after, .fa-duotone.#{$fa-css-prefix}-bowl-soft-serve::after { content: "\e46b\e46b"; }
.fad.#{$fa-css-prefix}-bowl-spoon::after, .fa-duotone.#{$fa-css-prefix}-bowl-spoon::after { content: "\e3e0\e3e0"; }
.fad.#{$fa-css-prefix}-bowling-ball::after, .fa-duotone.#{$fa-css-prefix}-bowling-ball::after { content: "\f436\f436"; }
.fad.#{$fa-css-prefix}-bowling-ball-pin::after, .fa-duotone.#{$fa-css-prefix}-bowling-ball-pin::after { content: "\e0c3\e0c3"; }
.fad.#{$fa-css-prefix}-bowling-pins::after, .fa-duotone.#{$fa-css-prefix}-bowling-pins::after { content: "\f437\f437"; }
.fad.#{$fa-css-prefix}-box::after, .fa-duotone.#{$fa-css-prefix}-box::after { content: "\f466\f466"; }
.fad.#{$fa-css-prefix}-box-archive::after, .fa-duotone.#{$fa-css-prefix}-box-archive::after { content: "\f187\f187"; }
.fad.#{$fa-css-prefix}-archive::after, .fa-duotone.#{$fa-css-prefix}-archive::after { content: "\f187\f187"; }
.fad.#{$fa-css-prefix}-box-ballot::after, .fa-duotone.#{$fa-css-prefix}-box-ballot::after { content: "\f735\f735"; }
.fad.#{$fa-css-prefix}-box-check::after, .fa-duotone.#{$fa-css-prefix}-box-check::after { content: "\f467\f467"; }
.fad.#{$fa-css-prefix}-box-circle-check::after, .fa-duotone.#{$fa-css-prefix}-box-circle-check::after { content: "\e0c4\e0c4"; }
.fad.#{$fa-css-prefix}-box-dollar::after, .fa-duotone.#{$fa-css-prefix}-box-dollar::after { content: "\f4a0\f4a0"; }
.fad.#{$fa-css-prefix}-box-usd::after, .fa-duotone.#{$fa-css-prefix}-box-usd::after { content: "\f4a0\f4a0"; }
.fad.#{$fa-css-prefix}-box-heart::after, .fa-duotone.#{$fa-css-prefix}-box-heart::after { content: "\f49d\f49d"; }
.fad.#{$fa-css-prefix}-box-open::after, .fa-duotone.#{$fa-css-prefix}-box-open::after { content: "\f49e\f49e"; }
.fad.#{$fa-css-prefix}-box-open-full::after, .fa-duotone.#{$fa-css-prefix}-box-open-full::after { content: "\f49c\f49c"; }
.fad.#{$fa-css-prefix}-box-full::after, .fa-duotone.#{$fa-css-prefix}-box-full::after { content: "\f49c\f49c"; }
.fad.#{$fa-css-prefix}-box-taped::after, .fa-duotone.#{$fa-css-prefix}-box-taped::after { content: "\f49a\f49a"; }
.fad.#{$fa-css-prefix}-box-alt::after, .fa-duotone.#{$fa-css-prefix}-box-alt::after { content: "\f49a\f49a"; }
.fad.#{$fa-css-prefix}-box-tissue::after, .fa-duotone.#{$fa-css-prefix}-box-tissue::after { content: "\e05b\e05b"; }
.fad.#{$fa-css-prefix}-boxes-stacked::after, .fa-duotone.#{$fa-css-prefix}-boxes-stacked::after { content: "\f468\f468"; }
.fad.#{$fa-css-prefix}-boxes::after, .fa-duotone.#{$fa-css-prefix}-boxes::after { content: "\f468\f468"; }
.fad.#{$fa-css-prefix}-boxes-alt::after, .fa-duotone.#{$fa-css-prefix}-boxes-alt::after { content: "\f468\f468"; }
.fad.#{$fa-css-prefix}-boxing-glove::after, .fa-duotone.#{$fa-css-prefix}-boxing-glove::after { content: "\f438\f438"; }
.fad.#{$fa-css-prefix}-glove-boxing::after, .fa-duotone.#{$fa-css-prefix}-glove-boxing::after { content: "\f438\f438"; }
.fad.#{$fa-css-prefix}-bracket-curly::after, .fa-duotone.#{$fa-css-prefix}-bracket-curly::after { content: "\7b\7b"; }
.fad.#{$fa-css-prefix}-bracket-curly-left::after, .fa-duotone.#{$fa-css-prefix}-bracket-curly-left::after { content: "\7b\7b"; }
.fad.#{$fa-css-prefix}-bracket-curly-right::after, .fa-duotone.#{$fa-css-prefix}-bracket-curly-right::after { content: "\7d\7d"; }
.fad.#{$fa-css-prefix}-bracket-round::after, .fa-duotone.#{$fa-css-prefix}-bracket-round::after { content: "\28\28"; }
.fad.#{$fa-css-prefix}-parenthesis::after, .fa-duotone.#{$fa-css-prefix}-parenthesis::after { content: "\28\28"; }
.fad.#{$fa-css-prefix}-bracket-round-right::after, .fa-duotone.#{$fa-css-prefix}-bracket-round-right::after { content: "\29\29"; }
.fad.#{$fa-css-prefix}-bracket-square::after, .fa-duotone.#{$fa-css-prefix}-bracket-square::after { content: "\5b\5b"; }
.fad.#{$fa-css-prefix}-bracket::after, .fa-duotone.#{$fa-css-prefix}-bracket::after { content: "\5b\5b"; }
.fad.#{$fa-css-prefix}-bracket-left::after, .fa-duotone.#{$fa-css-prefix}-bracket-left::after { content: "\5b\5b"; }
.fad.#{$fa-css-prefix}-bracket-square-right::after, .fa-duotone.#{$fa-css-prefix}-bracket-square-right::after { content: "\5d\5d"; }
.fad.#{$fa-css-prefix}-brackets-curly::after, .fa-duotone.#{$fa-css-prefix}-brackets-curly::after { content: "\f7ea\f7ea"; }
.fad.#{$fa-css-prefix}-brackets-round::after, .fa-duotone.#{$fa-css-prefix}-brackets-round::after { content: "\e0c5\e0c5"; }
.fad.#{$fa-css-prefix}-parentheses::after, .fa-duotone.#{$fa-css-prefix}-parentheses::after { content: "\e0c5\e0c5"; }
.fad.#{$fa-css-prefix}-brackets-square::after, .fa-duotone.#{$fa-css-prefix}-brackets-square::after { content: "\f7e9\f7e9"; }
.fad.#{$fa-css-prefix}-brackets::after, .fa-duotone.#{$fa-css-prefix}-brackets::after { content: "\f7e9\f7e9"; }
.fad.#{$fa-css-prefix}-braille::after, .fa-duotone.#{$fa-css-prefix}-braille::after { content: "\f2a1\f2a1"; }
.fad.#{$fa-css-prefix}-brain::after, .fa-duotone.#{$fa-css-prefix}-brain::after { content: "\f5dc\f5dc"; }
.fad.#{$fa-css-prefix}-brain-arrow-curved-right::after, .fa-duotone.#{$fa-css-prefix}-brain-arrow-curved-right::after { content: "\f677\f677"; }
.fad.#{$fa-css-prefix}-mind-share::after, .fa-duotone.#{$fa-css-prefix}-mind-share::after { content: "\f677\f677"; }
.fad.#{$fa-css-prefix}-brain-circuit::after, .fa-duotone.#{$fa-css-prefix}-brain-circuit::after { content: "\e0c6\e0c6"; }
.fad.#{$fa-css-prefix}-brake-warning::after, .fa-duotone.#{$fa-css-prefix}-brake-warning::after { content: "\e0c7\e0c7"; }
.fad.#{$fa-css-prefix}-brazilian-real-sign::after, .fa-duotone.#{$fa-css-prefix}-brazilian-real-sign::after { content: "\e46c\e46c"; }
.fad.#{$fa-css-prefix}-bread-loaf::after, .fa-duotone.#{$fa-css-prefix}-bread-loaf::after { content: "\f7eb\f7eb"; }
.fad.#{$fa-css-prefix}-bread-slice::after, .fa-duotone.#{$fa-css-prefix}-bread-slice::after { content: "\f7ec\f7ec"; }
.fad.#{$fa-css-prefix}-bread-slice-butter::after, .fa-duotone.#{$fa-css-prefix}-bread-slice-butter::after { content: "\e3e1\e3e1"; }
.fad.#{$fa-css-prefix}-briefcase::after, .fa-duotone.#{$fa-css-prefix}-briefcase::after { content: "\f0b1\f0b1"; }
.fad.#{$fa-css-prefix}-briefcase-arrow-right::after, .fa-duotone.#{$fa-css-prefix}-briefcase-arrow-right::after { content: "\e2f2\e2f2"; }
.fad.#{$fa-css-prefix}-briefcase-blank::after, .fa-duotone.#{$fa-css-prefix}-briefcase-blank::after { content: "\e0c8\e0c8"; }
.fad.#{$fa-css-prefix}-briefcase-medical::after, .fa-duotone.#{$fa-css-prefix}-briefcase-medical::after { content: "\f469\f469"; }
.fad.#{$fa-css-prefix}-brightness::after, .fa-duotone.#{$fa-css-prefix}-brightness::after { content: "\e0c9\e0c9"; }
.fad.#{$fa-css-prefix}-brightness-low::after, .fa-duotone.#{$fa-css-prefix}-brightness-low::after { content: "\e0ca\e0ca"; }
.fad.#{$fa-css-prefix}-bring-forward::after, .fa-duotone.#{$fa-css-prefix}-bring-forward::after { content: "\f856\f856"; }
.fad.#{$fa-css-prefix}-bring-front::after, .fa-duotone.#{$fa-css-prefix}-bring-front::after { content: "\f857\f857"; }
.fad.#{$fa-css-prefix}-broccoli::after, .fa-duotone.#{$fa-css-prefix}-broccoli::after { content: "\e3e2\e3e2"; }
.fad.#{$fa-css-prefix}-broom::after, .fa-duotone.#{$fa-css-prefix}-broom::after { content: "\f51a\f51a"; }
.fad.#{$fa-css-prefix}-broom-ball::after, .fa-duotone.#{$fa-css-prefix}-broom-ball::after { content: "\f458\f458"; }
.fad.#{$fa-css-prefix}-quidditch::after, .fa-duotone.#{$fa-css-prefix}-quidditch::after { content: "\f458\f458"; }
.fad.#{$fa-css-prefix}-quidditch-broom-ball::after, .fa-duotone.#{$fa-css-prefix}-quidditch-broom-ball::after { content: "\f458\f458"; }
.fad.#{$fa-css-prefix}-browser::after, .fa-duotone.#{$fa-css-prefix}-browser::after { content: "\f37e\f37e"; }
.fad.#{$fa-css-prefix}-browsers::after, .fa-duotone.#{$fa-css-prefix}-browsers::after { content: "\e0cb\e0cb"; }
.fad.#{$fa-css-prefix}-brush::after, .fa-duotone.#{$fa-css-prefix}-brush::after { content: "\f55d\f55d"; }
.fad.#{$fa-css-prefix}-bug::after, .fa-duotone.#{$fa-css-prefix}-bug::after { content: "\f188\f188"; }
.fad.#{$fa-css-prefix}-bug-slash::after, .fa-duotone.#{$fa-css-prefix}-bug-slash::after { content: "\e490\e490"; }
.fad.#{$fa-css-prefix}-building::after, .fa-duotone.#{$fa-css-prefix}-building::after { content: "\f1ad\f1ad"; }
.fad.#{$fa-css-prefix}-building-columns::after, .fa-duotone.#{$fa-css-prefix}-building-columns::after { content: "\f19c\f19c"; }
.fad.#{$fa-css-prefix}-bank::after, .fa-duotone.#{$fa-css-prefix}-bank::after { content: "\f19c\f19c"; }
.fad.#{$fa-css-prefix}-institution::after, .fa-duotone.#{$fa-css-prefix}-institution::after { content: "\f19c\f19c"; }
.fad.#{$fa-css-prefix}-museum::after, .fa-duotone.#{$fa-css-prefix}-museum::after { content: "\f19c\f19c"; }
.fad.#{$fa-css-prefix}-university::after, .fa-duotone.#{$fa-css-prefix}-university::after { content: "\f19c\f19c"; }
.fad.#{$fa-css-prefix}-buildings::after, .fa-duotone.#{$fa-css-prefix}-buildings::after { content: "\e0cc\e0cc"; }
.fad.#{$fa-css-prefix}-bullhorn::after, .fa-duotone.#{$fa-css-prefix}-bullhorn::after { content: "\f0a1\f0a1"; }
.fad.#{$fa-css-prefix}-bullseye::after, .fa-duotone.#{$fa-css-prefix}-bullseye::after { content: "\f140\f140"; }
.fad.#{$fa-css-prefix}-bullseye-arrow::after, .fa-duotone.#{$fa-css-prefix}-bullseye-arrow::after { content: "\f648\f648"; }
.fad.#{$fa-css-prefix}-bullseye-pointer::after, .fa-duotone.#{$fa-css-prefix}-bullseye-pointer::after { content: "\f649\f649"; }
.fad.#{$fa-css-prefix}-burger::after, .fa-duotone.#{$fa-css-prefix}-burger::after { content: "\f805\f805"; }
.fad.#{$fa-css-prefix}-hamburger::after, .fa-duotone.#{$fa-css-prefix}-hamburger::after { content: "\f805\f805"; }
.fad.#{$fa-css-prefix}-burger-cheese::after, .fa-duotone.#{$fa-css-prefix}-burger-cheese::after { content: "\f7f1\f7f1"; }
.fad.#{$fa-css-prefix}-cheeseburger::after, .fa-duotone.#{$fa-css-prefix}-cheeseburger::after { content: "\f7f1\f7f1"; }
.fad.#{$fa-css-prefix}-burger-fries::after, .fa-duotone.#{$fa-css-prefix}-burger-fries::after { content: "\e0cd\e0cd"; }
.fad.#{$fa-css-prefix}-burger-glass::after, .fa-duotone.#{$fa-css-prefix}-burger-glass::after { content: "\e0ce\e0ce"; }
.fad.#{$fa-css-prefix}-burger-lettuce::after, .fa-duotone.#{$fa-css-prefix}-burger-lettuce::after { content: "\e3e3\e3e3"; }
.fad.#{$fa-css-prefix}-burger-soda::after, .fa-duotone.#{$fa-css-prefix}-burger-soda::after { content: "\f858\f858"; }
.fad.#{$fa-css-prefix}-burrito::after, .fa-duotone.#{$fa-css-prefix}-burrito::after { content: "\f7ed\f7ed"; }
.fad.#{$fa-css-prefix}-bus::after, .fa-duotone.#{$fa-css-prefix}-bus::after { content: "\f207\f207"; }
.fad.#{$fa-css-prefix}-bus-school::after, .fa-duotone.#{$fa-css-prefix}-bus-school::after { content: "\f5dd\f5dd"; }
.fad.#{$fa-css-prefix}-bus-simple::after, .fa-duotone.#{$fa-css-prefix}-bus-simple::after { content: "\f55e\f55e"; }
.fad.#{$fa-css-prefix}-bus-alt::after, .fa-duotone.#{$fa-css-prefix}-bus-alt::after { content: "\f55e\f55e"; }
.fad.#{$fa-css-prefix}-business-time::after, .fa-duotone.#{$fa-css-prefix}-business-time::after { content: "\f64a\f64a"; }
.fad.#{$fa-css-prefix}-briefcase-clock::after, .fa-duotone.#{$fa-css-prefix}-briefcase-clock::after { content: "\f64a\f64a"; }
.fad.#{$fa-css-prefix}-butter::after, .fa-duotone.#{$fa-css-prefix}-butter::after { content: "\e3e4\e3e4"; }
.fad.#{$fa-css-prefix}-c::after, .fa-duotone.#{$fa-css-prefix}-c::after { content: "\43\43"; }
.fad.#{$fa-css-prefix}-cabin::after, .fa-duotone.#{$fa-css-prefix}-cabin::after { content: "\e46d\e46d"; }
.fad.#{$fa-css-prefix}-cabinet-filing::after, .fa-duotone.#{$fa-css-prefix}-cabinet-filing::after { content: "\f64b\f64b"; }
.fad.#{$fa-css-prefix}-cable-car::after, .fa-duotone.#{$fa-css-prefix}-cable-car::after { content: "\e0cf\e0cf"; }
.fad.#{$fa-css-prefix}-cactus::after, .fa-duotone.#{$fa-css-prefix}-cactus::after { content: "\f8a7\f8a7"; }
.fad.#{$fa-css-prefix}-cake-candles::after, .fa-duotone.#{$fa-css-prefix}-cake-candles::after { content: "\f1fd\f1fd"; }
.fad.#{$fa-css-prefix}-birthday-cake::after, .fa-duotone.#{$fa-css-prefix}-birthday-cake::after { content: "\f1fd\f1fd"; }
.fad.#{$fa-css-prefix}-cake::after, .fa-duotone.#{$fa-css-prefix}-cake::after { content: "\f1fd\f1fd"; }
.fad.#{$fa-css-prefix}-cake-slice::after, .fa-duotone.#{$fa-css-prefix}-cake-slice::after { content: "\e3e5\e3e5"; }
.fad.#{$fa-css-prefix}-shortcake::after, .fa-duotone.#{$fa-css-prefix}-shortcake::after { content: "\e3e5\e3e5"; }
.fad.#{$fa-css-prefix}-calculator::after, .fa-duotone.#{$fa-css-prefix}-calculator::after { content: "\f1ec\f1ec"; }
.fad.#{$fa-css-prefix}-calculator-simple::after, .fa-duotone.#{$fa-css-prefix}-calculator-simple::after { content: "\f64c\f64c"; }
.fad.#{$fa-css-prefix}-calculator-alt::after, .fa-duotone.#{$fa-css-prefix}-calculator-alt::after { content: "\f64c\f64c"; }
.fad.#{$fa-css-prefix}-calendar::after, .fa-duotone.#{$fa-css-prefix}-calendar::after { content: "\f133\f133"; }
.fad.#{$fa-css-prefix}-calendar-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-calendar-arrow-down::after { content: "\e0d0\e0d0"; }
.fad.#{$fa-css-prefix}-calendar-download::after, .fa-duotone.#{$fa-css-prefix}-calendar-download::after { content: "\e0d0\e0d0"; }
.fad.#{$fa-css-prefix}-calendar-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-calendar-arrow-up::after { content: "\e0d1\e0d1"; }
.fad.#{$fa-css-prefix}-calendar-upload::after, .fa-duotone.#{$fa-css-prefix}-calendar-upload::after { content: "\e0d1\e0d1"; }
.fad.#{$fa-css-prefix}-calendar-check::after, .fa-duotone.#{$fa-css-prefix}-calendar-check::after { content: "\f274\f274"; }
.fad.#{$fa-css-prefix}-calendar-circle-exclamation::after, .fa-duotone.#{$fa-css-prefix}-calendar-circle-exclamation::after { content: "\e46e\e46e"; }
.fad.#{$fa-css-prefix}-calendar-circle-minus::after, .fa-duotone.#{$fa-css-prefix}-calendar-circle-minus::after { content: "\e46f\e46f"; }
.fad.#{$fa-css-prefix}-calendar-circle-plus::after, .fa-duotone.#{$fa-css-prefix}-calendar-circle-plus::after { content: "\e470\e470"; }
.fad.#{$fa-css-prefix}-calendar-circle-user::after, .fa-duotone.#{$fa-css-prefix}-calendar-circle-user::after { content: "\e471\e471"; }
.fad.#{$fa-css-prefix}-calendar-clock::after, .fa-duotone.#{$fa-css-prefix}-calendar-clock::after { content: "\e0d2\e0d2"; }
.fad.#{$fa-css-prefix}-calendar-time::after, .fa-duotone.#{$fa-css-prefix}-calendar-time::after { content: "\e0d2\e0d2"; }
.fad.#{$fa-css-prefix}-calendar-day::after, .fa-duotone.#{$fa-css-prefix}-calendar-day::after { content: "\f783\f783"; }
.fad.#{$fa-css-prefix}-calendar-days::after, .fa-duotone.#{$fa-css-prefix}-calendar-days::after { content: "\f073\f073"; }
.fad.#{$fa-css-prefix}-calendar-alt::after, .fa-duotone.#{$fa-css-prefix}-calendar-alt::after { content: "\f073\f073"; }
.fad.#{$fa-css-prefix}-calendar-exclamation::after, .fa-duotone.#{$fa-css-prefix}-calendar-exclamation::after { content: "\f334\f334"; }
.fad.#{$fa-css-prefix}-calendar-heart::after, .fa-duotone.#{$fa-css-prefix}-calendar-heart::after { content: "\e0d3\e0d3"; }
.fad.#{$fa-css-prefix}-calendar-image::after, .fa-duotone.#{$fa-css-prefix}-calendar-image::after { content: "\e0d4\e0d4"; }
.fad.#{$fa-css-prefix}-calendar-lines::after, .fa-duotone.#{$fa-css-prefix}-calendar-lines::after { content: "\e0d5\e0d5"; }
.fad.#{$fa-css-prefix}-calendar-note::after, .fa-duotone.#{$fa-css-prefix}-calendar-note::after { content: "\e0d5\e0d5"; }
.fad.#{$fa-css-prefix}-calendar-lines-pen::after, .fa-duotone.#{$fa-css-prefix}-calendar-lines-pen::after { content: "\e472\e472"; }
.fad.#{$fa-css-prefix}-calendar-minus::after, .fa-duotone.#{$fa-css-prefix}-calendar-minus::after { content: "\f272\f272"; }
.fad.#{$fa-css-prefix}-calendar-pen::after, .fa-duotone.#{$fa-css-prefix}-calendar-pen::after { content: "\f333\f333"; }
.fad.#{$fa-css-prefix}-calendar-edit::after, .fa-duotone.#{$fa-css-prefix}-calendar-edit::after { content: "\f333\f333"; }
.fad.#{$fa-css-prefix}-calendar-plus::after, .fa-duotone.#{$fa-css-prefix}-calendar-plus::after { content: "\f271\f271"; }
.fad.#{$fa-css-prefix}-calendar-range::after, .fa-duotone.#{$fa-css-prefix}-calendar-range::after { content: "\e0d6\e0d6"; }
.fad.#{$fa-css-prefix}-calendar-star::after, .fa-duotone.#{$fa-css-prefix}-calendar-star::after { content: "\f736\f736"; }
.fad.#{$fa-css-prefix}-calendar-week::after, .fa-duotone.#{$fa-css-prefix}-calendar-week::after { content: "\f784\f784"; }
.fad.#{$fa-css-prefix}-calendar-xmark::after, .fa-duotone.#{$fa-css-prefix}-calendar-xmark::after { content: "\f273\f273"; }
.fad.#{$fa-css-prefix}-calendar-times::after, .fa-duotone.#{$fa-css-prefix}-calendar-times::after { content: "\f273\f273"; }
.fad.#{$fa-css-prefix}-calendars::after, .fa-duotone.#{$fa-css-prefix}-calendars::after { content: "\e0d7\e0d7"; }
.fad.#{$fa-css-prefix}-camcorder::after, .fa-duotone.#{$fa-css-prefix}-camcorder::after { content: "\f8a8\f8a8"; }
.fad.#{$fa-css-prefix}-video-handheld::after, .fa-duotone.#{$fa-css-prefix}-video-handheld::after { content: "\f8a8\f8a8"; }
.fad.#{$fa-css-prefix}-camera::after, .fa-duotone.#{$fa-css-prefix}-camera::after { content: "\f030\f030"; }
.fad.#{$fa-css-prefix}-camera-alt::after, .fa-duotone.#{$fa-css-prefix}-camera-alt::after { content: "\f030\f030"; }
.fad.#{$fa-css-prefix}-camera-cctv::after, .fa-duotone.#{$fa-css-prefix}-camera-cctv::after { content: "\f8ac\f8ac"; }
.fad.#{$fa-css-prefix}-cctv::after, .fa-duotone.#{$fa-css-prefix}-cctv::after { content: "\f8ac\f8ac"; }
.fad.#{$fa-css-prefix}-camera-movie::after, .fa-duotone.#{$fa-css-prefix}-camera-movie::after { content: "\f8a9\f8a9"; }
.fad.#{$fa-css-prefix}-camera-polaroid::after, .fa-duotone.#{$fa-css-prefix}-camera-polaroid::after { content: "\f8aa\f8aa"; }
.fad.#{$fa-css-prefix}-camera-retro::after, .fa-duotone.#{$fa-css-prefix}-camera-retro::after { content: "\f083\f083"; }
.fad.#{$fa-css-prefix}-camera-rotate::after, .fa-duotone.#{$fa-css-prefix}-camera-rotate::after { content: "\e0d8\e0d8"; }
.fad.#{$fa-css-prefix}-camera-security::after, .fa-duotone.#{$fa-css-prefix}-camera-security::after { content: "\f8fe\f8fe"; }
.fad.#{$fa-css-prefix}-camera-home::after, .fa-duotone.#{$fa-css-prefix}-camera-home::after { content: "\f8fe\f8fe"; }
.fad.#{$fa-css-prefix}-camera-slash::after, .fa-duotone.#{$fa-css-prefix}-camera-slash::after { content: "\e0d9\e0d9"; }
.fad.#{$fa-css-prefix}-camera-viewfinder::after, .fa-duotone.#{$fa-css-prefix}-camera-viewfinder::after { content: "\e0da\e0da"; }
.fad.#{$fa-css-prefix}-screenshot::after, .fa-duotone.#{$fa-css-prefix}-screenshot::after { content: "\e0da\e0da"; }
.fad.#{$fa-css-prefix}-camera-web::after, .fa-duotone.#{$fa-css-prefix}-camera-web::after { content: "\f832\f832"; }
.fad.#{$fa-css-prefix}-webcam::after, .fa-duotone.#{$fa-css-prefix}-webcam::after { content: "\f832\f832"; }
.fad.#{$fa-css-prefix}-camera-web-slash::after, .fa-duotone.#{$fa-css-prefix}-camera-web-slash::after { content: "\f833\f833"; }
.fad.#{$fa-css-prefix}-webcam-slash::after, .fa-duotone.#{$fa-css-prefix}-webcam-slash::after { content: "\f833\f833"; }
.fad.#{$fa-css-prefix}-campfire::after, .fa-duotone.#{$fa-css-prefix}-campfire::after { content: "\f6ba\f6ba"; }
.fad.#{$fa-css-prefix}-campground::after, .fa-duotone.#{$fa-css-prefix}-campground::after { content: "\f6bb\f6bb"; }
.fad.#{$fa-css-prefix}-can-food::after, .fa-duotone.#{$fa-css-prefix}-can-food::after { content: "\e3e6\e3e6"; }
.fad.#{$fa-css-prefix}-candle-holder::after, .fa-duotone.#{$fa-css-prefix}-candle-holder::after { content: "\f6bc\f6bc"; }
.fad.#{$fa-css-prefix}-candy::after, .fa-duotone.#{$fa-css-prefix}-candy::after { content: "\e3e7\e3e7"; }
.fad.#{$fa-css-prefix}-candy-bar::after, .fa-duotone.#{$fa-css-prefix}-candy-bar::after { content: "\e3e8\e3e8"; }
.fad.#{$fa-css-prefix}-chocolate-bar::after, .fa-duotone.#{$fa-css-prefix}-chocolate-bar::after { content: "\e3e8\e3e8"; }
.fad.#{$fa-css-prefix}-candy-cane::after, .fa-duotone.#{$fa-css-prefix}-candy-cane::after { content: "\f786\f786"; }
.fad.#{$fa-css-prefix}-candy-corn::after, .fa-duotone.#{$fa-css-prefix}-candy-corn::after { content: "\f6bd\f6bd"; }
.fad.#{$fa-css-prefix}-cannabis::after, .fa-duotone.#{$fa-css-prefix}-cannabis::after { content: "\f55f\f55f"; }
.fad.#{$fa-css-prefix}-capsules::after, .fa-duotone.#{$fa-css-prefix}-capsules::after { content: "\f46b\f46b"; }
.fad.#{$fa-css-prefix}-car::after, .fa-duotone.#{$fa-css-prefix}-car::after { content: "\f1b9\f1b9"; }
.fad.#{$fa-css-prefix}-automobile::after, .fa-duotone.#{$fa-css-prefix}-automobile::after { content: "\f1b9\f1b9"; }
.fad.#{$fa-css-prefix}-car-battery::after, .fa-duotone.#{$fa-css-prefix}-car-battery::after { content: "\f5df\f5df"; }
.fad.#{$fa-css-prefix}-battery-car::after, .fa-duotone.#{$fa-css-prefix}-battery-car::after { content: "\f5df\f5df"; }
.fad.#{$fa-css-prefix}-car-bolt::after, .fa-duotone.#{$fa-css-prefix}-car-bolt::after { content: "\e341\e341"; }
.fad.#{$fa-css-prefix}-car-building::after, .fa-duotone.#{$fa-css-prefix}-car-building::after { content: "\f859\f859"; }
.fad.#{$fa-css-prefix}-car-bump::after, .fa-duotone.#{$fa-css-prefix}-car-bump::after { content: "\f5e0\f5e0"; }
.fad.#{$fa-css-prefix}-car-bus::after, .fa-duotone.#{$fa-css-prefix}-car-bus::after { content: "\f85a\f85a"; }
.fad.#{$fa-css-prefix}-car-circle-bolt::after, .fa-duotone.#{$fa-css-prefix}-car-circle-bolt::after { content: "\e342\e342"; }
.fad.#{$fa-css-prefix}-car-crash::after, .fa-duotone.#{$fa-css-prefix}-car-crash::after { content: "\f5e1\f5e1"; }
.fad.#{$fa-css-prefix}-car-garage::after, .fa-duotone.#{$fa-css-prefix}-car-garage::after { content: "\f5e2\f5e2"; }
.fad.#{$fa-css-prefix}-car-mirrors::after, .fa-duotone.#{$fa-css-prefix}-car-mirrors::after { content: "\e343\e343"; }
.fad.#{$fa-css-prefix}-car-rear::after, .fa-duotone.#{$fa-css-prefix}-car-rear::after { content: "\f5de\f5de"; }
.fad.#{$fa-css-prefix}-car-alt::after, .fa-duotone.#{$fa-css-prefix}-car-alt::after { content: "\f5de\f5de"; }
.fad.#{$fa-css-prefix}-car-side::after, .fa-duotone.#{$fa-css-prefix}-car-side::after { content: "\f5e4\f5e4"; }
.fad.#{$fa-css-prefix}-car-side-bolt::after, .fa-duotone.#{$fa-css-prefix}-car-side-bolt::after { content: "\e344\e344"; }
.fad.#{$fa-css-prefix}-car-tilt::after, .fa-duotone.#{$fa-css-prefix}-car-tilt::after { content: "\f5e5\f5e5"; }
.fad.#{$fa-css-prefix}-car-wash::after, .fa-duotone.#{$fa-css-prefix}-car-wash::after { content: "\f5e6\f5e6"; }
.fad.#{$fa-css-prefix}-car-wrench::after, .fa-duotone.#{$fa-css-prefix}-car-wrench::after { content: "\f5e3\f5e3"; }
.fad.#{$fa-css-prefix}-car-mechanic::after, .fa-duotone.#{$fa-css-prefix}-car-mechanic::after { content: "\f5e3\f5e3"; }
.fad.#{$fa-css-prefix}-caravan::after, .fa-duotone.#{$fa-css-prefix}-caravan::after { content: "\f8ff\f8ff"; }
.fad.#{$fa-css-prefix}-caravan-simple::after, .fa-duotone.#{$fa-css-prefix}-caravan-simple::after { content: "\e000\e000"; }
.fad.#{$fa-css-prefix}-caravan-alt::after, .fa-duotone.#{$fa-css-prefix}-caravan-alt::after { content: "\e000\e000"; }
.fad.#{$fa-css-prefix}-card-club::after, .fa-duotone.#{$fa-css-prefix}-card-club::after { content: "\e3e9\e3e9"; }
.fad.#{$fa-css-prefix}-card-diamond::after, .fa-duotone.#{$fa-css-prefix}-card-diamond::after { content: "\e3ea\e3ea"; }
.fad.#{$fa-css-prefix}-card-heart::after, .fa-duotone.#{$fa-css-prefix}-card-heart::after { content: "\e3eb\e3eb"; }
.fad.#{$fa-css-prefix}-card-spade::after, .fa-duotone.#{$fa-css-prefix}-card-spade::after { content: "\e3ec\e3ec"; }
.fad.#{$fa-css-prefix}-cards::after, .fa-duotone.#{$fa-css-prefix}-cards::after { content: "\e3ed\e3ed"; }
.fad.#{$fa-css-prefix}-caret-down::after, .fa-duotone.#{$fa-css-prefix}-caret-down::after { content: "\f0d7\f0d7"; }
.fad.#{$fa-css-prefix}-caret-left::after, .fa-duotone.#{$fa-css-prefix}-caret-left::after { content: "\f0d9\f0d9"; }
.fad.#{$fa-css-prefix}-caret-right::after, .fa-duotone.#{$fa-css-prefix}-caret-right::after { content: "\f0da\f0da"; }
.fad.#{$fa-css-prefix}-caret-up::after, .fa-duotone.#{$fa-css-prefix}-caret-up::after { content: "\f0d8\f0d8"; }
.fad.#{$fa-css-prefix}-carrot::after, .fa-duotone.#{$fa-css-prefix}-carrot::after { content: "\f787\f787"; }
.fad.#{$fa-css-prefix}-cars::after, .fa-duotone.#{$fa-css-prefix}-cars::after { content: "\f85b\f85b"; }
.fad.#{$fa-css-prefix}-cart-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-cart-arrow-down::after { content: "\f218\f218"; }
.fad.#{$fa-css-prefix}-cart-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-cart-arrow-up::after { content: "\e3ee\e3ee"; }
.fad.#{$fa-css-prefix}-cart-circle-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-cart-circle-arrow-down::after { content: "\e3ef\e3ef"; }
.fad.#{$fa-css-prefix}-cart-circle-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-cart-circle-arrow-up::after { content: "\e3f0\e3f0"; }
.fad.#{$fa-css-prefix}-cart-circle-check::after, .fa-duotone.#{$fa-css-prefix}-cart-circle-check::after { content: "\e3f1\e3f1"; }
.fad.#{$fa-css-prefix}-cart-circle-exclamation::after, .fa-duotone.#{$fa-css-prefix}-cart-circle-exclamation::after { content: "\e3f2\e3f2"; }
.fad.#{$fa-css-prefix}-cart-circle-plus::after, .fa-duotone.#{$fa-css-prefix}-cart-circle-plus::after { content: "\e3f3\e3f3"; }
.fad.#{$fa-css-prefix}-cart-circle-xmark::after, .fa-duotone.#{$fa-css-prefix}-cart-circle-xmark::after { content: "\e3f4\e3f4"; }
.fad.#{$fa-css-prefix}-cart-flatbed::after, .fa-duotone.#{$fa-css-prefix}-cart-flatbed::after { content: "\f474\f474"; }
.fad.#{$fa-css-prefix}-dolly-flatbed::after, .fa-duotone.#{$fa-css-prefix}-dolly-flatbed::after { content: "\f474\f474"; }
.fad.#{$fa-css-prefix}-cart-flatbed-boxes::after, .fa-duotone.#{$fa-css-prefix}-cart-flatbed-boxes::after { content: "\f475\f475"; }
.fad.#{$fa-css-prefix}-dolly-flatbed-alt::after, .fa-duotone.#{$fa-css-prefix}-dolly-flatbed-alt::after { content: "\f475\f475"; }
.fad.#{$fa-css-prefix}-cart-flatbed-empty::after, .fa-duotone.#{$fa-css-prefix}-cart-flatbed-empty::after { content: "\f476\f476"; }
.fad.#{$fa-css-prefix}-dolly-flatbed-empty::after, .fa-duotone.#{$fa-css-prefix}-dolly-flatbed-empty::after { content: "\f476\f476"; }
.fad.#{$fa-css-prefix}-cart-flatbed-suitcase::after, .fa-duotone.#{$fa-css-prefix}-cart-flatbed-suitcase::after { content: "\f59d\f59d"; }
.fad.#{$fa-css-prefix}-luggage-cart::after, .fa-duotone.#{$fa-css-prefix}-luggage-cart::after { content: "\f59d\f59d"; }
.fad.#{$fa-css-prefix}-cart-minus::after, .fa-duotone.#{$fa-css-prefix}-cart-minus::after { content: "\e0db\e0db"; }
.fad.#{$fa-css-prefix}-cart-plus::after, .fa-duotone.#{$fa-css-prefix}-cart-plus::after { content: "\f217\f217"; }
.fad.#{$fa-css-prefix}-cart-shopping::after, .fa-duotone.#{$fa-css-prefix}-cart-shopping::after { content: "\f07a\f07a"; }
.fad.#{$fa-css-prefix}-shopping-cart::after, .fa-duotone.#{$fa-css-prefix}-shopping-cart::after { content: "\f07a\f07a"; }
.fad.#{$fa-css-prefix}-cart-shopping-fast::after, .fa-duotone.#{$fa-css-prefix}-cart-shopping-fast::after { content: "\e0dc\e0dc"; }
.fad.#{$fa-css-prefix}-cart-xmark::after, .fa-duotone.#{$fa-css-prefix}-cart-xmark::after { content: "\e0dd\e0dd"; }
.fad.#{$fa-css-prefix}-cash-register::after, .fa-duotone.#{$fa-css-prefix}-cash-register::after { content: "\f788\f788"; }
.fad.#{$fa-css-prefix}-cassette-betamax::after, .fa-duotone.#{$fa-css-prefix}-cassette-betamax::after { content: "\f8a4\f8a4"; }
.fad.#{$fa-css-prefix}-betamax::after, .fa-duotone.#{$fa-css-prefix}-betamax::after { content: "\f8a4\f8a4"; }
.fad.#{$fa-css-prefix}-cassette-tape::after, .fa-duotone.#{$fa-css-prefix}-cassette-tape::after { content: "\f8ab\f8ab"; }
.fad.#{$fa-css-prefix}-cassette-vhs::after, .fa-duotone.#{$fa-css-prefix}-cassette-vhs::after { content: "\f8ec\f8ec"; }
.fad.#{$fa-css-prefix}-vhs::after, .fa-duotone.#{$fa-css-prefix}-vhs::after { content: "\f8ec\f8ec"; }
.fad.#{$fa-css-prefix}-castle::after, .fa-duotone.#{$fa-css-prefix}-castle::after { content: "\e0de\e0de"; }
.fad.#{$fa-css-prefix}-cat::after, .fa-duotone.#{$fa-css-prefix}-cat::after { content: "\f6be\f6be"; }
.fad.#{$fa-css-prefix}-cat-space::after, .fa-duotone.#{$fa-css-prefix}-cat-space::after { content: "\e001\e001"; }
.fad.#{$fa-css-prefix}-cauldron::after, .fa-duotone.#{$fa-css-prefix}-cauldron::after { content: "\f6bf\f6bf"; }
.fad.#{$fa-css-prefix}-cedi-sign::after, .fa-duotone.#{$fa-css-prefix}-cedi-sign::after { content: "\e0df\e0df"; }
.fad.#{$fa-css-prefix}-cent-sign::after, .fa-duotone.#{$fa-css-prefix}-cent-sign::after { content: "\e3f5\e3f5"; }
.fad.#{$fa-css-prefix}-certificate::after, .fa-duotone.#{$fa-css-prefix}-certificate::after { content: "\f0a3\f0a3"; }
.fad.#{$fa-css-prefix}-chair::after, .fa-duotone.#{$fa-css-prefix}-chair::after { content: "\f6c0\f6c0"; }
.fad.#{$fa-css-prefix}-chair-office::after, .fa-duotone.#{$fa-css-prefix}-chair-office::after { content: "\f6c1\f6c1"; }
.fad.#{$fa-css-prefix}-chalkboard::after, .fa-duotone.#{$fa-css-prefix}-chalkboard::after { content: "\f51b\f51b"; }
.fad.#{$fa-css-prefix}-blackboard::after, .fa-duotone.#{$fa-css-prefix}-blackboard::after { content: "\f51b\f51b"; }
.fad.#{$fa-css-prefix}-chalkboard-user::after, .fa-duotone.#{$fa-css-prefix}-chalkboard-user::after { content: "\f51c\f51c"; }
.fad.#{$fa-css-prefix}-chalkboard-teacher::after, .fa-duotone.#{$fa-css-prefix}-chalkboard-teacher::after { content: "\f51c\f51c"; }
.fad.#{$fa-css-prefix}-champagne-glass::after, .fa-duotone.#{$fa-css-prefix}-champagne-glass::after { content: "\f79e\f79e"; }
.fad.#{$fa-css-prefix}-glass-champagne::after, .fa-duotone.#{$fa-css-prefix}-glass-champagne::after { content: "\f79e\f79e"; }
.fad.#{$fa-css-prefix}-champagne-glasses::after, .fa-duotone.#{$fa-css-prefix}-champagne-glasses::after { content: "\f79f\f79f"; }
.fad.#{$fa-css-prefix}-glass-cheers::after, .fa-duotone.#{$fa-css-prefix}-glass-cheers::after { content: "\f79f\f79f"; }
.fad.#{$fa-css-prefix}-charging-station::after, .fa-duotone.#{$fa-css-prefix}-charging-station::after { content: "\f5e7\f5e7"; }
.fad.#{$fa-css-prefix}-chart-area::after, .fa-duotone.#{$fa-css-prefix}-chart-area::after { content: "\f1fe\f1fe"; }
.fad.#{$fa-css-prefix}-area-chart::after, .fa-duotone.#{$fa-css-prefix}-area-chart::after { content: "\f1fe\f1fe"; }
.fad.#{$fa-css-prefix}-chart-bar::after, .fa-duotone.#{$fa-css-prefix}-chart-bar::after { content: "\f080\f080"; }
.fad.#{$fa-css-prefix}-bar-chart::after, .fa-duotone.#{$fa-css-prefix}-bar-chart::after { content: "\f080\f080"; }
.fad.#{$fa-css-prefix}-chart-bullet::after, .fa-duotone.#{$fa-css-prefix}-chart-bullet::after { content: "\e0e1\e0e1"; }
.fad.#{$fa-css-prefix}-chart-candlestick::after, .fa-duotone.#{$fa-css-prefix}-chart-candlestick::after { content: "\e0e2\e0e2"; }
.fad.#{$fa-css-prefix}-chart-column::after, .fa-duotone.#{$fa-css-prefix}-chart-column::after { content: "\e0e3\e0e3"; }
.fad.#{$fa-css-prefix}-chart-gantt::after, .fa-duotone.#{$fa-css-prefix}-chart-gantt::after { content: "\e0e4\e0e4"; }
.fad.#{$fa-css-prefix}-chart-line::after, .fa-duotone.#{$fa-css-prefix}-chart-line::after { content: "\f201\f201"; }
.fad.#{$fa-css-prefix}-line-chart::after, .fa-duotone.#{$fa-css-prefix}-line-chart::after { content: "\f201\f201"; }
.fad.#{$fa-css-prefix}-chart-line-down::after, .fa-duotone.#{$fa-css-prefix}-chart-line-down::after { content: "\f64d\f64d"; }
.fad.#{$fa-css-prefix}-chart-line-up::after, .fa-duotone.#{$fa-css-prefix}-chart-line-up::after { content: "\e0e5\e0e5"; }
.fad.#{$fa-css-prefix}-chart-mixed::after, .fa-duotone.#{$fa-css-prefix}-chart-mixed::after { content: "\f643\f643"; }
.fad.#{$fa-css-prefix}-analytics::after, .fa-duotone.#{$fa-css-prefix}-analytics::after { content: "\f643\f643"; }
.fad.#{$fa-css-prefix}-chart-network::after, .fa-duotone.#{$fa-css-prefix}-chart-network::after { content: "\f78a\f78a"; }
.fad.#{$fa-css-prefix}-chart-pie::after, .fa-duotone.#{$fa-css-prefix}-chart-pie::after { content: "\f200\f200"; }
.fad.#{$fa-css-prefix}-pie-chart::after, .fa-duotone.#{$fa-css-prefix}-pie-chart::after { content: "\f200\f200"; }
.fad.#{$fa-css-prefix}-chart-pie-simple::after, .fa-duotone.#{$fa-css-prefix}-chart-pie-simple::after { content: "\f64e\f64e"; }
.fad.#{$fa-css-prefix}-chart-pie-alt::after, .fa-duotone.#{$fa-css-prefix}-chart-pie-alt::after { content: "\f64e\f64e"; }
.fad.#{$fa-css-prefix}-chart-pyramid::after, .fa-duotone.#{$fa-css-prefix}-chart-pyramid::after { content: "\e0e6\e0e6"; }
.fad.#{$fa-css-prefix}-chart-radar::after, .fa-duotone.#{$fa-css-prefix}-chart-radar::after { content: "\e0e7\e0e7"; }
.fad.#{$fa-css-prefix}-chart-scatter::after, .fa-duotone.#{$fa-css-prefix}-chart-scatter::after { content: "\f7ee\f7ee"; }
.fad.#{$fa-css-prefix}-chart-scatter-3d::after, .fa-duotone.#{$fa-css-prefix}-chart-scatter-3d::after { content: "\e0e8\e0e8"; }
.fad.#{$fa-css-prefix}-chart-scatter-bubble::after, .fa-duotone.#{$fa-css-prefix}-chart-scatter-bubble::after { content: "\e0e9\e0e9"; }
.fad.#{$fa-css-prefix}-chart-simple::after, .fa-duotone.#{$fa-css-prefix}-chart-simple::after { content: "\e473\e473"; }
.fad.#{$fa-css-prefix}-chart-simple-horizontal::after, .fa-duotone.#{$fa-css-prefix}-chart-simple-horizontal::after { content: "\e474\e474"; }
.fad.#{$fa-css-prefix}-chart-tree-map::after, .fa-duotone.#{$fa-css-prefix}-chart-tree-map::after { content: "\e0ea\e0ea"; }
.fad.#{$fa-css-prefix}-chart-user::after, .fa-duotone.#{$fa-css-prefix}-chart-user::after { content: "\f6a3\f6a3"; }
.fad.#{$fa-css-prefix}-user-chart::after, .fa-duotone.#{$fa-css-prefix}-user-chart::after { content: "\f6a3\f6a3"; }
.fad.#{$fa-css-prefix}-chart-waterfall::after, .fa-duotone.#{$fa-css-prefix}-chart-waterfall::after { content: "\e0eb\e0eb"; }
.fad.#{$fa-css-prefix}-check::after, .fa-duotone.#{$fa-css-prefix}-check::after { content: "\f00c\f00c"; }
.fad.#{$fa-css-prefix}-check-double::after, .fa-duotone.#{$fa-css-prefix}-check-double::after { content: "\f560\f560"; }
.fad.#{$fa-css-prefix}-check-to-slot::after, .fa-duotone.#{$fa-css-prefix}-check-to-slot::after { content: "\f772\f772"; }
.fad.#{$fa-css-prefix}-vote-yea::after, .fa-duotone.#{$fa-css-prefix}-vote-yea::after { content: "\f772\f772"; }
.fad.#{$fa-css-prefix}-cheese::after, .fa-duotone.#{$fa-css-prefix}-cheese::after { content: "\f7ef\f7ef"; }
.fad.#{$fa-css-prefix}-cheese-swiss::after, .fa-duotone.#{$fa-css-prefix}-cheese-swiss::after { content: "\f7f0\f7f0"; }
.fad.#{$fa-css-prefix}-cherries::after, .fa-duotone.#{$fa-css-prefix}-cherries::after { content: "\e0ec\e0ec"; }
.fad.#{$fa-css-prefix}-chess::after, .fa-duotone.#{$fa-css-prefix}-chess::after { content: "\f439\f439"; }
.fad.#{$fa-css-prefix}-chess-bishop::after, .fa-duotone.#{$fa-css-prefix}-chess-bishop::after { content: "\f43a\f43a"; }
.fad.#{$fa-css-prefix}-chess-bishop-piece::after, .fa-duotone.#{$fa-css-prefix}-chess-bishop-piece::after { content: "\f43b\f43b"; }
.fad.#{$fa-css-prefix}-chess-bishop-alt::after, .fa-duotone.#{$fa-css-prefix}-chess-bishop-alt::after { content: "\f43b\f43b"; }
.fad.#{$fa-css-prefix}-chess-board::after, .fa-duotone.#{$fa-css-prefix}-chess-board::after { content: "\f43c\f43c"; }
.fad.#{$fa-css-prefix}-chess-clock::after, .fa-duotone.#{$fa-css-prefix}-chess-clock::after { content: "\f43d\f43d"; }
.fad.#{$fa-css-prefix}-chess-clock-flip::after, .fa-duotone.#{$fa-css-prefix}-chess-clock-flip::after { content: "\f43e\f43e"; }
.fad.#{$fa-css-prefix}-chess-clock-alt::after, .fa-duotone.#{$fa-css-prefix}-chess-clock-alt::after { content: "\f43e\f43e"; }
.fad.#{$fa-css-prefix}-chess-king::after, .fa-duotone.#{$fa-css-prefix}-chess-king::after { content: "\f43f\f43f"; }
.fad.#{$fa-css-prefix}-chess-king-piece::after, .fa-duotone.#{$fa-css-prefix}-chess-king-piece::after { content: "\f440\f440"; }
.fad.#{$fa-css-prefix}-chess-king-alt::after, .fa-duotone.#{$fa-css-prefix}-chess-king-alt::after { content: "\f440\f440"; }
.fad.#{$fa-css-prefix}-chess-knight::after, .fa-duotone.#{$fa-css-prefix}-chess-knight::after { content: "\f441\f441"; }
.fad.#{$fa-css-prefix}-chess-knight-piece::after, .fa-duotone.#{$fa-css-prefix}-chess-knight-piece::after { content: "\f442\f442"; }
.fad.#{$fa-css-prefix}-chess-knight-alt::after, .fa-duotone.#{$fa-css-prefix}-chess-knight-alt::after { content: "\f442\f442"; }
.fad.#{$fa-css-prefix}-chess-pawn::after, .fa-duotone.#{$fa-css-prefix}-chess-pawn::after { content: "\f443\f443"; }
.fad.#{$fa-css-prefix}-chess-pawn-piece::after, .fa-duotone.#{$fa-css-prefix}-chess-pawn-piece::after { content: "\f444\f444"; }
.fad.#{$fa-css-prefix}-chess-pawn-alt::after, .fa-duotone.#{$fa-css-prefix}-chess-pawn-alt::after { content: "\f444\f444"; }
.fad.#{$fa-css-prefix}-chess-queen::after, .fa-duotone.#{$fa-css-prefix}-chess-queen::after { content: "\f445\f445"; }
.fad.#{$fa-css-prefix}-chess-queen-piece::after, .fa-duotone.#{$fa-css-prefix}-chess-queen-piece::after { content: "\f446\f446"; }
.fad.#{$fa-css-prefix}-chess-queen-alt::after, .fa-duotone.#{$fa-css-prefix}-chess-queen-alt::after { content: "\f446\f446"; }
.fad.#{$fa-css-prefix}-chess-rook::after, .fa-duotone.#{$fa-css-prefix}-chess-rook::after { content: "\f447\f447"; }
.fad.#{$fa-css-prefix}-chess-rook-piece::after, .fa-duotone.#{$fa-css-prefix}-chess-rook-piece::after { content: "\f448\f448"; }
.fad.#{$fa-css-prefix}-chess-rook-alt::after, .fa-duotone.#{$fa-css-prefix}-chess-rook-alt::after { content: "\f448\f448"; }
.fad.#{$fa-css-prefix}-chestnut::after, .fa-duotone.#{$fa-css-prefix}-chestnut::after { content: "\e3f6\e3f6"; }
.fad.#{$fa-css-prefix}-chevron-down::after, .fa-duotone.#{$fa-css-prefix}-chevron-down::after { content: "\f078\f078"; }
.fad.#{$fa-css-prefix}-chevron-left::after, .fa-duotone.#{$fa-css-prefix}-chevron-left::after { content: "\f053\f053"; }
.fad.#{$fa-css-prefix}-chevron-right::after, .fa-duotone.#{$fa-css-prefix}-chevron-right::after { content: "\f054\f054"; }
.fad.#{$fa-css-prefix}-chevron-up::after, .fa-duotone.#{$fa-css-prefix}-chevron-up::after { content: "\f077\f077"; }
.fad.#{$fa-css-prefix}-chevrons-down::after, .fa-duotone.#{$fa-css-prefix}-chevrons-down::after { content: "\f322\f322"; }
.fad.#{$fa-css-prefix}-chevron-double-down::after, .fa-duotone.#{$fa-css-prefix}-chevron-double-down::after { content: "\f322\f322"; }
.fad.#{$fa-css-prefix}-chevrons-left::after, .fa-duotone.#{$fa-css-prefix}-chevrons-left::after { content: "\f323\f323"; }
.fad.#{$fa-css-prefix}-chevron-double-left::after, .fa-duotone.#{$fa-css-prefix}-chevron-double-left::after { content: "\f323\f323"; }
.fad.#{$fa-css-prefix}-chevrons-right::after, .fa-duotone.#{$fa-css-prefix}-chevrons-right::after { content: "\f324\f324"; }
.fad.#{$fa-css-prefix}-chevron-double-right::after, .fa-duotone.#{$fa-css-prefix}-chevron-double-right::after { content: "\f324\f324"; }
.fad.#{$fa-css-prefix}-chevrons-up::after, .fa-duotone.#{$fa-css-prefix}-chevrons-up::after { content: "\f325\f325"; }
.fad.#{$fa-css-prefix}-chevron-double-up::after, .fa-duotone.#{$fa-css-prefix}-chevron-double-up::after { content: "\f325\f325"; }
.fad.#{$fa-css-prefix}-child::after, .fa-duotone.#{$fa-css-prefix}-child::after { content: "\f1ae\f1ae"; }
.fad.#{$fa-css-prefix}-chimney::after, .fa-duotone.#{$fa-css-prefix}-chimney::after { content: "\f78b\f78b"; }
.fad.#{$fa-css-prefix}-chopsticks::after, .fa-duotone.#{$fa-css-prefix}-chopsticks::after { content: "\e3f7\e3f7"; }
.fad.#{$fa-css-prefix}-church::after, .fa-duotone.#{$fa-css-prefix}-church::after { content: "\f51d\f51d"; }
.fad.#{$fa-css-prefix}-circle::after, .fa-duotone.#{$fa-css-prefix}-circle::after { content: "\f111\f111"; }
.fad.#{$fa-css-prefix}-circle-0::after, .fa-duotone.#{$fa-css-prefix}-circle-0::after { content: "\e0ed\e0ed"; }
.fad.#{$fa-css-prefix}-circle-1::after, .fa-duotone.#{$fa-css-prefix}-circle-1::after { content: "\e0ee\e0ee"; }
.fad.#{$fa-css-prefix}-circle-2::after, .fa-duotone.#{$fa-css-prefix}-circle-2::after { content: "\e0ef\e0ef"; }
.fad.#{$fa-css-prefix}-circle-3::after, .fa-duotone.#{$fa-css-prefix}-circle-3::after { content: "\e0f0\e0f0"; }
.fad.#{$fa-css-prefix}-circle-4::after, .fa-duotone.#{$fa-css-prefix}-circle-4::after { content: "\e0f1\e0f1"; }
.fad.#{$fa-css-prefix}-circle-5::after, .fa-duotone.#{$fa-css-prefix}-circle-5::after { content: "\e0f2\e0f2"; }
.fad.#{$fa-css-prefix}-circle-6::after, .fa-duotone.#{$fa-css-prefix}-circle-6::after { content: "\e0f3\e0f3"; }
.fad.#{$fa-css-prefix}-circle-7::after, .fa-duotone.#{$fa-css-prefix}-circle-7::after { content: "\e0f4\e0f4"; }
.fad.#{$fa-css-prefix}-circle-8::after, .fa-duotone.#{$fa-css-prefix}-circle-8::after { content: "\e0f5\e0f5"; }
.fad.#{$fa-css-prefix}-circle-9::after, .fa-duotone.#{$fa-css-prefix}-circle-9::after { content: "\e0f6\e0f6"; }
.fad.#{$fa-css-prefix}-circle-a::after, .fa-duotone.#{$fa-css-prefix}-circle-a::after { content: "\e0f7\e0f7"; }
.fad.#{$fa-css-prefix}-circle-ampersand::after, .fa-duotone.#{$fa-css-prefix}-circle-ampersand::after { content: "\e0f8\e0f8"; }
.fad.#{$fa-css-prefix}-circle-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-down::after { content: "\f0ab\f0ab"; }
.fad.#{$fa-css-prefix}-arrow-circle-down::after, .fa-duotone.#{$fa-css-prefix}-arrow-circle-down::after { content: "\f0ab\f0ab"; }
.fad.#{$fa-css-prefix}-circle-arrow-down-left::after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-down-left::after { content: "\e0f9\e0f9"; }
.fad.#{$fa-css-prefix}-circle-arrow-down-right::after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-down-right::after { content: "\e0fa\e0fa"; }
.fad.#{$fa-css-prefix}-circle-arrow-left::after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-left::after { content: "\f0a8\f0a8"; }
.fad.#{$fa-css-prefix}-arrow-circle-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-circle-left::after { content: "\f0a8\f0a8"; }
.fad.#{$fa-css-prefix}-circle-arrow-right::after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-right::after { content: "\f0a9\f0a9"; }
.fad.#{$fa-css-prefix}-arrow-circle-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-circle-right::after { content: "\f0a9\f0a9"; }
.fad.#{$fa-css-prefix}-circle-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-up::after { content: "\f0aa\f0aa"; }
.fad.#{$fa-css-prefix}-arrow-circle-up::after, .fa-duotone.#{$fa-css-prefix}-arrow-circle-up::after { content: "\f0aa\f0aa"; }
.fad.#{$fa-css-prefix}-circle-arrow-up-left::after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-up-left::after { content: "\e0fb\e0fb"; }
.fad.#{$fa-css-prefix}-circle-arrow-up-right::after, .fa-duotone.#{$fa-css-prefix}-circle-arrow-up-right::after { content: "\e0fc\e0fc"; }
.fad.#{$fa-css-prefix}-circle-b::after, .fa-duotone.#{$fa-css-prefix}-circle-b::after { content: "\e0fd\e0fd"; }
.fad.#{$fa-css-prefix}-circle-bolt::after, .fa-duotone.#{$fa-css-prefix}-circle-bolt::after { content: "\e0fe\e0fe"; }
.fad.#{$fa-css-prefix}-circle-book-open::after, .fa-duotone.#{$fa-css-prefix}-circle-book-open::after { content: "\e0ff\e0ff"; }
.fad.#{$fa-css-prefix}-book-circle::after, .fa-duotone.#{$fa-css-prefix}-book-circle::after { content: "\e0ff\e0ff"; }
.fad.#{$fa-css-prefix}-circle-bookmark::after, .fa-duotone.#{$fa-css-prefix}-circle-bookmark::after { content: "\e100\e100"; }
.fad.#{$fa-css-prefix}-bookmark-circle::after, .fa-duotone.#{$fa-css-prefix}-bookmark-circle::after { content: "\e100\e100"; }
.fad.#{$fa-css-prefix}-circle-c::after, .fa-duotone.#{$fa-css-prefix}-circle-c::after { content: "\e101\e101"; }
.fad.#{$fa-css-prefix}-circle-calendar::after, .fa-duotone.#{$fa-css-prefix}-circle-calendar::after { content: "\e102\e102"; }
.fad.#{$fa-css-prefix}-calendar-circle::after, .fa-duotone.#{$fa-css-prefix}-calendar-circle::after { content: "\e102\e102"; }
.fad.#{$fa-css-prefix}-circle-camera::after, .fa-duotone.#{$fa-css-prefix}-circle-camera::after { content: "\e103\e103"; }
.fad.#{$fa-css-prefix}-camera-circle::after, .fa-duotone.#{$fa-css-prefix}-camera-circle::after { content: "\e103\e103"; }
.fad.#{$fa-css-prefix}-circle-caret-down::after, .fa-duotone.#{$fa-css-prefix}-circle-caret-down::after { content: "\f32d\f32d"; }
.fad.#{$fa-css-prefix}-caret-circle-down::after, .fa-duotone.#{$fa-css-prefix}-caret-circle-down::after { content: "\f32d\f32d"; }
.fad.#{$fa-css-prefix}-circle-caret-left::after, .fa-duotone.#{$fa-css-prefix}-circle-caret-left::after { content: "\f32e\f32e"; }
.fad.#{$fa-css-prefix}-caret-circle-left::after, .fa-duotone.#{$fa-css-prefix}-caret-circle-left::after { content: "\f32e\f32e"; }
.fad.#{$fa-css-prefix}-circle-caret-right::after, .fa-duotone.#{$fa-css-prefix}-circle-caret-right::after { content: "\f330\f330"; }
.fad.#{$fa-css-prefix}-caret-circle-right::after, .fa-duotone.#{$fa-css-prefix}-caret-circle-right::after { content: "\f330\f330"; }
.fad.#{$fa-css-prefix}-circle-caret-up::after, .fa-duotone.#{$fa-css-prefix}-circle-caret-up::after { content: "\f331\f331"; }
.fad.#{$fa-css-prefix}-caret-circle-up::after, .fa-duotone.#{$fa-css-prefix}-caret-circle-up::after { content: "\f331\f331"; }
.fad.#{$fa-css-prefix}-circle-check::after, .fa-duotone.#{$fa-css-prefix}-circle-check::after { content: "\f058\f058"; }
.fad.#{$fa-css-prefix}-check-circle::after, .fa-duotone.#{$fa-css-prefix}-check-circle::after { content: "\f058\f058"; }
.fad.#{$fa-css-prefix}-circle-chevron-down::after, .fa-duotone.#{$fa-css-prefix}-circle-chevron-down::after { content: "\f13a\f13a"; }
.fad.#{$fa-css-prefix}-chevron-circle-down::after, .fa-duotone.#{$fa-css-prefix}-chevron-circle-down::after { content: "\f13a\f13a"; }
.fad.#{$fa-css-prefix}-circle-chevron-left::after, .fa-duotone.#{$fa-css-prefix}-circle-chevron-left::after { content: "\f137\f137"; }
.fad.#{$fa-css-prefix}-chevron-circle-left::after, .fa-duotone.#{$fa-css-prefix}-chevron-circle-left::after { content: "\f137\f137"; }
.fad.#{$fa-css-prefix}-circle-chevron-right::after, .fa-duotone.#{$fa-css-prefix}-circle-chevron-right::after { content: "\f138\f138"; }
.fad.#{$fa-css-prefix}-chevron-circle-right::after, .fa-duotone.#{$fa-css-prefix}-chevron-circle-right::after { content: "\f138\f138"; }
.fad.#{$fa-css-prefix}-circle-chevron-up::after, .fa-duotone.#{$fa-css-prefix}-circle-chevron-up::after { content: "\f139\f139"; }
.fad.#{$fa-css-prefix}-chevron-circle-up::after, .fa-duotone.#{$fa-css-prefix}-chevron-circle-up::after { content: "\f139\f139"; }
.fad.#{$fa-css-prefix}-circle-d::after, .fa-duotone.#{$fa-css-prefix}-circle-d::after { content: "\e104\e104"; }
.fad.#{$fa-css-prefix}-circle-dashed::after, .fa-duotone.#{$fa-css-prefix}-circle-dashed::after { content: "\e105\e105"; }
.fad.#{$fa-css-prefix}-circle-divide::after, .fa-duotone.#{$fa-css-prefix}-circle-divide::after { content: "\e106\e106"; }
.fad.#{$fa-css-prefix}-circle-dollar::after, .fa-duotone.#{$fa-css-prefix}-circle-dollar::after { content: "\f2e8\f2e8"; }
.fad.#{$fa-css-prefix}-dollar-circle::after, .fa-duotone.#{$fa-css-prefix}-dollar-circle::after { content: "\f2e8\f2e8"; }
.fad.#{$fa-css-prefix}-usd-circle::after, .fa-duotone.#{$fa-css-prefix}-usd-circle::after { content: "\f2e8\f2e8"; }
.fad.#{$fa-css-prefix}-circle-dollar-to-slot::after, .fa-duotone.#{$fa-css-prefix}-circle-dollar-to-slot::after { content: "\f4b9\f4b9"; }
.fad.#{$fa-css-prefix}-donate::after, .fa-duotone.#{$fa-css-prefix}-donate::after { content: "\f4b9\f4b9"; }
.fad.#{$fa-css-prefix}-circle-dot::after, .fa-duotone.#{$fa-css-prefix}-circle-dot::after { content: "\f192\f192"; }
.fad.#{$fa-css-prefix}-dot-circle::after, .fa-duotone.#{$fa-css-prefix}-dot-circle::after { content: "\f192\f192"; }
.fad.#{$fa-css-prefix}-circle-down::after, .fa-duotone.#{$fa-css-prefix}-circle-down::after { content: "\f358\f358"; }
.fad.#{$fa-css-prefix}-arrow-alt-circle-down::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-circle-down::after { content: "\f358\f358"; }
.fad.#{$fa-css-prefix}-circle-down-left::after, .fa-duotone.#{$fa-css-prefix}-circle-down-left::after { content: "\e107\e107"; }
.fad.#{$fa-css-prefix}-circle-down-right::after, .fa-duotone.#{$fa-css-prefix}-circle-down-right::after { content: "\e108\e108"; }
.fad.#{$fa-css-prefix}-circle-e::after, .fa-duotone.#{$fa-css-prefix}-circle-e::after { content: "\e109\e109"; }
.fad.#{$fa-css-prefix}-circle-ellipsis::after, .fa-duotone.#{$fa-css-prefix}-circle-ellipsis::after { content: "\e10a\e10a"; }
.fad.#{$fa-css-prefix}-circle-ellipsis-vertical::after, .fa-duotone.#{$fa-css-prefix}-circle-ellipsis-vertical::after { content: "\e10b\e10b"; }
.fad.#{$fa-css-prefix}-circle-envelope::after, .fa-duotone.#{$fa-css-prefix}-circle-envelope::after { content: "\e10c\e10c"; }
.fad.#{$fa-css-prefix}-envelope-circle::after, .fa-duotone.#{$fa-css-prefix}-envelope-circle::after { content: "\e10c\e10c"; }
.fad.#{$fa-css-prefix}-circle-exclamation::after, .fa-duotone.#{$fa-css-prefix}-circle-exclamation::after { content: "\f06a\f06a"; }
.fad.#{$fa-css-prefix}-exclamation-circle::after, .fa-duotone.#{$fa-css-prefix}-exclamation-circle::after { content: "\f06a\f06a"; }
.fad.#{$fa-css-prefix}-circle-exclamation-check::after, .fa-duotone.#{$fa-css-prefix}-circle-exclamation-check::after { content: "\e10d\e10d"; }
.fad.#{$fa-css-prefix}-circle-f::after, .fa-duotone.#{$fa-css-prefix}-circle-f::after { content: "\e10e\e10e"; }
.fad.#{$fa-css-prefix}-circle-g::after, .fa-duotone.#{$fa-css-prefix}-circle-g::after { content: "\e10f\e10f"; }
.fad.#{$fa-css-prefix}-circle-h::after, .fa-duotone.#{$fa-css-prefix}-circle-h::after { content: "\f47e\f47e"; }
.fad.#{$fa-css-prefix}-hospital-symbol::after, .fa-duotone.#{$fa-css-prefix}-hospital-symbol::after { content: "\f47e\f47e"; }
.fad.#{$fa-css-prefix}-circle-half::after, .fa-duotone.#{$fa-css-prefix}-circle-half::after { content: "\e110\e110"; }
.fad.#{$fa-css-prefix}-circle-half-stroke::after, .fa-duotone.#{$fa-css-prefix}-circle-half-stroke::after { content: "\f042\f042"; }
.fad.#{$fa-css-prefix}-adjust::after, .fa-duotone.#{$fa-css-prefix}-adjust::after { content: "\f042\f042"; }
.fad.#{$fa-css-prefix}-circle-heart::after, .fa-duotone.#{$fa-css-prefix}-circle-heart::after { content: "\f4c7\f4c7"; }
.fad.#{$fa-css-prefix}-heart-circle::after, .fa-duotone.#{$fa-css-prefix}-heart-circle::after { content: "\f4c7\f4c7"; }
.fad.#{$fa-css-prefix}-circle-i::after, .fa-duotone.#{$fa-css-prefix}-circle-i::after { content: "\e111\e111"; }
.fad.#{$fa-css-prefix}-circle-info::after, .fa-duotone.#{$fa-css-prefix}-circle-info::after { content: "\f05a\f05a"; }
.fad.#{$fa-css-prefix}-info-circle::after, .fa-duotone.#{$fa-css-prefix}-info-circle::after { content: "\f05a\f05a"; }
.fad.#{$fa-css-prefix}-circle-j::after, .fa-duotone.#{$fa-css-prefix}-circle-j::after { content: "\e112\e112"; }
.fad.#{$fa-css-prefix}-circle-k::after, .fa-duotone.#{$fa-css-prefix}-circle-k::after { content: "\e113\e113"; }
.fad.#{$fa-css-prefix}-circle-l::after, .fa-duotone.#{$fa-css-prefix}-circle-l::after { content: "\e114\e114"; }
.fad.#{$fa-css-prefix}-circle-left::after, .fa-duotone.#{$fa-css-prefix}-circle-left::after { content: "\f359\f359"; }
.fad.#{$fa-css-prefix}-arrow-alt-circle-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-circle-left::after { content: "\f359\f359"; }
.fad.#{$fa-css-prefix}-circle-location-arrow::after, .fa-duotone.#{$fa-css-prefix}-circle-location-arrow::after { content: "\f602\f602"; }
.fad.#{$fa-css-prefix}-location-circle::after, .fa-duotone.#{$fa-css-prefix}-location-circle::after { content: "\f602\f602"; }
.fad.#{$fa-css-prefix}-circle-m::after, .fa-duotone.#{$fa-css-prefix}-circle-m::after { content: "\e115\e115"; }
.fad.#{$fa-css-prefix}-circle-microphone::after, .fa-duotone.#{$fa-css-prefix}-circle-microphone::after { content: "\e116\e116"; }
.fad.#{$fa-css-prefix}-microphone-circle::after, .fa-duotone.#{$fa-css-prefix}-microphone-circle::after { content: "\e116\e116"; }
.fad.#{$fa-css-prefix}-circle-microphone-lines::after, .fa-duotone.#{$fa-css-prefix}-circle-microphone-lines::after { content: "\e117\e117"; }
.fad.#{$fa-css-prefix}-microphone-circle-alt::after, .fa-duotone.#{$fa-css-prefix}-microphone-circle-alt::after { content: "\e117\e117"; }
.fad.#{$fa-css-prefix}-circle-minus::after, .fa-duotone.#{$fa-css-prefix}-circle-minus::after { content: "\f056\f056"; }
.fad.#{$fa-css-prefix}-minus-circle::after, .fa-duotone.#{$fa-css-prefix}-minus-circle::after { content: "\f056\f056"; }
.fad.#{$fa-css-prefix}-circle-n::after, .fa-duotone.#{$fa-css-prefix}-circle-n::after { content: "\e118\e118"; }
.fad.#{$fa-css-prefix}-circle-notch::after, .fa-duotone.#{$fa-css-prefix}-circle-notch::after { content: "\f1ce\f1ce"; }
.fad.#{$fa-css-prefix}-circle-o::after, .fa-duotone.#{$fa-css-prefix}-circle-o::after { content: "\e119\e119"; }
.fad.#{$fa-css-prefix}-circle-p::after, .fa-duotone.#{$fa-css-prefix}-circle-p::after { content: "\e11a\e11a"; }
.fad.#{$fa-css-prefix}-circle-parking::after, .fa-duotone.#{$fa-css-prefix}-circle-parking::after { content: "\f615\f615"; }
.fad.#{$fa-css-prefix}-parking-circle::after, .fa-duotone.#{$fa-css-prefix}-parking-circle::after { content: "\f615\f615"; }
.fad.#{$fa-css-prefix}-circle-pause::after, .fa-duotone.#{$fa-css-prefix}-circle-pause::after { content: "\f28b\f28b"; }
.fad.#{$fa-css-prefix}-pause-circle::after, .fa-duotone.#{$fa-css-prefix}-pause-circle::after { content: "\f28b\f28b"; }
.fad.#{$fa-css-prefix}-circle-phone::after, .fa-duotone.#{$fa-css-prefix}-circle-phone::after { content: "\e11b\e11b"; }
.fad.#{$fa-css-prefix}-phone-circle::after, .fa-duotone.#{$fa-css-prefix}-phone-circle::after { content: "\e11b\e11b"; }
.fad.#{$fa-css-prefix}-circle-phone-flip::after, .fa-duotone.#{$fa-css-prefix}-circle-phone-flip::after { content: "\e11c\e11c"; }
.fad.#{$fa-css-prefix}-phone-circle-alt::after, .fa-duotone.#{$fa-css-prefix}-phone-circle-alt::after { content: "\e11c\e11c"; }
.fad.#{$fa-css-prefix}-circle-phone-hangup::after, .fa-duotone.#{$fa-css-prefix}-circle-phone-hangup::after { content: "\e11d\e11d"; }
.fad.#{$fa-css-prefix}-phone-circle-down::after, .fa-duotone.#{$fa-css-prefix}-phone-circle-down::after { content: "\e11d\e11d"; }
.fad.#{$fa-css-prefix}-circle-play::after, .fa-duotone.#{$fa-css-prefix}-circle-play::after { content: "\f144\f144"; }
.fad.#{$fa-css-prefix}-play-circle::after, .fa-duotone.#{$fa-css-prefix}-play-circle::after { content: "\f144\f144"; }
.fad.#{$fa-css-prefix}-circle-plus::after, .fa-duotone.#{$fa-css-prefix}-circle-plus::after { content: "\f055\f055"; }
.fad.#{$fa-css-prefix}-plus-circle::after, .fa-duotone.#{$fa-css-prefix}-plus-circle::after { content: "\f055\f055"; }
.fad.#{$fa-css-prefix}-circle-q::after, .fa-duotone.#{$fa-css-prefix}-circle-q::after { content: "\e11e\e11e"; }
.fad.#{$fa-css-prefix}-circle-quarter::after, .fa-duotone.#{$fa-css-prefix}-circle-quarter::after { content: "\e11f\e11f"; }
.fad.#{$fa-css-prefix}-circle-quarters::after, .fa-duotone.#{$fa-css-prefix}-circle-quarters::after { content: "\e3f8\e3f8"; }
.fad.#{$fa-css-prefix}-circle-question::after, .fa-duotone.#{$fa-css-prefix}-circle-question::after { content: "\f059\f059"; }
.fad.#{$fa-css-prefix}-question-circle::after, .fa-duotone.#{$fa-css-prefix}-question-circle::after { content: "\f059\f059"; }
.fad.#{$fa-css-prefix}-circle-r::after, .fa-duotone.#{$fa-css-prefix}-circle-r::after { content: "\e120\e120"; }
.fad.#{$fa-css-prefix}-circle-radiation::after, .fa-duotone.#{$fa-css-prefix}-circle-radiation::after { content: "\f7ba\f7ba"; }
.fad.#{$fa-css-prefix}-radiation-alt::after, .fa-duotone.#{$fa-css-prefix}-radiation-alt::after { content: "\f7ba\f7ba"; }
.fad.#{$fa-css-prefix}-circle-right::after, .fa-duotone.#{$fa-css-prefix}-circle-right::after { content: "\f35a\f35a"; }
.fad.#{$fa-css-prefix}-arrow-alt-circle-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-circle-right::after { content: "\f35a\f35a"; }
.fad.#{$fa-css-prefix}-circle-s::after, .fa-duotone.#{$fa-css-prefix}-circle-s::after { content: "\e121\e121"; }
.fad.#{$fa-css-prefix}-circle-small::after, .fa-duotone.#{$fa-css-prefix}-circle-small::after { content: "\e122\e122"; }
.fad.#{$fa-css-prefix}-circle-sort::after, .fa-duotone.#{$fa-css-prefix}-circle-sort::after { content: "\e030\e030"; }
.fad.#{$fa-css-prefix}-sort-circle::after, .fa-duotone.#{$fa-css-prefix}-sort-circle::after { content: "\e030\e030"; }
.fad.#{$fa-css-prefix}-circle-sort-down::after, .fa-duotone.#{$fa-css-prefix}-circle-sort-down::after { content: "\e031\e031"; }
.fad.#{$fa-css-prefix}-sort-circle-down::after, .fa-duotone.#{$fa-css-prefix}-sort-circle-down::after { content: "\e031\e031"; }
.fad.#{$fa-css-prefix}-circle-sort-up::after, .fa-duotone.#{$fa-css-prefix}-circle-sort-up::after { content: "\e032\e032"; }
.fad.#{$fa-css-prefix}-sort-circle-up::after, .fa-duotone.#{$fa-css-prefix}-sort-circle-up::after { content: "\e032\e032"; }
.fad.#{$fa-css-prefix}-circle-star::after, .fa-duotone.#{$fa-css-prefix}-circle-star::after { content: "\e123\e123"; }
.fad.#{$fa-css-prefix}-star-circle::after, .fa-duotone.#{$fa-css-prefix}-star-circle::after { content: "\e123\e123"; }
.fad.#{$fa-css-prefix}-circle-stop::after, .fa-duotone.#{$fa-css-prefix}-circle-stop::after { content: "\f28d\f28d"; }
.fad.#{$fa-css-prefix}-stop-circle::after, .fa-duotone.#{$fa-css-prefix}-stop-circle::after { content: "\f28d\f28d"; }
.fad.#{$fa-css-prefix}-circle-t::after, .fa-duotone.#{$fa-css-prefix}-circle-t::after { content: "\e124\e124"; }
.fad.#{$fa-css-prefix}-circle-three-quarters::after, .fa-duotone.#{$fa-css-prefix}-circle-three-quarters::after { content: "\e125\e125"; }
.fad.#{$fa-css-prefix}-circle-trash::after, .fa-duotone.#{$fa-css-prefix}-circle-trash::after { content: "\e126\e126"; }
.fad.#{$fa-css-prefix}-trash-circle::after, .fa-duotone.#{$fa-css-prefix}-trash-circle::after { content: "\e126\e126"; }
.fad.#{$fa-css-prefix}-circle-u::after, .fa-duotone.#{$fa-css-prefix}-circle-u::after { content: "\e127\e127"; }
.fad.#{$fa-css-prefix}-circle-up::after, .fa-duotone.#{$fa-css-prefix}-circle-up::after { content: "\f35b\f35b"; }
.fad.#{$fa-css-prefix}-arrow-alt-circle-up::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-circle-up::after { content: "\f35b\f35b"; }
.fad.#{$fa-css-prefix}-circle-up-left::after, .fa-duotone.#{$fa-css-prefix}-circle-up-left::after { content: "\e128\e128"; }
.fad.#{$fa-css-prefix}-circle-up-right::after, .fa-duotone.#{$fa-css-prefix}-circle-up-right::after { content: "\e129\e129"; }
.fad.#{$fa-css-prefix}-circle-user::after, .fa-duotone.#{$fa-css-prefix}-circle-user::after { content: "\f2bd\f2bd"; }
.fad.#{$fa-css-prefix}-user-circle::after, .fa-duotone.#{$fa-css-prefix}-user-circle::after { content: "\f2bd\f2bd"; }
.fad.#{$fa-css-prefix}-circle-v::after, .fa-duotone.#{$fa-css-prefix}-circle-v::after { content: "\e12a\e12a"; }
.fad.#{$fa-css-prefix}-circle-video::after, .fa-duotone.#{$fa-css-prefix}-circle-video::after { content: "\e12b\e12b"; }
.fad.#{$fa-css-prefix}-video-circle::after, .fa-duotone.#{$fa-css-prefix}-video-circle::after { content: "\e12b\e12b"; }
.fad.#{$fa-css-prefix}-circle-w::after, .fa-duotone.#{$fa-css-prefix}-circle-w::after { content: "\e12c\e12c"; }
.fad.#{$fa-css-prefix}-circle-waveform-lines::after, .fa-duotone.#{$fa-css-prefix}-circle-waveform-lines::after { content: "\e12d\e12d"; }
.fad.#{$fa-css-prefix}-waveform-circle::after, .fa-duotone.#{$fa-css-prefix}-waveform-circle::after { content: "\e12d\e12d"; }
.fad.#{$fa-css-prefix}-circle-x::after, .fa-duotone.#{$fa-css-prefix}-circle-x::after { content: "\e12e\e12e"; }
.fad.#{$fa-css-prefix}-circle-xmark::after, .fa-duotone.#{$fa-css-prefix}-circle-xmark::after { content: "\f057\f057"; }
.fad.#{$fa-css-prefix}-times-circle::after, .fa-duotone.#{$fa-css-prefix}-times-circle::after { content: "\f057\f057"; }
.fad.#{$fa-css-prefix}-xmark-circle::after, .fa-duotone.#{$fa-css-prefix}-xmark-circle::after { content: "\f057\f057"; }
.fad.#{$fa-css-prefix}-circle-y::after, .fa-duotone.#{$fa-css-prefix}-circle-y::after { content: "\e12f\e12f"; }
.fad.#{$fa-css-prefix}-circle-z::after, .fa-duotone.#{$fa-css-prefix}-circle-z::after { content: "\e130\e130"; }
.fad.#{$fa-css-prefix}-citrus::after, .fa-duotone.#{$fa-css-prefix}-citrus::after { content: "\e2f4\e2f4"; }
.fad.#{$fa-css-prefix}-citrus-slice::after, .fa-duotone.#{$fa-css-prefix}-citrus-slice::after { content: "\e2f5\e2f5"; }
.fad.#{$fa-css-prefix}-city::after, .fa-duotone.#{$fa-css-prefix}-city::after { content: "\f64f\f64f"; }
.fad.#{$fa-css-prefix}-clapperboard::after, .fa-duotone.#{$fa-css-prefix}-clapperboard::after { content: "\e131\e131"; }
.fad.#{$fa-css-prefix}-clapperboard-play::after, .fa-duotone.#{$fa-css-prefix}-clapperboard-play::after { content: "\e132\e132"; }
.fad.#{$fa-css-prefix}-clarinet::after, .fa-duotone.#{$fa-css-prefix}-clarinet::after { content: "\f8ad\f8ad"; }
.fad.#{$fa-css-prefix}-claw-marks::after, .fa-duotone.#{$fa-css-prefix}-claw-marks::after { content: "\f6c2\f6c2"; }
.fad.#{$fa-css-prefix}-clipboard::after, .fa-duotone.#{$fa-css-prefix}-clipboard::after { content: "\f328\f328"; }
.fad.#{$fa-css-prefix}-clipboard-check::after, .fa-duotone.#{$fa-css-prefix}-clipboard-check::after { content: "\f46c\f46c"; }
.fad.#{$fa-css-prefix}-clipboard-list::after, .fa-duotone.#{$fa-css-prefix}-clipboard-list::after { content: "\f46d\f46d"; }
.fad.#{$fa-css-prefix}-clipboard-list-check::after, .fa-duotone.#{$fa-css-prefix}-clipboard-list-check::after { content: "\f737\f737"; }
.fad.#{$fa-css-prefix}-clipboard-medical::after, .fa-duotone.#{$fa-css-prefix}-clipboard-medical::after { content: "\e133\e133"; }
.fad.#{$fa-css-prefix}-clipboard-prescription::after, .fa-duotone.#{$fa-css-prefix}-clipboard-prescription::after { content: "\f5e8\f5e8"; }
.fad.#{$fa-css-prefix}-clipboard-user::after, .fa-duotone.#{$fa-css-prefix}-clipboard-user::after { content: "\f7f3\f7f3"; }
.fad.#{$fa-css-prefix}-clock::after, .fa-duotone.#{$fa-css-prefix}-clock::after { content: "\f017\f017"; }
.fad.#{$fa-css-prefix}-clock-four::after, .fa-duotone.#{$fa-css-prefix}-clock-four::after { content: "\f017\f017"; }
.fad.#{$fa-css-prefix}-clock-desk::after, .fa-duotone.#{$fa-css-prefix}-clock-desk::after { content: "\e134\e134"; }
.fad.#{$fa-css-prefix}-clock-eight::after, .fa-duotone.#{$fa-css-prefix}-clock-eight::after { content: "\e345\e345"; }
.fad.#{$fa-css-prefix}-clock-eight-thirty::after, .fa-duotone.#{$fa-css-prefix}-clock-eight-thirty::after { content: "\e346\e346"; }
.fad.#{$fa-css-prefix}-clock-eleven::after, .fa-duotone.#{$fa-css-prefix}-clock-eleven::after { content: "\e347\e347"; }
.fad.#{$fa-css-prefix}-clock-eleven-thirty::after, .fa-duotone.#{$fa-css-prefix}-clock-eleven-thirty::after { content: "\e348\e348"; }
.fad.#{$fa-css-prefix}-clock-five::after, .fa-duotone.#{$fa-css-prefix}-clock-five::after { content: "\e349\e349"; }
.fad.#{$fa-css-prefix}-clock-five-thirty::after, .fa-duotone.#{$fa-css-prefix}-clock-five-thirty::after { content: "\e34a\e34a"; }
.fad.#{$fa-css-prefix}-clock-four-thirty::after, .fa-duotone.#{$fa-css-prefix}-clock-four-thirty::after { content: "\e34b\e34b"; }
.fad.#{$fa-css-prefix}-clock-nine::after, .fa-duotone.#{$fa-css-prefix}-clock-nine::after { content: "\e34c\e34c"; }
.fad.#{$fa-css-prefix}-clock-nine-thirty::after, .fa-duotone.#{$fa-css-prefix}-clock-nine-thirty::after { content: "\e34d\e34d"; }
.fad.#{$fa-css-prefix}-clock-one::after, .fa-duotone.#{$fa-css-prefix}-clock-one::after { content: "\e34e\e34e"; }
.fad.#{$fa-css-prefix}-clock-one-thirty::after, .fa-duotone.#{$fa-css-prefix}-clock-one-thirty::after { content: "\e34f\e34f"; }
.fad.#{$fa-css-prefix}-clock-rotate-left::after, .fa-duotone.#{$fa-css-prefix}-clock-rotate-left::after { content: "\f1da\f1da"; }
.fad.#{$fa-css-prefix}-history::after, .fa-duotone.#{$fa-css-prefix}-history::after { content: "\f1da\f1da"; }
.fad.#{$fa-css-prefix}-clock-seven::after, .fa-duotone.#{$fa-css-prefix}-clock-seven::after { content: "\e350\e350"; }
.fad.#{$fa-css-prefix}-clock-seven-thirty::after, .fa-duotone.#{$fa-css-prefix}-clock-seven-thirty::after { content: "\e351\e351"; }
.fad.#{$fa-css-prefix}-clock-six::after, .fa-duotone.#{$fa-css-prefix}-clock-six::after { content: "\e352\e352"; }
.fad.#{$fa-css-prefix}-clock-six-thirty::after, .fa-duotone.#{$fa-css-prefix}-clock-six-thirty::after { content: "\e353\e353"; }
.fad.#{$fa-css-prefix}-clock-ten::after, .fa-duotone.#{$fa-css-prefix}-clock-ten::after { content: "\e354\e354"; }
.fad.#{$fa-css-prefix}-clock-ten-thirty::after, .fa-duotone.#{$fa-css-prefix}-clock-ten-thirty::after { content: "\e355\e355"; }
.fad.#{$fa-css-prefix}-clock-three::after, .fa-duotone.#{$fa-css-prefix}-clock-three::after { content: "\e356\e356"; }
.fad.#{$fa-css-prefix}-clock-three-thirty::after, .fa-duotone.#{$fa-css-prefix}-clock-three-thirty::after { content: "\e357\e357"; }
.fad.#{$fa-css-prefix}-clock-twelve::after, .fa-duotone.#{$fa-css-prefix}-clock-twelve::after { content: "\e358\e358"; }
.fad.#{$fa-css-prefix}-clock-twelve-thirty::after, .fa-duotone.#{$fa-css-prefix}-clock-twelve-thirty::after { content: "\e359\e359"; }
.fad.#{$fa-css-prefix}-clock-two::after, .fa-duotone.#{$fa-css-prefix}-clock-two::after { content: "\e35a\e35a"; }
.fad.#{$fa-css-prefix}-clock-two-thirty::after, .fa-duotone.#{$fa-css-prefix}-clock-two-thirty::after { content: "\e35b\e35b"; }
.fad.#{$fa-css-prefix}-clone::after, .fa-duotone.#{$fa-css-prefix}-clone::after { content: "\f24d\f24d"; }
.fad.#{$fa-css-prefix}-closed-captioning::after, .fa-duotone.#{$fa-css-prefix}-closed-captioning::after { content: "\f20a\f20a"; }
.fad.#{$fa-css-prefix}-closed-captioning-slash::after, .fa-duotone.#{$fa-css-prefix}-closed-captioning-slash::after { content: "\e135\e135"; }
.fad.#{$fa-css-prefix}-clothes-hanger::after, .fa-duotone.#{$fa-css-prefix}-clothes-hanger::after { content: "\e136\e136"; }
.fad.#{$fa-css-prefix}-cloud::after, .fa-duotone.#{$fa-css-prefix}-cloud::after { content: "\f0c2\f0c2"; }
.fad.#{$fa-css-prefix}-cloud-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-cloud-arrow-down::after { content: "\f0ed\f0ed"; }
.fad.#{$fa-css-prefix}-cloud-download::after, .fa-duotone.#{$fa-css-prefix}-cloud-download::after { content: "\f0ed\f0ed"; }
.fad.#{$fa-css-prefix}-cloud-download-alt::after, .fa-duotone.#{$fa-css-prefix}-cloud-download-alt::after { content: "\f0ed\f0ed"; }
.fad.#{$fa-css-prefix}-cloud-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-cloud-arrow-up::after { content: "\f0ee\f0ee"; }
.fad.#{$fa-css-prefix}-cloud-upload::after, .fa-duotone.#{$fa-css-prefix}-cloud-upload::after { content: "\f0ee\f0ee"; }
.fad.#{$fa-css-prefix}-cloud-upload-alt::after, .fa-duotone.#{$fa-css-prefix}-cloud-upload-alt::after { content: "\f0ee\f0ee"; }
.fad.#{$fa-css-prefix}-cloud-bolt::after, .fa-duotone.#{$fa-css-prefix}-cloud-bolt::after { content: "\f76c\f76c"; }
.fad.#{$fa-css-prefix}-thunderstorm::after, .fa-duotone.#{$fa-css-prefix}-thunderstorm::after { content: "\f76c\f76c"; }
.fad.#{$fa-css-prefix}-cloud-bolt-moon::after, .fa-duotone.#{$fa-css-prefix}-cloud-bolt-moon::after { content: "\f76d\f76d"; }
.fad.#{$fa-css-prefix}-thunderstorm-moon::after, .fa-duotone.#{$fa-css-prefix}-thunderstorm-moon::after { content: "\f76d\f76d"; }
.fad.#{$fa-css-prefix}-cloud-bolt-sun::after, .fa-duotone.#{$fa-css-prefix}-cloud-bolt-sun::after { content: "\f76e\f76e"; }
.fad.#{$fa-css-prefix}-thunderstorm-sun::after, .fa-duotone.#{$fa-css-prefix}-thunderstorm-sun::after { content: "\f76e\f76e"; }
.fad.#{$fa-css-prefix}-cloud-check::after, .fa-duotone.#{$fa-css-prefix}-cloud-check::after { content: "\e35c\e35c"; }
.fad.#{$fa-css-prefix}-cloud-drizzle::after, .fa-duotone.#{$fa-css-prefix}-cloud-drizzle::after { content: "\f738\f738"; }
.fad.#{$fa-css-prefix}-cloud-exclamation::after, .fa-duotone.#{$fa-css-prefix}-cloud-exclamation::after { content: "\e491\e491"; }
.fad.#{$fa-css-prefix}-cloud-fog::after, .fa-duotone.#{$fa-css-prefix}-cloud-fog::after { content: "\f74e\f74e"; }
.fad.#{$fa-css-prefix}-fog::after, .fa-duotone.#{$fa-css-prefix}-fog::after { content: "\f74e\f74e"; }
.fad.#{$fa-css-prefix}-cloud-hail::after, .fa-duotone.#{$fa-css-prefix}-cloud-hail::after { content: "\f739\f739"; }
.fad.#{$fa-css-prefix}-cloud-hail-mixed::after, .fa-duotone.#{$fa-css-prefix}-cloud-hail-mixed::after { content: "\f73a\f73a"; }
.fad.#{$fa-css-prefix}-cloud-meatball::after, .fa-duotone.#{$fa-css-prefix}-cloud-meatball::after { content: "\f73b\f73b"; }
.fad.#{$fa-css-prefix}-cloud-minus::after, .fa-duotone.#{$fa-css-prefix}-cloud-minus::after { content: "\e35d\e35d"; }
.fad.#{$fa-css-prefix}-cloud-moon::after, .fa-duotone.#{$fa-css-prefix}-cloud-moon::after { content: "\f6c3\f6c3"; }
.fad.#{$fa-css-prefix}-cloud-moon-rain::after, .fa-duotone.#{$fa-css-prefix}-cloud-moon-rain::after { content: "\f73c\f73c"; }
.fad.#{$fa-css-prefix}-cloud-music::after, .fa-duotone.#{$fa-css-prefix}-cloud-music::after { content: "\f8ae\f8ae"; }
.fad.#{$fa-css-prefix}-cloud-plus::after, .fa-duotone.#{$fa-css-prefix}-cloud-plus::after { content: "\e35e\e35e"; }
.fad.#{$fa-css-prefix}-cloud-question::after, .fa-duotone.#{$fa-css-prefix}-cloud-question::after { content: "\e492\e492"; }
.fad.#{$fa-css-prefix}-cloud-rain::after, .fa-duotone.#{$fa-css-prefix}-cloud-rain::after { content: "\f73d\f73d"; }
.fad.#{$fa-css-prefix}-cloud-rainbow::after, .fa-duotone.#{$fa-css-prefix}-cloud-rainbow::after { content: "\f73e\f73e"; }
.fad.#{$fa-css-prefix}-cloud-showers::after, .fa-duotone.#{$fa-css-prefix}-cloud-showers::after { content: "\f73f\f73f"; }
.fad.#{$fa-css-prefix}-cloud-showers-heavy::after, .fa-duotone.#{$fa-css-prefix}-cloud-showers-heavy::after { content: "\f740\f740"; }
.fad.#{$fa-css-prefix}-cloud-slash::after, .fa-duotone.#{$fa-css-prefix}-cloud-slash::after { content: "\e137\e137"; }
.fad.#{$fa-css-prefix}-cloud-sleet::after, .fa-duotone.#{$fa-css-prefix}-cloud-sleet::after { content: "\f741\f741"; }
.fad.#{$fa-css-prefix}-cloud-snow::after, .fa-duotone.#{$fa-css-prefix}-cloud-snow::after { content: "\f742\f742"; }
.fad.#{$fa-css-prefix}-cloud-sun::after, .fa-duotone.#{$fa-css-prefix}-cloud-sun::after { content: "\f6c4\f6c4"; }
.fad.#{$fa-css-prefix}-cloud-sun-rain::after, .fa-duotone.#{$fa-css-prefix}-cloud-sun-rain::after { content: "\f743\f743"; }
.fad.#{$fa-css-prefix}-cloud-word::after, .fa-duotone.#{$fa-css-prefix}-cloud-word::after { content: "\e138\e138"; }
.fad.#{$fa-css-prefix}-cloud-xmark::after, .fa-duotone.#{$fa-css-prefix}-cloud-xmark::after { content: "\e35f\e35f"; }
.fad.#{$fa-css-prefix}-clouds::after, .fa-duotone.#{$fa-css-prefix}-clouds::after { content: "\f744\f744"; }
.fad.#{$fa-css-prefix}-clouds-moon::after, .fa-duotone.#{$fa-css-prefix}-clouds-moon::after { content: "\f745\f745"; }
.fad.#{$fa-css-prefix}-clouds-sun::after, .fa-duotone.#{$fa-css-prefix}-clouds-sun::after { content: "\f746\f746"; }
.fad.#{$fa-css-prefix}-clover::after, .fa-duotone.#{$fa-css-prefix}-clover::after { content: "\e139\e139"; }
.fad.#{$fa-css-prefix}-club::after, .fa-duotone.#{$fa-css-prefix}-club::after { content: "\f327\f327"; }
.fad.#{$fa-css-prefix}-coconut::after, .fa-duotone.#{$fa-css-prefix}-coconut::after { content: "\e2f6\e2f6"; }
.fad.#{$fa-css-prefix}-code::after, .fa-duotone.#{$fa-css-prefix}-code::after { content: "\f121\f121"; }
.fad.#{$fa-css-prefix}-code-branch::after, .fa-duotone.#{$fa-css-prefix}-code-branch::after { content: "\f126\f126"; }
.fad.#{$fa-css-prefix}-code-commit::after, .fa-duotone.#{$fa-css-prefix}-code-commit::after { content: "\f386\f386"; }
.fad.#{$fa-css-prefix}-code-compare::after, .fa-duotone.#{$fa-css-prefix}-code-compare::after { content: "\e13a\e13a"; }
.fad.#{$fa-css-prefix}-code-fork::after, .fa-duotone.#{$fa-css-prefix}-code-fork::after { content: "\e13b\e13b"; }
.fad.#{$fa-css-prefix}-code-merge::after, .fa-duotone.#{$fa-css-prefix}-code-merge::after { content: "\f387\f387"; }
.fad.#{$fa-css-prefix}-code-pull-request::after, .fa-duotone.#{$fa-css-prefix}-code-pull-request::after { content: "\e13c\e13c"; }
.fad.#{$fa-css-prefix}-code-pull-request-closed::after, .fa-duotone.#{$fa-css-prefix}-code-pull-request-closed::after { content: "\e3f9\e3f9"; }
.fad.#{$fa-css-prefix}-code-pull-request-draft::after, .fa-duotone.#{$fa-css-prefix}-code-pull-request-draft::after { content: "\e3fa\e3fa"; }
.fad.#{$fa-css-prefix}-code-simple::after, .fa-duotone.#{$fa-css-prefix}-code-simple::after { content: "\e13d\e13d"; }
.fad.#{$fa-css-prefix}-coffee-bean::after, .fa-duotone.#{$fa-css-prefix}-coffee-bean::after { content: "\e13e\e13e"; }
.fad.#{$fa-css-prefix}-coffee-beans::after, .fa-duotone.#{$fa-css-prefix}-coffee-beans::after { content: "\e13f\e13f"; }
.fad.#{$fa-css-prefix}-coffee-pot::after, .fa-duotone.#{$fa-css-prefix}-coffee-pot::after { content: "\e002\e002"; }
.fad.#{$fa-css-prefix}-coffin::after, .fa-duotone.#{$fa-css-prefix}-coffin::after { content: "\f6c6\f6c6"; }
.fad.#{$fa-css-prefix}-coffin-cross::after, .fa-duotone.#{$fa-css-prefix}-coffin-cross::after { content: "\e051\e051"; }
.fad.#{$fa-css-prefix}-coin::after, .fa-duotone.#{$fa-css-prefix}-coin::after { content: "\f85c\f85c"; }
.fad.#{$fa-css-prefix}-coin-blank::after, .fa-duotone.#{$fa-css-prefix}-coin-blank::after { content: "\e3fb\e3fb"; }
.fad.#{$fa-css-prefix}-coin-front::after, .fa-duotone.#{$fa-css-prefix}-coin-front::after { content: "\e3fc\e3fc"; }
.fad.#{$fa-css-prefix}-coin-vertical::after, .fa-duotone.#{$fa-css-prefix}-coin-vertical::after { content: "\e3fd\e3fd"; }
.fad.#{$fa-css-prefix}-coins::after, .fa-duotone.#{$fa-css-prefix}-coins::after { content: "\f51e\f51e"; }
.fad.#{$fa-css-prefix}-colon::after, .fa-duotone.#{$fa-css-prefix}-colon::after { content: "\3a\3a"; }
.fad.#{$fa-css-prefix}-colon-sign::after, .fa-duotone.#{$fa-css-prefix}-colon-sign::after { content: "\e140\e140"; }
.fad.#{$fa-css-prefix}-columns-3::after, .fa-duotone.#{$fa-css-prefix}-columns-3::after { content: "\e361\e361"; }
.fad.#{$fa-css-prefix}-comet::after, .fa-duotone.#{$fa-css-prefix}-comet::after { content: "\e003\e003"; }
.fad.#{$fa-css-prefix}-comma::after, .fa-duotone.#{$fa-css-prefix}-comma::after { content: "\2c\2c"; }
.fad.#{$fa-css-prefix}-command::after, .fa-duotone.#{$fa-css-prefix}-command::after { content: "\e142\e142"; }
.fad.#{$fa-css-prefix}-comment::after, .fa-duotone.#{$fa-css-prefix}-comment::after { content: "\f075\f075"; }
.fad.#{$fa-css-prefix}-comment-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-comment-arrow-down::after { content: "\e143\e143"; }
.fad.#{$fa-css-prefix}-comment-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-comment-arrow-up::after { content: "\e144\e144"; }
.fad.#{$fa-css-prefix}-comment-arrow-up-right::after, .fa-duotone.#{$fa-css-prefix}-comment-arrow-up-right::after { content: "\e145\e145"; }
.fad.#{$fa-css-prefix}-comment-captions::after, .fa-duotone.#{$fa-css-prefix}-comment-captions::after { content: "\e146\e146"; }
.fad.#{$fa-css-prefix}-comment-check::after, .fa-duotone.#{$fa-css-prefix}-comment-check::after { content: "\f4ac\f4ac"; }
.fad.#{$fa-css-prefix}-comment-code::after, .fa-duotone.#{$fa-css-prefix}-comment-code::after { content: "\e147\e147"; }
.fad.#{$fa-css-prefix}-comment-dollar::after, .fa-duotone.#{$fa-css-prefix}-comment-dollar::after { content: "\f651\f651"; }
.fad.#{$fa-css-prefix}-comment-dots::after, .fa-duotone.#{$fa-css-prefix}-comment-dots::after { content: "\f4ad\f4ad"; }
.fad.#{$fa-css-prefix}-commenting::after, .fa-duotone.#{$fa-css-prefix}-commenting::after { content: "\f4ad\f4ad"; }
.fad.#{$fa-css-prefix}-comment-exclamation::after, .fa-duotone.#{$fa-css-prefix}-comment-exclamation::after { content: "\f4af\f4af"; }
.fad.#{$fa-css-prefix}-comment-image::after, .fa-duotone.#{$fa-css-prefix}-comment-image::after { content: "\e148\e148"; }
.fad.#{$fa-css-prefix}-comment-lines::after, .fa-duotone.#{$fa-css-prefix}-comment-lines::after { content: "\f4b0\f4b0"; }
.fad.#{$fa-css-prefix}-comment-medical::after, .fa-duotone.#{$fa-css-prefix}-comment-medical::after { content: "\f7f5\f7f5"; }
.fad.#{$fa-css-prefix}-comment-middle::after, .fa-duotone.#{$fa-css-prefix}-comment-middle::after { content: "\e149\e149"; }
.fad.#{$fa-css-prefix}-comment-middle-top::after, .fa-duotone.#{$fa-css-prefix}-comment-middle-top::after { content: "\e14a\e14a"; }
.fad.#{$fa-css-prefix}-comment-minus::after, .fa-duotone.#{$fa-css-prefix}-comment-minus::after { content: "\f4b1\f4b1"; }
.fad.#{$fa-css-prefix}-comment-music::after, .fa-duotone.#{$fa-css-prefix}-comment-music::after { content: "\f8b0\f8b0"; }
.fad.#{$fa-css-prefix}-comment-pen::after, .fa-duotone.#{$fa-css-prefix}-comment-pen::after { content: "\f4ae\f4ae"; }
.fad.#{$fa-css-prefix}-comment-edit::after, .fa-duotone.#{$fa-css-prefix}-comment-edit::after { content: "\f4ae\f4ae"; }
.fad.#{$fa-css-prefix}-comment-plus::after, .fa-duotone.#{$fa-css-prefix}-comment-plus::after { content: "\f4b2\f4b2"; }
.fad.#{$fa-css-prefix}-comment-question::after, .fa-duotone.#{$fa-css-prefix}-comment-question::after { content: "\e14b\e14b"; }
.fad.#{$fa-css-prefix}-comment-quote::after, .fa-duotone.#{$fa-css-prefix}-comment-quote::after { content: "\e14c\e14c"; }
.fad.#{$fa-css-prefix}-comment-slash::after, .fa-duotone.#{$fa-css-prefix}-comment-slash::after { content: "\f4b3\f4b3"; }
.fad.#{$fa-css-prefix}-comment-smile::after, .fa-duotone.#{$fa-css-prefix}-comment-smile::after { content: "\f4b4\f4b4"; }
.fad.#{$fa-css-prefix}-comment-sms::after, .fa-duotone.#{$fa-css-prefix}-comment-sms::after { content: "\f7cd\f7cd"; }
.fad.#{$fa-css-prefix}-sms::after, .fa-duotone.#{$fa-css-prefix}-sms::after { content: "\f7cd\f7cd"; }
.fad.#{$fa-css-prefix}-comment-text::after, .fa-duotone.#{$fa-css-prefix}-comment-text::after { content: "\e14d\e14d"; }
.fad.#{$fa-css-prefix}-comment-xmark::after, .fa-duotone.#{$fa-css-prefix}-comment-xmark::after { content: "\f4b5\f4b5"; }
.fad.#{$fa-css-prefix}-comment-times::after, .fa-duotone.#{$fa-css-prefix}-comment-times::after { content: "\f4b5\f4b5"; }
.fad.#{$fa-css-prefix}-comments::after, .fa-duotone.#{$fa-css-prefix}-comments::after { content: "\f086\f086"; }
.fad.#{$fa-css-prefix}-comments-dollar::after, .fa-duotone.#{$fa-css-prefix}-comments-dollar::after { content: "\f653\f653"; }
.fad.#{$fa-css-prefix}-comments-question::after, .fa-duotone.#{$fa-css-prefix}-comments-question::after { content: "\e14e\e14e"; }
.fad.#{$fa-css-prefix}-comments-question-check::after, .fa-duotone.#{$fa-css-prefix}-comments-question-check::after { content: "\e14f\e14f"; }
.fad.#{$fa-css-prefix}-compact-disc::after, .fa-duotone.#{$fa-css-prefix}-compact-disc::after { content: "\f51f\f51f"; }
.fad.#{$fa-css-prefix}-compass::after, .fa-duotone.#{$fa-css-prefix}-compass::after { content: "\f14e\f14e"; }
.fad.#{$fa-css-prefix}-compass-drafting::after, .fa-duotone.#{$fa-css-prefix}-compass-drafting::after { content: "\f568\f568"; }
.fad.#{$fa-css-prefix}-drafting-compass::after, .fa-duotone.#{$fa-css-prefix}-drafting-compass::after { content: "\f568\f568"; }
.fad.#{$fa-css-prefix}-compass-slash::after, .fa-duotone.#{$fa-css-prefix}-compass-slash::after { content: "\f5e9\f5e9"; }
.fad.#{$fa-css-prefix}-compress::after, .fa-duotone.#{$fa-css-prefix}-compress::after { content: "\f066\f066"; }
.fad.#{$fa-css-prefix}-compress-wide::after, .fa-duotone.#{$fa-css-prefix}-compress-wide::after { content: "\f326\f326"; }
.fad.#{$fa-css-prefix}-computer-classic::after, .fa-duotone.#{$fa-css-prefix}-computer-classic::after { content: "\f8b1\f8b1"; }
.fad.#{$fa-css-prefix}-computer-mouse::after, .fa-duotone.#{$fa-css-prefix}-computer-mouse::after { content: "\f8cc\f8cc"; }
.fad.#{$fa-css-prefix}-mouse::after, .fa-duotone.#{$fa-css-prefix}-mouse::after { content: "\f8cc\f8cc"; }
.fad.#{$fa-css-prefix}-computer-mouse-scrollwheel::after, .fa-duotone.#{$fa-css-prefix}-computer-mouse-scrollwheel::after { content: "\f8cd\f8cd"; }
.fad.#{$fa-css-prefix}-mouse-alt::after, .fa-duotone.#{$fa-css-prefix}-mouse-alt::after { content: "\f8cd\f8cd"; }
.fad.#{$fa-css-prefix}-computer-speaker::after, .fa-duotone.#{$fa-css-prefix}-computer-speaker::after { content: "\f8b2\f8b2"; }
.fad.#{$fa-css-prefix}-container-storage::after, .fa-duotone.#{$fa-css-prefix}-container-storage::after { content: "\f4b7\f4b7"; }
.fad.#{$fa-css-prefix}-conveyor-belt::after, .fa-duotone.#{$fa-css-prefix}-conveyor-belt::after { content: "\f46e\f46e"; }
.fad.#{$fa-css-prefix}-conveyor-belt-boxes::after, .fa-duotone.#{$fa-css-prefix}-conveyor-belt-boxes::after { content: "\f46f\f46f"; }
.fad.#{$fa-css-prefix}-conveyor-belt-alt::after, .fa-duotone.#{$fa-css-prefix}-conveyor-belt-alt::after { content: "\f46f\f46f"; }
.fad.#{$fa-css-prefix}-conveyor-belt-empty::after, .fa-duotone.#{$fa-css-prefix}-conveyor-belt-empty::after { content: "\e150\e150"; }
.fad.#{$fa-css-prefix}-cookie::after, .fa-duotone.#{$fa-css-prefix}-cookie::after { content: "\f563\f563"; }
.fad.#{$fa-css-prefix}-cookie-bite::after, .fa-duotone.#{$fa-css-prefix}-cookie-bite::after { content: "\f564\f564"; }
.fad.#{$fa-css-prefix}-copy::after, .fa-duotone.#{$fa-css-prefix}-copy::after { content: "\f0c5\f0c5"; }
.fad.#{$fa-css-prefix}-copyright::after, .fa-duotone.#{$fa-css-prefix}-copyright::after { content: "\f1f9\f1f9"; }
.fad.#{$fa-css-prefix}-corn::after, .fa-duotone.#{$fa-css-prefix}-corn::after { content: "\f6c7\f6c7"; }
.fad.#{$fa-css-prefix}-corner::after, .fa-duotone.#{$fa-css-prefix}-corner::after { content: "\e3fe\e3fe"; }
.fad.#{$fa-css-prefix}-couch::after, .fa-duotone.#{$fa-css-prefix}-couch::after { content: "\f4b8\f4b8"; }
.fad.#{$fa-css-prefix}-cow::after, .fa-duotone.#{$fa-css-prefix}-cow::after { content: "\f6c8\f6c8"; }
.fad.#{$fa-css-prefix}-cowbell::after, .fa-duotone.#{$fa-css-prefix}-cowbell::after { content: "\f8b3\f8b3"; }
.fad.#{$fa-css-prefix}-cowbell-circle-plus::after, .fa-duotone.#{$fa-css-prefix}-cowbell-circle-plus::after { content: "\f8b4\f8b4"; }
.fad.#{$fa-css-prefix}-cowbell-more::after, .fa-duotone.#{$fa-css-prefix}-cowbell-more::after { content: "\f8b4\f8b4"; }
.fad.#{$fa-css-prefix}-crab::after, .fa-duotone.#{$fa-css-prefix}-crab::after { content: "\e3ff\e3ff"; }
.fad.#{$fa-css-prefix}-crate-apple::after, .fa-duotone.#{$fa-css-prefix}-crate-apple::after { content: "\f6b1\f6b1"; }
.fad.#{$fa-css-prefix}-apple-crate::after, .fa-duotone.#{$fa-css-prefix}-apple-crate::after { content: "\f6b1\f6b1"; }
.fad.#{$fa-css-prefix}-crate-empty::after, .fa-duotone.#{$fa-css-prefix}-crate-empty::after { content: "\e151\e151"; }
.fad.#{$fa-css-prefix}-credit-card::after, .fa-duotone.#{$fa-css-prefix}-credit-card::after { content: "\f09d\f09d"; }
.fad.#{$fa-css-prefix}-credit-card-alt::after, .fa-duotone.#{$fa-css-prefix}-credit-card-alt::after { content: "\f09d\f09d"; }
.fad.#{$fa-css-prefix}-credit-card-blank::after, .fa-duotone.#{$fa-css-prefix}-credit-card-blank::after { content: "\f389\f389"; }
.fad.#{$fa-css-prefix}-credit-card-front::after, .fa-duotone.#{$fa-css-prefix}-credit-card-front::after { content: "\f38a\f38a"; }
.fad.#{$fa-css-prefix}-cricket-bat-ball::after, .fa-duotone.#{$fa-css-prefix}-cricket-bat-ball::after { content: "\f449\f449"; }
.fad.#{$fa-css-prefix}-cricket::after, .fa-duotone.#{$fa-css-prefix}-cricket::after { content: "\f449\f449"; }
.fad.#{$fa-css-prefix}-croissant::after, .fa-duotone.#{$fa-css-prefix}-croissant::after { content: "\f7f6\f7f6"; }
.fad.#{$fa-css-prefix}-crop::after, .fa-duotone.#{$fa-css-prefix}-crop::after { content: "\f125\f125"; }
.fad.#{$fa-css-prefix}-crop-simple::after, .fa-duotone.#{$fa-css-prefix}-crop-simple::after { content: "\f565\f565"; }
.fad.#{$fa-css-prefix}-crop-alt::after, .fa-duotone.#{$fa-css-prefix}-crop-alt::after { content: "\f565\f565"; }
.fad.#{$fa-css-prefix}-cross::after, .fa-duotone.#{$fa-css-prefix}-cross::after { content: "\f654\f654"; }
.fad.#{$fa-css-prefix}-crosshairs::after, .fa-duotone.#{$fa-css-prefix}-crosshairs::after { content: "\f05b\f05b"; }
.fad.#{$fa-css-prefix}-crow::after, .fa-duotone.#{$fa-css-prefix}-crow::after { content: "\f520\f520"; }
.fad.#{$fa-css-prefix}-crown::after, .fa-duotone.#{$fa-css-prefix}-crown::after { content: "\f521\f521"; }
.fad.#{$fa-css-prefix}-crutch::after, .fa-duotone.#{$fa-css-prefix}-crutch::after { content: "\f7f7\f7f7"; }
.fad.#{$fa-css-prefix}-crutches::after, .fa-duotone.#{$fa-css-prefix}-crutches::after { content: "\f7f8\f7f8"; }
.fad.#{$fa-css-prefix}-cruzeiro-sign::after, .fa-duotone.#{$fa-css-prefix}-cruzeiro-sign::after { content: "\e152\e152"; }
.fad.#{$fa-css-prefix}-crystal-ball::after, .fa-duotone.#{$fa-css-prefix}-crystal-ball::after { content: "\e362\e362"; }
.fad.#{$fa-css-prefix}-cube::after, .fa-duotone.#{$fa-css-prefix}-cube::after { content: "\f1b2\f1b2"; }
.fad.#{$fa-css-prefix}-cubes::after, .fa-duotone.#{$fa-css-prefix}-cubes::after { content: "\f1b3\f1b3"; }
.fad.#{$fa-css-prefix}-cucumber::after, .fa-duotone.#{$fa-css-prefix}-cucumber::after { content: "\e401\e401"; }
.fad.#{$fa-css-prefix}-cup-straw::after, .fa-duotone.#{$fa-css-prefix}-cup-straw::after { content: "\e363\e363"; }
.fad.#{$fa-css-prefix}-cup-straw-swoosh::after, .fa-duotone.#{$fa-css-prefix}-cup-straw-swoosh::after { content: "\e364\e364"; }
.fad.#{$fa-css-prefix}-cup-togo::after, .fa-duotone.#{$fa-css-prefix}-cup-togo::after { content: "\f6c5\f6c5"; }
.fad.#{$fa-css-prefix}-coffee-togo::after, .fa-duotone.#{$fa-css-prefix}-coffee-togo::after { content: "\f6c5\f6c5"; }
.fad.#{$fa-css-prefix}-cupcake::after, .fa-duotone.#{$fa-css-prefix}-cupcake::after { content: "\e402\e402"; }
.fad.#{$fa-css-prefix}-curling-stone::after, .fa-duotone.#{$fa-css-prefix}-curling-stone::after { content: "\f44a\f44a"; }
.fad.#{$fa-css-prefix}-curling::after, .fa-duotone.#{$fa-css-prefix}-curling::after { content: "\f44a\f44a"; }
.fad.#{$fa-css-prefix}-custard::after, .fa-duotone.#{$fa-css-prefix}-custard::after { content: "\e403\e403"; }
.fad.#{$fa-css-prefix}-d::after, .fa-duotone.#{$fa-css-prefix}-d::after { content: "\44\44"; }
.fad.#{$fa-css-prefix}-dagger::after, .fa-duotone.#{$fa-css-prefix}-dagger::after { content: "\f6cb\f6cb"; }
.fad.#{$fa-css-prefix}-dash::after, .fa-duotone.#{$fa-css-prefix}-dash::after { content: "\e404\e404"; }
.fad.#{$fa-css-prefix}-database::after, .fa-duotone.#{$fa-css-prefix}-database::after { content: "\f1c0\f1c0"; }
.fad.#{$fa-css-prefix}-deer::after, .fa-duotone.#{$fa-css-prefix}-deer::after { content: "\f78e\f78e"; }
.fad.#{$fa-css-prefix}-deer-rudolph::after, .fa-duotone.#{$fa-css-prefix}-deer-rudolph::after { content: "\f78f\f78f"; }
.fad.#{$fa-css-prefix}-delete-left::after, .fa-duotone.#{$fa-css-prefix}-delete-left::after { content: "\f55a\f55a"; }
.fad.#{$fa-css-prefix}-backspace::after, .fa-duotone.#{$fa-css-prefix}-backspace::after { content: "\f55a\f55a"; }
.fad.#{$fa-css-prefix}-delete-right::after, .fa-duotone.#{$fa-css-prefix}-delete-right::after { content: "\e154\e154"; }
.fad.#{$fa-css-prefix}-democrat::after, .fa-duotone.#{$fa-css-prefix}-democrat::after { content: "\f747\f747"; }
.fad.#{$fa-css-prefix}-desktop::after, .fa-duotone.#{$fa-css-prefix}-desktop::after { content: "\f390\f390"; }
.fad.#{$fa-css-prefix}-desktop-alt::after, .fa-duotone.#{$fa-css-prefix}-desktop-alt::after { content: "\f390\f390"; }
.fad.#{$fa-css-prefix}-desktop-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-desktop-arrow-down::after { content: "\e155\e155"; }
.fad.#{$fa-css-prefix}-dharmachakra::after, .fa-duotone.#{$fa-css-prefix}-dharmachakra::after { content: "\f655\f655"; }
.fad.#{$fa-css-prefix}-diagram-cells::after, .fa-duotone.#{$fa-css-prefix}-diagram-cells::after { content: "\e475\e475"; }
.fad.#{$fa-css-prefix}-diagram-lean-canvas::after, .fa-duotone.#{$fa-css-prefix}-diagram-lean-canvas::after { content: "\e156\e156"; }
.fad.#{$fa-css-prefix}-diagram-nested::after, .fa-duotone.#{$fa-css-prefix}-diagram-nested::after { content: "\e157\e157"; }
.fad.#{$fa-css-prefix}-diagram-next::after, .fa-duotone.#{$fa-css-prefix}-diagram-next::after { content: "\e476\e476"; }
.fad.#{$fa-css-prefix}-diagram-predecessor::after, .fa-duotone.#{$fa-css-prefix}-diagram-predecessor::after { content: "\e477\e477"; }
.fad.#{$fa-css-prefix}-diagram-previous::after, .fa-duotone.#{$fa-css-prefix}-diagram-previous::after { content: "\e478\e478"; }
.fad.#{$fa-css-prefix}-diagram-project::after, .fa-duotone.#{$fa-css-prefix}-diagram-project::after { content: "\f542\f542"; }
.fad.#{$fa-css-prefix}-project-diagram::after, .fa-duotone.#{$fa-css-prefix}-project-diagram::after { content: "\f542\f542"; }
.fad.#{$fa-css-prefix}-diagram-sankey::after, .fa-duotone.#{$fa-css-prefix}-diagram-sankey::after { content: "\e158\e158"; }
.fad.#{$fa-css-prefix}-diagram-subtask::after, .fa-duotone.#{$fa-css-prefix}-diagram-subtask::after { content: "\e479\e479"; }
.fad.#{$fa-css-prefix}-diagram-successor::after, .fa-duotone.#{$fa-css-prefix}-diagram-successor::after { content: "\e47a\e47a"; }
.fad.#{$fa-css-prefix}-diagram-venn::after, .fa-duotone.#{$fa-css-prefix}-diagram-venn::after { content: "\e15a\e15a"; }
.fad.#{$fa-css-prefix}-dial::after, .fa-duotone.#{$fa-css-prefix}-dial::after { content: "\e15b\e15b"; }
.fad.#{$fa-css-prefix}-dial-med-high::after, .fa-duotone.#{$fa-css-prefix}-dial-med-high::after { content: "\e15b\e15b"; }
.fad.#{$fa-css-prefix}-dial-high::after, .fa-duotone.#{$fa-css-prefix}-dial-high::after { content: "\e15c\e15c"; }
.fad.#{$fa-css-prefix}-dial-low::after, .fa-duotone.#{$fa-css-prefix}-dial-low::after { content: "\e15d\e15d"; }
.fad.#{$fa-css-prefix}-dial-max::after, .fa-duotone.#{$fa-css-prefix}-dial-max::after { content: "\e15e\e15e"; }
.fad.#{$fa-css-prefix}-dial-med::after, .fa-duotone.#{$fa-css-prefix}-dial-med::after { content: "\e15f\e15f"; }
.fad.#{$fa-css-prefix}-dial-med-low::after, .fa-duotone.#{$fa-css-prefix}-dial-med-low::after { content: "\e160\e160"; }
.fad.#{$fa-css-prefix}-dial-min::after, .fa-duotone.#{$fa-css-prefix}-dial-min::after { content: "\e161\e161"; }
.fad.#{$fa-css-prefix}-dial-off::after, .fa-duotone.#{$fa-css-prefix}-dial-off::after { content: "\e162\e162"; }
.fad.#{$fa-css-prefix}-diamond::after, .fa-duotone.#{$fa-css-prefix}-diamond::after { content: "\f219\f219"; }
.fad.#{$fa-css-prefix}-diamond-exclamation::after, .fa-duotone.#{$fa-css-prefix}-diamond-exclamation::after { content: "\e405\e405"; }
.fad.#{$fa-css-prefix}-diamond-turn-right::after, .fa-duotone.#{$fa-css-prefix}-diamond-turn-right::after { content: "\f5eb\f5eb"; }
.fad.#{$fa-css-prefix}-directions::after, .fa-duotone.#{$fa-css-prefix}-directions::after { content: "\f5eb\f5eb"; }
.fad.#{$fa-css-prefix}-dice::after, .fa-duotone.#{$fa-css-prefix}-dice::after { content: "\f522\f522"; }
.fad.#{$fa-css-prefix}-dice-d10::after, .fa-duotone.#{$fa-css-prefix}-dice-d10::after { content: "\f6cd\f6cd"; }
.fad.#{$fa-css-prefix}-dice-d12::after, .fa-duotone.#{$fa-css-prefix}-dice-d12::after { content: "\f6ce\f6ce"; }
.fad.#{$fa-css-prefix}-dice-d20::after, .fa-duotone.#{$fa-css-prefix}-dice-d20::after { content: "\f6cf\f6cf"; }
.fad.#{$fa-css-prefix}-dice-d4::after, .fa-duotone.#{$fa-css-prefix}-dice-d4::after { content: "\f6d0\f6d0"; }
.fad.#{$fa-css-prefix}-dice-d6::after, .fa-duotone.#{$fa-css-prefix}-dice-d6::after { content: "\f6d1\f6d1"; }
.fad.#{$fa-css-prefix}-dice-d8::after, .fa-duotone.#{$fa-css-prefix}-dice-d8::after { content: "\f6d2\f6d2"; }
.fad.#{$fa-css-prefix}-dice-five::after, .fa-duotone.#{$fa-css-prefix}-dice-five::after { content: "\f523\f523"; }
.fad.#{$fa-css-prefix}-dice-four::after, .fa-duotone.#{$fa-css-prefix}-dice-four::after { content: "\f524\f524"; }
.fad.#{$fa-css-prefix}-dice-one::after, .fa-duotone.#{$fa-css-prefix}-dice-one::after { content: "\f525\f525"; }
.fad.#{$fa-css-prefix}-dice-six::after, .fa-duotone.#{$fa-css-prefix}-dice-six::after { content: "\f526\f526"; }
.fad.#{$fa-css-prefix}-dice-three::after, .fa-duotone.#{$fa-css-prefix}-dice-three::after { content: "\f527\f527"; }
.fad.#{$fa-css-prefix}-dice-two::after, .fa-duotone.#{$fa-css-prefix}-dice-two::after { content: "\f528\f528"; }
.fad.#{$fa-css-prefix}-diploma::after, .fa-duotone.#{$fa-css-prefix}-diploma::after { content: "\f5ea\f5ea"; }
.fad.#{$fa-css-prefix}-scroll-ribbon::after, .fa-duotone.#{$fa-css-prefix}-scroll-ribbon::after { content: "\f5ea\f5ea"; }
.fad.#{$fa-css-prefix}-disc-drive::after, .fa-duotone.#{$fa-css-prefix}-disc-drive::after { content: "\f8b5\f8b5"; }
.fad.#{$fa-css-prefix}-disease::after, .fa-duotone.#{$fa-css-prefix}-disease::after { content: "\f7fa\f7fa"; }
.fad.#{$fa-css-prefix}-display::after, .fa-duotone.#{$fa-css-prefix}-display::after { content: "\e163\e163"; }
.fad.#{$fa-css-prefix}-display-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-display-arrow-down::after { content: "\e164\e164"; }
.fad.#{$fa-css-prefix}-display-code::after, .fa-duotone.#{$fa-css-prefix}-display-code::after { content: "\e165\e165"; }
.fad.#{$fa-css-prefix}-desktop-code::after, .fa-duotone.#{$fa-css-prefix}-desktop-code::after { content: "\e165\e165"; }
.fad.#{$fa-css-prefix}-display-medical::after, .fa-duotone.#{$fa-css-prefix}-display-medical::after { content: "\e166\e166"; }
.fad.#{$fa-css-prefix}-desktop-medical::after, .fa-duotone.#{$fa-css-prefix}-desktop-medical::after { content: "\e166\e166"; }
.fad.#{$fa-css-prefix}-display-slash::after, .fa-duotone.#{$fa-css-prefix}-display-slash::after { content: "\e2fa\e2fa"; }
.fad.#{$fa-css-prefix}-desktop-slash::after, .fa-duotone.#{$fa-css-prefix}-desktop-slash::after { content: "\e2fa\e2fa"; }
.fad.#{$fa-css-prefix}-distribute-spacing-horizontal::after, .fa-duotone.#{$fa-css-prefix}-distribute-spacing-horizontal::after { content: "\e365\e365"; }
.fad.#{$fa-css-prefix}-distribute-spacing-vertical::after, .fa-duotone.#{$fa-css-prefix}-distribute-spacing-vertical::after { content: "\e366\e366"; }
.fad.#{$fa-css-prefix}-ditto::after, .fa-duotone.#{$fa-css-prefix}-ditto::after { content: "\22\22"; }
.fad.#{$fa-css-prefix}-divide::after, .fa-duotone.#{$fa-css-prefix}-divide::after { content: "\f529\f529"; }
.fad.#{$fa-css-prefix}-dna::after, .fa-duotone.#{$fa-css-prefix}-dna::after { content: "\f471\f471"; }
.fad.#{$fa-css-prefix}-do-not-enter::after, .fa-duotone.#{$fa-css-prefix}-do-not-enter::after { content: "\f5ec\f5ec"; }
.fad.#{$fa-css-prefix}-dog::after, .fa-duotone.#{$fa-css-prefix}-dog::after { content: "\f6d3\f6d3"; }
.fad.#{$fa-css-prefix}-dog-leashed::after, .fa-duotone.#{$fa-css-prefix}-dog-leashed::after { content: "\f6d4\f6d4"; }
.fad.#{$fa-css-prefix}-dollar-sign::after, .fa-duotone.#{$fa-css-prefix}-dollar-sign::after { content: "\24\24"; }
.fad.#{$fa-css-prefix}-dollar::after, .fa-duotone.#{$fa-css-prefix}-dollar::after { content: "\24\24"; }
.fad.#{$fa-css-prefix}-usd::after, .fa-duotone.#{$fa-css-prefix}-usd::after { content: "\24\24"; }
.fad.#{$fa-css-prefix}-dolly::after, .fa-duotone.#{$fa-css-prefix}-dolly::after { content: "\f472\f472"; }
.fad.#{$fa-css-prefix}-dolly-box::after, .fa-duotone.#{$fa-css-prefix}-dolly-box::after { content: "\f472\f472"; }
.fad.#{$fa-css-prefix}-dolly-empty::after, .fa-duotone.#{$fa-css-prefix}-dolly-empty::after { content: "\f473\f473"; }
.fad.#{$fa-css-prefix}-dolphin::after, .fa-duotone.#{$fa-css-prefix}-dolphin::after { content: "\e168\e168"; }
.fad.#{$fa-css-prefix}-dong-sign::after, .fa-duotone.#{$fa-css-prefix}-dong-sign::after { content: "\e169\e169"; }
.fad.#{$fa-css-prefix}-donut::after, .fa-duotone.#{$fa-css-prefix}-donut::after { content: "\e406\e406"; }
.fad.#{$fa-css-prefix}-doughnut::after, .fa-duotone.#{$fa-css-prefix}-doughnut::after { content: "\e406\e406"; }
.fad.#{$fa-css-prefix}-door-closed::after, .fa-duotone.#{$fa-css-prefix}-door-closed::after { content: "\f52a\f52a"; }
.fad.#{$fa-css-prefix}-door-open::after, .fa-duotone.#{$fa-css-prefix}-door-open::after { content: "\f52b\f52b"; }
.fad.#{$fa-css-prefix}-dove::after, .fa-duotone.#{$fa-css-prefix}-dove::after { content: "\f4ba\f4ba"; }
.fad.#{$fa-css-prefix}-down::after, .fa-duotone.#{$fa-css-prefix}-down::after { content: "\f354\f354"; }
.fad.#{$fa-css-prefix}-arrow-alt-down::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-down::after { content: "\f354\f354"; }
.fad.#{$fa-css-prefix}-down-from-dotted-line::after, .fa-duotone.#{$fa-css-prefix}-down-from-dotted-line::after { content: "\e407\e407"; }
.fad.#{$fa-css-prefix}-down-from-line::after, .fa-duotone.#{$fa-css-prefix}-down-from-line::after { content: "\f349\f349"; }
.fad.#{$fa-css-prefix}-arrow-alt-from-top::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-from-top::after { content: "\f349\f349"; }
.fad.#{$fa-css-prefix}-down-left::after, .fa-duotone.#{$fa-css-prefix}-down-left::after { content: "\e16a\e16a"; }
.fad.#{$fa-css-prefix}-down-left-and-up-right-to-center::after, .fa-duotone.#{$fa-css-prefix}-down-left-and-up-right-to-center::after { content: "\f422\f422"; }
.fad.#{$fa-css-prefix}-compress-alt::after, .fa-duotone.#{$fa-css-prefix}-compress-alt::after { content: "\f422\f422"; }
.fad.#{$fa-css-prefix}-down-long::after, .fa-duotone.#{$fa-css-prefix}-down-long::after { content: "\f309\f309"; }
.fad.#{$fa-css-prefix}-long-arrow-alt-down::after, .fa-duotone.#{$fa-css-prefix}-long-arrow-alt-down::after { content: "\f309\f309"; }
.fad.#{$fa-css-prefix}-down-right::after, .fa-duotone.#{$fa-css-prefix}-down-right::after { content: "\e16b\e16b"; }
.fad.#{$fa-css-prefix}-down-to-dotted-line::after, .fa-duotone.#{$fa-css-prefix}-down-to-dotted-line::after { content: "\e408\e408"; }
.fad.#{$fa-css-prefix}-down-to-line::after, .fa-duotone.#{$fa-css-prefix}-down-to-line::after { content: "\f34a\f34a"; }
.fad.#{$fa-css-prefix}-arrow-alt-to-bottom::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-to-bottom::after { content: "\f34a\f34a"; }
.fad.#{$fa-css-prefix}-download::after, .fa-duotone.#{$fa-css-prefix}-download::after { content: "\f019\f019"; }
.fad.#{$fa-css-prefix}-dragon::after, .fa-duotone.#{$fa-css-prefix}-dragon::after { content: "\f6d5\f6d5"; }
.fad.#{$fa-css-prefix}-draw-circle::after, .fa-duotone.#{$fa-css-prefix}-draw-circle::after { content: "\f5ed\f5ed"; }
.fad.#{$fa-css-prefix}-draw-polygon::after, .fa-duotone.#{$fa-css-prefix}-draw-polygon::after { content: "\f5ee\f5ee"; }
.fad.#{$fa-css-prefix}-draw-square::after, .fa-duotone.#{$fa-css-prefix}-draw-square::after { content: "\f5ef\f5ef"; }
.fad.#{$fa-css-prefix}-dreidel::after, .fa-duotone.#{$fa-css-prefix}-dreidel::after { content: "\f792\f792"; }
.fad.#{$fa-css-prefix}-drone::after, .fa-duotone.#{$fa-css-prefix}-drone::after { content: "\f85f\f85f"; }
.fad.#{$fa-css-prefix}-drone-front::after, .fa-duotone.#{$fa-css-prefix}-drone-front::after { content: "\f860\f860"; }
.fad.#{$fa-css-prefix}-drone-alt::after, .fa-duotone.#{$fa-css-prefix}-drone-alt::after { content: "\f860\f860"; }
.fad.#{$fa-css-prefix}-droplet::after, .fa-duotone.#{$fa-css-prefix}-droplet::after { content: "\f043\f043"; }
.fad.#{$fa-css-prefix}-tint::after, .fa-duotone.#{$fa-css-prefix}-tint::after { content: "\f043\f043"; }
.fad.#{$fa-css-prefix}-droplet-degree::after, .fa-duotone.#{$fa-css-prefix}-droplet-degree::after { content: "\f748\f748"; }
.fad.#{$fa-css-prefix}-dewpoint::after, .fa-duotone.#{$fa-css-prefix}-dewpoint::after { content: "\f748\f748"; }
.fad.#{$fa-css-prefix}-droplet-percent::after, .fa-duotone.#{$fa-css-prefix}-droplet-percent::after { content: "\f750\f750"; }
.fad.#{$fa-css-prefix}-humidity::after, .fa-duotone.#{$fa-css-prefix}-humidity::after { content: "\f750\f750"; }
.fad.#{$fa-css-prefix}-droplet-slash::after, .fa-duotone.#{$fa-css-prefix}-droplet-slash::after { content: "\f5c7\f5c7"; }
.fad.#{$fa-css-prefix}-tint-slash::after, .fa-duotone.#{$fa-css-prefix}-tint-slash::after { content: "\f5c7\f5c7"; }
.fad.#{$fa-css-prefix}-drum::after, .fa-duotone.#{$fa-css-prefix}-drum::after { content: "\f569\f569"; }
.fad.#{$fa-css-prefix}-drum-steelpan::after, .fa-duotone.#{$fa-css-prefix}-drum-steelpan::after { content: "\f56a\f56a"; }
.fad.#{$fa-css-prefix}-drumstick::after, .fa-duotone.#{$fa-css-prefix}-drumstick::after { content: "\f6d6\f6d6"; }
.fad.#{$fa-css-prefix}-drumstick-bite::after, .fa-duotone.#{$fa-css-prefix}-drumstick-bite::after { content: "\f6d7\f6d7"; }
.fad.#{$fa-css-prefix}-dryer::after, .fa-duotone.#{$fa-css-prefix}-dryer::after { content: "\f861\f861"; }
.fad.#{$fa-css-prefix}-dryer-heat::after, .fa-duotone.#{$fa-css-prefix}-dryer-heat::after { content: "\f862\f862"; }
.fad.#{$fa-css-prefix}-dryer-alt::after, .fa-duotone.#{$fa-css-prefix}-dryer-alt::after { content: "\f862\f862"; }
.fad.#{$fa-css-prefix}-duck::after, .fa-duotone.#{$fa-css-prefix}-duck::after { content: "\f6d8\f6d8"; }
.fad.#{$fa-css-prefix}-dumbbell::after, .fa-duotone.#{$fa-css-prefix}-dumbbell::after { content: "\f44b\f44b"; }
.fad.#{$fa-css-prefix}-dumpster::after, .fa-duotone.#{$fa-css-prefix}-dumpster::after { content: "\f793\f793"; }
.fad.#{$fa-css-prefix}-dumpster-fire::after, .fa-duotone.#{$fa-css-prefix}-dumpster-fire::after { content: "\f794\f794"; }
.fad.#{$fa-css-prefix}-dungeon::after, .fa-duotone.#{$fa-css-prefix}-dungeon::after { content: "\f6d9\f6d9"; }
.fad.#{$fa-css-prefix}-e::after, .fa-duotone.#{$fa-css-prefix}-e::after { content: "\45\45"; }
.fad.#{$fa-css-prefix}-ear::after, .fa-duotone.#{$fa-css-prefix}-ear::after { content: "\f5f0\f5f0"; }
.fad.#{$fa-css-prefix}-ear-deaf::after, .fa-duotone.#{$fa-css-prefix}-ear-deaf::after { content: "\f2a4\f2a4"; }
.fad.#{$fa-css-prefix}-deaf::after, .fa-duotone.#{$fa-css-prefix}-deaf::after { content: "\f2a4\f2a4"; }
.fad.#{$fa-css-prefix}-deafness::after, .fa-duotone.#{$fa-css-prefix}-deafness::after { content: "\f2a4\f2a4"; }
.fad.#{$fa-css-prefix}-hard-of-hearing::after, .fa-duotone.#{$fa-css-prefix}-hard-of-hearing::after { content: "\f2a4\f2a4"; }
.fad.#{$fa-css-prefix}-ear-listen::after, .fa-duotone.#{$fa-css-prefix}-ear-listen::after { content: "\f2a2\f2a2"; }
.fad.#{$fa-css-prefix}-assistive-listening-systems::after, .fa-duotone.#{$fa-css-prefix}-assistive-listening-systems::after { content: "\f2a2\f2a2"; }
.fad.#{$fa-css-prefix}-ear-muffs::after, .fa-duotone.#{$fa-css-prefix}-ear-muffs::after { content: "\f795\f795"; }
.fad.#{$fa-css-prefix}-earth-africa::after, .fa-duotone.#{$fa-css-prefix}-earth-africa::after { content: "\f57c\f57c"; }
.fad.#{$fa-css-prefix}-globe-africa::after, .fa-duotone.#{$fa-css-prefix}-globe-africa::after { content: "\f57c\f57c"; }
.fad.#{$fa-css-prefix}-earth-americas::after, .fa-duotone.#{$fa-css-prefix}-earth-americas::after { content: "\f57d\f57d"; }
.fad.#{$fa-css-prefix}-earth::after, .fa-duotone.#{$fa-css-prefix}-earth::after { content: "\f57d\f57d"; }
.fad.#{$fa-css-prefix}-earth-america::after, .fa-duotone.#{$fa-css-prefix}-earth-america::after { content: "\f57d\f57d"; }
.fad.#{$fa-css-prefix}-globe-americas::after, .fa-duotone.#{$fa-css-prefix}-globe-americas::after { content: "\f57d\f57d"; }
.fad.#{$fa-css-prefix}-earth-asia::after, .fa-duotone.#{$fa-css-prefix}-earth-asia::after { content: "\f57e\f57e"; }
.fad.#{$fa-css-prefix}-globe-asia::after, .fa-duotone.#{$fa-css-prefix}-globe-asia::after { content: "\f57e\f57e"; }
.fad.#{$fa-css-prefix}-earth-europe::after, .fa-duotone.#{$fa-css-prefix}-earth-europe::after { content: "\f7a2\f7a2"; }
.fad.#{$fa-css-prefix}-globe-europe::after, .fa-duotone.#{$fa-css-prefix}-globe-europe::after { content: "\f7a2\f7a2"; }
.fad.#{$fa-css-prefix}-earth-oceania::after, .fa-duotone.#{$fa-css-prefix}-earth-oceania::after { content: "\e47b\e47b"; }
.fad.#{$fa-css-prefix}-globe-oceania::after, .fa-duotone.#{$fa-css-prefix}-globe-oceania::after { content: "\e47b\e47b"; }
.fad.#{$fa-css-prefix}-eclipse::after, .fa-duotone.#{$fa-css-prefix}-eclipse::after { content: "\f749\f749"; }
.fad.#{$fa-css-prefix}-egg::after, .fa-duotone.#{$fa-css-prefix}-egg::after { content: "\f7fb\f7fb"; }
.fad.#{$fa-css-prefix}-egg-fried::after, .fa-duotone.#{$fa-css-prefix}-egg-fried::after { content: "\f7fc\f7fc"; }
.fad.#{$fa-css-prefix}-eggplant::after, .fa-duotone.#{$fa-css-prefix}-eggplant::after { content: "\e16c\e16c"; }
.fad.#{$fa-css-prefix}-eject::after, .fa-duotone.#{$fa-css-prefix}-eject::after { content: "\f052\f052"; }
.fad.#{$fa-css-prefix}-elephant::after, .fa-duotone.#{$fa-css-prefix}-elephant::after { content: "\f6da\f6da"; }
.fad.#{$fa-css-prefix}-elevator::after, .fa-duotone.#{$fa-css-prefix}-elevator::after { content: "\e16d\e16d"; }
.fad.#{$fa-css-prefix}-ellipsis::after, .fa-duotone.#{$fa-css-prefix}-ellipsis::after { content: "\f141\f141"; }
.fad.#{$fa-css-prefix}-ellipsis-h::after, .fa-duotone.#{$fa-css-prefix}-ellipsis-h::after { content: "\f141\f141"; }
.fad.#{$fa-css-prefix}-ellipsis-stroke::after, .fa-duotone.#{$fa-css-prefix}-ellipsis-stroke::after { content: "\f39b\f39b"; }
.fad.#{$fa-css-prefix}-ellipsis-h-alt::after, .fa-duotone.#{$fa-css-prefix}-ellipsis-h-alt::after { content: "\f39b\f39b"; }
.fad.#{$fa-css-prefix}-ellipsis-stroke-vertical::after, .fa-duotone.#{$fa-css-prefix}-ellipsis-stroke-vertical::after { content: "\f39c\f39c"; }
.fad.#{$fa-css-prefix}-ellipsis-v-alt::after, .fa-duotone.#{$fa-css-prefix}-ellipsis-v-alt::after { content: "\f39c\f39c"; }
.fad.#{$fa-css-prefix}-ellipsis-vertical::after, .fa-duotone.#{$fa-css-prefix}-ellipsis-vertical::after { content: "\f142\f142"; }
.fad.#{$fa-css-prefix}-ellipsis-v::after, .fa-duotone.#{$fa-css-prefix}-ellipsis-v::after { content: "\f142\f142"; }
.fad.#{$fa-css-prefix}-empty-set::after, .fa-duotone.#{$fa-css-prefix}-empty-set::after { content: "\f656\f656"; }
.fad.#{$fa-css-prefix}-engine::after, .fa-duotone.#{$fa-css-prefix}-engine::after { content: "\e16e\e16e"; }
.fad.#{$fa-css-prefix}-engine-warning::after, .fa-duotone.#{$fa-css-prefix}-engine-warning::after { content: "\f5f2\f5f2"; }
.fad.#{$fa-css-prefix}-engine-exclamation::after, .fa-duotone.#{$fa-css-prefix}-engine-exclamation::after { content: "\f5f2\f5f2"; }
.fad.#{$fa-css-prefix}-envelope::after, .fa-duotone.#{$fa-css-prefix}-envelope::after { content: "\f0e0\f0e0"; }
.fad.#{$fa-css-prefix}-envelope-dot::after, .fa-duotone.#{$fa-css-prefix}-envelope-dot::after { content: "\e16f\e16f"; }
.fad.#{$fa-css-prefix}-envelope-badge::after, .fa-duotone.#{$fa-css-prefix}-envelope-badge::after { content: "\e16f\e16f"; }
.fad.#{$fa-css-prefix}-envelope-open::after, .fa-duotone.#{$fa-css-prefix}-envelope-open::after { content: "\f2b6\f2b6"; }
.fad.#{$fa-css-prefix}-envelope-open-dollar::after, .fa-duotone.#{$fa-css-prefix}-envelope-open-dollar::after { content: "\f657\f657"; }
.fad.#{$fa-css-prefix}-envelope-open-text::after, .fa-duotone.#{$fa-css-prefix}-envelope-open-text::after { content: "\f658\f658"; }
.fad.#{$fa-css-prefix}-envelopes::after, .fa-duotone.#{$fa-css-prefix}-envelopes::after { content: "\e170\e170"; }
.fad.#{$fa-css-prefix}-envelopes-bulk::after, .fa-duotone.#{$fa-css-prefix}-envelopes-bulk::after { content: "\f674\f674"; }
.fad.#{$fa-css-prefix}-mail-bulk::after, .fa-duotone.#{$fa-css-prefix}-mail-bulk::after { content: "\f674\f674"; }
.fad.#{$fa-css-prefix}-equals::after, .fa-duotone.#{$fa-css-prefix}-equals::after { content: "\3d\3d"; }
.fad.#{$fa-css-prefix}-eraser::after, .fa-duotone.#{$fa-css-prefix}-eraser::after { content: "\f12d\f12d"; }
.fad.#{$fa-css-prefix}-escalator::after, .fa-duotone.#{$fa-css-prefix}-escalator::after { content: "\e171\e171"; }
.fad.#{$fa-css-prefix}-ethernet::after, .fa-duotone.#{$fa-css-prefix}-ethernet::after { content: "\f796\f796"; }
.fad.#{$fa-css-prefix}-euro-sign::after, .fa-duotone.#{$fa-css-prefix}-euro-sign::after { content: "\f153\f153"; }
.fad.#{$fa-css-prefix}-eur::after, .fa-duotone.#{$fa-css-prefix}-eur::after { content: "\f153\f153"; }
.fad.#{$fa-css-prefix}-euro::after, .fa-duotone.#{$fa-css-prefix}-euro::after { content: "\f153\f153"; }
.fad.#{$fa-css-prefix}-exclamation::after, .fa-duotone.#{$fa-css-prefix}-exclamation::after { content: "\21\21"; }
.fad.#{$fa-css-prefix}-expand::after, .fa-duotone.#{$fa-css-prefix}-expand::after { content: "\f065\f065"; }
.fad.#{$fa-css-prefix}-expand-wide::after, .fa-duotone.#{$fa-css-prefix}-expand-wide::after { content: "\f320\f320"; }
.fad.#{$fa-css-prefix}-eye::after, .fa-duotone.#{$fa-css-prefix}-eye::after { content: "\f06e\f06e"; }
.fad.#{$fa-css-prefix}-eye-dropper::after, .fa-duotone.#{$fa-css-prefix}-eye-dropper::after { content: "\f1fb\f1fb"; }
.fad.#{$fa-css-prefix}-eye-dropper-empty::after, .fa-duotone.#{$fa-css-prefix}-eye-dropper-empty::after { content: "\f1fb\f1fb"; }
.fad.#{$fa-css-prefix}-eyedropper::after, .fa-duotone.#{$fa-css-prefix}-eyedropper::after { content: "\f1fb\f1fb"; }
.fad.#{$fa-css-prefix}-eye-dropper-full::after, .fa-duotone.#{$fa-css-prefix}-eye-dropper-full::after { content: "\e172\e172"; }
.fad.#{$fa-css-prefix}-eye-dropper-half::after, .fa-duotone.#{$fa-css-prefix}-eye-dropper-half::after { content: "\e173\e173"; }
.fad.#{$fa-css-prefix}-eye-evil::after, .fa-duotone.#{$fa-css-prefix}-eye-evil::after { content: "\f6db\f6db"; }
.fad.#{$fa-css-prefix}-eye-low-vision::after, .fa-duotone.#{$fa-css-prefix}-eye-low-vision::after { content: "\f2a8\f2a8"; }
.fad.#{$fa-css-prefix}-low-vision::after, .fa-duotone.#{$fa-css-prefix}-low-vision::after { content: "\f2a8\f2a8"; }
.fad.#{$fa-css-prefix}-eye-slash::after, .fa-duotone.#{$fa-css-prefix}-eye-slash::after { content: "\f070\f070"; }
.fad.#{$fa-css-prefix}-eyes::after, .fa-duotone.#{$fa-css-prefix}-eyes::after { content: "\e367\e367"; }
.fad.#{$fa-css-prefix}-f::after, .fa-duotone.#{$fa-css-prefix}-f::after { content: "\46\46"; }
.fad.#{$fa-css-prefix}-face-angry::after, .fa-duotone.#{$fa-css-prefix}-face-angry::after { content: "\f556\f556"; }
.fad.#{$fa-css-prefix}-angry::after, .fa-duotone.#{$fa-css-prefix}-angry::after { content: "\f556\f556"; }
.fad.#{$fa-css-prefix}-face-angry-horns::after, .fa-duotone.#{$fa-css-prefix}-face-angry-horns::after { content: "\e368\e368"; }
.fad.#{$fa-css-prefix}-face-anguished::after, .fa-duotone.#{$fa-css-prefix}-face-anguished::after { content: "\e369\e369"; }
.fad.#{$fa-css-prefix}-face-anxious-sweat::after, .fa-duotone.#{$fa-css-prefix}-face-anxious-sweat::after { content: "\e36a\e36a"; }
.fad.#{$fa-css-prefix}-face-astonished::after, .fa-duotone.#{$fa-css-prefix}-face-astonished::after { content: "\e36b\e36b"; }
.fad.#{$fa-css-prefix}-face-awesome::after, .fa-duotone.#{$fa-css-prefix}-face-awesome::after { content: "\e409\e409"; }
.fad.#{$fa-css-prefix}-gave-dandy::after, .fa-duotone.#{$fa-css-prefix}-gave-dandy::after { content: "\e409\e409"; }
.fad.#{$fa-css-prefix}-face-beam-hand-over-mouth::after, .fa-duotone.#{$fa-css-prefix}-face-beam-hand-over-mouth::after { content: "\e47c\e47c"; }
.fad.#{$fa-css-prefix}-face-clouds::after, .fa-duotone.#{$fa-css-prefix}-face-clouds::after { content: "\e47d\e47d"; }
.fad.#{$fa-css-prefix}-face-confounded::after, .fa-duotone.#{$fa-css-prefix}-face-confounded::after { content: "\e36c\e36c"; }
.fad.#{$fa-css-prefix}-face-confused::after, .fa-duotone.#{$fa-css-prefix}-face-confused::after { content: "\e36d\e36d"; }
.fad.#{$fa-css-prefix}-face-cowboy-hat::after, .fa-duotone.#{$fa-css-prefix}-face-cowboy-hat::after { content: "\e36e\e36e"; }
.fad.#{$fa-css-prefix}-face-diagonal-mouth::after, .fa-duotone.#{$fa-css-prefix}-face-diagonal-mouth::after { content: "\e47e\e47e"; }
.fad.#{$fa-css-prefix}-face-disappointed::after, .fa-duotone.#{$fa-css-prefix}-face-disappointed::after { content: "\e36f\e36f"; }
.fad.#{$fa-css-prefix}-face-disguise::after, .fa-duotone.#{$fa-css-prefix}-face-disguise::after { content: "\e370\e370"; }
.fad.#{$fa-css-prefix}-face-dizzy::after, .fa-duotone.#{$fa-css-prefix}-face-dizzy::after { content: "\f567\f567"; }
.fad.#{$fa-css-prefix}-dizzy::after, .fa-duotone.#{$fa-css-prefix}-dizzy::after { content: "\f567\f567"; }
.fad.#{$fa-css-prefix}-face-dotted::after, .fa-duotone.#{$fa-css-prefix}-face-dotted::after { content: "\e47f\e47f"; }
.fad.#{$fa-css-prefix}-face-downcast-sweat::after, .fa-duotone.#{$fa-css-prefix}-face-downcast-sweat::after { content: "\e371\e371"; }
.fad.#{$fa-css-prefix}-face-drooling::after, .fa-duotone.#{$fa-css-prefix}-face-drooling::after { content: "\e372\e372"; }
.fad.#{$fa-css-prefix}-face-exhaling::after, .fa-duotone.#{$fa-css-prefix}-face-exhaling::after { content: "\e480\e480"; }
.fad.#{$fa-css-prefix}-face-explode::after, .fa-duotone.#{$fa-css-prefix}-face-explode::after { content: "\e2fe\e2fe"; }
.fad.#{$fa-css-prefix}-exploding-head::after, .fa-duotone.#{$fa-css-prefix}-exploding-head::after { content: "\e2fe\e2fe"; }
.fad.#{$fa-css-prefix}-face-expressionless::after, .fa-duotone.#{$fa-css-prefix}-face-expressionless::after { content: "\e373\e373"; }
.fad.#{$fa-css-prefix}-face-eyes-xmarks::after, .fa-duotone.#{$fa-css-prefix}-face-eyes-xmarks::after { content: "\e374\e374"; }
.fad.#{$fa-css-prefix}-face-fearful::after, .fa-duotone.#{$fa-css-prefix}-face-fearful::after { content: "\e375\e375"; }
.fad.#{$fa-css-prefix}-face-flushed::after, .fa-duotone.#{$fa-css-prefix}-face-flushed::after { content: "\f579\f579"; }
.fad.#{$fa-css-prefix}-flushed::after, .fa-duotone.#{$fa-css-prefix}-flushed::after { content: "\f579\f579"; }
.fad.#{$fa-css-prefix}-face-frown::after, .fa-duotone.#{$fa-css-prefix}-face-frown::after { content: "\f119\f119"; }
.fad.#{$fa-css-prefix}-frown::after, .fa-duotone.#{$fa-css-prefix}-frown::after { content: "\f119\f119"; }
.fad.#{$fa-css-prefix}-face-frown-open::after, .fa-duotone.#{$fa-css-prefix}-face-frown-open::after { content: "\f57a\f57a"; }
.fad.#{$fa-css-prefix}-frown-open::after, .fa-duotone.#{$fa-css-prefix}-frown-open::after { content: "\f57a\f57a"; }
.fad.#{$fa-css-prefix}-face-frown-slight::after, .fa-duotone.#{$fa-css-prefix}-face-frown-slight::after { content: "\e376\e376"; }
.fad.#{$fa-css-prefix}-face-glasses::after, .fa-duotone.#{$fa-css-prefix}-face-glasses::after { content: "\e377\e377"; }
.fad.#{$fa-css-prefix}-face-grimace::after, .fa-duotone.#{$fa-css-prefix}-face-grimace::after { content: "\f57f\f57f"; }
.fad.#{$fa-css-prefix}-grimace::after, .fa-duotone.#{$fa-css-prefix}-grimace::after { content: "\f57f\f57f"; }
.fad.#{$fa-css-prefix}-face-grin::after, .fa-duotone.#{$fa-css-prefix}-face-grin::after { content: "\f580\f580"; }
.fad.#{$fa-css-prefix}-grin::after, .fa-duotone.#{$fa-css-prefix}-grin::after { content: "\f580\f580"; }
.fad.#{$fa-css-prefix}-face-grin-beam::after, .fa-duotone.#{$fa-css-prefix}-face-grin-beam::after { content: "\f582\f582"; }
.fad.#{$fa-css-prefix}-grin-beam::after, .fa-duotone.#{$fa-css-prefix}-grin-beam::after { content: "\f582\f582"; }
.fad.#{$fa-css-prefix}-face-grin-beam-sweat::after, .fa-duotone.#{$fa-css-prefix}-face-grin-beam-sweat::after { content: "\f583\f583"; }
.fad.#{$fa-css-prefix}-grin-beam-sweat::after, .fa-duotone.#{$fa-css-prefix}-grin-beam-sweat::after { content: "\f583\f583"; }
.fad.#{$fa-css-prefix}-face-grin-hearts::after, .fa-duotone.#{$fa-css-prefix}-face-grin-hearts::after { content: "\f584\f584"; }
.fad.#{$fa-css-prefix}-grin-hearts::after, .fa-duotone.#{$fa-css-prefix}-grin-hearts::after { content: "\f584\f584"; }
.fad.#{$fa-css-prefix}-face-grin-squint::after, .fa-duotone.#{$fa-css-prefix}-face-grin-squint::after { content: "\f585\f585"; }
.fad.#{$fa-css-prefix}-grin-squint::after, .fa-duotone.#{$fa-css-prefix}-grin-squint::after { content: "\f585\f585"; }
.fad.#{$fa-css-prefix}-face-grin-squint-tears::after, .fa-duotone.#{$fa-css-prefix}-face-grin-squint-tears::after { content: "\f586\f586"; }
.fad.#{$fa-css-prefix}-grin-squint-tears::after, .fa-duotone.#{$fa-css-prefix}-grin-squint-tears::after { content: "\f586\f586"; }
.fad.#{$fa-css-prefix}-face-grin-stars::after, .fa-duotone.#{$fa-css-prefix}-face-grin-stars::after { content: "\f587\f587"; }
.fad.#{$fa-css-prefix}-grin-stars::after, .fa-duotone.#{$fa-css-prefix}-grin-stars::after { content: "\f587\f587"; }
.fad.#{$fa-css-prefix}-face-grin-tears::after, .fa-duotone.#{$fa-css-prefix}-face-grin-tears::after { content: "\f588\f588"; }
.fad.#{$fa-css-prefix}-grin-tears::after, .fa-duotone.#{$fa-css-prefix}-grin-tears::after { content: "\f588\f588"; }
.fad.#{$fa-css-prefix}-face-grin-tongue::after, .fa-duotone.#{$fa-css-prefix}-face-grin-tongue::after { content: "\f589\f589"; }
.fad.#{$fa-css-prefix}-grin-tongue::after, .fa-duotone.#{$fa-css-prefix}-grin-tongue::after { content: "\f589\f589"; }
.fad.#{$fa-css-prefix}-face-grin-tongue-squint::after, .fa-duotone.#{$fa-css-prefix}-face-grin-tongue-squint::after { content: "\f58a\f58a"; }
.fad.#{$fa-css-prefix}-grin-tongue-squint::after, .fa-duotone.#{$fa-css-prefix}-grin-tongue-squint::after { content: "\f58a\f58a"; }
.fad.#{$fa-css-prefix}-face-grin-tongue-wink::after, .fa-duotone.#{$fa-css-prefix}-face-grin-tongue-wink::after { content: "\f58b\f58b"; }
.fad.#{$fa-css-prefix}-grin-tongue-wink::after, .fa-duotone.#{$fa-css-prefix}-grin-tongue-wink::after { content: "\f58b\f58b"; }
.fad.#{$fa-css-prefix}-face-grin-wide::after, .fa-duotone.#{$fa-css-prefix}-face-grin-wide::after { content: "\f581\f581"; }
.fad.#{$fa-css-prefix}-grin-alt::after, .fa-duotone.#{$fa-css-prefix}-grin-alt::after { content: "\f581\f581"; }
.fad.#{$fa-css-prefix}-face-grin-wink::after, .fa-duotone.#{$fa-css-prefix}-face-grin-wink::after { content: "\f58c\f58c"; }
.fad.#{$fa-css-prefix}-grin-wink::after, .fa-duotone.#{$fa-css-prefix}-grin-wink::after { content: "\f58c\f58c"; }
.fad.#{$fa-css-prefix}-face-hand-over-mouth::after, .fa-duotone.#{$fa-css-prefix}-face-hand-over-mouth::after { content: "\e378\e378"; }
.fad.#{$fa-css-prefix}-face-hand-peeking::after, .fa-duotone.#{$fa-css-prefix}-face-hand-peeking::after { content: "\e481\e481"; }
.fad.#{$fa-css-prefix}-face-hand-yawn::after, .fa-duotone.#{$fa-css-prefix}-face-hand-yawn::after { content: "\e379\e379"; }
.fad.#{$fa-css-prefix}-face-head-bandage::after, .fa-duotone.#{$fa-css-prefix}-face-head-bandage::after { content: "\e37a\e37a"; }
.fad.#{$fa-css-prefix}-face-holding-back-tears::after, .fa-duotone.#{$fa-css-prefix}-face-holding-back-tears::after { content: "\e482\e482"; }
.fad.#{$fa-css-prefix}-face-hushed::after, .fa-duotone.#{$fa-css-prefix}-face-hushed::after { content: "\e37b\e37b"; }
.fad.#{$fa-css-prefix}-face-icicles::after, .fa-duotone.#{$fa-css-prefix}-face-icicles::after { content: "\e37c\e37c"; }
.fad.#{$fa-css-prefix}-face-kiss::after, .fa-duotone.#{$fa-css-prefix}-face-kiss::after { content: "\f596\f596"; }
.fad.#{$fa-css-prefix}-kiss::after, .fa-duotone.#{$fa-css-prefix}-kiss::after { content: "\f596\f596"; }
.fad.#{$fa-css-prefix}-face-kiss-beam::after, .fa-duotone.#{$fa-css-prefix}-face-kiss-beam::after { content: "\f597\f597"; }
.fad.#{$fa-css-prefix}-kiss-beam::after, .fa-duotone.#{$fa-css-prefix}-kiss-beam::after { content: "\f597\f597"; }
.fad.#{$fa-css-prefix}-face-kiss-closed-eyes::after, .fa-duotone.#{$fa-css-prefix}-face-kiss-closed-eyes::after { content: "\e37d\e37d"; }
.fad.#{$fa-css-prefix}-face-kiss-wink-heart::after, .fa-duotone.#{$fa-css-prefix}-face-kiss-wink-heart::after { content: "\f598\f598"; }
.fad.#{$fa-css-prefix}-kiss-wink-heart::after, .fa-duotone.#{$fa-css-prefix}-kiss-wink-heart::after { content: "\f598\f598"; }
.fad.#{$fa-css-prefix}-face-laugh::after, .fa-duotone.#{$fa-css-prefix}-face-laugh::after { content: "\f599\f599"; }
.fad.#{$fa-css-prefix}-laugh::after, .fa-duotone.#{$fa-css-prefix}-laugh::after { content: "\f599\f599"; }
.fad.#{$fa-css-prefix}-face-laugh-beam::after, .fa-duotone.#{$fa-css-prefix}-face-laugh-beam::after { content: "\f59a\f59a"; }
.fad.#{$fa-css-prefix}-laugh-beam::after, .fa-duotone.#{$fa-css-prefix}-laugh-beam::after { content: "\f59a\f59a"; }
.fad.#{$fa-css-prefix}-face-laugh-squint::after, .fa-duotone.#{$fa-css-prefix}-face-laugh-squint::after { content: "\f59b\f59b"; }
.fad.#{$fa-css-prefix}-laugh-squint::after, .fa-duotone.#{$fa-css-prefix}-laugh-squint::after { content: "\f59b\f59b"; }
.fad.#{$fa-css-prefix}-face-laugh-wink::after, .fa-duotone.#{$fa-css-prefix}-face-laugh-wink::after { content: "\f59c\f59c"; }
.fad.#{$fa-css-prefix}-laugh-wink::after, .fa-duotone.#{$fa-css-prefix}-laugh-wink::after { content: "\f59c\f59c"; }
.fad.#{$fa-css-prefix}-face-lying::after, .fa-duotone.#{$fa-css-prefix}-face-lying::after { content: "\e37e\e37e"; }
.fad.#{$fa-css-prefix}-face-mask::after, .fa-duotone.#{$fa-css-prefix}-face-mask::after { content: "\e37f\e37f"; }
.fad.#{$fa-css-prefix}-face-meh::after, .fa-duotone.#{$fa-css-prefix}-face-meh::after { content: "\f11a\f11a"; }
.fad.#{$fa-css-prefix}-meh::after, .fa-duotone.#{$fa-css-prefix}-meh::after { content: "\f11a\f11a"; }
.fad.#{$fa-css-prefix}-face-meh-blank::after, .fa-duotone.#{$fa-css-prefix}-face-meh-blank::after { content: "\f5a4\f5a4"; }
.fad.#{$fa-css-prefix}-meh-blank::after, .fa-duotone.#{$fa-css-prefix}-meh-blank::after { content: "\f5a4\f5a4"; }
.fad.#{$fa-css-prefix}-face-melting::after, .fa-duotone.#{$fa-css-prefix}-face-melting::after { content: "\e483\e483"; }
.fad.#{$fa-css-prefix}-face-monocle::after, .fa-duotone.#{$fa-css-prefix}-face-monocle::after { content: "\e380\e380"; }
.fad.#{$fa-css-prefix}-face-nauseated::after, .fa-duotone.#{$fa-css-prefix}-face-nauseated::after { content: "\e381\e381"; }
.fad.#{$fa-css-prefix}-face-nose-steam::after, .fa-duotone.#{$fa-css-prefix}-face-nose-steam::after { content: "\e382\e382"; }
.fad.#{$fa-css-prefix}-face-party::after, .fa-duotone.#{$fa-css-prefix}-face-party::after { content: "\e383\e383"; }
.fad.#{$fa-css-prefix}-face-pensive::after, .fa-duotone.#{$fa-css-prefix}-face-pensive::after { content: "\e384\e384"; }
.fad.#{$fa-css-prefix}-face-persevering::after, .fa-duotone.#{$fa-css-prefix}-face-persevering::after { content: "\e385\e385"; }
.fad.#{$fa-css-prefix}-face-pleading::after, .fa-duotone.#{$fa-css-prefix}-face-pleading::after { content: "\e386\e386"; }
.fad.#{$fa-css-prefix}-face-pouting::after, .fa-duotone.#{$fa-css-prefix}-face-pouting::after { content: "\e387\e387"; }
.fad.#{$fa-css-prefix}-face-raised-eyebrow::after, .fa-duotone.#{$fa-css-prefix}-face-raised-eyebrow::after { content: "\e388\e388"; }
.fad.#{$fa-css-prefix}-face-relieved::after, .fa-duotone.#{$fa-css-prefix}-face-relieved::after { content: "\e389\e389"; }
.fad.#{$fa-css-prefix}-face-rolling-eyes::after, .fa-duotone.#{$fa-css-prefix}-face-rolling-eyes::after { content: "\f5a5\f5a5"; }
.fad.#{$fa-css-prefix}-meh-rolling-eyes::after, .fa-duotone.#{$fa-css-prefix}-meh-rolling-eyes::after { content: "\f5a5\f5a5"; }
.fad.#{$fa-css-prefix}-face-sad-cry::after, .fa-duotone.#{$fa-css-prefix}-face-sad-cry::after { content: "\f5b3\f5b3"; }
.fad.#{$fa-css-prefix}-sad-cry::after, .fa-duotone.#{$fa-css-prefix}-sad-cry::after { content: "\f5b3\f5b3"; }
.fad.#{$fa-css-prefix}-face-sad-sweat::after, .fa-duotone.#{$fa-css-prefix}-face-sad-sweat::after { content: "\e38a\e38a"; }
.fad.#{$fa-css-prefix}-face-sad-tear::after, .fa-duotone.#{$fa-css-prefix}-face-sad-tear::after { content: "\f5b4\f5b4"; }
.fad.#{$fa-css-prefix}-sad-tear::after, .fa-duotone.#{$fa-css-prefix}-sad-tear::after { content: "\f5b4\f5b4"; }
.fad.#{$fa-css-prefix}-face-saluting::after, .fa-duotone.#{$fa-css-prefix}-face-saluting::after { content: "\e484\e484"; }
.fad.#{$fa-css-prefix}-face-scream::after, .fa-duotone.#{$fa-css-prefix}-face-scream::after { content: "\e38b\e38b"; }
.fad.#{$fa-css-prefix}-face-shush::after, .fa-duotone.#{$fa-css-prefix}-face-shush::after { content: "\e38c\e38c"; }
.fad.#{$fa-css-prefix}-face-sleeping::after, .fa-duotone.#{$fa-css-prefix}-face-sleeping::after { content: "\e38d\e38d"; }
.fad.#{$fa-css-prefix}-face-sleepy::after, .fa-duotone.#{$fa-css-prefix}-face-sleepy::after { content: "\e38e\e38e"; }
.fad.#{$fa-css-prefix}-face-smile::after, .fa-duotone.#{$fa-css-prefix}-face-smile::after { content: "\f118\f118"; }
.fad.#{$fa-css-prefix}-smile::after, .fa-duotone.#{$fa-css-prefix}-smile::after { content: "\f118\f118"; }
.fad.#{$fa-css-prefix}-face-smile-beam::after, .fa-duotone.#{$fa-css-prefix}-face-smile-beam::after { content: "\f5b8\f5b8"; }
.fad.#{$fa-css-prefix}-smile-beam::after, .fa-duotone.#{$fa-css-prefix}-smile-beam::after { content: "\f5b8\f5b8"; }
.fad.#{$fa-css-prefix}-face-smile-halo::after, .fa-duotone.#{$fa-css-prefix}-face-smile-halo::after { content: "\e38f\e38f"; }
.fad.#{$fa-css-prefix}-face-smile-hearts::after, .fa-duotone.#{$fa-css-prefix}-face-smile-hearts::after { content: "\e390\e390"; }
.fad.#{$fa-css-prefix}-face-smile-horns::after, .fa-duotone.#{$fa-css-prefix}-face-smile-horns::after { content: "\e391\e391"; }
.fad.#{$fa-css-prefix}-face-smile-plus::after, .fa-duotone.#{$fa-css-prefix}-face-smile-plus::after { content: "\f5b9\f5b9"; }
.fad.#{$fa-css-prefix}-smile-plus::after, .fa-duotone.#{$fa-css-prefix}-smile-plus::after { content: "\f5b9\f5b9"; }
.fad.#{$fa-css-prefix}-face-smile-relaxed::after, .fa-duotone.#{$fa-css-prefix}-face-smile-relaxed::after { content: "\e392\e392"; }
.fad.#{$fa-css-prefix}-face-smile-tear::after, .fa-duotone.#{$fa-css-prefix}-face-smile-tear::after { content: "\e393\e393"; }
.fad.#{$fa-css-prefix}-face-smile-tongue::after, .fa-duotone.#{$fa-css-prefix}-face-smile-tongue::after { content: "\e394\e394"; }
.fad.#{$fa-css-prefix}-face-smile-upside-down::after, .fa-duotone.#{$fa-css-prefix}-face-smile-upside-down::after { content: "\e395\e395"; }
.fad.#{$fa-css-prefix}-face-smile-wink::after, .fa-duotone.#{$fa-css-prefix}-face-smile-wink::after { content: "\f4da\f4da"; }
.fad.#{$fa-css-prefix}-smile-wink::after, .fa-duotone.#{$fa-css-prefix}-smile-wink::after { content: "\f4da\f4da"; }
.fad.#{$fa-css-prefix}-face-smiling-hands::after, .fa-duotone.#{$fa-css-prefix}-face-smiling-hands::after { content: "\e396\e396"; }
.fad.#{$fa-css-prefix}-face-smirking::after, .fa-duotone.#{$fa-css-prefix}-face-smirking::after { content: "\e397\e397"; }
.fad.#{$fa-css-prefix}-face-spiral-eyes::after, .fa-duotone.#{$fa-css-prefix}-face-spiral-eyes::after { content: "\e485\e485"; }
.fad.#{$fa-css-prefix}-face-sunglasses::after, .fa-duotone.#{$fa-css-prefix}-face-sunglasses::after { content: "\e398\e398"; }
.fad.#{$fa-css-prefix}-face-surprise::after, .fa-duotone.#{$fa-css-prefix}-face-surprise::after { content: "\f5c2\f5c2"; }
.fad.#{$fa-css-prefix}-surprise::after, .fa-duotone.#{$fa-css-prefix}-surprise::after { content: "\f5c2\f5c2"; }
.fad.#{$fa-css-prefix}-face-swear::after, .fa-duotone.#{$fa-css-prefix}-face-swear::after { content: "\e399\e399"; }
.fad.#{$fa-css-prefix}-face-thermometer::after, .fa-duotone.#{$fa-css-prefix}-face-thermometer::after { content: "\e39a\e39a"; }
.fad.#{$fa-css-prefix}-face-thinking::after, .fa-duotone.#{$fa-css-prefix}-face-thinking::after { content: "\e39b\e39b"; }
.fad.#{$fa-css-prefix}-face-tired::after, .fa-duotone.#{$fa-css-prefix}-face-tired::after { content: "\f5c8\f5c8"; }
.fad.#{$fa-css-prefix}-tired::after, .fa-duotone.#{$fa-css-prefix}-tired::after { content: "\f5c8\f5c8"; }
.fad.#{$fa-css-prefix}-face-tissue::after, .fa-duotone.#{$fa-css-prefix}-face-tissue::after { content: "\e39c\e39c"; }
.fad.#{$fa-css-prefix}-face-tongue-money::after, .fa-duotone.#{$fa-css-prefix}-face-tongue-money::after { content: "\e39d\e39d"; }
.fad.#{$fa-css-prefix}-face-tongue-sweat::after, .fa-duotone.#{$fa-css-prefix}-face-tongue-sweat::after { content: "\e39e\e39e"; }
.fad.#{$fa-css-prefix}-face-unamused::after, .fa-duotone.#{$fa-css-prefix}-face-unamused::after { content: "\e39f\e39f"; }
.fad.#{$fa-css-prefix}-face-viewfinder::after, .fa-duotone.#{$fa-css-prefix}-face-viewfinder::after { content: "\e2ff\e2ff"; }
.fad.#{$fa-css-prefix}-face-vomit::after, .fa-duotone.#{$fa-css-prefix}-face-vomit::after { content: "\e3a0\e3a0"; }
.fad.#{$fa-css-prefix}-face-weary::after, .fa-duotone.#{$fa-css-prefix}-face-weary::after { content: "\e3a1\e3a1"; }
.fad.#{$fa-css-prefix}-face-woozy::after, .fa-duotone.#{$fa-css-prefix}-face-woozy::after { content: "\e3a2\e3a2"; }
.fad.#{$fa-css-prefix}-face-worried::after, .fa-duotone.#{$fa-css-prefix}-face-worried::after { content: "\e3a3\e3a3"; }
.fad.#{$fa-css-prefix}-face-zany::after, .fa-duotone.#{$fa-css-prefix}-face-zany::after { content: "\e3a4\e3a4"; }
.fad.#{$fa-css-prefix}-face-zipper::after, .fa-duotone.#{$fa-css-prefix}-face-zipper::after { content: "\e3a5\e3a5"; }
.fad.#{$fa-css-prefix}-falafel::after, .fa-duotone.#{$fa-css-prefix}-falafel::after { content: "\e40a\e40a"; }
.fad.#{$fa-css-prefix}-family::after, .fa-duotone.#{$fa-css-prefix}-family::after { content: "\e300\e300"; }
.fad.#{$fa-css-prefix}-family-dress::after, .fa-duotone.#{$fa-css-prefix}-family-dress::after { content: "\e301\e301"; }
.fad.#{$fa-css-prefix}-family-pants::after, .fa-duotone.#{$fa-css-prefix}-family-pants::after { content: "\e302\e302"; }
.fad.#{$fa-css-prefix}-fan::after, .fa-duotone.#{$fa-css-prefix}-fan::after { content: "\f863\f863"; }
.fad.#{$fa-css-prefix}-fan-table::after, .fa-duotone.#{$fa-css-prefix}-fan-table::after { content: "\e004\e004"; }
.fad.#{$fa-css-prefix}-farm::after, .fa-duotone.#{$fa-css-prefix}-farm::after { content: "\f864\f864"; }
.fad.#{$fa-css-prefix}-barn-silo::after, .fa-duotone.#{$fa-css-prefix}-barn-silo::after { content: "\f864\f864"; }
.fad.#{$fa-css-prefix}-faucet::after, .fa-duotone.#{$fa-css-prefix}-faucet::after { content: "\e005\e005"; }
.fad.#{$fa-css-prefix}-faucet-drip::after, .fa-duotone.#{$fa-css-prefix}-faucet-drip::after { content: "\e006\e006"; }
.fad.#{$fa-css-prefix}-fax::after, .fa-duotone.#{$fa-css-prefix}-fax::after { content: "\f1ac\f1ac"; }
.fad.#{$fa-css-prefix}-feather::after, .fa-duotone.#{$fa-css-prefix}-feather::after { content: "\f52d\f52d"; }
.fad.#{$fa-css-prefix}-feather-pointed::after, .fa-duotone.#{$fa-css-prefix}-feather-pointed::after { content: "\f56b\f56b"; }
.fad.#{$fa-css-prefix}-feather-alt::after, .fa-duotone.#{$fa-css-prefix}-feather-alt::after { content: "\f56b\f56b"; }
.fad.#{$fa-css-prefix}-fence::after, .fa-duotone.#{$fa-css-prefix}-fence::after { content: "\e303\e303"; }
.fad.#{$fa-css-prefix}-ferris-wheel::after, .fa-duotone.#{$fa-css-prefix}-ferris-wheel::after { content: "\e174\e174"; }
.fad.#{$fa-css-prefix}-field-hockey-stick-ball::after, .fa-duotone.#{$fa-css-prefix}-field-hockey-stick-ball::after { content: "\f44c\f44c"; }
.fad.#{$fa-css-prefix}-field-hockey::after, .fa-duotone.#{$fa-css-prefix}-field-hockey::after { content: "\f44c\f44c"; }
.fad.#{$fa-css-prefix}-file::after, .fa-duotone.#{$fa-css-prefix}-file::after { content: "\f15b\f15b"; }
.fad.#{$fa-css-prefix}-file-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-file-arrow-down::after { content: "\f56d\f56d"; }
.fad.#{$fa-css-prefix}-file-download::after, .fa-duotone.#{$fa-css-prefix}-file-download::after { content: "\f56d\f56d"; }
.fad.#{$fa-css-prefix}-file-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-file-arrow-up::after { content: "\f574\f574"; }
.fad.#{$fa-css-prefix}-file-upload::after, .fa-duotone.#{$fa-css-prefix}-file-upload::after { content: "\f574\f574"; }
.fad.#{$fa-css-prefix}-file-audio::after, .fa-duotone.#{$fa-css-prefix}-file-audio::after { content: "\f1c7\f1c7"; }
.fad.#{$fa-css-prefix}-file-binary::after, .fa-duotone.#{$fa-css-prefix}-file-binary::after { content: "\e175\e175"; }
.fad.#{$fa-css-prefix}-file-certificate::after, .fa-duotone.#{$fa-css-prefix}-file-certificate::after { content: "\f5f3\f5f3"; }
.fad.#{$fa-css-prefix}-file-award::after, .fa-duotone.#{$fa-css-prefix}-file-award::after { content: "\f5f3\f5f3"; }
.fad.#{$fa-css-prefix}-file-chart-column::after, .fa-duotone.#{$fa-css-prefix}-file-chart-column::after { content: "\f659\f659"; }
.fad.#{$fa-css-prefix}-file-chart-line::after, .fa-duotone.#{$fa-css-prefix}-file-chart-line::after { content: "\f659\f659"; }
.fad.#{$fa-css-prefix}-file-chart-pie::after, .fa-duotone.#{$fa-css-prefix}-file-chart-pie::after { content: "\f65a\f65a"; }
.fad.#{$fa-css-prefix}-file-check::after, .fa-duotone.#{$fa-css-prefix}-file-check::after { content: "\f316\f316"; }
.fad.#{$fa-css-prefix}-file-circle-info::after, .fa-duotone.#{$fa-css-prefix}-file-circle-info::after { content: "\e493\e493"; }
.fad.#{$fa-css-prefix}-file-circle-plus::after, .fa-duotone.#{$fa-css-prefix}-file-circle-plus::after { content: "\e494\e494"; }
.fad.#{$fa-css-prefix}-file-code::after, .fa-duotone.#{$fa-css-prefix}-file-code::after { content: "\f1c9\f1c9"; }
.fad.#{$fa-css-prefix}-file-contract::after, .fa-duotone.#{$fa-css-prefix}-file-contract::after { content: "\f56c\f56c"; }
.fad.#{$fa-css-prefix}-file-csv::after, .fa-duotone.#{$fa-css-prefix}-file-csv::after { content: "\f6dd\f6dd"; }
.fad.#{$fa-css-prefix}-file-dashed-line::after, .fa-duotone.#{$fa-css-prefix}-file-dashed-line::after { content: "\f877\f877"; }
.fad.#{$fa-css-prefix}-page-break::after, .fa-duotone.#{$fa-css-prefix}-page-break::after { content: "\f877\f877"; }
.fad.#{$fa-css-prefix}-file-excel::after, .fa-duotone.#{$fa-css-prefix}-file-excel::after { content: "\f1c3\f1c3"; }
.fad.#{$fa-css-prefix}-file-exclamation::after, .fa-duotone.#{$fa-css-prefix}-file-exclamation::after { content: "\f31a\f31a"; }
.fad.#{$fa-css-prefix}-file-export::after, .fa-duotone.#{$fa-css-prefix}-file-export::after { content: "\f56e\f56e"; }
.fad.#{$fa-css-prefix}-arrow-right-from-file::after, .fa-duotone.#{$fa-css-prefix}-arrow-right-from-file::after { content: "\f56e\f56e"; }
.fad.#{$fa-css-prefix}-file-heart::after, .fa-duotone.#{$fa-css-prefix}-file-heart::after { content: "\e176\e176"; }
.fad.#{$fa-css-prefix}-file-image::after, .fa-duotone.#{$fa-css-prefix}-file-image::after { content: "\f1c5\f1c5"; }
.fad.#{$fa-css-prefix}-file-import::after, .fa-duotone.#{$fa-css-prefix}-file-import::after { content: "\f56f\f56f"; }
.fad.#{$fa-css-prefix}-arrow-right-to-file::after, .fa-duotone.#{$fa-css-prefix}-arrow-right-to-file::after { content: "\f56f\f56f"; }
.fad.#{$fa-css-prefix}-file-invoice::after, .fa-duotone.#{$fa-css-prefix}-file-invoice::after { content: "\f570\f570"; }
.fad.#{$fa-css-prefix}-file-invoice-dollar::after, .fa-duotone.#{$fa-css-prefix}-file-invoice-dollar::after { content: "\f571\f571"; }
.fad.#{$fa-css-prefix}-file-lines::after, .fa-duotone.#{$fa-css-prefix}-file-lines::after { content: "\f15c\f15c"; }
.fad.#{$fa-css-prefix}-file-alt::after, .fa-duotone.#{$fa-css-prefix}-file-alt::after { content: "\f15c\f15c"; }
.fad.#{$fa-css-prefix}-file-text::after, .fa-duotone.#{$fa-css-prefix}-file-text::after { content: "\f15c\f15c"; }
.fad.#{$fa-css-prefix}-file-lock::after, .fa-duotone.#{$fa-css-prefix}-file-lock::after { content: "\e3a6\e3a6"; }
.fad.#{$fa-css-prefix}-file-magnifying-glass::after, .fa-duotone.#{$fa-css-prefix}-file-magnifying-glass::after { content: "\f865\f865"; }
.fad.#{$fa-css-prefix}-file-search::after, .fa-duotone.#{$fa-css-prefix}-file-search::after { content: "\f865\f865"; }
.fad.#{$fa-css-prefix}-file-medical::after, .fa-duotone.#{$fa-css-prefix}-file-medical::after { content: "\f477\f477"; }
.fad.#{$fa-css-prefix}-file-minus::after, .fa-duotone.#{$fa-css-prefix}-file-minus::after { content: "\f318\f318"; }
.fad.#{$fa-css-prefix}-file-music::after, .fa-duotone.#{$fa-css-prefix}-file-music::after { content: "\f8b6\f8b6"; }
.fad.#{$fa-css-prefix}-file-pdf::after, .fa-duotone.#{$fa-css-prefix}-file-pdf::after { content: "\f1c1\f1c1"; }
.fad.#{$fa-css-prefix}-file-pen::after, .fa-duotone.#{$fa-css-prefix}-file-pen::after { content: "\f31c\f31c"; }
.fad.#{$fa-css-prefix}-file-edit::after, .fa-duotone.#{$fa-css-prefix}-file-edit::after { content: "\f31c\f31c"; }
.fad.#{$fa-css-prefix}-file-plus::after, .fa-duotone.#{$fa-css-prefix}-file-plus::after { content: "\f319\f319"; }
.fad.#{$fa-css-prefix}-file-plus-minus::after, .fa-duotone.#{$fa-css-prefix}-file-plus-minus::after { content: "\e177\e177"; }
.fad.#{$fa-css-prefix}-file-powerpoint::after, .fa-duotone.#{$fa-css-prefix}-file-powerpoint::after { content: "\f1c4\f1c4"; }
.fad.#{$fa-css-prefix}-file-prescription::after, .fa-duotone.#{$fa-css-prefix}-file-prescription::after { content: "\f572\f572"; }
.fad.#{$fa-css-prefix}-file-signature::after, .fa-duotone.#{$fa-css-prefix}-file-signature::after { content: "\f573\f573"; }
.fad.#{$fa-css-prefix}-file-slash::after, .fa-duotone.#{$fa-css-prefix}-file-slash::after { content: "\e3a7\e3a7"; }
.fad.#{$fa-css-prefix}-file-spreadsheet::after, .fa-duotone.#{$fa-css-prefix}-file-spreadsheet::after { content: "\f65b\f65b"; }
.fad.#{$fa-css-prefix}-file-user::after, .fa-duotone.#{$fa-css-prefix}-file-user::after { content: "\f65c\f65c"; }
.fad.#{$fa-css-prefix}-file-video::after, .fa-duotone.#{$fa-css-prefix}-file-video::after { content: "\f1c8\f1c8"; }
.fad.#{$fa-css-prefix}-file-waveform::after, .fa-duotone.#{$fa-css-prefix}-file-waveform::after { content: "\f478\f478"; }
.fad.#{$fa-css-prefix}-file-medical-alt::after, .fa-duotone.#{$fa-css-prefix}-file-medical-alt::after { content: "\f478\f478"; }
.fad.#{$fa-css-prefix}-file-word::after, .fa-duotone.#{$fa-css-prefix}-file-word::after { content: "\f1c2\f1c2"; }
.fad.#{$fa-css-prefix}-file-xmark::after, .fa-duotone.#{$fa-css-prefix}-file-xmark::after { content: "\f317\f317"; }
.fad.#{$fa-css-prefix}-file-times::after, .fa-duotone.#{$fa-css-prefix}-file-times::after { content: "\f317\f317"; }
.fad.#{$fa-css-prefix}-file-zipper::after, .fa-duotone.#{$fa-css-prefix}-file-zipper::after { content: "\f1c6\f1c6"; }
.fad.#{$fa-css-prefix}-file-archive::after, .fa-duotone.#{$fa-css-prefix}-file-archive::after { content: "\f1c6\f1c6"; }
.fad.#{$fa-css-prefix}-files::after, .fa-duotone.#{$fa-css-prefix}-files::after { content: "\e178\e178"; }
.fad.#{$fa-css-prefix}-files-medical::after, .fa-duotone.#{$fa-css-prefix}-files-medical::after { content: "\f7fd\f7fd"; }
.fad.#{$fa-css-prefix}-fill::after, .fa-duotone.#{$fa-css-prefix}-fill::after { content: "\f575\f575"; }
.fad.#{$fa-css-prefix}-fill-drip::after, .fa-duotone.#{$fa-css-prefix}-fill-drip::after { content: "\f576\f576"; }
.fad.#{$fa-css-prefix}-film::after, .fa-duotone.#{$fa-css-prefix}-film::after { content: "\f008\f008"; }
.fad.#{$fa-css-prefix}-film-canister::after, .fa-duotone.#{$fa-css-prefix}-film-canister::after { content: "\f8b7\f8b7"; }
.fad.#{$fa-css-prefix}-film-simple::after, .fa-duotone.#{$fa-css-prefix}-film-simple::after { content: "\f3a0\f3a0"; }
.fad.#{$fa-css-prefix}-film-alt::after, .fa-duotone.#{$fa-css-prefix}-film-alt::after { content: "\f3a0\f3a0"; }
.fad.#{$fa-css-prefix}-film-slash::after, .fa-duotone.#{$fa-css-prefix}-film-slash::after { content: "\e179\e179"; }
.fad.#{$fa-css-prefix}-films::after, .fa-duotone.#{$fa-css-prefix}-films::after { content: "\e17a\e17a"; }
.fad.#{$fa-css-prefix}-filter::after, .fa-duotone.#{$fa-css-prefix}-filter::after { content: "\f0b0\f0b0"; }
.fad.#{$fa-css-prefix}-filter-circle-dollar::after, .fa-duotone.#{$fa-css-prefix}-filter-circle-dollar::after { content: "\f662\f662"; }
.fad.#{$fa-css-prefix}-funnel-dollar::after, .fa-duotone.#{$fa-css-prefix}-funnel-dollar::after { content: "\f662\f662"; }
.fad.#{$fa-css-prefix}-filter-circle-xmark::after, .fa-duotone.#{$fa-css-prefix}-filter-circle-xmark::after { content: "\e17b\e17b"; }
.fad.#{$fa-css-prefix}-filter-list::after, .fa-duotone.#{$fa-css-prefix}-filter-list::after { content: "\e17c\e17c"; }
.fad.#{$fa-css-prefix}-filter-slash::after, .fa-duotone.#{$fa-css-prefix}-filter-slash::after { content: "\e17d\e17d"; }
.fad.#{$fa-css-prefix}-filters::after, .fa-duotone.#{$fa-css-prefix}-filters::after { content: "\e17e\e17e"; }
.fad.#{$fa-css-prefix}-fingerprint::after, .fa-duotone.#{$fa-css-prefix}-fingerprint::after { content: "\f577\f577"; }
.fad.#{$fa-css-prefix}-fire::after, .fa-duotone.#{$fa-css-prefix}-fire::after { content: "\f06d\f06d"; }
.fad.#{$fa-css-prefix}-fire-extinguisher::after, .fa-duotone.#{$fa-css-prefix}-fire-extinguisher::after { content: "\f134\f134"; }
.fad.#{$fa-css-prefix}-fire-flame::after, .fa-duotone.#{$fa-css-prefix}-fire-flame::after { content: "\f6df\f6df"; }
.fad.#{$fa-css-prefix}-flame::after, .fa-duotone.#{$fa-css-prefix}-flame::after { content: "\f6df\f6df"; }
.fad.#{$fa-css-prefix}-fire-flame-curved::after, .fa-duotone.#{$fa-css-prefix}-fire-flame-curved::after { content: "\f7e4\f7e4"; }
.fad.#{$fa-css-prefix}-fire-alt::after, .fa-duotone.#{$fa-css-prefix}-fire-alt::after { content: "\f7e4\f7e4"; }
.fad.#{$fa-css-prefix}-fire-flame-simple::after, .fa-duotone.#{$fa-css-prefix}-fire-flame-simple::after { content: "\f46a\f46a"; }
.fad.#{$fa-css-prefix}-burn::after, .fa-duotone.#{$fa-css-prefix}-burn::after { content: "\f46a\f46a"; }
.fad.#{$fa-css-prefix}-fire-hydrant::after, .fa-duotone.#{$fa-css-prefix}-fire-hydrant::after { content: "\e17f\e17f"; }
.fad.#{$fa-css-prefix}-fire-smoke::after, .fa-duotone.#{$fa-css-prefix}-fire-smoke::after { content: "\f74b\f74b"; }
.fad.#{$fa-css-prefix}-fireplace::after, .fa-duotone.#{$fa-css-prefix}-fireplace::after { content: "\f79a\f79a"; }
.fad.#{$fa-css-prefix}-fish::after, .fa-duotone.#{$fa-css-prefix}-fish::after { content: "\f578\f578"; }
.fad.#{$fa-css-prefix}-fish-bones::after, .fa-duotone.#{$fa-css-prefix}-fish-bones::after { content: "\e304\e304"; }
.fad.#{$fa-css-prefix}-fish-cooked::after, .fa-duotone.#{$fa-css-prefix}-fish-cooked::after { content: "\f7fe\f7fe"; }
.fad.#{$fa-css-prefix}-fishing-rod::after, .fa-duotone.#{$fa-css-prefix}-fishing-rod::after { content: "\e3a8\e3a8"; }
.fad.#{$fa-css-prefix}-flag::after, .fa-duotone.#{$fa-css-prefix}-flag::after { content: "\f024\f024"; }
.fad.#{$fa-css-prefix}-flag-checkered::after, .fa-duotone.#{$fa-css-prefix}-flag-checkered::after { content: "\f11e\f11e"; }
.fad.#{$fa-css-prefix}-flag-pennant::after, .fa-duotone.#{$fa-css-prefix}-flag-pennant::after { content: "\f456\f456"; }
.fad.#{$fa-css-prefix}-pennant::after, .fa-duotone.#{$fa-css-prefix}-pennant::after { content: "\f456\f456"; }
.fad.#{$fa-css-prefix}-flag-swallowtail::after, .fa-duotone.#{$fa-css-prefix}-flag-swallowtail::after { content: "\f74c\f74c"; }
.fad.#{$fa-css-prefix}-flag-alt::after, .fa-duotone.#{$fa-css-prefix}-flag-alt::after { content: "\f74c\f74c"; }
.fad.#{$fa-css-prefix}-flag-usa::after, .fa-duotone.#{$fa-css-prefix}-flag-usa::after { content: "\f74d\f74d"; }
.fad.#{$fa-css-prefix}-flashlight::after, .fa-duotone.#{$fa-css-prefix}-flashlight::after { content: "\f8b8\f8b8"; }
.fad.#{$fa-css-prefix}-flask::after, .fa-duotone.#{$fa-css-prefix}-flask::after { content: "\f0c3\f0c3"; }
.fad.#{$fa-css-prefix}-flask-round-poison::after, .fa-duotone.#{$fa-css-prefix}-flask-round-poison::after { content: "\f6e0\f6e0"; }
.fad.#{$fa-css-prefix}-flask-poison::after, .fa-duotone.#{$fa-css-prefix}-flask-poison::after { content: "\f6e0\f6e0"; }
.fad.#{$fa-css-prefix}-flask-round-potion::after, .fa-duotone.#{$fa-css-prefix}-flask-round-potion::after { content: "\f6e1\f6e1"; }
.fad.#{$fa-css-prefix}-flask-potion::after, .fa-duotone.#{$fa-css-prefix}-flask-potion::after { content: "\f6e1\f6e1"; }
.fad.#{$fa-css-prefix}-flatbread::after, .fa-duotone.#{$fa-css-prefix}-flatbread::after { content: "\e40b\e40b"; }
.fad.#{$fa-css-prefix}-flatbread-stuffed::after, .fa-duotone.#{$fa-css-prefix}-flatbread-stuffed::after { content: "\e40c\e40c"; }
.fad.#{$fa-css-prefix}-floppy-disk::after, .fa-duotone.#{$fa-css-prefix}-floppy-disk::after { content: "\f0c7\f0c7"; }
.fad.#{$fa-css-prefix}-save::after, .fa-duotone.#{$fa-css-prefix}-save::after { content: "\f0c7\f0c7"; }
.fad.#{$fa-css-prefix}-floppy-disk-circle-arrow-right::after, .fa-duotone.#{$fa-css-prefix}-floppy-disk-circle-arrow-right::after { content: "\e180\e180"; }
.fad.#{$fa-css-prefix}-save-circle-arrow-right::after, .fa-duotone.#{$fa-css-prefix}-save-circle-arrow-right::after { content: "\e180\e180"; }
.fad.#{$fa-css-prefix}-floppy-disk-circle-xmark::after, .fa-duotone.#{$fa-css-prefix}-floppy-disk-circle-xmark::after { content: "\e181\e181"; }
.fad.#{$fa-css-prefix}-floppy-disk-times::after, .fa-duotone.#{$fa-css-prefix}-floppy-disk-times::after { content: "\e181\e181"; }
.fad.#{$fa-css-prefix}-save-circle-xmark::after, .fa-duotone.#{$fa-css-prefix}-save-circle-xmark::after { content: "\e181\e181"; }
.fad.#{$fa-css-prefix}-save-times::after, .fa-duotone.#{$fa-css-prefix}-save-times::after { content: "\e181\e181"; }
.fad.#{$fa-css-prefix}-floppy-disk-pen::after, .fa-duotone.#{$fa-css-prefix}-floppy-disk-pen::after { content: "\e182\e182"; }
.fad.#{$fa-css-prefix}-floppy-disks::after, .fa-duotone.#{$fa-css-prefix}-floppy-disks::after { content: "\e183\e183"; }
.fad.#{$fa-css-prefix}-florin-sign::after, .fa-duotone.#{$fa-css-prefix}-florin-sign::after { content: "\e184\e184"; }
.fad.#{$fa-css-prefix}-flower::after, .fa-duotone.#{$fa-css-prefix}-flower::after { content: "\f7ff\f7ff"; }
.fad.#{$fa-css-prefix}-flower-daffodil::after, .fa-duotone.#{$fa-css-prefix}-flower-daffodil::after { content: "\f800\f800"; }
.fad.#{$fa-css-prefix}-flower-tulip::after, .fa-duotone.#{$fa-css-prefix}-flower-tulip::after { content: "\f801\f801"; }
.fad.#{$fa-css-prefix}-flute::after, .fa-duotone.#{$fa-css-prefix}-flute::after { content: "\f8b9\f8b9"; }
.fad.#{$fa-css-prefix}-flux-capacitor::after, .fa-duotone.#{$fa-css-prefix}-flux-capacitor::after { content: "\f8ba\f8ba"; }
.fad.#{$fa-css-prefix}-flying-disc::after, .fa-duotone.#{$fa-css-prefix}-flying-disc::after { content: "\e3a9\e3a9"; }
.fad.#{$fa-css-prefix}-folder::after, .fa-duotone.#{$fa-css-prefix}-folder::after { content: "\f07b\f07b"; }
.fad.#{$fa-css-prefix}-folder-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-folder-arrow-down::after { content: "\e053\e053"; }
.fad.#{$fa-css-prefix}-folder-download::after, .fa-duotone.#{$fa-css-prefix}-folder-download::after { content: "\e053\e053"; }
.fad.#{$fa-css-prefix}-folder-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-folder-arrow-up::after { content: "\e054\e054"; }
.fad.#{$fa-css-prefix}-folder-upload::after, .fa-duotone.#{$fa-css-prefix}-folder-upload::after { content: "\e054\e054"; }
.fad.#{$fa-css-prefix}-folder-blank::after, .fa-duotone.#{$fa-css-prefix}-folder-blank::after { content: "\e185\e185"; }
.fad.#{$fa-css-prefix}-folder-bookmark::after, .fa-duotone.#{$fa-css-prefix}-folder-bookmark::after { content: "\e186\e186"; }
.fad.#{$fa-css-prefix}-folder-gear::after, .fa-duotone.#{$fa-css-prefix}-folder-gear::after { content: "\e187\e187"; }
.fad.#{$fa-css-prefix}-folder-cog::after, .fa-duotone.#{$fa-css-prefix}-folder-cog::after { content: "\e187\e187"; }
.fad.#{$fa-css-prefix}-folder-grid::after, .fa-duotone.#{$fa-css-prefix}-folder-grid::after { content: "\e188\e188"; }
.fad.#{$fa-css-prefix}-folder-heart::after, .fa-duotone.#{$fa-css-prefix}-folder-heart::after { content: "\e189\e189"; }
.fad.#{$fa-css-prefix}-folder-image::after, .fa-duotone.#{$fa-css-prefix}-folder-image::after { content: "\e18a\e18a"; }
.fad.#{$fa-css-prefix}-folder-magnifying-glass::after, .fa-duotone.#{$fa-css-prefix}-folder-magnifying-glass::after { content: "\e18b\e18b"; }
.fad.#{$fa-css-prefix}-folder-search::after, .fa-duotone.#{$fa-css-prefix}-folder-search::after { content: "\e18b\e18b"; }
.fad.#{$fa-css-prefix}-folder-medical::after, .fa-duotone.#{$fa-css-prefix}-folder-medical::after { content: "\e18c\e18c"; }
.fad.#{$fa-css-prefix}-folder-minus::after, .fa-duotone.#{$fa-css-prefix}-folder-minus::after { content: "\f65d\f65d"; }
.fad.#{$fa-css-prefix}-folder-music::after, .fa-duotone.#{$fa-css-prefix}-folder-music::after { content: "\e18d\e18d"; }
.fad.#{$fa-css-prefix}-folder-open::after, .fa-duotone.#{$fa-css-prefix}-folder-open::after { content: "\f07c\f07c"; }
.fad.#{$fa-css-prefix}-folder-plus::after, .fa-duotone.#{$fa-css-prefix}-folder-plus::after { content: "\f65e\f65e"; }
.fad.#{$fa-css-prefix}-folder-tree::after, .fa-duotone.#{$fa-css-prefix}-folder-tree::after { content: "\f802\f802"; }
.fad.#{$fa-css-prefix}-folder-user::after, .fa-duotone.#{$fa-css-prefix}-folder-user::after { content: "\e18e\e18e"; }
.fad.#{$fa-css-prefix}-folder-xmark::after, .fa-duotone.#{$fa-css-prefix}-folder-xmark::after { content: "\f65f\f65f"; }
.fad.#{$fa-css-prefix}-folder-times::after, .fa-duotone.#{$fa-css-prefix}-folder-times::after { content: "\f65f\f65f"; }
.fad.#{$fa-css-prefix}-folders::after, .fa-duotone.#{$fa-css-prefix}-folders::after { content: "\f660\f660"; }
.fad.#{$fa-css-prefix}-fondue-pot::after, .fa-duotone.#{$fa-css-prefix}-fondue-pot::after { content: "\e40d\e40d"; }
.fad.#{$fa-css-prefix}-font::after, .fa-duotone.#{$fa-css-prefix}-font::after { content: "\f031\f031"; }
.fad.#{$fa-css-prefix}-font-awesome::after, .fa-duotone.#{$fa-css-prefix}-font-awesome::after { content: "\f2b4\f2b4"; }
.fad.#{$fa-css-prefix}-font-awesome-flag::after, .fa-duotone.#{$fa-css-prefix}-font-awesome-flag::after { content: "\f2b4\f2b4"; }
.fad.#{$fa-css-prefix}-font-awesome-logo-full::after, .fa-duotone.#{$fa-css-prefix}-font-awesome-logo-full::after { content: "\f2b4\f2b4"; }
.fad.#{$fa-css-prefix}-font-case::after, .fa-duotone.#{$fa-css-prefix}-font-case::after { content: "\f866\f866"; }
.fad.#{$fa-css-prefix}-football::after, .fa-duotone.#{$fa-css-prefix}-football::after { content: "\f44e\f44e"; }
.fad.#{$fa-css-prefix}-football-ball::after, .fa-duotone.#{$fa-css-prefix}-football-ball::after { content: "\f44e\f44e"; }
.fad.#{$fa-css-prefix}-football-helmet::after, .fa-duotone.#{$fa-css-prefix}-football-helmet::after { content: "\f44f\f44f"; }
.fad.#{$fa-css-prefix}-fork::after, .fa-duotone.#{$fa-css-prefix}-fork::after { content: "\f2e3\f2e3"; }
.fad.#{$fa-css-prefix}-utensil-fork::after, .fa-duotone.#{$fa-css-prefix}-utensil-fork::after { content: "\f2e3\f2e3"; }
.fad.#{$fa-css-prefix}-fork-knife::after, .fa-duotone.#{$fa-css-prefix}-fork-knife::after { content: "\f2e6\f2e6"; }
.fad.#{$fa-css-prefix}-utensils-alt::after, .fa-duotone.#{$fa-css-prefix}-utensils-alt::after { content: "\f2e6\f2e6"; }
.fad.#{$fa-css-prefix}-forklift::after, .fa-duotone.#{$fa-css-prefix}-forklift::after { content: "\f47a\f47a"; }
.fad.#{$fa-css-prefix}-fort::after, .fa-duotone.#{$fa-css-prefix}-fort::after { content: "\e486\e486"; }
.fad.#{$fa-css-prefix}-forward::after, .fa-duotone.#{$fa-css-prefix}-forward::after { content: "\f04e\f04e"; }
.fad.#{$fa-css-prefix}-forward-fast::after, .fa-duotone.#{$fa-css-prefix}-forward-fast::after { content: "\f050\f050"; }
.fad.#{$fa-css-prefix}-fast-forward::after, .fa-duotone.#{$fa-css-prefix}-fast-forward::after { content: "\f050\f050"; }
.fad.#{$fa-css-prefix}-forward-step::after, .fa-duotone.#{$fa-css-prefix}-forward-step::after { content: "\f051\f051"; }
.fad.#{$fa-css-prefix}-step-forward::after, .fa-duotone.#{$fa-css-prefix}-step-forward::after { content: "\f051\f051"; }
.fad.#{$fa-css-prefix}-frame::after, .fa-duotone.#{$fa-css-prefix}-frame::after { content: "\e495\e495"; }
.fad.#{$fa-css-prefix}-franc-sign::after, .fa-duotone.#{$fa-css-prefix}-franc-sign::after { content: "\e18f\e18f"; }
.fad.#{$fa-css-prefix}-french-fries::after, .fa-duotone.#{$fa-css-prefix}-french-fries::after { content: "\f803\f803"; }
.fad.#{$fa-css-prefix}-frog::after, .fa-duotone.#{$fa-css-prefix}-frog::after { content: "\f52e\f52e"; }
.fad.#{$fa-css-prefix}-function::after, .fa-duotone.#{$fa-css-prefix}-function::after { content: "\f661\f661"; }
.fad.#{$fa-css-prefix}-futbol::after, .fa-duotone.#{$fa-css-prefix}-futbol::after { content: "\f1e3\f1e3"; }
.fad.#{$fa-css-prefix}-futbol-ball::after, .fa-duotone.#{$fa-css-prefix}-futbol-ball::after { content: "\f1e3\f1e3"; }
.fad.#{$fa-css-prefix}-soccer-ball::after, .fa-duotone.#{$fa-css-prefix}-soccer-ball::after { content: "\f1e3\f1e3"; }
.fad.#{$fa-css-prefix}-g::after, .fa-duotone.#{$fa-css-prefix}-g::after { content: "\47\47"; }
.fad.#{$fa-css-prefix}-galaxy::after, .fa-duotone.#{$fa-css-prefix}-galaxy::after { content: "\e008\e008"; }
.fad.#{$fa-css-prefix}-gallery-thumbnails::after, .fa-duotone.#{$fa-css-prefix}-gallery-thumbnails::after { content: "\e3aa\e3aa"; }
.fad.#{$fa-css-prefix}-game-board::after, .fa-duotone.#{$fa-css-prefix}-game-board::after { content: "\f867\f867"; }
.fad.#{$fa-css-prefix}-game-board-simple::after, .fa-duotone.#{$fa-css-prefix}-game-board-simple::after { content: "\f868\f868"; }
.fad.#{$fa-css-prefix}-game-board-alt::after, .fa-duotone.#{$fa-css-prefix}-game-board-alt::after { content: "\f868\f868"; }
.fad.#{$fa-css-prefix}-game-console-handheld::after, .fa-duotone.#{$fa-css-prefix}-game-console-handheld::after { content: "\f8bb\f8bb"; }
.fad.#{$fa-css-prefix}-gamepad::after, .fa-duotone.#{$fa-css-prefix}-gamepad::after { content: "\f11b\f11b"; }
.fad.#{$fa-css-prefix}-gamepad-modern::after, .fa-duotone.#{$fa-css-prefix}-gamepad-modern::after { content: "\f8bc\f8bc"; }
.fad.#{$fa-css-prefix}-gamepad-alt::after, .fa-duotone.#{$fa-css-prefix}-gamepad-alt::after { content: "\f8bc\f8bc"; }
.fad.#{$fa-css-prefix}-garage::after, .fa-duotone.#{$fa-css-prefix}-garage::after { content: "\e009\e009"; }
.fad.#{$fa-css-prefix}-garage-car::after, .fa-duotone.#{$fa-css-prefix}-garage-car::after { content: "\e00a\e00a"; }
.fad.#{$fa-css-prefix}-garage-open::after, .fa-duotone.#{$fa-css-prefix}-garage-open::after { content: "\e00b\e00b"; }
.fad.#{$fa-css-prefix}-garlic::after, .fa-duotone.#{$fa-css-prefix}-garlic::after { content: "\e40e\e40e"; }
.fad.#{$fa-css-prefix}-gas-pump::after, .fa-duotone.#{$fa-css-prefix}-gas-pump::after { content: "\f52f\f52f"; }
.fad.#{$fa-css-prefix}-gas-pump-slash::after, .fa-duotone.#{$fa-css-prefix}-gas-pump-slash::after { content: "\f5f4\f5f4"; }
.fad.#{$fa-css-prefix}-gauge::after, .fa-duotone.#{$fa-css-prefix}-gauge::after { content: "\f624\f624"; }
.fad.#{$fa-css-prefix}-dashboard::after, .fa-duotone.#{$fa-css-prefix}-dashboard::after { content: "\f624\f624"; }
.fad.#{$fa-css-prefix}-gauge-med::after, .fa-duotone.#{$fa-css-prefix}-gauge-med::after { content: "\f624\f624"; }
.fad.#{$fa-css-prefix}-tachometer-alt-average::after, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-average::after { content: "\f624\f624"; }
.fad.#{$fa-css-prefix}-gauge-circle-bolt::after, .fa-duotone.#{$fa-css-prefix}-gauge-circle-bolt::after { content: "\e496\e496"; }
.fad.#{$fa-css-prefix}-gauge-circle-minus::after, .fa-duotone.#{$fa-css-prefix}-gauge-circle-minus::after { content: "\e497\e497"; }
.fad.#{$fa-css-prefix}-gauge-circle-plus::after, .fa-duotone.#{$fa-css-prefix}-gauge-circle-plus::after { content: "\e498\e498"; }
.fad.#{$fa-css-prefix}-gauge-high::after, .fa-duotone.#{$fa-css-prefix}-gauge-high::after { content: "\f625\f625"; }
.fad.#{$fa-css-prefix}-tachometer-alt::after, .fa-duotone.#{$fa-css-prefix}-tachometer-alt::after { content: "\f625\f625"; }
.fad.#{$fa-css-prefix}-tachometer-alt-fast::after, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-fast::after { content: "\f625\f625"; }
.fad.#{$fa-css-prefix}-gauge-low::after, .fa-duotone.#{$fa-css-prefix}-gauge-low::after { content: "\f627\f627"; }
.fad.#{$fa-css-prefix}-tachometer-alt-slow::after, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-slow::after { content: "\f627\f627"; }
.fad.#{$fa-css-prefix}-gauge-max::after, .fa-duotone.#{$fa-css-prefix}-gauge-max::after { content: "\f626\f626"; }
.fad.#{$fa-css-prefix}-tachometer-alt-fastest::after, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-fastest::after { content: "\f626\f626"; }
.fad.#{$fa-css-prefix}-gauge-min::after, .fa-duotone.#{$fa-css-prefix}-gauge-min::after { content: "\f628\f628"; }
.fad.#{$fa-css-prefix}-tachometer-alt-slowest::after, .fa-duotone.#{$fa-css-prefix}-tachometer-alt-slowest::after { content: "\f628\f628"; }
.fad.#{$fa-css-prefix}-gauge-simple::after, .fa-duotone.#{$fa-css-prefix}-gauge-simple::after { content: "\f629\f629"; }
.fad.#{$fa-css-prefix}-gauge-simple-med::after, .fa-duotone.#{$fa-css-prefix}-gauge-simple-med::after { content: "\f629\f629"; }
.fad.#{$fa-css-prefix}-tachometer-average::after, .fa-duotone.#{$fa-css-prefix}-tachometer-average::after { content: "\f629\f629"; }
.fad.#{$fa-css-prefix}-gauge-simple-high::after, .fa-duotone.#{$fa-css-prefix}-gauge-simple-high::after { content: "\f62a\f62a"; }
.fad.#{$fa-css-prefix}-tachometer::after, .fa-duotone.#{$fa-css-prefix}-tachometer::after { content: "\f62a\f62a"; }
.fad.#{$fa-css-prefix}-tachometer-fast::after, .fa-duotone.#{$fa-css-prefix}-tachometer-fast::after { content: "\f62a\f62a"; }
.fad.#{$fa-css-prefix}-gauge-simple-low::after, .fa-duotone.#{$fa-css-prefix}-gauge-simple-low::after { content: "\f62c\f62c"; }
.fad.#{$fa-css-prefix}-tachometer-slow::after, .fa-duotone.#{$fa-css-prefix}-tachometer-slow::after { content: "\f62c\f62c"; }
.fad.#{$fa-css-prefix}-gauge-simple-max::after, .fa-duotone.#{$fa-css-prefix}-gauge-simple-max::after { content: "\f62b\f62b"; }
.fad.#{$fa-css-prefix}-tachometer-fastest::after, .fa-duotone.#{$fa-css-prefix}-tachometer-fastest::after { content: "\f62b\f62b"; }
.fad.#{$fa-css-prefix}-gauge-simple-min::after, .fa-duotone.#{$fa-css-prefix}-gauge-simple-min::after { content: "\f62d\f62d"; }
.fad.#{$fa-css-prefix}-tachometer-slowest::after, .fa-duotone.#{$fa-css-prefix}-tachometer-slowest::after { content: "\f62d\f62d"; }
.fad.#{$fa-css-prefix}-gavel::after, .fa-duotone.#{$fa-css-prefix}-gavel::after { content: "\f0e3\f0e3"; }
.fad.#{$fa-css-prefix}-legal::after, .fa-duotone.#{$fa-css-prefix}-legal::after { content: "\f0e3\f0e3"; }
.fad.#{$fa-css-prefix}-gear::after, .fa-duotone.#{$fa-css-prefix}-gear::after { content: "\f013\f013"; }
.fad.#{$fa-css-prefix}-cog::after, .fa-duotone.#{$fa-css-prefix}-cog::after { content: "\f013\f013"; }
.fad.#{$fa-css-prefix}-gears::after, .fa-duotone.#{$fa-css-prefix}-gears::after { content: "\f085\f085"; }
.fad.#{$fa-css-prefix}-cogs::after, .fa-duotone.#{$fa-css-prefix}-cogs::after { content: "\f085\f085"; }
.fad.#{$fa-css-prefix}-gem::after, .fa-duotone.#{$fa-css-prefix}-gem::after { content: "\f3a5\f3a5"; }
.fad.#{$fa-css-prefix}-genderless::after, .fa-duotone.#{$fa-css-prefix}-genderless::after { content: "\f22d\f22d"; }
.fad.#{$fa-css-prefix}-ghost::after, .fa-duotone.#{$fa-css-prefix}-ghost::after { content: "\f6e2\f6e2"; }
.fad.#{$fa-css-prefix}-gif::after, .fa-duotone.#{$fa-css-prefix}-gif::after { content: "\e190\e190"; }
.fad.#{$fa-css-prefix}-gift::after, .fa-duotone.#{$fa-css-prefix}-gift::after { content: "\f06b\f06b"; }
.fad.#{$fa-css-prefix}-gift-card::after, .fa-duotone.#{$fa-css-prefix}-gift-card::after { content: "\f663\f663"; }
.fad.#{$fa-css-prefix}-gifts::after, .fa-duotone.#{$fa-css-prefix}-gifts::after { content: "\f79c\f79c"; }
.fad.#{$fa-css-prefix}-gingerbread-man::after, .fa-duotone.#{$fa-css-prefix}-gingerbread-man::after { content: "\f79d\f79d"; }
.fad.#{$fa-css-prefix}-glass::after, .fa-duotone.#{$fa-css-prefix}-glass::after { content: "\f804\f804"; }
.fad.#{$fa-css-prefix}-glass-citrus::after, .fa-duotone.#{$fa-css-prefix}-glass-citrus::after { content: "\f869\f869"; }
.fad.#{$fa-css-prefix}-glass-empty::after, .fa-duotone.#{$fa-css-prefix}-glass-empty::after { content: "\e191\e191"; }
.fad.#{$fa-css-prefix}-glass-half::after, .fa-duotone.#{$fa-css-prefix}-glass-half::after { content: "\e192\e192"; }
.fad.#{$fa-css-prefix}-glass-half-empty::after, .fa-duotone.#{$fa-css-prefix}-glass-half-empty::after { content: "\e192\e192"; }
.fad.#{$fa-css-prefix}-glass-half-full::after, .fa-duotone.#{$fa-css-prefix}-glass-half-full::after { content: "\e192\e192"; }
.fad.#{$fa-css-prefix}-glasses::after, .fa-duotone.#{$fa-css-prefix}-glasses::after { content: "\f530\f530"; }
.fad.#{$fa-css-prefix}-glasses-round::after, .fa-duotone.#{$fa-css-prefix}-glasses-round::after { content: "\f5f5\f5f5"; }
.fad.#{$fa-css-prefix}-glasses-alt::after, .fa-duotone.#{$fa-css-prefix}-glasses-alt::after { content: "\f5f5\f5f5"; }
.fad.#{$fa-css-prefix}-globe::after, .fa-duotone.#{$fa-css-prefix}-globe::after { content: "\f0ac\f0ac"; }
.fad.#{$fa-css-prefix}-globe-snow::after, .fa-duotone.#{$fa-css-prefix}-globe-snow::after { content: "\f7a3\f7a3"; }
.fad.#{$fa-css-prefix}-globe-stand::after, .fa-duotone.#{$fa-css-prefix}-globe-stand::after { content: "\f5f6\f5f6"; }
.fad.#{$fa-css-prefix}-goal-net::after, .fa-duotone.#{$fa-css-prefix}-goal-net::after { content: "\e3ab\e3ab"; }
.fad.#{$fa-css-prefix}-golf-ball-tee::after, .fa-duotone.#{$fa-css-prefix}-golf-ball-tee::after { content: "\f450\f450"; }
.fad.#{$fa-css-prefix}-golf-ball::after, .fa-duotone.#{$fa-css-prefix}-golf-ball::after { content: "\f450\f450"; }
.fad.#{$fa-css-prefix}-golf-club::after, .fa-duotone.#{$fa-css-prefix}-golf-club::after { content: "\f451\f451"; }
.fad.#{$fa-css-prefix}-golf-flag-hole::after, .fa-duotone.#{$fa-css-prefix}-golf-flag-hole::after { content: "\e3ac\e3ac"; }
.fad.#{$fa-css-prefix}-gopuram::after, .fa-duotone.#{$fa-css-prefix}-gopuram::after { content: "\f664\f664"; }
.fad.#{$fa-css-prefix}-graduation-cap::after, .fa-duotone.#{$fa-css-prefix}-graduation-cap::after { content: "\f19d\f19d"; }
.fad.#{$fa-css-prefix}-mortar-board::after, .fa-duotone.#{$fa-css-prefix}-mortar-board::after { content: "\f19d\f19d"; }
.fad.#{$fa-css-prefix}-gramophone::after, .fa-duotone.#{$fa-css-prefix}-gramophone::after { content: "\f8bd\f8bd"; }
.fad.#{$fa-css-prefix}-grapes::after, .fa-duotone.#{$fa-css-prefix}-grapes::after { content: "\e306\e306"; }
.fad.#{$fa-css-prefix}-grate::after, .fa-duotone.#{$fa-css-prefix}-grate::after { content: "\e193\e193"; }
.fad.#{$fa-css-prefix}-grate-droplet::after, .fa-duotone.#{$fa-css-prefix}-grate-droplet::after { content: "\e194\e194"; }
.fad.#{$fa-css-prefix}-greater-than::after, .fa-duotone.#{$fa-css-prefix}-greater-than::after { content: "\3e\3e"; }
.fad.#{$fa-css-prefix}-greater-than-equal::after, .fa-duotone.#{$fa-css-prefix}-greater-than-equal::after { content: "\f532\f532"; }
.fad.#{$fa-css-prefix}-grid::after, .fa-duotone.#{$fa-css-prefix}-grid::after { content: "\e195\e195"; }
.fad.#{$fa-css-prefix}-grid-3::after, .fa-duotone.#{$fa-css-prefix}-grid-3::after { content: "\e195\e195"; }
.fad.#{$fa-css-prefix}-grid-2::after, .fa-duotone.#{$fa-css-prefix}-grid-2::after { content: "\e196\e196"; }
.fad.#{$fa-css-prefix}-grid-2-plus::after, .fa-duotone.#{$fa-css-prefix}-grid-2-plus::after { content: "\e197\e197"; }
.fad.#{$fa-css-prefix}-grid-4::after, .fa-duotone.#{$fa-css-prefix}-grid-4::after { content: "\e198\e198"; }
.fad.#{$fa-css-prefix}-grid-5::after, .fa-duotone.#{$fa-css-prefix}-grid-5::after { content: "\e199\e199"; }
.fad.#{$fa-css-prefix}-grid-dividers::after, .fa-duotone.#{$fa-css-prefix}-grid-dividers::after { content: "\e3ad\e3ad"; }
.fad.#{$fa-css-prefix}-grid-horizontal::after, .fa-duotone.#{$fa-css-prefix}-grid-horizontal::after { content: "\e307\e307"; }
.fad.#{$fa-css-prefix}-grip::after, .fa-duotone.#{$fa-css-prefix}-grip::after { content: "\f58d\f58d"; }
.fad.#{$fa-css-prefix}-grip-horizontal::after, .fa-duotone.#{$fa-css-prefix}-grip-horizontal::after { content: "\f58d\f58d"; }
.fad.#{$fa-css-prefix}-grip-dots::after, .fa-duotone.#{$fa-css-prefix}-grip-dots::after { content: "\e410\e410"; }
.fad.#{$fa-css-prefix}-grip-dots-vertical::after, .fa-duotone.#{$fa-css-prefix}-grip-dots-vertical::after { content: "\e411\e411"; }
.fad.#{$fa-css-prefix}-grip-lines::after, .fa-duotone.#{$fa-css-prefix}-grip-lines::after { content: "\f7a4\f7a4"; }
.fad.#{$fa-css-prefix}-grip-lines-vertical::after, .fa-duotone.#{$fa-css-prefix}-grip-lines-vertical::after { content: "\f7a5\f7a5"; }
.fad.#{$fa-css-prefix}-grip-vertical::after, .fa-duotone.#{$fa-css-prefix}-grip-vertical::after { content: "\f58e\f58e"; }
.fad.#{$fa-css-prefix}-guarani-sign::after, .fa-duotone.#{$fa-css-prefix}-guarani-sign::after { content: "\e19a\e19a"; }
.fad.#{$fa-css-prefix}-guitar::after, .fa-duotone.#{$fa-css-prefix}-guitar::after { content: "\f7a6\f7a6"; }
.fad.#{$fa-css-prefix}-guitar-electric::after, .fa-duotone.#{$fa-css-prefix}-guitar-electric::after { content: "\f8be\f8be"; }
.fad.#{$fa-css-prefix}-guitars::after, .fa-duotone.#{$fa-css-prefix}-guitars::after { content: "\f8bf\f8bf"; }
.fad.#{$fa-css-prefix}-gun::after, .fa-duotone.#{$fa-css-prefix}-gun::after { content: "\e19b\e19b"; }
.fad.#{$fa-css-prefix}-gun-slash::after, .fa-duotone.#{$fa-css-prefix}-gun-slash::after { content: "\e19c\e19c"; }
.fad.#{$fa-css-prefix}-gun-squirt::after, .fa-duotone.#{$fa-css-prefix}-gun-squirt::after { content: "\e19d\e19d"; }
.fad.#{$fa-css-prefix}-h::after, .fa-duotone.#{$fa-css-prefix}-h::after { content: "\48\48"; }
.fad.#{$fa-css-prefix}-h1::after, .fa-duotone.#{$fa-css-prefix}-h1::after { content: "\f313\f313"; }
.fad.#{$fa-css-prefix}-h2::after, .fa-duotone.#{$fa-css-prefix}-h2::after { content: "\f314\f314"; }
.fad.#{$fa-css-prefix}-h3::after, .fa-duotone.#{$fa-css-prefix}-h3::after { content: "\f315\f315"; }
.fad.#{$fa-css-prefix}-h4::after, .fa-duotone.#{$fa-css-prefix}-h4::after { content: "\f86a\f86a"; }
.fad.#{$fa-css-prefix}-h5::after, .fa-duotone.#{$fa-css-prefix}-h5::after { content: "\e412\e412"; }
.fad.#{$fa-css-prefix}-h6::after, .fa-duotone.#{$fa-css-prefix}-h6::after { content: "\e413\e413"; }
.fad.#{$fa-css-prefix}-hammer::after, .fa-duotone.#{$fa-css-prefix}-hammer::after { content: "\f6e3\f6e3"; }
.fad.#{$fa-css-prefix}-hammer-crash::after, .fa-duotone.#{$fa-css-prefix}-hammer-crash::after { content: "\e414\e414"; }
.fad.#{$fa-css-prefix}-hammer-war::after, .fa-duotone.#{$fa-css-prefix}-hammer-war::after { content: "\f6e4\f6e4"; }
.fad.#{$fa-css-prefix}-hamsa::after, .fa-duotone.#{$fa-css-prefix}-hamsa::after { content: "\f665\f665"; }
.fad.#{$fa-css-prefix}-hand::after, .fa-duotone.#{$fa-css-prefix}-hand::after { content: "\f256\f256"; }
.fad.#{$fa-css-prefix}-hand-paper::after, .fa-duotone.#{$fa-css-prefix}-hand-paper::after { content: "\f256\f256"; }
.fad.#{$fa-css-prefix}-hand-back-fist::after, .fa-duotone.#{$fa-css-prefix}-hand-back-fist::after { content: "\f255\f255"; }
.fad.#{$fa-css-prefix}-hand-rock::after, .fa-duotone.#{$fa-css-prefix}-hand-rock::after { content: "\f255\f255"; }
.fad.#{$fa-css-prefix}-hand-back-point-down::after, .fa-duotone.#{$fa-css-prefix}-hand-back-point-down::after { content: "\e19e\e19e"; }
.fad.#{$fa-css-prefix}-hand-back-point-left::after, .fa-duotone.#{$fa-css-prefix}-hand-back-point-left::after { content: "\e19f\e19f"; }
.fad.#{$fa-css-prefix}-hand-back-point-ribbon::after, .fa-duotone.#{$fa-css-prefix}-hand-back-point-ribbon::after { content: "\e1a0\e1a0"; }
.fad.#{$fa-css-prefix}-hand-back-point-right::after, .fa-duotone.#{$fa-css-prefix}-hand-back-point-right::after { content: "\e1a1\e1a1"; }
.fad.#{$fa-css-prefix}-hand-back-point-up::after, .fa-duotone.#{$fa-css-prefix}-hand-back-point-up::after { content: "\e1a2\e1a2"; }
.fad.#{$fa-css-prefix}-hand-dots::after, .fa-duotone.#{$fa-css-prefix}-hand-dots::after { content: "\f461\f461"; }
.fad.#{$fa-css-prefix}-allergies::after, .fa-duotone.#{$fa-css-prefix}-allergies::after { content: "\f461\f461"; }
.fad.#{$fa-css-prefix}-hand-fingers-crossed::after, .fa-duotone.#{$fa-css-prefix}-hand-fingers-crossed::after { content: "\e1a3\e1a3"; }
.fad.#{$fa-css-prefix}-hand-fist::after, .fa-duotone.#{$fa-css-prefix}-hand-fist::after { content: "\f6de\f6de"; }
.fad.#{$fa-css-prefix}-fist-raised::after, .fa-duotone.#{$fa-css-prefix}-fist-raised::after { content: "\f6de\f6de"; }
.fad.#{$fa-css-prefix}-hand-heart::after, .fa-duotone.#{$fa-css-prefix}-hand-heart::after { content: "\f4bc\f4bc"; }
.fad.#{$fa-css-prefix}-hand-holding::after, .fa-duotone.#{$fa-css-prefix}-hand-holding::after { content: "\f4bd\f4bd"; }
.fad.#{$fa-css-prefix}-hand-holding-box::after, .fa-duotone.#{$fa-css-prefix}-hand-holding-box::after { content: "\f47b\f47b"; }
.fad.#{$fa-css-prefix}-hand-holding-dollar::after, .fa-duotone.#{$fa-css-prefix}-hand-holding-dollar::after { content: "\f4c0\f4c0"; }
.fad.#{$fa-css-prefix}-hand-holding-usd::after, .fa-duotone.#{$fa-css-prefix}-hand-holding-usd::after { content: "\f4c0\f4c0"; }
.fad.#{$fa-css-prefix}-hand-holding-droplet::after, .fa-duotone.#{$fa-css-prefix}-hand-holding-droplet::after { content: "\f4c1\f4c1"; }
.fad.#{$fa-css-prefix}-hand-holding-water::after, .fa-duotone.#{$fa-css-prefix}-hand-holding-water::after { content: "\f4c1\f4c1"; }
.fad.#{$fa-css-prefix}-hand-holding-heart::after, .fa-duotone.#{$fa-css-prefix}-hand-holding-heart::after { content: "\f4be\f4be"; }
.fad.#{$fa-css-prefix}-hand-holding-magic::after, .fa-duotone.#{$fa-css-prefix}-hand-holding-magic::after { content: "\f6e5\f6e5"; }
.fad.#{$fa-css-prefix}-hand-holding-medical::after, .fa-duotone.#{$fa-css-prefix}-hand-holding-medical::after { content: "\e05c\e05c"; }
.fad.#{$fa-css-prefix}-hand-holding-seedling::after, .fa-duotone.#{$fa-css-prefix}-hand-holding-seedling::after { content: "\f4bf\f4bf"; }
.fad.#{$fa-css-prefix}-hand-holding-skull::after, .fa-duotone.#{$fa-css-prefix}-hand-holding-skull::after { content: "\e1a4\e1a4"; }
.fad.#{$fa-css-prefix}-hand-horns::after, .fa-duotone.#{$fa-css-prefix}-hand-horns::after { content: "\e1a9\e1a9"; }
.fad.#{$fa-css-prefix}-hand-lizard::after, .fa-duotone.#{$fa-css-prefix}-hand-lizard::after { content: "\f258\f258"; }
.fad.#{$fa-css-prefix}-hand-love::after, .fa-duotone.#{$fa-css-prefix}-hand-love::after { content: "\e1a5\e1a5"; }
.fad.#{$fa-css-prefix}-hand-middle-finger::after, .fa-duotone.#{$fa-css-prefix}-hand-middle-finger::after { content: "\f806\f806"; }
.fad.#{$fa-css-prefix}-hand-peace::after, .fa-duotone.#{$fa-css-prefix}-hand-peace::after { content: "\f25b\f25b"; }
.fad.#{$fa-css-prefix}-hand-point-down::after, .fa-duotone.#{$fa-css-prefix}-hand-point-down::after { content: "\f0a7\f0a7"; }
.fad.#{$fa-css-prefix}-hand-point-left::after, .fa-duotone.#{$fa-css-prefix}-hand-point-left::after { content: "\f0a5\f0a5"; }
.fad.#{$fa-css-prefix}-hand-point-ribbon::after, .fa-duotone.#{$fa-css-prefix}-hand-point-ribbon::after { content: "\e1a6\e1a6"; }
.fad.#{$fa-css-prefix}-hand-point-right::after, .fa-duotone.#{$fa-css-prefix}-hand-point-right::after { content: "\f0a4\f0a4"; }
.fad.#{$fa-css-prefix}-hand-point-up::after, .fa-duotone.#{$fa-css-prefix}-hand-point-up::after { content: "\f0a6\f0a6"; }
.fad.#{$fa-css-prefix}-hand-pointer::after, .fa-duotone.#{$fa-css-prefix}-hand-pointer::after { content: "\f25a\f25a"; }
.fad.#{$fa-css-prefix}-hand-scissors::after, .fa-duotone.#{$fa-css-prefix}-hand-scissors::after { content: "\f257\f257"; }
.fad.#{$fa-css-prefix}-hand-sparkles::after, .fa-duotone.#{$fa-css-prefix}-hand-sparkles::after { content: "\e05d\e05d"; }
.fad.#{$fa-css-prefix}-hand-spock::after, .fa-duotone.#{$fa-css-prefix}-hand-spock::after { content: "\f259\f259"; }
.fad.#{$fa-css-prefix}-hand-wave::after, .fa-duotone.#{$fa-css-prefix}-hand-wave::after { content: "\e1a7\e1a7"; }
.fad.#{$fa-css-prefix}-hands::after, .fa-duotone.#{$fa-css-prefix}-hands::after { content: "\f2a7\f2a7"; }
.fad.#{$fa-css-prefix}-sign-language::after, .fa-duotone.#{$fa-css-prefix}-sign-language::after { content: "\f2a7\f2a7"; }
.fad.#{$fa-css-prefix}-signing::after, .fa-duotone.#{$fa-css-prefix}-signing::after { content: "\f2a7\f2a7"; }
.fad.#{$fa-css-prefix}-hands-asl-interpreting::after, .fa-duotone.#{$fa-css-prefix}-hands-asl-interpreting::after { content: "\f2a3\f2a3"; }
.fad.#{$fa-css-prefix}-american-sign-language-interpreting::after, .fa-duotone.#{$fa-css-prefix}-american-sign-language-interpreting::after { content: "\f2a3\f2a3"; }
.fad.#{$fa-css-prefix}-asl-interpreting::after, .fa-duotone.#{$fa-css-prefix}-asl-interpreting::after { content: "\f2a3\f2a3"; }
.fad.#{$fa-css-prefix}-hands-american-sign-language-interpreting::after, .fa-duotone.#{$fa-css-prefix}-hands-american-sign-language-interpreting::after { content: "\f2a3\f2a3"; }
.fad.#{$fa-css-prefix}-hands-bubbles::after, .fa-duotone.#{$fa-css-prefix}-hands-bubbles::after { content: "\e05e\e05e"; }
.fad.#{$fa-css-prefix}-hands-wash::after, .fa-duotone.#{$fa-css-prefix}-hands-wash::after { content: "\e05e\e05e"; }
.fad.#{$fa-css-prefix}-hands-clapping::after, .fa-duotone.#{$fa-css-prefix}-hands-clapping::after { content: "\e1a8\e1a8"; }
.fad.#{$fa-css-prefix}-hands-holding::after, .fa-duotone.#{$fa-css-prefix}-hands-holding::after { content: "\f4c2\f4c2"; }
.fad.#{$fa-css-prefix}-hands-holding-diamond::after, .fa-duotone.#{$fa-css-prefix}-hands-holding-diamond::after { content: "\f47c\f47c"; }
.fad.#{$fa-css-prefix}-hand-receiving::after, .fa-duotone.#{$fa-css-prefix}-hand-receiving::after { content: "\f47c\f47c"; }
.fad.#{$fa-css-prefix}-hands-holding-dollar::after, .fa-duotone.#{$fa-css-prefix}-hands-holding-dollar::after { content: "\f4c5\f4c5"; }
.fad.#{$fa-css-prefix}-hands-usd::after, .fa-duotone.#{$fa-css-prefix}-hands-usd::after { content: "\f4c5\f4c5"; }
.fad.#{$fa-css-prefix}-hands-holding-heart::after, .fa-duotone.#{$fa-css-prefix}-hands-holding-heart::after { content: "\f4c3\f4c3"; }
.fad.#{$fa-css-prefix}-hands-heart::after, .fa-duotone.#{$fa-css-prefix}-hands-heart::after { content: "\f4c3\f4c3"; }
.fad.#{$fa-css-prefix}-hands-praying::after, .fa-duotone.#{$fa-css-prefix}-hands-praying::after { content: "\f684\f684"; }
.fad.#{$fa-css-prefix}-praying-hands::after, .fa-duotone.#{$fa-css-prefix}-praying-hands::after { content: "\f684\f684"; }
.fad.#{$fa-css-prefix}-handshake::after, .fa-duotone.#{$fa-css-prefix}-handshake::after { content: "\f2b5\f2b5"; }
.fad.#{$fa-css-prefix}-handshake-angle::after, .fa-duotone.#{$fa-css-prefix}-handshake-angle::after { content: "\f4c4\f4c4"; }
.fad.#{$fa-css-prefix}-hands-helping::after, .fa-duotone.#{$fa-css-prefix}-hands-helping::after { content: "\f4c4\f4c4"; }
.fad.#{$fa-css-prefix}-handshake-simple::after, .fa-duotone.#{$fa-css-prefix}-handshake-simple::after { content: "\f4c6\f4c6"; }
.fad.#{$fa-css-prefix}-handshake-alt::after, .fa-duotone.#{$fa-css-prefix}-handshake-alt::after { content: "\f4c6\f4c6"; }
.fad.#{$fa-css-prefix}-handshake-simple-slash::after, .fa-duotone.#{$fa-css-prefix}-handshake-simple-slash::after { content: "\e05f\e05f"; }
.fad.#{$fa-css-prefix}-handshake-alt-slash::after, .fa-duotone.#{$fa-css-prefix}-handshake-alt-slash::after { content: "\e05f\e05f"; }
.fad.#{$fa-css-prefix}-handshake-slash::after, .fa-duotone.#{$fa-css-prefix}-handshake-slash::after { content: "\e060\e060"; }
.fad.#{$fa-css-prefix}-hanukiah::after, .fa-duotone.#{$fa-css-prefix}-hanukiah::after { content: "\f6e6\f6e6"; }
.fad.#{$fa-css-prefix}-hard-drive::after, .fa-duotone.#{$fa-css-prefix}-hard-drive::after { content: "\f0a0\f0a0"; }
.fad.#{$fa-css-prefix}-hdd::after, .fa-duotone.#{$fa-css-prefix}-hdd::after { content: "\f0a0\f0a0"; }
.fad.#{$fa-css-prefix}-hashtag::after, .fa-duotone.#{$fa-css-prefix}-hashtag::after { content: "\23\23"; }
.fad.#{$fa-css-prefix}-hashtag-lock::after, .fa-duotone.#{$fa-css-prefix}-hashtag-lock::after { content: "\e415\e415"; }
.fad.#{$fa-css-prefix}-hat-chef::after, .fa-duotone.#{$fa-css-prefix}-hat-chef::after { content: "\f86b\f86b"; }
.fad.#{$fa-css-prefix}-hat-cowboy::after, .fa-duotone.#{$fa-css-prefix}-hat-cowboy::after { content: "\f8c0\f8c0"; }
.fad.#{$fa-css-prefix}-hat-cowboy-side::after, .fa-duotone.#{$fa-css-prefix}-hat-cowboy-side::after { content: "\f8c1\f8c1"; }
.fad.#{$fa-css-prefix}-hat-santa::after, .fa-duotone.#{$fa-css-prefix}-hat-santa::after { content: "\f7a7\f7a7"; }
.fad.#{$fa-css-prefix}-hat-winter::after, .fa-duotone.#{$fa-css-prefix}-hat-winter::after { content: "\f7a8\f7a8"; }
.fad.#{$fa-css-prefix}-hat-witch::after, .fa-duotone.#{$fa-css-prefix}-hat-witch::after { content: "\f6e7\f6e7"; }
.fad.#{$fa-css-prefix}-hat-wizard::after, .fa-duotone.#{$fa-css-prefix}-hat-wizard::after { content: "\f6e8\f6e8"; }
.fad.#{$fa-css-prefix}-head-side::after, .fa-duotone.#{$fa-css-prefix}-head-side::after { content: "\f6e9\f6e9"; }
.fad.#{$fa-css-prefix}-head-side-brain::after, .fa-duotone.#{$fa-css-prefix}-head-side-brain::after { content: "\f808\f808"; }
.fad.#{$fa-css-prefix}-head-side-cough::after, .fa-duotone.#{$fa-css-prefix}-head-side-cough::after { content: "\e061\e061"; }
.fad.#{$fa-css-prefix}-head-side-cough-slash::after, .fa-duotone.#{$fa-css-prefix}-head-side-cough-slash::after { content: "\e062\e062"; }
.fad.#{$fa-css-prefix}-head-side-goggles::after, .fa-duotone.#{$fa-css-prefix}-head-side-goggles::after { content: "\f6ea\f6ea"; }
.fad.#{$fa-css-prefix}-head-vr::after, .fa-duotone.#{$fa-css-prefix}-head-vr::after { content: "\f6ea\f6ea"; }
.fad.#{$fa-css-prefix}-head-side-headphones::after, .fa-duotone.#{$fa-css-prefix}-head-side-headphones::after { content: "\f8c2\f8c2"; }
.fad.#{$fa-css-prefix}-head-side-heart::after, .fa-duotone.#{$fa-css-prefix}-head-side-heart::after { content: "\e1aa\e1aa"; }
.fad.#{$fa-css-prefix}-head-side-mask::after, .fa-duotone.#{$fa-css-prefix}-head-side-mask::after { content: "\e063\e063"; }
.fad.#{$fa-css-prefix}-head-side-medical::after, .fa-duotone.#{$fa-css-prefix}-head-side-medical::after { content: "\f809\f809"; }
.fad.#{$fa-css-prefix}-head-side-virus::after, .fa-duotone.#{$fa-css-prefix}-head-side-virus::after { content: "\e064\e064"; }
.fad.#{$fa-css-prefix}-heading::after, .fa-duotone.#{$fa-css-prefix}-heading::after { content: "\f1dc\f1dc"; }
.fad.#{$fa-css-prefix}-header::after, .fa-duotone.#{$fa-css-prefix}-header::after { content: "\f1dc\f1dc"; }
.fad.#{$fa-css-prefix}-headphones::after, .fa-duotone.#{$fa-css-prefix}-headphones::after { content: "\f025\f025"; }
.fad.#{$fa-css-prefix}-headphones-simple::after, .fa-duotone.#{$fa-css-prefix}-headphones-simple::after { content: "\f58f\f58f"; }
.fad.#{$fa-css-prefix}-headphones-alt::after, .fa-duotone.#{$fa-css-prefix}-headphones-alt::after { content: "\f58f\f58f"; }
.fad.#{$fa-css-prefix}-headset::after, .fa-duotone.#{$fa-css-prefix}-headset::after { content: "\f590\f590"; }
.fad.#{$fa-css-prefix}-heart::after, .fa-duotone.#{$fa-css-prefix}-heart::after { content: "\f004\f004"; }
.fad.#{$fa-css-prefix}-heart-crack::after, .fa-duotone.#{$fa-css-prefix}-heart-crack::after { content: "\f7a9\f7a9"; }
.fad.#{$fa-css-prefix}-heart-broken::after, .fa-duotone.#{$fa-css-prefix}-heart-broken::after { content: "\f7a9\f7a9"; }
.fad.#{$fa-css-prefix}-heart-half::after, .fa-duotone.#{$fa-css-prefix}-heart-half::after { content: "\e1ab\e1ab"; }
.fad.#{$fa-css-prefix}-heart-half-stroke::after, .fa-duotone.#{$fa-css-prefix}-heart-half-stroke::after { content: "\e1ac\e1ac"; }
.fad.#{$fa-css-prefix}-heart-half-alt::after, .fa-duotone.#{$fa-css-prefix}-heart-half-alt::after { content: "\e1ac\e1ac"; }
.fad.#{$fa-css-prefix}-heart-pulse::after, .fa-duotone.#{$fa-css-prefix}-heart-pulse::after { content: "\f21e\f21e"; }
.fad.#{$fa-css-prefix}-heartbeat::after, .fa-duotone.#{$fa-css-prefix}-heartbeat::after { content: "\f21e\f21e"; }
.fad.#{$fa-css-prefix}-heat::after, .fa-duotone.#{$fa-css-prefix}-heat::after { content: "\e00c\e00c"; }
.fad.#{$fa-css-prefix}-helicopter::after, .fa-duotone.#{$fa-css-prefix}-helicopter::after { content: "\f533\f533"; }
.fad.#{$fa-css-prefix}-helmet-battle::after, .fa-duotone.#{$fa-css-prefix}-helmet-battle::after { content: "\f6eb\f6eb"; }
.fad.#{$fa-css-prefix}-helmet-safety::after, .fa-duotone.#{$fa-css-prefix}-helmet-safety::after { content: "\f807\f807"; }
.fad.#{$fa-css-prefix}-hard-hat::after, .fa-duotone.#{$fa-css-prefix}-hard-hat::after { content: "\f807\f807"; }
.fad.#{$fa-css-prefix}-hat-hard::after, .fa-duotone.#{$fa-css-prefix}-hat-hard::after { content: "\f807\f807"; }
.fad.#{$fa-css-prefix}-hexagon::after, .fa-duotone.#{$fa-css-prefix}-hexagon::after { content: "\f312\f312"; }
.fad.#{$fa-css-prefix}-hexagon-check::after, .fa-duotone.#{$fa-css-prefix}-hexagon-check::after { content: "\e416\e416"; }
.fad.#{$fa-css-prefix}-hexagon-divide::after, .fa-duotone.#{$fa-css-prefix}-hexagon-divide::after { content: "\e1ad\e1ad"; }
.fad.#{$fa-css-prefix}-hexagon-exclamation::after, .fa-duotone.#{$fa-css-prefix}-hexagon-exclamation::after { content: "\e417\e417"; }
.fad.#{$fa-css-prefix}-hexagon-minus::after, .fa-duotone.#{$fa-css-prefix}-hexagon-minus::after { content: "\f307\f307"; }
.fad.#{$fa-css-prefix}-minus-hexagon::after, .fa-duotone.#{$fa-css-prefix}-minus-hexagon::after { content: "\f307\f307"; }
.fad.#{$fa-css-prefix}-hexagon-plus::after, .fa-duotone.#{$fa-css-prefix}-hexagon-plus::after { content: "\f300\f300"; }
.fad.#{$fa-css-prefix}-plus-hexagon::after, .fa-duotone.#{$fa-css-prefix}-plus-hexagon::after { content: "\f300\f300"; }
.fad.#{$fa-css-prefix}-hexagon-xmark::after, .fa-duotone.#{$fa-css-prefix}-hexagon-xmark::after { content: "\f2ee\f2ee"; }
.fad.#{$fa-css-prefix}-times-hexagon::after, .fa-duotone.#{$fa-css-prefix}-times-hexagon::after { content: "\f2ee\f2ee"; }
.fad.#{$fa-css-prefix}-xmark-hexagon::after, .fa-duotone.#{$fa-css-prefix}-xmark-hexagon::after { content: "\f2ee\f2ee"; }
.fad.#{$fa-css-prefix}-high-definition::after, .fa-duotone.#{$fa-css-prefix}-high-definition::after { content: "\e1ae\e1ae"; }
.fad.#{$fa-css-prefix}-rectangle-hd::after, .fa-duotone.#{$fa-css-prefix}-rectangle-hd::after { content: "\e1ae\e1ae"; }
.fad.#{$fa-css-prefix}-highlighter::after, .fa-duotone.#{$fa-css-prefix}-highlighter::after { content: "\f591\f591"; }
.fad.#{$fa-css-prefix}-highlighter-line::after, .fa-duotone.#{$fa-css-prefix}-highlighter-line::after { content: "\e1af\e1af"; }
.fad.#{$fa-css-prefix}-hippo::after, .fa-duotone.#{$fa-css-prefix}-hippo::after { content: "\f6ed\f6ed"; }
.fad.#{$fa-css-prefix}-hockey-mask::after, .fa-duotone.#{$fa-css-prefix}-hockey-mask::after { content: "\f6ee\f6ee"; }
.fad.#{$fa-css-prefix}-hockey-puck::after, .fa-duotone.#{$fa-css-prefix}-hockey-puck::after { content: "\f453\f453"; }
.fad.#{$fa-css-prefix}-hockey-stick-puck::after, .fa-duotone.#{$fa-css-prefix}-hockey-stick-puck::after { content: "\e3ae\e3ae"; }
.fad.#{$fa-css-prefix}-hockey-sticks::after, .fa-duotone.#{$fa-css-prefix}-hockey-sticks::after { content: "\f454\f454"; }
.fad.#{$fa-css-prefix}-holly-berry::after, .fa-duotone.#{$fa-css-prefix}-holly-berry::after { content: "\f7aa\f7aa"; }
.fad.#{$fa-css-prefix}-honey-pot::after, .fa-duotone.#{$fa-css-prefix}-honey-pot::after { content: "\e418\e418"; }
.fad.#{$fa-css-prefix}-hood-cloak::after, .fa-duotone.#{$fa-css-prefix}-hood-cloak::after { content: "\f6ef\f6ef"; }
.fad.#{$fa-css-prefix}-horizontal-rule::after, .fa-duotone.#{$fa-css-prefix}-horizontal-rule::after { content: "\f86c\f86c"; }
.fad.#{$fa-css-prefix}-horse::after, .fa-duotone.#{$fa-css-prefix}-horse::after { content: "\f6f0\f6f0"; }
.fad.#{$fa-css-prefix}-horse-head::after, .fa-duotone.#{$fa-css-prefix}-horse-head::after { content: "\f7ab\f7ab"; }
.fad.#{$fa-css-prefix}-horse-saddle::after, .fa-duotone.#{$fa-css-prefix}-horse-saddle::after { content: "\f8c3\f8c3"; }
.fad.#{$fa-css-prefix}-hose::after, .fa-duotone.#{$fa-css-prefix}-hose::after { content: "\e419\e419"; }
.fad.#{$fa-css-prefix}-hose-reel::after, .fa-duotone.#{$fa-css-prefix}-hose-reel::after { content: "\e41a\e41a"; }
.fad.#{$fa-css-prefix}-hospital::after, .fa-duotone.#{$fa-css-prefix}-hospital::after { content: "\f0f8\f0f8"; }
.fad.#{$fa-css-prefix}-hospital-alt::after, .fa-duotone.#{$fa-css-prefix}-hospital-alt::after { content: "\f0f8\f0f8"; }
.fad.#{$fa-css-prefix}-hospital-wide::after, .fa-duotone.#{$fa-css-prefix}-hospital-wide::after { content: "\f0f8\f0f8"; }
.fad.#{$fa-css-prefix}-hospital-user::after, .fa-duotone.#{$fa-css-prefix}-hospital-user::after { content: "\f80d\f80d"; }
.fad.#{$fa-css-prefix}-hospitals::after, .fa-duotone.#{$fa-css-prefix}-hospitals::after { content: "\f80e\f80e"; }
.fad.#{$fa-css-prefix}-hot-tub-person::after, .fa-duotone.#{$fa-css-prefix}-hot-tub-person::after { content: "\f593\f593"; }
.fad.#{$fa-css-prefix}-hot-tub::after, .fa-duotone.#{$fa-css-prefix}-hot-tub::after { content: "\f593\f593"; }
.fad.#{$fa-css-prefix}-hotdog::after, .fa-duotone.#{$fa-css-prefix}-hotdog::after { content: "\f80f\f80f"; }
.fad.#{$fa-css-prefix}-hotel::after, .fa-duotone.#{$fa-css-prefix}-hotel::after { content: "\f594\f594"; }
.fad.#{$fa-css-prefix}-hourglass::after, .fa-duotone.#{$fa-css-prefix}-hourglass::after { content: "\f254\f254"; }
.fad.#{$fa-css-prefix}-hourglass-2::after, .fa-duotone.#{$fa-css-prefix}-hourglass-2::after { content: "\f254\f254"; }
.fad.#{$fa-css-prefix}-hourglass-half::after, .fa-duotone.#{$fa-css-prefix}-hourglass-half::after { content: "\f254\f254"; }
.fad.#{$fa-css-prefix}-hourglass-clock::after, .fa-duotone.#{$fa-css-prefix}-hourglass-clock::after { content: "\e41b\e41b"; }
.fad.#{$fa-css-prefix}-hourglass-empty::after, .fa-duotone.#{$fa-css-prefix}-hourglass-empty::after { content: "\f252\f252"; }
.fad.#{$fa-css-prefix}-hourglass-end::after, .fa-duotone.#{$fa-css-prefix}-hourglass-end::after { content: "\f253\f253"; }
.fad.#{$fa-css-prefix}-hourglass-3::after, .fa-duotone.#{$fa-css-prefix}-hourglass-3::after { content: "\f253\f253"; }
.fad.#{$fa-css-prefix}-hourglass-start::after, .fa-duotone.#{$fa-css-prefix}-hourglass-start::after { content: "\f251\f251"; }
.fad.#{$fa-css-prefix}-hourglass-1::after, .fa-duotone.#{$fa-css-prefix}-hourglass-1::after { content: "\f251\f251"; }
.fad.#{$fa-css-prefix}-house::after, .fa-duotone.#{$fa-css-prefix}-house::after { content: "\f015\f015"; }
.fad.#{$fa-css-prefix}-home::after, .fa-duotone.#{$fa-css-prefix}-home::after { content: "\f015\f015"; }
.fad.#{$fa-css-prefix}-home-alt::after, .fa-duotone.#{$fa-css-prefix}-home-alt::after { content: "\f015\f015"; }
.fad.#{$fa-css-prefix}-home-lg-alt::after, .fa-duotone.#{$fa-css-prefix}-home-lg-alt::after { content: "\f015\f015"; }
.fad.#{$fa-css-prefix}-house-blank::after, .fa-duotone.#{$fa-css-prefix}-house-blank::after { content: "\e487\e487"; }
.fad.#{$fa-css-prefix}-home-blank::after, .fa-duotone.#{$fa-css-prefix}-home-blank::after { content: "\e487\e487"; }
.fad.#{$fa-css-prefix}-house-building::after, .fa-duotone.#{$fa-css-prefix}-house-building::after { content: "\e1b1\e1b1"; }
.fad.#{$fa-css-prefix}-house-chimney::after, .fa-duotone.#{$fa-css-prefix}-house-chimney::after { content: "\e3af\e3af"; }
.fad.#{$fa-css-prefix}-home-lg::after, .fa-duotone.#{$fa-css-prefix}-home-lg::after { content: "\e3af\e3af"; }
.fad.#{$fa-css-prefix}-house-chimney-blank::after, .fa-duotone.#{$fa-css-prefix}-house-chimney-blank::after { content: "\e3b0\e3b0"; }
.fad.#{$fa-css-prefix}-house-chimney-crack::after, .fa-duotone.#{$fa-css-prefix}-house-chimney-crack::after { content: "\f6f1\f6f1"; }
.fad.#{$fa-css-prefix}-house-damage::after, .fa-duotone.#{$fa-css-prefix}-house-damage::after { content: "\f6f1\f6f1"; }
.fad.#{$fa-css-prefix}-house-chimney-heart::after, .fa-duotone.#{$fa-css-prefix}-house-chimney-heart::after { content: "\e1b2\e1b2"; }
.fad.#{$fa-css-prefix}-house-chimney-medical::after, .fa-duotone.#{$fa-css-prefix}-house-chimney-medical::after { content: "\f7f2\f7f2"; }
.fad.#{$fa-css-prefix}-clinic-medical::after, .fa-duotone.#{$fa-css-prefix}-clinic-medical::after { content: "\f7f2\f7f2"; }
.fad.#{$fa-css-prefix}-house-chimney-user::after, .fa-duotone.#{$fa-css-prefix}-house-chimney-user::after { content: "\e065\e065"; }
.fad.#{$fa-css-prefix}-house-chimney-window::after, .fa-duotone.#{$fa-css-prefix}-house-chimney-window::after { content: "\e00d\e00d"; }
.fad.#{$fa-css-prefix}-house-crack::after, .fa-duotone.#{$fa-css-prefix}-house-crack::after { content: "\e3b1\e3b1"; }
.fad.#{$fa-css-prefix}-house-day::after, .fa-duotone.#{$fa-css-prefix}-house-day::after { content: "\e00e\e00e"; }
.fad.#{$fa-css-prefix}-house-flood::after, .fa-duotone.#{$fa-css-prefix}-house-flood::after { content: "\f74f\f74f"; }
.fad.#{$fa-css-prefix}-house-heart::after, .fa-duotone.#{$fa-css-prefix}-house-heart::after { content: "\f4c9\f4c9"; }
.fad.#{$fa-css-prefix}-home-heart::after, .fa-duotone.#{$fa-css-prefix}-home-heart::after { content: "\f4c9\f4c9"; }
.fad.#{$fa-css-prefix}-house-laptop::after, .fa-duotone.#{$fa-css-prefix}-house-laptop::after { content: "\e066\e066"; }
.fad.#{$fa-css-prefix}-laptop-house::after, .fa-duotone.#{$fa-css-prefix}-laptop-house::after { content: "\e066\e066"; }
.fad.#{$fa-css-prefix}-house-medical::after, .fa-duotone.#{$fa-css-prefix}-house-medical::after { content: "\e3b2\e3b2"; }
.fad.#{$fa-css-prefix}-house-night::after, .fa-duotone.#{$fa-css-prefix}-house-night::after { content: "\e010\e010"; }
.fad.#{$fa-css-prefix}-house-person-leave::after, .fa-duotone.#{$fa-css-prefix}-house-person-leave::after { content: "\e00f\e00f"; }
.fad.#{$fa-css-prefix}-house-leave::after, .fa-duotone.#{$fa-css-prefix}-house-leave::after { content: "\e00f\e00f"; }
.fad.#{$fa-css-prefix}-house-person-depart::after, .fa-duotone.#{$fa-css-prefix}-house-person-depart::after { content: "\e00f\e00f"; }
.fad.#{$fa-css-prefix}-house-person-return::after, .fa-duotone.#{$fa-css-prefix}-house-person-return::after { content: "\e011\e011"; }
.fad.#{$fa-css-prefix}-house-person-arrive::after, .fa-duotone.#{$fa-css-prefix}-house-person-arrive::after { content: "\e011\e011"; }
.fad.#{$fa-css-prefix}-house-return::after, .fa-duotone.#{$fa-css-prefix}-house-return::after { content: "\e011\e011"; }
.fad.#{$fa-css-prefix}-house-signal::after, .fa-duotone.#{$fa-css-prefix}-house-signal::after { content: "\e012\e012"; }
.fad.#{$fa-css-prefix}-house-tree::after, .fa-duotone.#{$fa-css-prefix}-house-tree::after { content: "\e1b3\e1b3"; }
.fad.#{$fa-css-prefix}-house-turret::after, .fa-duotone.#{$fa-css-prefix}-house-turret::after { content: "\e1b4\e1b4"; }
.fad.#{$fa-css-prefix}-house-user::after, .fa-duotone.#{$fa-css-prefix}-house-user::after { content: "\e1b0\e1b0"; }
.fad.#{$fa-css-prefix}-home-user::after, .fa-duotone.#{$fa-css-prefix}-home-user::after { content: "\e1b0\e1b0"; }
.fad.#{$fa-css-prefix}-house-window::after, .fa-duotone.#{$fa-css-prefix}-house-window::after { content: "\e3b3\e3b3"; }
.fad.#{$fa-css-prefix}-hryvnia-sign::after, .fa-duotone.#{$fa-css-prefix}-hryvnia-sign::after { content: "\f6f2\f6f2"; }
.fad.#{$fa-css-prefix}-hryvnia::after, .fa-duotone.#{$fa-css-prefix}-hryvnia::after { content: "\f6f2\f6f2"; }
.fad.#{$fa-css-prefix}-hundred-points::after, .fa-duotone.#{$fa-css-prefix}-hundred-points::after { content: "\e41c\e41c"; }
.fad.#{$fa-css-prefix}-100::after, .fa-duotone.#{$fa-css-prefix}-100::after { content: "\e41c\e41c"; }
.fad.#{$fa-css-prefix}-hurricane::after, .fa-duotone.#{$fa-css-prefix}-hurricane::after { content: "\f751\f751"; }
.fad.#{$fa-css-prefix}-hyphen::after, .fa-duotone.#{$fa-css-prefix}-hyphen::after { content: "\2d\2d"; }
.fad.#{$fa-css-prefix}-i::after, .fa-duotone.#{$fa-css-prefix}-i::after { content: "\49\49"; }
.fad.#{$fa-css-prefix}-i-cursor::after, .fa-duotone.#{$fa-css-prefix}-i-cursor::after { content: "\f246\f246"; }
.fad.#{$fa-css-prefix}-ice-cream::after, .fa-duotone.#{$fa-css-prefix}-ice-cream::after { content: "\f810\f810"; }
.fad.#{$fa-css-prefix}-ice-skate::after, .fa-duotone.#{$fa-css-prefix}-ice-skate::after { content: "\f7ac\f7ac"; }
.fad.#{$fa-css-prefix}-icicles::after, .fa-duotone.#{$fa-css-prefix}-icicles::after { content: "\f7ad\f7ad"; }
.fad.#{$fa-css-prefix}-icons::after, .fa-duotone.#{$fa-css-prefix}-icons::after { content: "\f86d\f86d"; }
.fad.#{$fa-css-prefix}-heart-music-camera-bolt::after, .fa-duotone.#{$fa-css-prefix}-heart-music-camera-bolt::after { content: "\f86d\f86d"; }
.fad.#{$fa-css-prefix}-id-badge::after, .fa-duotone.#{$fa-css-prefix}-id-badge::after { content: "\f2c1\f2c1"; }
.fad.#{$fa-css-prefix}-id-card::after, .fa-duotone.#{$fa-css-prefix}-id-card::after { content: "\f2c2\f2c2"; }
.fad.#{$fa-css-prefix}-drivers-license::after, .fa-duotone.#{$fa-css-prefix}-drivers-license::after { content: "\f2c2\f2c2"; }
.fad.#{$fa-css-prefix}-id-card-clip::after, .fa-duotone.#{$fa-css-prefix}-id-card-clip::after { content: "\f47f\f47f"; }
.fad.#{$fa-css-prefix}-id-card-alt::after, .fa-duotone.#{$fa-css-prefix}-id-card-alt::after { content: "\f47f\f47f"; }
.fad.#{$fa-css-prefix}-igloo::after, .fa-duotone.#{$fa-css-prefix}-igloo::after { content: "\f7ae\f7ae"; }
.fad.#{$fa-css-prefix}-image::after, .fa-duotone.#{$fa-css-prefix}-image::after { content: "\f03e\f03e"; }
.fad.#{$fa-css-prefix}-image-landscape::after, .fa-duotone.#{$fa-css-prefix}-image-landscape::after { content: "\e1b5\e1b5"; }
.fad.#{$fa-css-prefix}-landscape::after, .fa-duotone.#{$fa-css-prefix}-landscape::after { content: "\e1b5\e1b5"; }
.fad.#{$fa-css-prefix}-image-polaroid::after, .fa-duotone.#{$fa-css-prefix}-image-polaroid::after { content: "\f8c4\f8c4"; }
.fad.#{$fa-css-prefix}-image-polaroid-user::after, .fa-duotone.#{$fa-css-prefix}-image-polaroid-user::after { content: "\e1b6\e1b6"; }
.fad.#{$fa-css-prefix}-image-portrait::after, .fa-duotone.#{$fa-css-prefix}-image-portrait::after { content: "\f3e0\f3e0"; }
.fad.#{$fa-css-prefix}-portrait::after, .fa-duotone.#{$fa-css-prefix}-portrait::after { content: "\f3e0\f3e0"; }
.fad.#{$fa-css-prefix}-image-slash::after, .fa-duotone.#{$fa-css-prefix}-image-slash::after { content: "\e1b7\e1b7"; }
.fad.#{$fa-css-prefix}-image-user::after, .fa-duotone.#{$fa-css-prefix}-image-user::after { content: "\e1b8\e1b8"; }
.fad.#{$fa-css-prefix}-images::after, .fa-duotone.#{$fa-css-prefix}-images::after { content: "\f302\f302"; }
.fad.#{$fa-css-prefix}-images-user::after, .fa-duotone.#{$fa-css-prefix}-images-user::after { content: "\e1b9\e1b9"; }
.fad.#{$fa-css-prefix}-inbox::after, .fa-duotone.#{$fa-css-prefix}-inbox::after { content: "\f01c\f01c"; }
.fad.#{$fa-css-prefix}-inbox-full::after, .fa-duotone.#{$fa-css-prefix}-inbox-full::after { content: "\e1ba\e1ba"; }
.fad.#{$fa-css-prefix}-inbox-in::after, .fa-duotone.#{$fa-css-prefix}-inbox-in::after { content: "\f310\f310"; }
.fad.#{$fa-css-prefix}-inbox-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-inbox-arrow-down::after { content: "\f310\f310"; }
.fad.#{$fa-css-prefix}-inbox-out::after, .fa-duotone.#{$fa-css-prefix}-inbox-out::after { content: "\f311\f311"; }
.fad.#{$fa-css-prefix}-inbox-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-inbox-arrow-up::after { content: "\f311\f311"; }
.fad.#{$fa-css-prefix}-inboxes::after, .fa-duotone.#{$fa-css-prefix}-inboxes::after { content: "\e1bb\e1bb"; }
.fad.#{$fa-css-prefix}-indent::after, .fa-duotone.#{$fa-css-prefix}-indent::after { content: "\f03c\f03c"; }
.fad.#{$fa-css-prefix}-indian-rupee-sign::after, .fa-duotone.#{$fa-css-prefix}-indian-rupee-sign::after { content: "\e1bc\e1bc"; }
.fad.#{$fa-css-prefix}-indian-rupee::after, .fa-duotone.#{$fa-css-prefix}-indian-rupee::after { content: "\e1bc\e1bc"; }
.fad.#{$fa-css-prefix}-inr::after, .fa-duotone.#{$fa-css-prefix}-inr::after { content: "\e1bc\e1bc"; }
.fad.#{$fa-css-prefix}-industry::after, .fa-duotone.#{$fa-css-prefix}-industry::after { content: "\f275\f275"; }
.fad.#{$fa-css-prefix}-industry-windows::after, .fa-duotone.#{$fa-css-prefix}-industry-windows::after { content: "\f3b3\f3b3"; }
.fad.#{$fa-css-prefix}-industry-alt::after, .fa-duotone.#{$fa-css-prefix}-industry-alt::after { content: "\f3b3\f3b3"; }
.fad.#{$fa-css-prefix}-infinity::after, .fa-duotone.#{$fa-css-prefix}-infinity::after { content: "\f534\f534"; }
.fad.#{$fa-css-prefix}-info::after, .fa-duotone.#{$fa-css-prefix}-info::after { content: "\f129\f129"; }
.fad.#{$fa-css-prefix}-inhaler::after, .fa-duotone.#{$fa-css-prefix}-inhaler::after { content: "\f5f9\f5f9"; }
.fad.#{$fa-css-prefix}-input-numeric::after, .fa-duotone.#{$fa-css-prefix}-input-numeric::after { content: "\e1bd\e1bd"; }
.fad.#{$fa-css-prefix}-input-pipe::after, .fa-duotone.#{$fa-css-prefix}-input-pipe::after { content: "\e1be\e1be"; }
.fad.#{$fa-css-prefix}-input-text::after, .fa-duotone.#{$fa-css-prefix}-input-text::after { content: "\e1bf\e1bf"; }
.fad.#{$fa-css-prefix}-integral::after, .fa-duotone.#{$fa-css-prefix}-integral::after { content: "\f667\f667"; }
.fad.#{$fa-css-prefix}-intersection::after, .fa-duotone.#{$fa-css-prefix}-intersection::after { content: "\f668\f668"; }
.fad.#{$fa-css-prefix}-island-tropical::after, .fa-duotone.#{$fa-css-prefix}-island-tropical::after { content: "\f811\f811"; }
.fad.#{$fa-css-prefix}-island-tree-palm::after, .fa-duotone.#{$fa-css-prefix}-island-tree-palm::after { content: "\f811\f811"; }
.fad.#{$fa-css-prefix}-italic::after, .fa-duotone.#{$fa-css-prefix}-italic::after { content: "\f033\f033"; }
.fad.#{$fa-css-prefix}-j::after, .fa-duotone.#{$fa-css-prefix}-j::after { content: "\4a\4a"; }
.fad.#{$fa-css-prefix}-jack-o-lantern::after, .fa-duotone.#{$fa-css-prefix}-jack-o-lantern::after { content: "\f30e\f30e"; }
.fad.#{$fa-css-prefix}-jedi::after, .fa-duotone.#{$fa-css-prefix}-jedi::after { content: "\f669\f669"; }
.fad.#{$fa-css-prefix}-jet-fighter::after, .fa-duotone.#{$fa-css-prefix}-jet-fighter::after { content: "\f0fb\f0fb"; }
.fad.#{$fa-css-prefix}-fighter-jet::after, .fa-duotone.#{$fa-css-prefix}-fighter-jet::after { content: "\f0fb\f0fb"; }
.fad.#{$fa-css-prefix}-joint::after, .fa-duotone.#{$fa-css-prefix}-joint::after { content: "\f595\f595"; }
.fad.#{$fa-css-prefix}-joystick::after, .fa-duotone.#{$fa-css-prefix}-joystick::after { content: "\f8c5\f8c5"; }
.fad.#{$fa-css-prefix}-jug::after, .fa-duotone.#{$fa-css-prefix}-jug::after { content: "\f8c6\f8c6"; }
.fad.#{$fa-css-prefix}-k::after, .fa-duotone.#{$fa-css-prefix}-k::after { content: "\4b\4b"; }
.fad.#{$fa-css-prefix}-kaaba::after, .fa-duotone.#{$fa-css-prefix}-kaaba::after { content: "\f66b\f66b"; }
.fad.#{$fa-css-prefix}-kazoo::after, .fa-duotone.#{$fa-css-prefix}-kazoo::after { content: "\f8c7\f8c7"; }
.fad.#{$fa-css-prefix}-kerning::after, .fa-duotone.#{$fa-css-prefix}-kerning::after { content: "\f86f\f86f"; }
.fad.#{$fa-css-prefix}-key::after, .fa-duotone.#{$fa-css-prefix}-key::after { content: "\f084\f084"; }
.fad.#{$fa-css-prefix}-key-skeleton::after, .fa-duotone.#{$fa-css-prefix}-key-skeleton::after { content: "\f6f3\f6f3"; }
.fad.#{$fa-css-prefix}-key-skeleton-left-right::after, .fa-duotone.#{$fa-css-prefix}-key-skeleton-left-right::after { content: "\e3b4\e3b4"; }
.fad.#{$fa-css-prefix}-keyboard::after, .fa-duotone.#{$fa-css-prefix}-keyboard::after { content: "\f11c\f11c"; }
.fad.#{$fa-css-prefix}-keyboard-brightness::after, .fa-duotone.#{$fa-css-prefix}-keyboard-brightness::after { content: "\e1c0\e1c0"; }
.fad.#{$fa-css-prefix}-keyboard-brightness-low::after, .fa-duotone.#{$fa-css-prefix}-keyboard-brightness-low::after { content: "\e1c1\e1c1"; }
.fad.#{$fa-css-prefix}-keyboard-down::after, .fa-duotone.#{$fa-css-prefix}-keyboard-down::after { content: "\e1c2\e1c2"; }
.fad.#{$fa-css-prefix}-keyboard-left::after, .fa-duotone.#{$fa-css-prefix}-keyboard-left::after { content: "\e1c3\e1c3"; }
.fad.#{$fa-css-prefix}-keynote::after, .fa-duotone.#{$fa-css-prefix}-keynote::after { content: "\f66c\f66c"; }
.fad.#{$fa-css-prefix}-khanda::after, .fa-duotone.#{$fa-css-prefix}-khanda::after { content: "\f66d\f66d"; }
.fad.#{$fa-css-prefix}-kidneys::after, .fa-duotone.#{$fa-css-prefix}-kidneys::after { content: "\f5fb\f5fb"; }
.fad.#{$fa-css-prefix}-kip-sign::after, .fa-duotone.#{$fa-css-prefix}-kip-sign::after { content: "\e1c4\e1c4"; }
.fad.#{$fa-css-prefix}-kit-medical::after, .fa-duotone.#{$fa-css-prefix}-kit-medical::after { content: "\f479\f479"; }
.fad.#{$fa-css-prefix}-first-aid::after, .fa-duotone.#{$fa-css-prefix}-first-aid::after { content: "\f479\f479"; }
.fad.#{$fa-css-prefix}-kite::after, .fa-duotone.#{$fa-css-prefix}-kite::after { content: "\f6f4\f6f4"; }
.fad.#{$fa-css-prefix}-kiwi-bird::after, .fa-duotone.#{$fa-css-prefix}-kiwi-bird::after { content: "\f535\f535"; }
.fad.#{$fa-css-prefix}-kiwi-fruit::after, .fa-duotone.#{$fa-css-prefix}-kiwi-fruit::after { content: "\e30c\e30c"; }
.fad.#{$fa-css-prefix}-knife::after, .fa-duotone.#{$fa-css-prefix}-knife::after { content: "\f2e4\f2e4"; }
.fad.#{$fa-css-prefix}-utensil-knife::after, .fa-duotone.#{$fa-css-prefix}-utensil-knife::after { content: "\f2e4\f2e4"; }
.fad.#{$fa-css-prefix}-knife-kitchen::after, .fa-duotone.#{$fa-css-prefix}-knife-kitchen::after { content: "\f6f5\f6f5"; }
.fad.#{$fa-css-prefix}-l::after, .fa-duotone.#{$fa-css-prefix}-l::after { content: "\4c\4c"; }
.fad.#{$fa-css-prefix}-lacrosse-stick::after, .fa-duotone.#{$fa-css-prefix}-lacrosse-stick::after { content: "\e3b5\e3b5"; }
.fad.#{$fa-css-prefix}-lacrosse-stick-ball::after, .fa-duotone.#{$fa-css-prefix}-lacrosse-stick-ball::after { content: "\e3b6\e3b6"; }
.fad.#{$fa-css-prefix}-lambda::after, .fa-duotone.#{$fa-css-prefix}-lambda::after { content: "\f66e\f66e"; }
.fad.#{$fa-css-prefix}-lamp::after, .fa-duotone.#{$fa-css-prefix}-lamp::after { content: "\f4ca\f4ca"; }
.fad.#{$fa-css-prefix}-lamp-desk::after, .fa-duotone.#{$fa-css-prefix}-lamp-desk::after { content: "\e014\e014"; }
.fad.#{$fa-css-prefix}-lamp-floor::after, .fa-duotone.#{$fa-css-prefix}-lamp-floor::after { content: "\e015\e015"; }
.fad.#{$fa-css-prefix}-lamp-street::after, .fa-duotone.#{$fa-css-prefix}-lamp-street::after { content: "\e1c5\e1c5"; }
.fad.#{$fa-css-prefix}-landmark::after, .fa-duotone.#{$fa-css-prefix}-landmark::after { content: "\f66f\f66f"; }
.fad.#{$fa-css-prefix}-landmark-dome::after, .fa-duotone.#{$fa-css-prefix}-landmark-dome::after { content: "\f752\f752"; }
.fad.#{$fa-css-prefix}-landmark-alt::after, .fa-duotone.#{$fa-css-prefix}-landmark-alt::after { content: "\f752\f752"; }
.fad.#{$fa-css-prefix}-language::after, .fa-duotone.#{$fa-css-prefix}-language::after { content: "\f1ab\f1ab"; }
.fad.#{$fa-css-prefix}-laptop::after, .fa-duotone.#{$fa-css-prefix}-laptop::after { content: "\f109\f109"; }
.fad.#{$fa-css-prefix}-laptop-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-laptop-arrow-down::after { content: "\e1c6\e1c6"; }
.fad.#{$fa-css-prefix}-laptop-code::after, .fa-duotone.#{$fa-css-prefix}-laptop-code::after { content: "\f5fc\f5fc"; }
.fad.#{$fa-css-prefix}-laptop-medical::after, .fa-duotone.#{$fa-css-prefix}-laptop-medical::after { content: "\f812\f812"; }
.fad.#{$fa-css-prefix}-laptop-mobile::after, .fa-duotone.#{$fa-css-prefix}-laptop-mobile::after { content: "\f87a\f87a"; }
.fad.#{$fa-css-prefix}-phone-laptop::after, .fa-duotone.#{$fa-css-prefix}-phone-laptop::after { content: "\f87a\f87a"; }
.fad.#{$fa-css-prefix}-laptop-slash::after, .fa-duotone.#{$fa-css-prefix}-laptop-slash::after { content: "\e1c7\e1c7"; }
.fad.#{$fa-css-prefix}-lari-sign::after, .fa-duotone.#{$fa-css-prefix}-lari-sign::after { content: "\e1c8\e1c8"; }
.fad.#{$fa-css-prefix}-lasso::after, .fa-duotone.#{$fa-css-prefix}-lasso::after { content: "\f8c8\f8c8"; }
.fad.#{$fa-css-prefix}-lasso-sparkles::after, .fa-duotone.#{$fa-css-prefix}-lasso-sparkles::after { content: "\e1c9\e1c9"; }
.fad.#{$fa-css-prefix}-layer-group::after, .fa-duotone.#{$fa-css-prefix}-layer-group::after { content: "\f5fd\f5fd"; }
.fad.#{$fa-css-prefix}-layer-minus::after, .fa-duotone.#{$fa-css-prefix}-layer-minus::after { content: "\f5fe\f5fe"; }
.fad.#{$fa-css-prefix}-layer-group-minus::after, .fa-duotone.#{$fa-css-prefix}-layer-group-minus::after { content: "\f5fe\f5fe"; }
.fad.#{$fa-css-prefix}-layer-plus::after, .fa-duotone.#{$fa-css-prefix}-layer-plus::after { content: "\f5ff\f5ff"; }
.fad.#{$fa-css-prefix}-layer-group-plus::after, .fa-duotone.#{$fa-css-prefix}-layer-group-plus::after { content: "\f5ff\f5ff"; }
.fad.#{$fa-css-prefix}-leaf::after, .fa-duotone.#{$fa-css-prefix}-leaf::after { content: "\f06c\f06c"; }
.fad.#{$fa-css-prefix}-leaf-heart::after, .fa-duotone.#{$fa-css-prefix}-leaf-heart::after { content: "\f4cb\f4cb"; }
.fad.#{$fa-css-prefix}-leaf-maple::after, .fa-duotone.#{$fa-css-prefix}-leaf-maple::after { content: "\f6f6\f6f6"; }
.fad.#{$fa-css-prefix}-leaf-oak::after, .fa-duotone.#{$fa-css-prefix}-leaf-oak::after { content: "\f6f7\f6f7"; }
.fad.#{$fa-css-prefix}-leafy-green::after, .fa-duotone.#{$fa-css-prefix}-leafy-green::after { content: "\e41d\e41d"; }
.fad.#{$fa-css-prefix}-left::after, .fa-duotone.#{$fa-css-prefix}-left::after { content: "\f355\f355"; }
.fad.#{$fa-css-prefix}-arrow-alt-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-left::after { content: "\f355\f355"; }
.fad.#{$fa-css-prefix}-left-from-line::after, .fa-duotone.#{$fa-css-prefix}-left-from-line::after { content: "\f348\f348"; }
.fad.#{$fa-css-prefix}-arrow-alt-from-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-from-right::after { content: "\f348\f348"; }
.fad.#{$fa-css-prefix}-left-long::after, .fa-duotone.#{$fa-css-prefix}-left-long::after { content: "\f30a\f30a"; }
.fad.#{$fa-css-prefix}-long-arrow-alt-left::after, .fa-duotone.#{$fa-css-prefix}-long-arrow-alt-left::after { content: "\f30a\f30a"; }
.fad.#{$fa-css-prefix}-left-long-to-line::after, .fa-duotone.#{$fa-css-prefix}-left-long-to-line::after { content: "\e41e\e41e"; }
.fad.#{$fa-css-prefix}-left-right::after, .fa-duotone.#{$fa-css-prefix}-left-right::after { content: "\f337\f337"; }
.fad.#{$fa-css-prefix}-arrows-alt-h::after, .fa-duotone.#{$fa-css-prefix}-arrows-alt-h::after { content: "\f337\f337"; }
.fad.#{$fa-css-prefix}-left-to-line::after, .fa-duotone.#{$fa-css-prefix}-left-to-line::after { content: "\f34b\f34b"; }
.fad.#{$fa-css-prefix}-arrow-alt-to-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-to-left::after { content: "\f34b\f34b"; }
.fad.#{$fa-css-prefix}-lemon::after, .fa-duotone.#{$fa-css-prefix}-lemon::after { content: "\f094\f094"; }
.fad.#{$fa-css-prefix}-less-than::after, .fa-duotone.#{$fa-css-prefix}-less-than::after { content: "\3c\3c"; }
.fad.#{$fa-css-prefix}-less-than-equal::after, .fa-duotone.#{$fa-css-prefix}-less-than-equal::after { content: "\f537\f537"; }
.fad.#{$fa-css-prefix}-life-ring::after, .fa-duotone.#{$fa-css-prefix}-life-ring::after { content: "\f1cd\f1cd"; }
.fad.#{$fa-css-prefix}-light-ceiling::after, .fa-duotone.#{$fa-css-prefix}-light-ceiling::after { content: "\e016\e016"; }
.fad.#{$fa-css-prefix}-light-emergency::after, .fa-duotone.#{$fa-css-prefix}-light-emergency::after { content: "\e41f\e41f"; }
.fad.#{$fa-css-prefix}-light-emergency-on::after, .fa-duotone.#{$fa-css-prefix}-light-emergency-on::after { content: "\e420\e420"; }
.fad.#{$fa-css-prefix}-light-switch::after, .fa-duotone.#{$fa-css-prefix}-light-switch::after { content: "\e017\e017"; }
.fad.#{$fa-css-prefix}-light-switch-off::after, .fa-duotone.#{$fa-css-prefix}-light-switch-off::after { content: "\e018\e018"; }
.fad.#{$fa-css-prefix}-light-switch-on::after, .fa-duotone.#{$fa-css-prefix}-light-switch-on::after { content: "\e019\e019"; }
.fad.#{$fa-css-prefix}-lightbulb::after, .fa-duotone.#{$fa-css-prefix}-lightbulb::after { content: "\f0eb\f0eb"; }
.fad.#{$fa-css-prefix}-lightbulb-dollar::after, .fa-duotone.#{$fa-css-prefix}-lightbulb-dollar::after { content: "\f670\f670"; }
.fad.#{$fa-css-prefix}-lightbulb-exclamation::after, .fa-duotone.#{$fa-css-prefix}-lightbulb-exclamation::after { content: "\f671\f671"; }
.fad.#{$fa-css-prefix}-lightbulb-exclamation-on::after, .fa-duotone.#{$fa-css-prefix}-lightbulb-exclamation-on::after { content: "\e1ca\e1ca"; }
.fad.#{$fa-css-prefix}-lightbulb-on::after, .fa-duotone.#{$fa-css-prefix}-lightbulb-on::after { content: "\f672\f672"; }
.fad.#{$fa-css-prefix}-lightbulb-slash::after, .fa-duotone.#{$fa-css-prefix}-lightbulb-slash::after { content: "\f673\f673"; }
.fad.#{$fa-css-prefix}-lights-holiday::after, .fa-duotone.#{$fa-css-prefix}-lights-holiday::after { content: "\f7b2\f7b2"; }
.fad.#{$fa-css-prefix}-line-columns::after, .fa-duotone.#{$fa-css-prefix}-line-columns::after { content: "\f870\f870"; }
.fad.#{$fa-css-prefix}-line-height::after, .fa-duotone.#{$fa-css-prefix}-line-height::after { content: "\f871\f871"; }
.fad.#{$fa-css-prefix}-link::after, .fa-duotone.#{$fa-css-prefix}-link::after { content: "\f0c1\f0c1"; }
.fad.#{$fa-css-prefix}-chain::after, .fa-duotone.#{$fa-css-prefix}-chain::after { content: "\f0c1\f0c1"; }
.fad.#{$fa-css-prefix}-link-horizontal::after, .fa-duotone.#{$fa-css-prefix}-link-horizontal::after { content: "\e1cb\e1cb"; }
.fad.#{$fa-css-prefix}-chain-horizontal::after, .fa-duotone.#{$fa-css-prefix}-chain-horizontal::after { content: "\e1cb\e1cb"; }
.fad.#{$fa-css-prefix}-link-horizontal-slash::after, .fa-duotone.#{$fa-css-prefix}-link-horizontal-slash::after { content: "\e1cc\e1cc"; }
.fad.#{$fa-css-prefix}-chain-horizontal-slash::after, .fa-duotone.#{$fa-css-prefix}-chain-horizontal-slash::after { content: "\e1cc\e1cc"; }
.fad.#{$fa-css-prefix}-link-simple::after, .fa-duotone.#{$fa-css-prefix}-link-simple::after { content: "\e1cd\e1cd"; }
.fad.#{$fa-css-prefix}-link-simple-slash::after, .fa-duotone.#{$fa-css-prefix}-link-simple-slash::after { content: "\e1ce\e1ce"; }
.fad.#{$fa-css-prefix}-link-slash::after, .fa-duotone.#{$fa-css-prefix}-link-slash::after { content: "\f127\f127"; }
.fad.#{$fa-css-prefix}-chain-broken::after, .fa-duotone.#{$fa-css-prefix}-chain-broken::after { content: "\f127\f127"; }
.fad.#{$fa-css-prefix}-chain-slash::after, .fa-duotone.#{$fa-css-prefix}-chain-slash::after { content: "\f127\f127"; }
.fad.#{$fa-css-prefix}-unlink::after, .fa-duotone.#{$fa-css-prefix}-unlink::after { content: "\f127\f127"; }
.fad.#{$fa-css-prefix}-lips::after, .fa-duotone.#{$fa-css-prefix}-lips::after { content: "\f600\f600"; }
.fad.#{$fa-css-prefix}-lira-sign::after, .fa-duotone.#{$fa-css-prefix}-lira-sign::after { content: "\f195\f195"; }
.fad.#{$fa-css-prefix}-list::after, .fa-duotone.#{$fa-css-prefix}-list::after { content: "\f03a\f03a"; }
.fad.#{$fa-css-prefix}-list-squares::after, .fa-duotone.#{$fa-css-prefix}-list-squares::after { content: "\f03a\f03a"; }
.fad.#{$fa-css-prefix}-list-check::after, .fa-duotone.#{$fa-css-prefix}-list-check::after { content: "\f0ae\f0ae"; }
.fad.#{$fa-css-prefix}-tasks::after, .fa-duotone.#{$fa-css-prefix}-tasks::after { content: "\f0ae\f0ae"; }
.fad.#{$fa-css-prefix}-list-dropdown::after, .fa-duotone.#{$fa-css-prefix}-list-dropdown::after { content: "\e1cf\e1cf"; }
.fad.#{$fa-css-prefix}-list-music::after, .fa-duotone.#{$fa-css-prefix}-list-music::after { content: "\f8c9\f8c9"; }
.fad.#{$fa-css-prefix}-list-ol::after, .fa-duotone.#{$fa-css-prefix}-list-ol::after { content: "\f0cb\f0cb"; }
.fad.#{$fa-css-prefix}-list-1-2::after, .fa-duotone.#{$fa-css-prefix}-list-1-2::after { content: "\f0cb\f0cb"; }
.fad.#{$fa-css-prefix}-list-numeric::after, .fa-duotone.#{$fa-css-prefix}-list-numeric::after { content: "\f0cb\f0cb"; }
.fad.#{$fa-css-prefix}-list-radio::after, .fa-duotone.#{$fa-css-prefix}-list-radio::after { content: "\e1d0\e1d0"; }
.fad.#{$fa-css-prefix}-list-timeline::after, .fa-duotone.#{$fa-css-prefix}-list-timeline::after { content: "\e1d1\e1d1"; }
.fad.#{$fa-css-prefix}-list-tree::after, .fa-duotone.#{$fa-css-prefix}-list-tree::after { content: "\e1d2\e1d2"; }
.fad.#{$fa-css-prefix}-list-ul::after, .fa-duotone.#{$fa-css-prefix}-list-ul::after { content: "\f0ca\f0ca"; }
.fad.#{$fa-css-prefix}-list-dots::after, .fa-duotone.#{$fa-css-prefix}-list-dots::after { content: "\f0ca\f0ca"; }
.fad.#{$fa-css-prefix}-litecoin-sign::after, .fa-duotone.#{$fa-css-prefix}-litecoin-sign::after { content: "\e1d3\e1d3"; }
.fad.#{$fa-css-prefix}-loader::after, .fa-duotone.#{$fa-css-prefix}-loader::after { content: "\e1d4\e1d4"; }
.fad.#{$fa-css-prefix}-lobster::after, .fa-duotone.#{$fa-css-prefix}-lobster::after { content: "\e421\e421"; }
.fad.#{$fa-css-prefix}-location-arrow::after, .fa-duotone.#{$fa-css-prefix}-location-arrow::after { content: "\f124\f124"; }
.fad.#{$fa-css-prefix}-location-check::after, .fa-duotone.#{$fa-css-prefix}-location-check::after { content: "\f606\f606"; }
.fad.#{$fa-css-prefix}-map-marker-check::after, .fa-duotone.#{$fa-css-prefix}-map-marker-check::after { content: "\f606\f606"; }
.fad.#{$fa-css-prefix}-location-crosshairs::after, .fa-duotone.#{$fa-css-prefix}-location-crosshairs::after { content: "\f601\f601"; }
.fad.#{$fa-css-prefix}-location::after, .fa-duotone.#{$fa-css-prefix}-location::after { content: "\f601\f601"; }
.fad.#{$fa-css-prefix}-location-crosshairs-slash::after, .fa-duotone.#{$fa-css-prefix}-location-crosshairs-slash::after { content: "\f603\f603"; }
.fad.#{$fa-css-prefix}-location-slash::after, .fa-duotone.#{$fa-css-prefix}-location-slash::after { content: "\f603\f603"; }
.fad.#{$fa-css-prefix}-location-dot::after, .fa-duotone.#{$fa-css-prefix}-location-dot::after { content: "\f3c5\f3c5"; }
.fad.#{$fa-css-prefix}-map-marker-alt::after, .fa-duotone.#{$fa-css-prefix}-map-marker-alt::after { content: "\f3c5\f3c5"; }
.fad.#{$fa-css-prefix}-location-dot-slash::after, .fa-duotone.#{$fa-css-prefix}-location-dot-slash::after { content: "\f605\f605"; }
.fad.#{$fa-css-prefix}-map-marker-alt-slash::after, .fa-duotone.#{$fa-css-prefix}-map-marker-alt-slash::after { content: "\f605\f605"; }
.fad.#{$fa-css-prefix}-location-exclamation::after, .fa-duotone.#{$fa-css-prefix}-location-exclamation::after { content: "\f608\f608"; }
.fad.#{$fa-css-prefix}-map-marker-exclamation::after, .fa-duotone.#{$fa-css-prefix}-map-marker-exclamation::after { content: "\f608\f608"; }
.fad.#{$fa-css-prefix}-location-minus::after, .fa-duotone.#{$fa-css-prefix}-location-minus::after { content: "\f609\f609"; }
.fad.#{$fa-css-prefix}-map-marker-minus::after, .fa-duotone.#{$fa-css-prefix}-map-marker-minus::after { content: "\f609\f609"; }
.fad.#{$fa-css-prefix}-location-pen::after, .fa-duotone.#{$fa-css-prefix}-location-pen::after { content: "\f607\f607"; }
.fad.#{$fa-css-prefix}-map-marker-edit::after, .fa-duotone.#{$fa-css-prefix}-map-marker-edit::after { content: "\f607\f607"; }
.fad.#{$fa-css-prefix}-location-pin::after, .fa-duotone.#{$fa-css-prefix}-location-pin::after { content: "\f041\f041"; }
.fad.#{$fa-css-prefix}-map-marker::after, .fa-duotone.#{$fa-css-prefix}-map-marker::after { content: "\f041\f041"; }
.fad.#{$fa-css-prefix}-location-pin-slash::after, .fa-duotone.#{$fa-css-prefix}-location-pin-slash::after { content: "\f60c\f60c"; }
.fad.#{$fa-css-prefix}-map-marker-slash::after, .fa-duotone.#{$fa-css-prefix}-map-marker-slash::after { content: "\f60c\f60c"; }
.fad.#{$fa-css-prefix}-location-plus::after, .fa-duotone.#{$fa-css-prefix}-location-plus::after { content: "\f60a\f60a"; }
.fad.#{$fa-css-prefix}-map-marker-plus::after, .fa-duotone.#{$fa-css-prefix}-map-marker-plus::after { content: "\f60a\f60a"; }
.fad.#{$fa-css-prefix}-location-question::after, .fa-duotone.#{$fa-css-prefix}-location-question::after { content: "\f60b\f60b"; }
.fad.#{$fa-css-prefix}-map-marker-question::after, .fa-duotone.#{$fa-css-prefix}-map-marker-question::after { content: "\f60b\f60b"; }
.fad.#{$fa-css-prefix}-location-smile::after, .fa-duotone.#{$fa-css-prefix}-location-smile::after { content: "\f60d\f60d"; }
.fad.#{$fa-css-prefix}-map-marker-smile::after, .fa-duotone.#{$fa-css-prefix}-map-marker-smile::after { content: "\f60d\f60d"; }
.fad.#{$fa-css-prefix}-location-xmark::after, .fa-duotone.#{$fa-css-prefix}-location-xmark::after { content: "\f60e\f60e"; }
.fad.#{$fa-css-prefix}-map-marker-times::after, .fa-duotone.#{$fa-css-prefix}-map-marker-times::after { content: "\f60e\f60e"; }
.fad.#{$fa-css-prefix}-map-marker-xmark::after, .fa-duotone.#{$fa-css-prefix}-map-marker-xmark::after { content: "\f60e\f60e"; }
.fad.#{$fa-css-prefix}-lock::after, .fa-duotone.#{$fa-css-prefix}-lock::after { content: "\f023\f023"; }
.fad.#{$fa-css-prefix}-lock-a::after, .fa-duotone.#{$fa-css-prefix}-lock-a::after { content: "\e422\e422"; }
.fad.#{$fa-css-prefix}-lock-hashtag::after, .fa-duotone.#{$fa-css-prefix}-lock-hashtag::after { content: "\e423\e423"; }
.fad.#{$fa-css-prefix}-lock-keyhole::after, .fa-duotone.#{$fa-css-prefix}-lock-keyhole::after { content: "\f30d\f30d"; }
.fad.#{$fa-css-prefix}-lock-alt::after, .fa-duotone.#{$fa-css-prefix}-lock-alt::after { content: "\f30d\f30d"; }
.fad.#{$fa-css-prefix}-lock-keyhole-open::after, .fa-duotone.#{$fa-css-prefix}-lock-keyhole-open::after { content: "\f3c2\f3c2"; }
.fad.#{$fa-css-prefix}-lock-open-alt::after, .fa-duotone.#{$fa-css-prefix}-lock-open-alt::after { content: "\f3c2\f3c2"; }
.fad.#{$fa-css-prefix}-lock-open::after, .fa-duotone.#{$fa-css-prefix}-lock-open::after { content: "\f3c1\f3c1"; }
.fad.#{$fa-css-prefix}-lollipop::after, .fa-duotone.#{$fa-css-prefix}-lollipop::after { content: "\e424\e424"; }
.fad.#{$fa-css-prefix}-lollypop::after, .fa-duotone.#{$fa-css-prefix}-lollypop::after { content: "\e424\e424"; }
.fad.#{$fa-css-prefix}-loveseat::after, .fa-duotone.#{$fa-css-prefix}-loveseat::after { content: "\f4cc\f4cc"; }
.fad.#{$fa-css-prefix}-couch-small::after, .fa-duotone.#{$fa-css-prefix}-couch-small::after { content: "\f4cc\f4cc"; }
.fad.#{$fa-css-prefix}-luchador-mask::after, .fa-duotone.#{$fa-css-prefix}-luchador-mask::after { content: "\f455\f455"; }
.fad.#{$fa-css-prefix}-luchador::after, .fa-duotone.#{$fa-css-prefix}-luchador::after { content: "\f455\f455"; }
.fad.#{$fa-css-prefix}-mask-luchador::after, .fa-duotone.#{$fa-css-prefix}-mask-luchador::after { content: "\f455\f455"; }
.fad.#{$fa-css-prefix}-lungs::after, .fa-duotone.#{$fa-css-prefix}-lungs::after { content: "\f604\f604"; }
.fad.#{$fa-css-prefix}-lungs-virus::after, .fa-duotone.#{$fa-css-prefix}-lungs-virus::after { content: "\e067\e067"; }
.fad.#{$fa-css-prefix}-m::after, .fa-duotone.#{$fa-css-prefix}-m::after { content: "\4d\4d"; }
.fad.#{$fa-css-prefix}-mace::after, .fa-duotone.#{$fa-css-prefix}-mace::after { content: "\f6f8\f6f8"; }
.fad.#{$fa-css-prefix}-magnet::after, .fa-duotone.#{$fa-css-prefix}-magnet::after { content: "\f076\f076"; }
.fad.#{$fa-css-prefix}-magnifying-glass::after, .fa-duotone.#{$fa-css-prefix}-magnifying-glass::after { content: "\f002\f002"; }
.fad.#{$fa-css-prefix}-search::after, .fa-duotone.#{$fa-css-prefix}-search::after { content: "\f002\f002"; }
.fad.#{$fa-css-prefix}-magnifying-glass-dollar::after, .fa-duotone.#{$fa-css-prefix}-magnifying-glass-dollar::after { content: "\f688\f688"; }
.fad.#{$fa-css-prefix}-search-dollar::after, .fa-duotone.#{$fa-css-prefix}-search-dollar::after { content: "\f688\f688"; }
.fad.#{$fa-css-prefix}-magnifying-glass-location::after, .fa-duotone.#{$fa-css-prefix}-magnifying-glass-location::after { content: "\f689\f689"; }
.fad.#{$fa-css-prefix}-search-location::after, .fa-duotone.#{$fa-css-prefix}-search-location::after { content: "\f689\f689"; }
.fad.#{$fa-css-prefix}-magnifying-glass-minus::after, .fa-duotone.#{$fa-css-prefix}-magnifying-glass-minus::after { content: "\f010\f010"; }
.fad.#{$fa-css-prefix}-search-minus::after, .fa-duotone.#{$fa-css-prefix}-search-minus::after { content: "\f010\f010"; }
.fad.#{$fa-css-prefix}-magnifying-glass-plus::after, .fa-duotone.#{$fa-css-prefix}-magnifying-glass-plus::after { content: "\f00e\f00e"; }
.fad.#{$fa-css-prefix}-search-plus::after, .fa-duotone.#{$fa-css-prefix}-search-plus::after { content: "\f00e\f00e"; }
.fad.#{$fa-css-prefix}-mailbox::after, .fa-duotone.#{$fa-css-prefix}-mailbox::after { content: "\f813\f813"; }
.fad.#{$fa-css-prefix}-manat-sign::after, .fa-duotone.#{$fa-css-prefix}-manat-sign::after { content: "\e1d5\e1d5"; }
.fad.#{$fa-css-prefix}-mandolin::after, .fa-duotone.#{$fa-css-prefix}-mandolin::after { content: "\f6f9\f6f9"; }
.fad.#{$fa-css-prefix}-mango::after, .fa-duotone.#{$fa-css-prefix}-mango::after { content: "\e30f\e30f"; }
.fad.#{$fa-css-prefix}-manhole::after, .fa-duotone.#{$fa-css-prefix}-manhole::after { content: "\e1d6\e1d6"; }
.fad.#{$fa-css-prefix}-map::after, .fa-duotone.#{$fa-css-prefix}-map::after { content: "\f279\f279"; }
.fad.#{$fa-css-prefix}-map-location::after, .fa-duotone.#{$fa-css-prefix}-map-location::after { content: "\f59f\f59f"; }
.fad.#{$fa-css-prefix}-map-marked::after, .fa-duotone.#{$fa-css-prefix}-map-marked::after { content: "\f59f\f59f"; }
.fad.#{$fa-css-prefix}-map-location-dot::after, .fa-duotone.#{$fa-css-prefix}-map-location-dot::after { content: "\f5a0\f5a0"; }
.fad.#{$fa-css-prefix}-map-marked-alt::after, .fa-duotone.#{$fa-css-prefix}-map-marked-alt::after { content: "\f5a0\f5a0"; }
.fad.#{$fa-css-prefix}-map-pin::after, .fa-duotone.#{$fa-css-prefix}-map-pin::after { content: "\f276\f276"; }
.fad.#{$fa-css-prefix}-marker::after, .fa-duotone.#{$fa-css-prefix}-marker::after { content: "\f5a1\f5a1"; }
.fad.#{$fa-css-prefix}-mars::after, .fa-duotone.#{$fa-css-prefix}-mars::after { content: "\f222\f222"; }
.fad.#{$fa-css-prefix}-mars-and-venus::after, .fa-duotone.#{$fa-css-prefix}-mars-and-venus::after { content: "\f224\f224"; }
.fad.#{$fa-css-prefix}-mars-double::after, .fa-duotone.#{$fa-css-prefix}-mars-double::after { content: "\f227\f227"; }
.fad.#{$fa-css-prefix}-mars-stroke::after, .fa-duotone.#{$fa-css-prefix}-mars-stroke::after { content: "\f229\f229"; }
.fad.#{$fa-css-prefix}-mars-stroke-right::after, .fa-duotone.#{$fa-css-prefix}-mars-stroke-right::after { content: "\f22b\f22b"; }
.fad.#{$fa-css-prefix}-mars-stroke-h::after, .fa-duotone.#{$fa-css-prefix}-mars-stroke-h::after { content: "\f22b\f22b"; }
.fad.#{$fa-css-prefix}-mars-stroke-up::after, .fa-duotone.#{$fa-css-prefix}-mars-stroke-up::after { content: "\f22a\f22a"; }
.fad.#{$fa-css-prefix}-mars-stroke-v::after, .fa-duotone.#{$fa-css-prefix}-mars-stroke-v::after { content: "\f22a\f22a"; }
.fad.#{$fa-css-prefix}-martini-glass::after, .fa-duotone.#{$fa-css-prefix}-martini-glass::after { content: "\f57b\f57b"; }
.fad.#{$fa-css-prefix}-glass-martini-alt::after, .fa-duotone.#{$fa-css-prefix}-glass-martini-alt::after { content: "\f57b\f57b"; }
.fad.#{$fa-css-prefix}-martini-glass-citrus::after, .fa-duotone.#{$fa-css-prefix}-martini-glass-citrus::after { content: "\f561\f561"; }
.fad.#{$fa-css-prefix}-cocktail::after, .fa-duotone.#{$fa-css-prefix}-cocktail::after { content: "\f561\f561"; }
.fad.#{$fa-css-prefix}-martini-glass-empty::after, .fa-duotone.#{$fa-css-prefix}-martini-glass-empty::after { content: "\f000\f000"; }
.fad.#{$fa-css-prefix}-glass-martini::after, .fa-duotone.#{$fa-css-prefix}-glass-martini::after { content: "\f000\f000"; }
.fad.#{$fa-css-prefix}-mask::after, .fa-duotone.#{$fa-css-prefix}-mask::after { content: "\f6fa\f6fa"; }
.fad.#{$fa-css-prefix}-mask-face::after, .fa-duotone.#{$fa-css-prefix}-mask-face::after { content: "\e1d7\e1d7"; }
.fad.#{$fa-css-prefix}-mask-snorkel::after, .fa-duotone.#{$fa-css-prefix}-mask-snorkel::after { content: "\e3b7\e3b7"; }
.fad.#{$fa-css-prefix}-masks-theater::after, .fa-duotone.#{$fa-css-prefix}-masks-theater::after { content: "\f630\f630"; }
.fad.#{$fa-css-prefix}-theater-masks::after, .fa-duotone.#{$fa-css-prefix}-theater-masks::after { content: "\f630\f630"; }
.fad.#{$fa-css-prefix}-maximize::after, .fa-duotone.#{$fa-css-prefix}-maximize::after { content: "\f31e\f31e"; }
.fad.#{$fa-css-prefix}-expand-arrows-alt::after, .fa-duotone.#{$fa-css-prefix}-expand-arrows-alt::after { content: "\f31e\f31e"; }
.fad.#{$fa-css-prefix}-meat::after, .fa-duotone.#{$fa-css-prefix}-meat::after { content: "\f814\f814"; }
.fad.#{$fa-css-prefix}-medal::after, .fa-duotone.#{$fa-css-prefix}-medal::after { content: "\f5a2\f5a2"; }
.fad.#{$fa-css-prefix}-megaphone::after, .fa-duotone.#{$fa-css-prefix}-megaphone::after { content: "\f675\f675"; }
.fad.#{$fa-css-prefix}-melon::after, .fa-duotone.#{$fa-css-prefix}-melon::after { content: "\e310\e310"; }
.fad.#{$fa-css-prefix}-melon-slice::after, .fa-duotone.#{$fa-css-prefix}-melon-slice::after { content: "\e311\e311"; }
.fad.#{$fa-css-prefix}-memo::after, .fa-duotone.#{$fa-css-prefix}-memo::after { content: "\e1d8\e1d8"; }
.fad.#{$fa-css-prefix}-memo-circle-check::after, .fa-duotone.#{$fa-css-prefix}-memo-circle-check::after { content: "\e1d9\e1d9"; }
.fad.#{$fa-css-prefix}-memo-circle-info::after, .fa-duotone.#{$fa-css-prefix}-memo-circle-info::after { content: "\e49a\e49a"; }
.fad.#{$fa-css-prefix}-memo-pad::after, .fa-duotone.#{$fa-css-prefix}-memo-pad::after { content: "\e1da\e1da"; }
.fad.#{$fa-css-prefix}-memory::after, .fa-duotone.#{$fa-css-prefix}-memory::after { content: "\f538\f538"; }
.fad.#{$fa-css-prefix}-menorah::after, .fa-duotone.#{$fa-css-prefix}-menorah::after { content: "\f676\f676"; }
.fad.#{$fa-css-prefix}-mercury::after, .fa-duotone.#{$fa-css-prefix}-mercury::after { content: "\f223\f223"; }
.fad.#{$fa-css-prefix}-message::after, .fa-duotone.#{$fa-css-prefix}-message::after { content: "\f27a\f27a"; }
.fad.#{$fa-css-prefix}-comment-alt::after, .fa-duotone.#{$fa-css-prefix}-comment-alt::after { content: "\f27a\f27a"; }
.fad.#{$fa-css-prefix}-message-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-message-arrow-down::after { content: "\e1db\e1db"; }
.fad.#{$fa-css-prefix}-comment-alt-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-arrow-down::after { content: "\e1db\e1db"; }
.fad.#{$fa-css-prefix}-message-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-message-arrow-up::after { content: "\e1dc\e1dc"; }
.fad.#{$fa-css-prefix}-comment-alt-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-arrow-up::after { content: "\e1dc\e1dc"; }
.fad.#{$fa-css-prefix}-message-arrow-up-right::after, .fa-duotone.#{$fa-css-prefix}-message-arrow-up-right::after { content: "\e1dd\e1dd"; }
.fad.#{$fa-css-prefix}-message-bot::after, .fa-duotone.#{$fa-css-prefix}-message-bot::after { content: "\e3b8\e3b8"; }
.fad.#{$fa-css-prefix}-message-captions::after, .fa-duotone.#{$fa-css-prefix}-message-captions::after { content: "\e1de\e1de"; }
.fad.#{$fa-css-prefix}-comment-alt-captions::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-captions::after { content: "\e1de\e1de"; }
.fad.#{$fa-css-prefix}-message-check::after, .fa-duotone.#{$fa-css-prefix}-message-check::after { content: "\f4a2\f4a2"; }
.fad.#{$fa-css-prefix}-comment-alt-check::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-check::after { content: "\f4a2\f4a2"; }
.fad.#{$fa-css-prefix}-message-code::after, .fa-duotone.#{$fa-css-prefix}-message-code::after { content: "\e1df\e1df"; }
.fad.#{$fa-css-prefix}-message-dollar::after, .fa-duotone.#{$fa-css-prefix}-message-dollar::after { content: "\f650\f650"; }
.fad.#{$fa-css-prefix}-comment-alt-dollar::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-dollar::after { content: "\f650\f650"; }
.fad.#{$fa-css-prefix}-message-dots::after, .fa-duotone.#{$fa-css-prefix}-message-dots::after { content: "\f4a3\f4a3"; }
.fad.#{$fa-css-prefix}-comment-alt-dots::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-dots::after { content: "\f4a3\f4a3"; }
.fad.#{$fa-css-prefix}-messaging::after, .fa-duotone.#{$fa-css-prefix}-messaging::after { content: "\f4a3\f4a3"; }
.fad.#{$fa-css-prefix}-message-exclamation::after, .fa-duotone.#{$fa-css-prefix}-message-exclamation::after { content: "\f4a5\f4a5"; }
.fad.#{$fa-css-prefix}-comment-alt-exclamation::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-exclamation::after { content: "\f4a5\f4a5"; }
.fad.#{$fa-css-prefix}-message-image::after, .fa-duotone.#{$fa-css-prefix}-message-image::after { content: "\e1e0\e1e0"; }
.fad.#{$fa-css-prefix}-comment-alt-image::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-image::after { content: "\e1e0\e1e0"; }
.fad.#{$fa-css-prefix}-message-lines::after, .fa-duotone.#{$fa-css-prefix}-message-lines::after { content: "\f4a6\f4a6"; }
.fad.#{$fa-css-prefix}-comment-alt-lines::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-lines::after { content: "\f4a6\f4a6"; }
.fad.#{$fa-css-prefix}-message-medical::after, .fa-duotone.#{$fa-css-prefix}-message-medical::after { content: "\f7f4\f7f4"; }
.fad.#{$fa-css-prefix}-comment-alt-medical::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-medical::after { content: "\f7f4\f7f4"; }
.fad.#{$fa-css-prefix}-message-middle::after, .fa-duotone.#{$fa-css-prefix}-message-middle::after { content: "\e1e1\e1e1"; }
.fad.#{$fa-css-prefix}-comment-middle-alt::after, .fa-duotone.#{$fa-css-prefix}-comment-middle-alt::after { content: "\e1e1\e1e1"; }
.fad.#{$fa-css-prefix}-message-middle-top::after, .fa-duotone.#{$fa-css-prefix}-message-middle-top::after { content: "\e1e2\e1e2"; }
.fad.#{$fa-css-prefix}-comment-middle-top-alt::after, .fa-duotone.#{$fa-css-prefix}-comment-middle-top-alt::after { content: "\e1e2\e1e2"; }
.fad.#{$fa-css-prefix}-message-minus::after, .fa-duotone.#{$fa-css-prefix}-message-minus::after { content: "\f4a7\f4a7"; }
.fad.#{$fa-css-prefix}-comment-alt-minus::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-minus::after { content: "\f4a7\f4a7"; }
.fad.#{$fa-css-prefix}-message-music::after, .fa-duotone.#{$fa-css-prefix}-message-music::after { content: "\f8af\f8af"; }
.fad.#{$fa-css-prefix}-comment-alt-music::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-music::after { content: "\f8af\f8af"; }
.fad.#{$fa-css-prefix}-message-pen::after, .fa-duotone.#{$fa-css-prefix}-message-pen::after { content: "\f4a4\f4a4"; }
.fad.#{$fa-css-prefix}-comment-alt-edit::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-edit::after { content: "\f4a4\f4a4"; }
.fad.#{$fa-css-prefix}-message-edit::after, .fa-duotone.#{$fa-css-prefix}-message-edit::after { content: "\f4a4\f4a4"; }
.fad.#{$fa-css-prefix}-message-plus::after, .fa-duotone.#{$fa-css-prefix}-message-plus::after { content: "\f4a8\f4a8"; }
.fad.#{$fa-css-prefix}-comment-alt-plus::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-plus::after { content: "\f4a8\f4a8"; }
.fad.#{$fa-css-prefix}-message-question::after, .fa-duotone.#{$fa-css-prefix}-message-question::after { content: "\e1e3\e1e3"; }
.fad.#{$fa-css-prefix}-message-quote::after, .fa-duotone.#{$fa-css-prefix}-message-quote::after { content: "\e1e4\e1e4"; }
.fad.#{$fa-css-prefix}-comment-alt-quote::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-quote::after { content: "\e1e4\e1e4"; }
.fad.#{$fa-css-prefix}-message-slash::after, .fa-duotone.#{$fa-css-prefix}-message-slash::after { content: "\f4a9\f4a9"; }
.fad.#{$fa-css-prefix}-comment-alt-slash::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-slash::after { content: "\f4a9\f4a9"; }
.fad.#{$fa-css-prefix}-message-smile::after, .fa-duotone.#{$fa-css-prefix}-message-smile::after { content: "\f4aa\f4aa"; }
.fad.#{$fa-css-prefix}-comment-alt-smile::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-smile::after { content: "\f4aa\f4aa"; }
.fad.#{$fa-css-prefix}-message-sms::after, .fa-duotone.#{$fa-css-prefix}-message-sms::after { content: "\e1e5\e1e5"; }
.fad.#{$fa-css-prefix}-message-text::after, .fa-duotone.#{$fa-css-prefix}-message-text::after { content: "\e1e6\e1e6"; }
.fad.#{$fa-css-prefix}-comment-alt-text::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-text::after { content: "\e1e6\e1e6"; }
.fad.#{$fa-css-prefix}-message-xmark::after, .fa-duotone.#{$fa-css-prefix}-message-xmark::after { content: "\f4ab\f4ab"; }
.fad.#{$fa-css-prefix}-comment-alt-times::after, .fa-duotone.#{$fa-css-prefix}-comment-alt-times::after { content: "\f4ab\f4ab"; }
.fad.#{$fa-css-prefix}-message-times::after, .fa-duotone.#{$fa-css-prefix}-message-times::after { content: "\f4ab\f4ab"; }
.fad.#{$fa-css-prefix}-messages::after, .fa-duotone.#{$fa-css-prefix}-messages::after { content: "\f4b6\f4b6"; }
.fad.#{$fa-css-prefix}-comments-alt::after, .fa-duotone.#{$fa-css-prefix}-comments-alt::after { content: "\f4b6\f4b6"; }
.fad.#{$fa-css-prefix}-messages-dollar::after, .fa-duotone.#{$fa-css-prefix}-messages-dollar::after { content: "\f652\f652"; }
.fad.#{$fa-css-prefix}-comments-alt-dollar::after, .fa-duotone.#{$fa-css-prefix}-comments-alt-dollar::after { content: "\f652\f652"; }
.fad.#{$fa-css-prefix}-messages-question::after, .fa-duotone.#{$fa-css-prefix}-messages-question::after { content: "\e1e7\e1e7"; }
.fad.#{$fa-css-prefix}-meteor::after, .fa-duotone.#{$fa-css-prefix}-meteor::after { content: "\f753\f753"; }
.fad.#{$fa-css-prefix}-meter::after, .fa-duotone.#{$fa-css-prefix}-meter::after { content: "\e1e8\e1e8"; }
.fad.#{$fa-css-prefix}-meter-bolt::after, .fa-duotone.#{$fa-css-prefix}-meter-bolt::after { content: "\e1e9\e1e9"; }
.fad.#{$fa-css-prefix}-meter-droplet::after, .fa-duotone.#{$fa-css-prefix}-meter-droplet::after { content: "\e1ea\e1ea"; }
.fad.#{$fa-css-prefix}-meter-fire::after, .fa-duotone.#{$fa-css-prefix}-meter-fire::after { content: "\e1eb\e1eb"; }
.fad.#{$fa-css-prefix}-microchip::after, .fa-duotone.#{$fa-css-prefix}-microchip::after { content: "\f2db\f2db"; }
.fad.#{$fa-css-prefix}-microchip-ai::after, .fa-duotone.#{$fa-css-prefix}-microchip-ai::after { content: "\e1ec\e1ec"; }
.fad.#{$fa-css-prefix}-microphone::after, .fa-duotone.#{$fa-css-prefix}-microphone::after { content: "\f130\f130"; }
.fad.#{$fa-css-prefix}-microphone-lines::after, .fa-duotone.#{$fa-css-prefix}-microphone-lines::after { content: "\f3c9\f3c9"; }
.fad.#{$fa-css-prefix}-microphone-alt::after, .fa-duotone.#{$fa-css-prefix}-microphone-alt::after { content: "\f3c9\f3c9"; }
.fad.#{$fa-css-prefix}-microphone-lines-slash::after, .fa-duotone.#{$fa-css-prefix}-microphone-lines-slash::after { content: "\f539\f539"; }
.fad.#{$fa-css-prefix}-microphone-alt-slash::after, .fa-duotone.#{$fa-css-prefix}-microphone-alt-slash::after { content: "\f539\f539"; }
.fad.#{$fa-css-prefix}-microphone-slash::after, .fa-duotone.#{$fa-css-prefix}-microphone-slash::after { content: "\f131\f131"; }
.fad.#{$fa-css-prefix}-microphone-stand::after, .fa-duotone.#{$fa-css-prefix}-microphone-stand::after { content: "\f8cb\f8cb"; }
.fad.#{$fa-css-prefix}-microscope::after, .fa-duotone.#{$fa-css-prefix}-microscope::after { content: "\f610\f610"; }
.fad.#{$fa-css-prefix}-microwave::after, .fa-duotone.#{$fa-css-prefix}-microwave::after { content: "\e01b\e01b"; }
.fad.#{$fa-css-prefix}-mill-sign::after, .fa-duotone.#{$fa-css-prefix}-mill-sign::after { content: "\e1ed\e1ed"; }
.fad.#{$fa-css-prefix}-minimize::after, .fa-duotone.#{$fa-css-prefix}-minimize::after { content: "\f78c\f78c"; }
.fad.#{$fa-css-prefix}-compress-arrows-alt::after, .fa-duotone.#{$fa-css-prefix}-compress-arrows-alt::after { content: "\f78c\f78c"; }
.fad.#{$fa-css-prefix}-minus::after, .fa-duotone.#{$fa-css-prefix}-minus::after { content: "\f068\f068"; }
.fad.#{$fa-css-prefix}-subtract::after, .fa-duotone.#{$fa-css-prefix}-subtract::after { content: "\f068\f068"; }
.fad.#{$fa-css-prefix}-mistletoe::after, .fa-duotone.#{$fa-css-prefix}-mistletoe::after { content: "\f7b4\f7b4"; }
.fad.#{$fa-css-prefix}-mitten::after, .fa-duotone.#{$fa-css-prefix}-mitten::after { content: "\f7b5\f7b5"; }
.fad.#{$fa-css-prefix}-mobile::after, .fa-duotone.#{$fa-css-prefix}-mobile::after { content: "\f3ce\f3ce"; }
.fad.#{$fa-css-prefix}-mobile-android::after, .fa-duotone.#{$fa-css-prefix}-mobile-android::after { content: "\f3ce\f3ce"; }
.fad.#{$fa-css-prefix}-mobile-phone::after, .fa-duotone.#{$fa-css-prefix}-mobile-phone::after { content: "\f3ce\f3ce"; }
.fad.#{$fa-css-prefix}-mobile-button::after, .fa-duotone.#{$fa-css-prefix}-mobile-button::after { content: "\f10b\f10b"; }
.fad.#{$fa-css-prefix}-mobile-notch::after, .fa-duotone.#{$fa-css-prefix}-mobile-notch::after { content: "\e1ee\e1ee"; }
.fad.#{$fa-css-prefix}-mobile-iphone::after, .fa-duotone.#{$fa-css-prefix}-mobile-iphone::after { content: "\e1ee\e1ee"; }
.fad.#{$fa-css-prefix}-mobile-screen::after, .fa-duotone.#{$fa-css-prefix}-mobile-screen::after { content: "\f3cf\f3cf"; }
.fad.#{$fa-css-prefix}-mobile-android-alt::after, .fa-duotone.#{$fa-css-prefix}-mobile-android-alt::after { content: "\f3cf\f3cf"; }
.fad.#{$fa-css-prefix}-mobile-screen-button::after, .fa-duotone.#{$fa-css-prefix}-mobile-screen-button::after { content: "\f3cd\f3cd"; }
.fad.#{$fa-css-prefix}-mobile-alt::after, .fa-duotone.#{$fa-css-prefix}-mobile-alt::after { content: "\f3cd\f3cd"; }
.fad.#{$fa-css-prefix}-mobile-signal::after, .fa-duotone.#{$fa-css-prefix}-mobile-signal::after { content: "\e1ef\e1ef"; }
.fad.#{$fa-css-prefix}-mobile-signal-out::after, .fa-duotone.#{$fa-css-prefix}-mobile-signal-out::after { content: "\e1f0\e1f0"; }
.fad.#{$fa-css-prefix}-money-bill::after, .fa-duotone.#{$fa-css-prefix}-money-bill::after { content: "\f0d6\f0d6"; }
.fad.#{$fa-css-prefix}-money-bill-1::after, .fa-duotone.#{$fa-css-prefix}-money-bill-1::after { content: "\f3d1\f3d1"; }
.fad.#{$fa-css-prefix}-money-bill-alt::after, .fa-duotone.#{$fa-css-prefix}-money-bill-alt::after { content: "\f3d1\f3d1"; }
.fad.#{$fa-css-prefix}-money-bill-1-wave::after, .fa-duotone.#{$fa-css-prefix}-money-bill-1-wave::after { content: "\f53b\f53b"; }
.fad.#{$fa-css-prefix}-money-bill-wave-alt::after, .fa-duotone.#{$fa-css-prefix}-money-bill-wave-alt::after { content: "\f53b\f53b"; }
.fad.#{$fa-css-prefix}-money-bill-simple::after, .fa-duotone.#{$fa-css-prefix}-money-bill-simple::after { content: "\e1f1\e1f1"; }
.fad.#{$fa-css-prefix}-money-bill-simple-wave::after, .fa-duotone.#{$fa-css-prefix}-money-bill-simple-wave::after { content: "\e1f2\e1f2"; }
.fad.#{$fa-css-prefix}-money-bill-wave::after, .fa-duotone.#{$fa-css-prefix}-money-bill-wave::after { content: "\f53a\f53a"; }
.fad.#{$fa-css-prefix}-money-bills::after, .fa-duotone.#{$fa-css-prefix}-money-bills::after { content: "\e1f3\e1f3"; }
.fad.#{$fa-css-prefix}-money-bills-simple::after, .fa-duotone.#{$fa-css-prefix}-money-bills-simple::after { content: "\e1f4\e1f4"; }
.fad.#{$fa-css-prefix}-money-bills-alt::after, .fa-duotone.#{$fa-css-prefix}-money-bills-alt::after { content: "\e1f4\e1f4"; }
.fad.#{$fa-css-prefix}-money-check::after, .fa-duotone.#{$fa-css-prefix}-money-check::after { content: "\f53c\f53c"; }
.fad.#{$fa-css-prefix}-money-check-dollar::after, .fa-duotone.#{$fa-css-prefix}-money-check-dollar::after { content: "\f53d\f53d"; }
.fad.#{$fa-css-prefix}-money-check-alt::after, .fa-duotone.#{$fa-css-prefix}-money-check-alt::after { content: "\f53d\f53d"; }
.fad.#{$fa-css-prefix}-money-check-dollar-pen::after, .fa-duotone.#{$fa-css-prefix}-money-check-dollar-pen::after { content: "\f873\f873"; }
.fad.#{$fa-css-prefix}-money-check-edit-alt::after, .fa-duotone.#{$fa-css-prefix}-money-check-edit-alt::after { content: "\f873\f873"; }
.fad.#{$fa-css-prefix}-money-check-pen::after, .fa-duotone.#{$fa-css-prefix}-money-check-pen::after { content: "\f872\f872"; }
.fad.#{$fa-css-prefix}-money-check-edit::after, .fa-duotone.#{$fa-css-prefix}-money-check-edit::after { content: "\f872\f872"; }
.fad.#{$fa-css-prefix}-money-from-bracket::after, .fa-duotone.#{$fa-css-prefix}-money-from-bracket::after { content: "\e312\e312"; }
.fad.#{$fa-css-prefix}-money-simple-from-bracket::after, .fa-duotone.#{$fa-css-prefix}-money-simple-from-bracket::after { content: "\e313\e313"; }
.fad.#{$fa-css-prefix}-monitor-waveform::after, .fa-duotone.#{$fa-css-prefix}-monitor-waveform::after { content: "\f611\f611"; }
.fad.#{$fa-css-prefix}-monitor-heart-rate::after, .fa-duotone.#{$fa-css-prefix}-monitor-heart-rate::after { content: "\f611\f611"; }
.fad.#{$fa-css-prefix}-monkey::after, .fa-duotone.#{$fa-css-prefix}-monkey::after { content: "\f6fb\f6fb"; }
.fad.#{$fa-css-prefix}-monument::after, .fa-duotone.#{$fa-css-prefix}-monument::after { content: "\f5a6\f5a6"; }
.fad.#{$fa-css-prefix}-moon::after, .fa-duotone.#{$fa-css-prefix}-moon::after { content: "\f186\f186"; }
.fad.#{$fa-css-prefix}-moon-cloud::after, .fa-duotone.#{$fa-css-prefix}-moon-cloud::after { content: "\f754\f754"; }
.fad.#{$fa-css-prefix}-moon-over-sun::after, .fa-duotone.#{$fa-css-prefix}-moon-over-sun::after { content: "\f74a\f74a"; }
.fad.#{$fa-css-prefix}-eclipse-alt::after, .fa-duotone.#{$fa-css-prefix}-eclipse-alt::after { content: "\f74a\f74a"; }
.fad.#{$fa-css-prefix}-moon-stars::after, .fa-duotone.#{$fa-css-prefix}-moon-stars::after { content: "\f755\f755"; }
.fad.#{$fa-css-prefix}-moped::after, .fa-duotone.#{$fa-css-prefix}-moped::after { content: "\e3b9\e3b9"; }
.fad.#{$fa-css-prefix}-mortar-pestle::after, .fa-duotone.#{$fa-css-prefix}-mortar-pestle::after { content: "\f5a7\f5a7"; }
.fad.#{$fa-css-prefix}-mosque::after, .fa-duotone.#{$fa-css-prefix}-mosque::after { content: "\f678\f678"; }
.fad.#{$fa-css-prefix}-motorcycle::after, .fa-duotone.#{$fa-css-prefix}-motorcycle::after { content: "\f21c\f21c"; }
.fad.#{$fa-css-prefix}-mountain::after, .fa-duotone.#{$fa-css-prefix}-mountain::after { content: "\f6fc\f6fc"; }
.fad.#{$fa-css-prefix}-mountains::after, .fa-duotone.#{$fa-css-prefix}-mountains::after { content: "\f6fd\f6fd"; }
.fad.#{$fa-css-prefix}-mp3-player::after, .fa-duotone.#{$fa-css-prefix}-mp3-player::after { content: "\f8ce\f8ce"; }
.fad.#{$fa-css-prefix}-mug::after, .fa-duotone.#{$fa-css-prefix}-mug::after { content: "\f874\f874"; }
.fad.#{$fa-css-prefix}-mug-hot::after, .fa-duotone.#{$fa-css-prefix}-mug-hot::after { content: "\f7b6\f7b6"; }
.fad.#{$fa-css-prefix}-mug-marshmallows::after, .fa-duotone.#{$fa-css-prefix}-mug-marshmallows::after { content: "\f7b7\f7b7"; }
.fad.#{$fa-css-prefix}-mug-saucer::after, .fa-duotone.#{$fa-css-prefix}-mug-saucer::after { content: "\f0f4\f0f4"; }
.fad.#{$fa-css-prefix}-coffee::after, .fa-duotone.#{$fa-css-prefix}-coffee::after { content: "\f0f4\f0f4"; }
.fad.#{$fa-css-prefix}-mug-tea::after, .fa-duotone.#{$fa-css-prefix}-mug-tea::after { content: "\f875\f875"; }
.fad.#{$fa-css-prefix}-mug-tea-saucer::after, .fa-duotone.#{$fa-css-prefix}-mug-tea-saucer::after { content: "\e1f5\e1f5"; }
.fad.#{$fa-css-prefix}-mushroom::after, .fa-duotone.#{$fa-css-prefix}-mushroom::after { content: "\e425\e425"; }
.fad.#{$fa-css-prefix}-music::after, .fa-duotone.#{$fa-css-prefix}-music::after { content: "\f001\f001"; }
.fad.#{$fa-css-prefix}-music-note::after, .fa-duotone.#{$fa-css-prefix}-music-note::after { content: "\f8cf\f8cf"; }
.fad.#{$fa-css-prefix}-music-alt::after, .fa-duotone.#{$fa-css-prefix}-music-alt::after { content: "\f8cf\f8cf"; }
.fad.#{$fa-css-prefix}-music-note-slash::after, .fa-duotone.#{$fa-css-prefix}-music-note-slash::after { content: "\f8d0\f8d0"; }
.fad.#{$fa-css-prefix}-music-alt-slash::after, .fa-duotone.#{$fa-css-prefix}-music-alt-slash::after { content: "\f8d0\f8d0"; }
.fad.#{$fa-css-prefix}-music-slash::after, .fa-duotone.#{$fa-css-prefix}-music-slash::after { content: "\f8d1\f8d1"; }
.fad.#{$fa-css-prefix}-n::after, .fa-duotone.#{$fa-css-prefix}-n::after { content: "\4e\4e"; }
.fad.#{$fa-css-prefix}-naira-sign::after, .fa-duotone.#{$fa-css-prefix}-naira-sign::after { content: "\e1f6\e1f6"; }
.fad.#{$fa-css-prefix}-narwhal::after, .fa-duotone.#{$fa-css-prefix}-narwhal::after { content: "\f6fe\f6fe"; }
.fad.#{$fa-css-prefix}-nesting-dolls::after, .fa-duotone.#{$fa-css-prefix}-nesting-dolls::after { content: "\e3ba\e3ba"; }
.fad.#{$fa-css-prefix}-network-wired::after, .fa-duotone.#{$fa-css-prefix}-network-wired::after { content: "\f6ff\f6ff"; }
.fad.#{$fa-css-prefix}-neuter::after, .fa-duotone.#{$fa-css-prefix}-neuter::after { content: "\f22c\f22c"; }
.fad.#{$fa-css-prefix}-newspaper::after, .fa-duotone.#{$fa-css-prefix}-newspaper::after { content: "\f1ea\f1ea"; }
.fad.#{$fa-css-prefix}-nfc::after, .fa-duotone.#{$fa-css-prefix}-nfc::after { content: "\e1f7\e1f7"; }
.fad.#{$fa-css-prefix}-nfc-lock::after, .fa-duotone.#{$fa-css-prefix}-nfc-lock::after { content: "\e1f8\e1f8"; }
.fad.#{$fa-css-prefix}-nfc-magnifying-glass::after, .fa-duotone.#{$fa-css-prefix}-nfc-magnifying-glass::after { content: "\e1f9\e1f9"; }
.fad.#{$fa-css-prefix}-nfc-pen::after, .fa-duotone.#{$fa-css-prefix}-nfc-pen::after { content: "\e1fa\e1fa"; }
.fad.#{$fa-css-prefix}-nfc-signal::after, .fa-duotone.#{$fa-css-prefix}-nfc-signal::after { content: "\e1fb\e1fb"; }
.fad.#{$fa-css-prefix}-nfc-slash::after, .fa-duotone.#{$fa-css-prefix}-nfc-slash::after { content: "\e1fc\e1fc"; }
.fad.#{$fa-css-prefix}-nfc-trash::after, .fa-duotone.#{$fa-css-prefix}-nfc-trash::after { content: "\e1fd\e1fd"; }
.fad.#{$fa-css-prefix}-not-equal::after, .fa-duotone.#{$fa-css-prefix}-not-equal::after { content: "\f53e\f53e"; }
.fad.#{$fa-css-prefix}-notdef::after, .fa-duotone.#{$fa-css-prefix}-notdef::after { content: "\e1fe\e1fe"; }
.fad.#{$fa-css-prefix}-note::after, .fa-duotone.#{$fa-css-prefix}-note::after { content: "\e1ff\e1ff"; }
.fad.#{$fa-css-prefix}-note-medical::after, .fa-duotone.#{$fa-css-prefix}-note-medical::after { content: "\e200\e200"; }
.fad.#{$fa-css-prefix}-note-sticky::after, .fa-duotone.#{$fa-css-prefix}-note-sticky::after { content: "\f249\f249"; }
.fad.#{$fa-css-prefix}-sticky-note::after, .fa-duotone.#{$fa-css-prefix}-sticky-note::after { content: "\f249\f249"; }
.fad.#{$fa-css-prefix}-notebook::after, .fa-duotone.#{$fa-css-prefix}-notebook::after { content: "\e201\e201"; }
.fad.#{$fa-css-prefix}-notes::after, .fa-duotone.#{$fa-css-prefix}-notes::after { content: "\e202\e202"; }
.fad.#{$fa-css-prefix}-notes-medical::after, .fa-duotone.#{$fa-css-prefix}-notes-medical::after { content: "\f481\f481"; }
.fad.#{$fa-css-prefix}-o::after, .fa-duotone.#{$fa-css-prefix}-o::after { content: "\4f\4f"; }
.fad.#{$fa-css-prefix}-object-exclude::after, .fa-duotone.#{$fa-css-prefix}-object-exclude::after { content: "\e49c\e49c"; }
.fad.#{$fa-css-prefix}-object-group::after, .fa-duotone.#{$fa-css-prefix}-object-group::after { content: "\f247\f247"; }
.fad.#{$fa-css-prefix}-object-intersect::after, .fa-duotone.#{$fa-css-prefix}-object-intersect::after { content: "\e49d\e49d"; }
.fad.#{$fa-css-prefix}-object-subtract::after, .fa-duotone.#{$fa-css-prefix}-object-subtract::after { content: "\e49e\e49e"; }
.fad.#{$fa-css-prefix}-object-ungroup::after, .fa-duotone.#{$fa-css-prefix}-object-ungroup::after { content: "\f248\f248"; }
.fad.#{$fa-css-prefix}-object-union::after, .fa-duotone.#{$fa-css-prefix}-object-union::after { content: "\e49f\e49f"; }
.fad.#{$fa-css-prefix}-objects-align-bottom::after, .fa-duotone.#{$fa-css-prefix}-objects-align-bottom::after { content: "\e3bb\e3bb"; }
.fad.#{$fa-css-prefix}-objects-align-center-horizontal::after, .fa-duotone.#{$fa-css-prefix}-objects-align-center-horizontal::after { content: "\e3bc\e3bc"; }
.fad.#{$fa-css-prefix}-objects-align-center-vertical::after, .fa-duotone.#{$fa-css-prefix}-objects-align-center-vertical::after { content: "\e3bd\e3bd"; }
.fad.#{$fa-css-prefix}-objects-align-left::after, .fa-duotone.#{$fa-css-prefix}-objects-align-left::after { content: "\e3be\e3be"; }
.fad.#{$fa-css-prefix}-objects-align-right::after, .fa-duotone.#{$fa-css-prefix}-objects-align-right::after { content: "\e3bf\e3bf"; }
.fad.#{$fa-css-prefix}-objects-align-top::after, .fa-duotone.#{$fa-css-prefix}-objects-align-top::after { content: "\e3c0\e3c0"; }
.fad.#{$fa-css-prefix}-objects-column::after, .fa-duotone.#{$fa-css-prefix}-objects-column::after { content: "\e3c1\e3c1"; }
.fad.#{$fa-css-prefix}-octagon::after, .fa-duotone.#{$fa-css-prefix}-octagon::after { content: "\f306\f306"; }
.fad.#{$fa-css-prefix}-octagon-check::after, .fa-duotone.#{$fa-css-prefix}-octagon-check::after { content: "\e426\e426"; }
.fad.#{$fa-css-prefix}-octagon-divide::after, .fa-duotone.#{$fa-css-prefix}-octagon-divide::after { content: "\e203\e203"; }
.fad.#{$fa-css-prefix}-octagon-exclamation::after, .fa-duotone.#{$fa-css-prefix}-octagon-exclamation::after { content: "\e204\e204"; }
.fad.#{$fa-css-prefix}-octagon-minus::after, .fa-duotone.#{$fa-css-prefix}-octagon-minus::after { content: "\f308\f308"; }
.fad.#{$fa-css-prefix}-minus-octagon::after, .fa-duotone.#{$fa-css-prefix}-minus-octagon::after { content: "\f308\f308"; }
.fad.#{$fa-css-prefix}-octagon-plus::after, .fa-duotone.#{$fa-css-prefix}-octagon-plus::after { content: "\f301\f301"; }
.fad.#{$fa-css-prefix}-plus-octagon::after, .fa-duotone.#{$fa-css-prefix}-plus-octagon::after { content: "\f301\f301"; }
.fad.#{$fa-css-prefix}-octagon-xmark::after, .fa-duotone.#{$fa-css-prefix}-octagon-xmark::after { content: "\f2f0\f2f0"; }
.fad.#{$fa-css-prefix}-times-octagon::after, .fa-duotone.#{$fa-css-prefix}-times-octagon::after { content: "\f2f0\f2f0"; }
.fad.#{$fa-css-prefix}-xmark-octagon::after, .fa-duotone.#{$fa-css-prefix}-xmark-octagon::after { content: "\f2f0\f2f0"; }
.fad.#{$fa-css-prefix}-oil-can::after, .fa-duotone.#{$fa-css-prefix}-oil-can::after { content: "\f613\f613"; }
.fad.#{$fa-css-prefix}-oil-can-drip::after, .fa-duotone.#{$fa-css-prefix}-oil-can-drip::after { content: "\e205\e205"; }
.fad.#{$fa-css-prefix}-oil-temperature::after, .fa-duotone.#{$fa-css-prefix}-oil-temperature::after { content: "\f614\f614"; }
.fad.#{$fa-css-prefix}-oil-temp::after, .fa-duotone.#{$fa-css-prefix}-oil-temp::after { content: "\f614\f614"; }
.fad.#{$fa-css-prefix}-olive::after, .fa-duotone.#{$fa-css-prefix}-olive::after { content: "\e316\e316"; }
.fad.#{$fa-css-prefix}-olive-branch::after, .fa-duotone.#{$fa-css-prefix}-olive-branch::after { content: "\e317\e317"; }
.fad.#{$fa-css-prefix}-om::after, .fa-duotone.#{$fa-css-prefix}-om::after { content: "\f679\f679"; }
.fad.#{$fa-css-prefix}-omega::after, .fa-duotone.#{$fa-css-prefix}-omega::after { content: "\f67a\f67a"; }
.fad.#{$fa-css-prefix}-onion::after, .fa-duotone.#{$fa-css-prefix}-onion::after { content: "\e427\e427"; }
.fad.#{$fa-css-prefix}-option::after, .fa-duotone.#{$fa-css-prefix}-option::after { content: "\e318\e318"; }
.fad.#{$fa-css-prefix}-ornament::after, .fa-duotone.#{$fa-css-prefix}-ornament::after { content: "\f7b8\f7b8"; }
.fad.#{$fa-css-prefix}-otter::after, .fa-duotone.#{$fa-css-prefix}-otter::after { content: "\f700\f700"; }
.fad.#{$fa-css-prefix}-outdent::after, .fa-duotone.#{$fa-css-prefix}-outdent::after { content: "\f03b\f03b"; }
.fad.#{$fa-css-prefix}-dedent::after, .fa-duotone.#{$fa-css-prefix}-dedent::after { content: "\f03b\f03b"; }
.fad.#{$fa-css-prefix}-outlet::after, .fa-duotone.#{$fa-css-prefix}-outlet::after { content: "\e01c\e01c"; }
.fad.#{$fa-css-prefix}-oven::after, .fa-duotone.#{$fa-css-prefix}-oven::after { content: "\e01d\e01d"; }
.fad.#{$fa-css-prefix}-overline::after, .fa-duotone.#{$fa-css-prefix}-overline::after { content: "\f876\f876"; }
.fad.#{$fa-css-prefix}-p::after, .fa-duotone.#{$fa-css-prefix}-p::after { content: "\50\50"; }
.fad.#{$fa-css-prefix}-page::after, .fa-duotone.#{$fa-css-prefix}-page::after { content: "\e428\e428"; }
.fad.#{$fa-css-prefix}-page-caret-down::after, .fa-duotone.#{$fa-css-prefix}-page-caret-down::after { content: "\e429\e429"; }
.fad.#{$fa-css-prefix}-file-caret-down::after, .fa-duotone.#{$fa-css-prefix}-file-caret-down::after { content: "\e429\e429"; }
.fad.#{$fa-css-prefix}-page-caret-up::after, .fa-duotone.#{$fa-css-prefix}-page-caret-up::after { content: "\e42a\e42a"; }
.fad.#{$fa-css-prefix}-file-caret-up::after, .fa-duotone.#{$fa-css-prefix}-file-caret-up::after { content: "\e42a\e42a"; }
.fad.#{$fa-css-prefix}-pager::after, .fa-duotone.#{$fa-css-prefix}-pager::after { content: "\f815\f815"; }
.fad.#{$fa-css-prefix}-paint-roller::after, .fa-duotone.#{$fa-css-prefix}-paint-roller::after { content: "\f5aa\f5aa"; }
.fad.#{$fa-css-prefix}-paintbrush::after, .fa-duotone.#{$fa-css-prefix}-paintbrush::after { content: "\f1fc\f1fc"; }
.fad.#{$fa-css-prefix}-paint-brush::after, .fa-duotone.#{$fa-css-prefix}-paint-brush::after { content: "\f1fc\f1fc"; }
.fad.#{$fa-css-prefix}-paintbrush-fine::after, .fa-duotone.#{$fa-css-prefix}-paintbrush-fine::after { content: "\f5a9\f5a9"; }
.fad.#{$fa-css-prefix}-paint-brush-alt::after, .fa-duotone.#{$fa-css-prefix}-paint-brush-alt::after { content: "\f5a9\f5a9"; }
.fad.#{$fa-css-prefix}-paint-brush-fine::after, .fa-duotone.#{$fa-css-prefix}-paint-brush-fine::after { content: "\f5a9\f5a9"; }
.fad.#{$fa-css-prefix}-paintbrush-alt::after, .fa-duotone.#{$fa-css-prefix}-paintbrush-alt::after { content: "\f5a9\f5a9"; }
.fad.#{$fa-css-prefix}-paintbrush-pencil::after, .fa-duotone.#{$fa-css-prefix}-paintbrush-pencil::after { content: "\e206\e206"; }
.fad.#{$fa-css-prefix}-palette::after, .fa-duotone.#{$fa-css-prefix}-palette::after { content: "\f53f\f53f"; }
.fad.#{$fa-css-prefix}-pallet::after, .fa-duotone.#{$fa-css-prefix}-pallet::after { content: "\f482\f482"; }
.fad.#{$fa-css-prefix}-pallet-box::after, .fa-duotone.#{$fa-css-prefix}-pallet-box::after { content: "\e208\e208"; }
.fad.#{$fa-css-prefix}-pallet-boxes::after, .fa-duotone.#{$fa-css-prefix}-pallet-boxes::after { content: "\f483\f483"; }
.fad.#{$fa-css-prefix}-palette-boxes::after, .fa-duotone.#{$fa-css-prefix}-palette-boxes::after { content: "\f483\f483"; }
.fad.#{$fa-css-prefix}-pallet-alt::after, .fa-duotone.#{$fa-css-prefix}-pallet-alt::after { content: "\f483\f483"; }
.fad.#{$fa-css-prefix}-pan-food::after, .fa-duotone.#{$fa-css-prefix}-pan-food::after { content: "\e42b\e42b"; }
.fad.#{$fa-css-prefix}-pan-frying::after, .fa-duotone.#{$fa-css-prefix}-pan-frying::after { content: "\e42c\e42c"; }
.fad.#{$fa-css-prefix}-pancakes::after, .fa-duotone.#{$fa-css-prefix}-pancakes::after { content: "\e42d\e42d"; }
.fad.#{$fa-css-prefix}-panel-ews::after, .fa-duotone.#{$fa-css-prefix}-panel-ews::after { content: "\e42e\e42e"; }
.fad.#{$fa-css-prefix}-panel-fire::after, .fa-duotone.#{$fa-css-prefix}-panel-fire::after { content: "\e42f\e42f"; }
.fad.#{$fa-css-prefix}-panorama::after, .fa-duotone.#{$fa-css-prefix}-panorama::after { content: "\e209\e209"; }
.fad.#{$fa-css-prefix}-paper-plane::after, .fa-duotone.#{$fa-css-prefix}-paper-plane::after { content: "\f1d8\f1d8"; }
.fad.#{$fa-css-prefix}-paper-plane-top::after, .fa-duotone.#{$fa-css-prefix}-paper-plane-top::after { content: "\e20a\e20a"; }
.fad.#{$fa-css-prefix}-paper-plane-alt::after, .fa-duotone.#{$fa-css-prefix}-paper-plane-alt::after { content: "\e20a\e20a"; }
.fad.#{$fa-css-prefix}-send::after, .fa-duotone.#{$fa-css-prefix}-send::after { content: "\e20a\e20a"; }
.fad.#{$fa-css-prefix}-paperclip::after, .fa-duotone.#{$fa-css-prefix}-paperclip::after { content: "\f0c6\f0c6"; }
.fad.#{$fa-css-prefix}-paperclip-vertical::after, .fa-duotone.#{$fa-css-prefix}-paperclip-vertical::after { content: "\e3c2\e3c2"; }
.fad.#{$fa-css-prefix}-parachute-box::after, .fa-duotone.#{$fa-css-prefix}-parachute-box::after { content: "\f4cd\f4cd"; }
.fad.#{$fa-css-prefix}-paragraph::after, .fa-duotone.#{$fa-css-prefix}-paragraph::after { content: "\f1dd\f1dd"; }
.fad.#{$fa-css-prefix}-paragraph-left::after, .fa-duotone.#{$fa-css-prefix}-paragraph-left::after { content: "\f878\f878"; }
.fad.#{$fa-css-prefix}-paragraph-rtl::after, .fa-duotone.#{$fa-css-prefix}-paragraph-rtl::after { content: "\f878\f878"; }
.fad.#{$fa-css-prefix}-party-bell::after, .fa-duotone.#{$fa-css-prefix}-party-bell::after { content: "\e31a\e31a"; }
.fad.#{$fa-css-prefix}-party-horn::after, .fa-duotone.#{$fa-css-prefix}-party-horn::after { content: "\e31b\e31b"; }
.fad.#{$fa-css-prefix}-passport::after, .fa-duotone.#{$fa-css-prefix}-passport::after { content: "\f5ab\f5ab"; }
.fad.#{$fa-css-prefix}-paste::after, .fa-duotone.#{$fa-css-prefix}-paste::after { content: "\f0ea\f0ea"; }
.fad.#{$fa-css-prefix}-file-clipboard::after, .fa-duotone.#{$fa-css-prefix}-file-clipboard::after { content: "\f0ea\f0ea"; }
.fad.#{$fa-css-prefix}-pause::after, .fa-duotone.#{$fa-css-prefix}-pause::after { content: "\f04c\f04c"; }
.fad.#{$fa-css-prefix}-paw::after, .fa-duotone.#{$fa-css-prefix}-paw::after { content: "\f1b0\f1b0"; }
.fad.#{$fa-css-prefix}-paw-claws::after, .fa-duotone.#{$fa-css-prefix}-paw-claws::after { content: "\f702\f702"; }
.fad.#{$fa-css-prefix}-paw-simple::after, .fa-duotone.#{$fa-css-prefix}-paw-simple::after { content: "\f701\f701"; }
.fad.#{$fa-css-prefix}-paw-alt::after, .fa-duotone.#{$fa-css-prefix}-paw-alt::after { content: "\f701\f701"; }
.fad.#{$fa-css-prefix}-peace::after, .fa-duotone.#{$fa-css-prefix}-peace::after { content: "\f67c\f67c"; }
.fad.#{$fa-css-prefix}-peach::after, .fa-duotone.#{$fa-css-prefix}-peach::after { content: "\e20b\e20b"; }
.fad.#{$fa-css-prefix}-peanut::after, .fa-duotone.#{$fa-css-prefix}-peanut::after { content: "\e430\e430"; }
.fad.#{$fa-css-prefix}-peanuts::after, .fa-duotone.#{$fa-css-prefix}-peanuts::after { content: "\e431\e431"; }
.fad.#{$fa-css-prefix}-peapod::after, .fa-duotone.#{$fa-css-prefix}-peapod::after { content: "\e31c\e31c"; }
.fad.#{$fa-css-prefix}-pear::after, .fa-duotone.#{$fa-css-prefix}-pear::after { content: "\e20c\e20c"; }
.fad.#{$fa-css-prefix}-pedestal::after, .fa-duotone.#{$fa-css-prefix}-pedestal::after { content: "\e20d\e20d"; }
.fad.#{$fa-css-prefix}-pegasus::after, .fa-duotone.#{$fa-css-prefix}-pegasus::after { content: "\f703\f703"; }
.fad.#{$fa-css-prefix}-pen::after, .fa-duotone.#{$fa-css-prefix}-pen::after { content: "\f304\f304"; }
.fad.#{$fa-css-prefix}-pen-circle::after, .fa-duotone.#{$fa-css-prefix}-pen-circle::after { content: "\e20e\e20e"; }
.fad.#{$fa-css-prefix}-pen-clip::after, .fa-duotone.#{$fa-css-prefix}-pen-clip::after { content: "\f305\f305"; }
.fad.#{$fa-css-prefix}-pen-alt::after, .fa-duotone.#{$fa-css-prefix}-pen-alt::after { content: "\f305\f305"; }
.fad.#{$fa-css-prefix}-pen-clip-slash::after, .fa-duotone.#{$fa-css-prefix}-pen-clip-slash::after { content: "\e20f\e20f"; }
.fad.#{$fa-css-prefix}-pen-alt-slash::after, .fa-duotone.#{$fa-css-prefix}-pen-alt-slash::after { content: "\e20f\e20f"; }
.fad.#{$fa-css-prefix}-pen-fancy::after, .fa-duotone.#{$fa-css-prefix}-pen-fancy::after { content: "\f5ac\f5ac"; }
.fad.#{$fa-css-prefix}-pen-fancy-slash::after, .fa-duotone.#{$fa-css-prefix}-pen-fancy-slash::after { content: "\e210\e210"; }
.fad.#{$fa-css-prefix}-pen-field::after, .fa-duotone.#{$fa-css-prefix}-pen-field::after { content: "\e211\e211"; }
.fad.#{$fa-css-prefix}-pen-line::after, .fa-duotone.#{$fa-css-prefix}-pen-line::after { content: "\e212\e212"; }
.fad.#{$fa-css-prefix}-pen-nib::after, .fa-duotone.#{$fa-css-prefix}-pen-nib::after { content: "\f5ad\f5ad"; }
.fad.#{$fa-css-prefix}-pen-nib-slash::after, .fa-duotone.#{$fa-css-prefix}-pen-nib-slash::after { content: "\e4a1\e4a1"; }
.fad.#{$fa-css-prefix}-pen-paintbrush::after, .fa-duotone.#{$fa-css-prefix}-pen-paintbrush::after { content: "\f618\f618"; }
.fad.#{$fa-css-prefix}-pencil-paintbrush::after, .fa-duotone.#{$fa-css-prefix}-pencil-paintbrush::after { content: "\f618\f618"; }
.fad.#{$fa-css-prefix}-pen-ruler::after, .fa-duotone.#{$fa-css-prefix}-pen-ruler::after { content: "\f5ae\f5ae"; }
.fad.#{$fa-css-prefix}-pencil-ruler::after, .fa-duotone.#{$fa-css-prefix}-pencil-ruler::after { content: "\f5ae\f5ae"; }
.fad.#{$fa-css-prefix}-pen-slash::after, .fa-duotone.#{$fa-css-prefix}-pen-slash::after { content: "\e213\e213"; }
.fad.#{$fa-css-prefix}-pen-swirl::after, .fa-duotone.#{$fa-css-prefix}-pen-swirl::after { content: "\e214\e214"; }
.fad.#{$fa-css-prefix}-pen-to-square::after, .fa-duotone.#{$fa-css-prefix}-pen-to-square::after { content: "\f044\f044"; }
.fad.#{$fa-css-prefix}-edit::after, .fa-duotone.#{$fa-css-prefix}-edit::after { content: "\f044\f044"; }
.fad.#{$fa-css-prefix}-pencil::after, .fa-duotone.#{$fa-css-prefix}-pencil::after { content: "\f303\f303"; }
.fad.#{$fa-css-prefix}-pencil-alt::after, .fa-duotone.#{$fa-css-prefix}-pencil-alt::after { content: "\f303\f303"; }
.fad.#{$fa-css-prefix}-pencil-slash::after, .fa-duotone.#{$fa-css-prefix}-pencil-slash::after { content: "\e215\e215"; }
.fad.#{$fa-css-prefix}-people::after, .fa-duotone.#{$fa-css-prefix}-people::after { content: "\e216\e216"; }
.fad.#{$fa-css-prefix}-people-arrows-left-right::after, .fa-duotone.#{$fa-css-prefix}-people-arrows-left-right::after { content: "\e068\e068"; }
.fad.#{$fa-css-prefix}-people-arrows::after, .fa-duotone.#{$fa-css-prefix}-people-arrows::after { content: "\e068\e068"; }
.fad.#{$fa-css-prefix}-people-carry-box::after, .fa-duotone.#{$fa-css-prefix}-people-carry-box::after { content: "\f4ce\f4ce"; }
.fad.#{$fa-css-prefix}-people-carry::after, .fa-duotone.#{$fa-css-prefix}-people-carry::after { content: "\f4ce\f4ce"; }
.fad.#{$fa-css-prefix}-people-dress::after, .fa-duotone.#{$fa-css-prefix}-people-dress::after { content: "\e217\e217"; }
.fad.#{$fa-css-prefix}-people-dress-simple::after, .fa-duotone.#{$fa-css-prefix}-people-dress-simple::after { content: "\e218\e218"; }
.fad.#{$fa-css-prefix}-people-pants::after, .fa-duotone.#{$fa-css-prefix}-people-pants::after { content: "\e219\e219"; }
.fad.#{$fa-css-prefix}-people-pants-simple::after, .fa-duotone.#{$fa-css-prefix}-people-pants-simple::after { content: "\e21a\e21a"; }
.fad.#{$fa-css-prefix}-people-simple::after, .fa-duotone.#{$fa-css-prefix}-people-simple::after { content: "\e21b\e21b"; }
.fad.#{$fa-css-prefix}-pepper::after, .fa-duotone.#{$fa-css-prefix}-pepper::after { content: "\e432\e432"; }
.fad.#{$fa-css-prefix}-pepper-hot::after, .fa-duotone.#{$fa-css-prefix}-pepper-hot::after { content: "\f816\f816"; }
.fad.#{$fa-css-prefix}-percent::after, .fa-duotone.#{$fa-css-prefix}-percent::after { content: "\25\25"; }
.fad.#{$fa-css-prefix}-percentage::after, .fa-duotone.#{$fa-css-prefix}-percentage::after { content: "\25\25"; }
.fad.#{$fa-css-prefix}-period::after, .fa-duotone.#{$fa-css-prefix}-period::after { content: "\2e\2e"; }
.fad.#{$fa-css-prefix}-person::after, .fa-duotone.#{$fa-css-prefix}-person::after { content: "\f183\f183"; }
.fad.#{$fa-css-prefix}-male::after, .fa-duotone.#{$fa-css-prefix}-male::after { content: "\f183\f183"; }
.fad.#{$fa-css-prefix}-person-biking::after, .fa-duotone.#{$fa-css-prefix}-person-biking::after { content: "\f84a\f84a"; }
.fad.#{$fa-css-prefix}-biking::after, .fa-duotone.#{$fa-css-prefix}-biking::after { content: "\f84a\f84a"; }
.fad.#{$fa-css-prefix}-person-biking-mountain::after, .fa-duotone.#{$fa-css-prefix}-person-biking-mountain::after { content: "\f84b\f84b"; }
.fad.#{$fa-css-prefix}-biking-mountain::after, .fa-duotone.#{$fa-css-prefix}-biking-mountain::after { content: "\f84b\f84b"; }
.fad.#{$fa-css-prefix}-person-booth::after, .fa-duotone.#{$fa-css-prefix}-person-booth::after { content: "\f756\f756"; }
.fad.#{$fa-css-prefix}-person-carry-box::after, .fa-duotone.#{$fa-css-prefix}-person-carry-box::after { content: "\f4cf\f4cf"; }
.fad.#{$fa-css-prefix}-person-carry::after, .fa-duotone.#{$fa-css-prefix}-person-carry::after { content: "\f4cf\f4cf"; }
.fad.#{$fa-css-prefix}-person-digging::after, .fa-duotone.#{$fa-css-prefix}-person-digging::after { content: "\f85e\f85e"; }
.fad.#{$fa-css-prefix}-digging::after, .fa-duotone.#{$fa-css-prefix}-digging::after { content: "\f85e\f85e"; }
.fad.#{$fa-css-prefix}-person-dolly::after, .fa-duotone.#{$fa-css-prefix}-person-dolly::after { content: "\f4d0\f4d0"; }
.fad.#{$fa-css-prefix}-person-dolly-empty::after, .fa-duotone.#{$fa-css-prefix}-person-dolly-empty::after { content: "\f4d1\f4d1"; }
.fad.#{$fa-css-prefix}-person-dots-from-line::after, .fa-duotone.#{$fa-css-prefix}-person-dots-from-line::after { content: "\f470\f470"; }
.fad.#{$fa-css-prefix}-diagnoses::after, .fa-duotone.#{$fa-css-prefix}-diagnoses::after { content: "\f470\f470"; }
.fad.#{$fa-css-prefix}-person-dress::after, .fa-duotone.#{$fa-css-prefix}-person-dress::after { content: "\f182\f182"; }
.fad.#{$fa-css-prefix}-female::after, .fa-duotone.#{$fa-css-prefix}-female::after { content: "\f182\f182"; }
.fad.#{$fa-css-prefix}-person-dress-simple::after, .fa-duotone.#{$fa-css-prefix}-person-dress-simple::after { content: "\e21c\e21c"; }
.fad.#{$fa-css-prefix}-person-from-portal::after, .fa-duotone.#{$fa-css-prefix}-person-from-portal::after { content: "\e023\e023"; }
.fad.#{$fa-css-prefix}-portal-exit::after, .fa-duotone.#{$fa-css-prefix}-portal-exit::after { content: "\e023\e023"; }
.fad.#{$fa-css-prefix}-person-hiking::after, .fa-duotone.#{$fa-css-prefix}-person-hiking::after { content: "\f6ec\f6ec"; }
.fad.#{$fa-css-prefix}-hiking::after, .fa-duotone.#{$fa-css-prefix}-hiking::after { content: "\f6ec\f6ec"; }
.fad.#{$fa-css-prefix}-person-pinball::after, .fa-duotone.#{$fa-css-prefix}-person-pinball::after { content: "\e21d\e21d"; }
.fad.#{$fa-css-prefix}-person-praying::after, .fa-duotone.#{$fa-css-prefix}-person-praying::after { content: "\f683\f683"; }
.fad.#{$fa-css-prefix}-pray::after, .fa-duotone.#{$fa-css-prefix}-pray::after { content: "\f683\f683"; }
.fad.#{$fa-css-prefix}-person-pregnant::after, .fa-duotone.#{$fa-css-prefix}-person-pregnant::after { content: "\e31e\e31e"; }
.fad.#{$fa-css-prefix}-person-running::after, .fa-duotone.#{$fa-css-prefix}-person-running::after { content: "\f70c\f70c"; }
.fad.#{$fa-css-prefix}-running::after, .fa-duotone.#{$fa-css-prefix}-running::after { content: "\f70c\f70c"; }
.fad.#{$fa-css-prefix}-person-seat::after, .fa-duotone.#{$fa-css-prefix}-person-seat::after { content: "\e21e\e21e"; }
.fad.#{$fa-css-prefix}-person-seat-reclined::after, .fa-duotone.#{$fa-css-prefix}-person-seat-reclined::after { content: "\e21f\e21f"; }
.fad.#{$fa-css-prefix}-person-sign::after, .fa-duotone.#{$fa-css-prefix}-person-sign::after { content: "\f757\f757"; }
.fad.#{$fa-css-prefix}-person-simple::after, .fa-duotone.#{$fa-css-prefix}-person-simple::after { content: "\e220\e220"; }
.fad.#{$fa-css-prefix}-person-skating::after, .fa-duotone.#{$fa-css-prefix}-person-skating::after { content: "\f7c5\f7c5"; }
.fad.#{$fa-css-prefix}-skating::after, .fa-duotone.#{$fa-css-prefix}-skating::after { content: "\f7c5\f7c5"; }
.fad.#{$fa-css-prefix}-person-ski-jumping::after, .fa-duotone.#{$fa-css-prefix}-person-ski-jumping::after { content: "\f7c7\f7c7"; }
.fad.#{$fa-css-prefix}-ski-jump::after, .fa-duotone.#{$fa-css-prefix}-ski-jump::after { content: "\f7c7\f7c7"; }
.fad.#{$fa-css-prefix}-person-ski-lift::after, .fa-duotone.#{$fa-css-prefix}-person-ski-lift::after { content: "\f7c8\f7c8"; }
.fad.#{$fa-css-prefix}-ski-lift::after, .fa-duotone.#{$fa-css-prefix}-ski-lift::after { content: "\f7c8\f7c8"; }
.fad.#{$fa-css-prefix}-person-skiing::after, .fa-duotone.#{$fa-css-prefix}-person-skiing::after { content: "\f7c9\f7c9"; }
.fad.#{$fa-css-prefix}-skiing::after, .fa-duotone.#{$fa-css-prefix}-skiing::after { content: "\f7c9\f7c9"; }
.fad.#{$fa-css-prefix}-person-skiing-nordic::after, .fa-duotone.#{$fa-css-prefix}-person-skiing-nordic::after { content: "\f7ca\f7ca"; }
.fad.#{$fa-css-prefix}-skiing-nordic::after, .fa-duotone.#{$fa-css-prefix}-skiing-nordic::after { content: "\f7ca\f7ca"; }
.fad.#{$fa-css-prefix}-person-sledding::after, .fa-duotone.#{$fa-css-prefix}-person-sledding::after { content: "\f7cb\f7cb"; }
.fad.#{$fa-css-prefix}-sledding::after, .fa-duotone.#{$fa-css-prefix}-sledding::after { content: "\f7cb\f7cb"; }
.fad.#{$fa-css-prefix}-person-snowboarding::after, .fa-duotone.#{$fa-css-prefix}-person-snowboarding::after { content: "\f7ce\f7ce"; }
.fad.#{$fa-css-prefix}-snowboarding::after, .fa-duotone.#{$fa-css-prefix}-snowboarding::after { content: "\f7ce\f7ce"; }
.fad.#{$fa-css-prefix}-person-snowmobiling::after, .fa-duotone.#{$fa-css-prefix}-person-snowmobiling::after { content: "\f7d1\f7d1"; }
.fad.#{$fa-css-prefix}-snowmobile::after, .fa-duotone.#{$fa-css-prefix}-snowmobile::after { content: "\f7d1\f7d1"; }
.fad.#{$fa-css-prefix}-person-swimming::after, .fa-duotone.#{$fa-css-prefix}-person-swimming::after { content: "\f5c4\f5c4"; }
.fad.#{$fa-css-prefix}-swimmer::after, .fa-duotone.#{$fa-css-prefix}-swimmer::after { content: "\f5c4\f5c4"; }
.fad.#{$fa-css-prefix}-person-to-door::after, .fa-duotone.#{$fa-css-prefix}-person-to-door::after { content: "\e433\e433"; }
.fad.#{$fa-css-prefix}-person-to-portal::after, .fa-duotone.#{$fa-css-prefix}-person-to-portal::after { content: "\e022\e022"; }
.fad.#{$fa-css-prefix}-portal-enter::after, .fa-duotone.#{$fa-css-prefix}-portal-enter::after { content: "\e022\e022"; }
.fad.#{$fa-css-prefix}-person-walking::after, .fa-duotone.#{$fa-css-prefix}-person-walking::after { content: "\f554\f554"; }
.fad.#{$fa-css-prefix}-walking::after, .fa-duotone.#{$fa-css-prefix}-walking::after { content: "\f554\f554"; }
.fad.#{$fa-css-prefix}-person-walking-with-cane::after, .fa-duotone.#{$fa-css-prefix}-person-walking-with-cane::after { content: "\f29d\f29d"; }
.fad.#{$fa-css-prefix}-blind::after, .fa-duotone.#{$fa-css-prefix}-blind::after { content: "\f29d\f29d"; }
.fad.#{$fa-css-prefix}-peseta-sign::after, .fa-duotone.#{$fa-css-prefix}-peseta-sign::after { content: "\e221\e221"; }
.fad.#{$fa-css-prefix}-peso-sign::after, .fa-duotone.#{$fa-css-prefix}-peso-sign::after { content: "\e222\e222"; }
.fad.#{$fa-css-prefix}-phone::after, .fa-duotone.#{$fa-css-prefix}-phone::after { content: "\f095\f095"; }
.fad.#{$fa-css-prefix}-phone-arrow-down-left::after, .fa-duotone.#{$fa-css-prefix}-phone-arrow-down-left::after { content: "\e223\e223"; }
.fad.#{$fa-css-prefix}-phone-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-phone-arrow-down::after { content: "\e223\e223"; }
.fad.#{$fa-css-prefix}-phone-incoming::after, .fa-duotone.#{$fa-css-prefix}-phone-incoming::after { content: "\e223\e223"; }
.fad.#{$fa-css-prefix}-phone-arrow-up-right::after, .fa-duotone.#{$fa-css-prefix}-phone-arrow-up-right::after { content: "\e224\e224"; }
.fad.#{$fa-css-prefix}-phone-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-phone-arrow-up::after { content: "\e224\e224"; }
.fad.#{$fa-css-prefix}-phone-outgoing::after, .fa-duotone.#{$fa-css-prefix}-phone-outgoing::after { content: "\e224\e224"; }
.fad.#{$fa-css-prefix}-phone-flip::after, .fa-duotone.#{$fa-css-prefix}-phone-flip::after { content: "\f879\f879"; }
.fad.#{$fa-css-prefix}-phone-alt::after, .fa-duotone.#{$fa-css-prefix}-phone-alt::after { content: "\f879\f879"; }
.fad.#{$fa-css-prefix}-phone-hangup::after, .fa-duotone.#{$fa-css-prefix}-phone-hangup::after { content: "\e225\e225"; }
.fad.#{$fa-css-prefix}-phone-intercom::after, .fa-duotone.#{$fa-css-prefix}-phone-intercom::after { content: "\e434\e434"; }
.fad.#{$fa-css-prefix}-phone-missed::after, .fa-duotone.#{$fa-css-prefix}-phone-missed::after { content: "\e226\e226"; }
.fad.#{$fa-css-prefix}-phone-office::after, .fa-duotone.#{$fa-css-prefix}-phone-office::after { content: "\f67d\f67d"; }
.fad.#{$fa-css-prefix}-phone-plus::after, .fa-duotone.#{$fa-css-prefix}-phone-plus::after { content: "\f4d2\f4d2"; }
.fad.#{$fa-css-prefix}-phone-rotary::after, .fa-duotone.#{$fa-css-prefix}-phone-rotary::after { content: "\f8d3\f8d3"; }
.fad.#{$fa-css-prefix}-phone-slash::after, .fa-duotone.#{$fa-css-prefix}-phone-slash::after { content: "\f3dd\f3dd"; }
.fad.#{$fa-css-prefix}-phone-volume::after, .fa-duotone.#{$fa-css-prefix}-phone-volume::after { content: "\f2a0\f2a0"; }
.fad.#{$fa-css-prefix}-volume-control-phone::after, .fa-duotone.#{$fa-css-prefix}-volume-control-phone::after { content: "\f2a0\f2a0"; }
.fad.#{$fa-css-prefix}-phone-xmark::after, .fa-duotone.#{$fa-css-prefix}-phone-xmark::after { content: "\e227\e227"; }
.fad.#{$fa-css-prefix}-photo-film::after, .fa-duotone.#{$fa-css-prefix}-photo-film::after { content: "\f87c\f87c"; }
.fad.#{$fa-css-prefix}-photo-video::after, .fa-duotone.#{$fa-css-prefix}-photo-video::after { content: "\f87c\f87c"; }
.fad.#{$fa-css-prefix}-photo-film-music::after, .fa-duotone.#{$fa-css-prefix}-photo-film-music::after { content: "\e228\e228"; }
.fad.#{$fa-css-prefix}-pi::after, .fa-duotone.#{$fa-css-prefix}-pi::after { content: "\f67e\f67e"; }
.fad.#{$fa-css-prefix}-piano::after, .fa-duotone.#{$fa-css-prefix}-piano::after { content: "\f8d4\f8d4"; }
.fad.#{$fa-css-prefix}-piano-keyboard::after, .fa-duotone.#{$fa-css-prefix}-piano-keyboard::after { content: "\f8d5\f8d5"; }
.fad.#{$fa-css-prefix}-pickleball::after, .fa-duotone.#{$fa-css-prefix}-pickleball::after { content: "\e435\e435"; }
.fad.#{$fa-css-prefix}-pie::after, .fa-duotone.#{$fa-css-prefix}-pie::after { content: "\f705\f705"; }
.fad.#{$fa-css-prefix}-pig::after, .fa-duotone.#{$fa-css-prefix}-pig::after { content: "\f706\f706"; }
.fad.#{$fa-css-prefix}-piggy-bank::after, .fa-duotone.#{$fa-css-prefix}-piggy-bank::after { content: "\f4d3\f4d3"; }
.fad.#{$fa-css-prefix}-pills::after, .fa-duotone.#{$fa-css-prefix}-pills::after { content: "\f484\f484"; }
.fad.#{$fa-css-prefix}-pinata::after, .fa-duotone.#{$fa-css-prefix}-pinata::after { content: "\e3c3\e3c3"; }
.fad.#{$fa-css-prefix}-pinball::after, .fa-duotone.#{$fa-css-prefix}-pinball::after { content: "\e229\e229"; }
.fad.#{$fa-css-prefix}-pineapple::after, .fa-duotone.#{$fa-css-prefix}-pineapple::after { content: "\e31f\e31f"; }
.fad.#{$fa-css-prefix}-pipe::after, .fa-duotone.#{$fa-css-prefix}-pipe::after { content: "\7c\7c"; }
.fad.#{$fa-css-prefix}-pipe-circle-check::after, .fa-duotone.#{$fa-css-prefix}-pipe-circle-check::after { content: "\e436\e436"; }
.fad.#{$fa-css-prefix}-pipe-collar::after, .fa-duotone.#{$fa-css-prefix}-pipe-collar::after { content: "\e437\e437"; }
.fad.#{$fa-css-prefix}-pipe-section::after, .fa-duotone.#{$fa-css-prefix}-pipe-section::after { content: "\e438\e438"; }
.fad.#{$fa-css-prefix}-pipe-smoking::after, .fa-duotone.#{$fa-css-prefix}-pipe-smoking::after { content: "\e3c4\e3c4"; }
.fad.#{$fa-css-prefix}-pipe-valve::after, .fa-duotone.#{$fa-css-prefix}-pipe-valve::after { content: "\e439\e439"; }
.fad.#{$fa-css-prefix}-pizza::after, .fa-duotone.#{$fa-css-prefix}-pizza::after { content: "\f817\f817"; }
.fad.#{$fa-css-prefix}-pizza-slice::after, .fa-duotone.#{$fa-css-prefix}-pizza-slice::after { content: "\f818\f818"; }
.fad.#{$fa-css-prefix}-place-of-worship::after, .fa-duotone.#{$fa-css-prefix}-place-of-worship::after { content: "\f67f\f67f"; }
.fad.#{$fa-css-prefix}-plane::after, .fa-duotone.#{$fa-css-prefix}-plane::after { content: "\f072\f072"; }
.fad.#{$fa-css-prefix}-plane-arrival::after, .fa-duotone.#{$fa-css-prefix}-plane-arrival::after { content: "\f5af\f5af"; }
.fad.#{$fa-css-prefix}-plane-departure::after, .fa-duotone.#{$fa-css-prefix}-plane-departure::after { content: "\f5b0\f5b0"; }
.fad.#{$fa-css-prefix}-plane-engines::after, .fa-duotone.#{$fa-css-prefix}-plane-engines::after { content: "\f3de\f3de"; }
.fad.#{$fa-css-prefix}-plane-alt::after, .fa-duotone.#{$fa-css-prefix}-plane-alt::after { content: "\f3de\f3de"; }
.fad.#{$fa-css-prefix}-plane-prop::after, .fa-duotone.#{$fa-css-prefix}-plane-prop::after { content: "\e22b\e22b"; }
.fad.#{$fa-css-prefix}-plane-slash::after, .fa-duotone.#{$fa-css-prefix}-plane-slash::after { content: "\e069\e069"; }
.fad.#{$fa-css-prefix}-plane-tail::after, .fa-duotone.#{$fa-css-prefix}-plane-tail::after { content: "\e22c\e22c"; }
.fad.#{$fa-css-prefix}-plane-up::after, .fa-duotone.#{$fa-css-prefix}-plane-up::after { content: "\e22d\e22d"; }
.fad.#{$fa-css-prefix}-plane-up-slash::after, .fa-duotone.#{$fa-css-prefix}-plane-up-slash::after { content: "\e22e\e22e"; }
.fad.#{$fa-css-prefix}-planet-moon::after, .fa-duotone.#{$fa-css-prefix}-planet-moon::after { content: "\e01f\e01f"; }
.fad.#{$fa-css-prefix}-planet-ringed::after, .fa-duotone.#{$fa-css-prefix}-planet-ringed::after { content: "\e020\e020"; }
.fad.#{$fa-css-prefix}-plate-utensils::after, .fa-duotone.#{$fa-css-prefix}-plate-utensils::after { content: "\e43b\e43b"; }
.fad.#{$fa-css-prefix}-play::after, .fa-duotone.#{$fa-css-prefix}-play::after { content: "\f04b\f04b"; }
.fad.#{$fa-css-prefix}-play-pause::after, .fa-duotone.#{$fa-css-prefix}-play-pause::after { content: "\e22f\e22f"; }
.fad.#{$fa-css-prefix}-plug::after, .fa-duotone.#{$fa-css-prefix}-plug::after { content: "\f1e6\f1e6"; }
.fad.#{$fa-css-prefix}-plus::after, .fa-duotone.#{$fa-css-prefix}-plus::after { content: "\2b\2b"; }
.fad.#{$fa-css-prefix}-add::after, .fa-duotone.#{$fa-css-prefix}-add::after { content: "\2b\2b"; }
.fad.#{$fa-css-prefix}-plus-minus::after, .fa-duotone.#{$fa-css-prefix}-plus-minus::after { content: "\e43c\e43c"; }
.fad.#{$fa-css-prefix}-podcast::after, .fa-duotone.#{$fa-css-prefix}-podcast::after { content: "\f2ce\f2ce"; }
.fad.#{$fa-css-prefix}-podium::after, .fa-duotone.#{$fa-css-prefix}-podium::after { content: "\f680\f680"; }
.fad.#{$fa-css-prefix}-podium-star::after, .fa-duotone.#{$fa-css-prefix}-podium-star::after { content: "\f758\f758"; }
.fad.#{$fa-css-prefix}-police-box::after, .fa-duotone.#{$fa-css-prefix}-police-box::after { content: "\e021\e021"; }
.fad.#{$fa-css-prefix}-poll-people::after, .fa-duotone.#{$fa-css-prefix}-poll-people::after { content: "\f759\f759"; }
.fad.#{$fa-css-prefix}-pompebled::after, .fa-duotone.#{$fa-css-prefix}-pompebled::after { content: "\e43d\e43d"; }
.fad.#{$fa-css-prefix}-poo::after, .fa-duotone.#{$fa-css-prefix}-poo::after { content: "\f2fe\f2fe"; }
.fad.#{$fa-css-prefix}-poo-storm::after, .fa-duotone.#{$fa-css-prefix}-poo-storm::after { content: "\f75a\f75a"; }
.fad.#{$fa-css-prefix}-poo-bolt::after, .fa-duotone.#{$fa-css-prefix}-poo-bolt::after { content: "\f75a\f75a"; }
.fad.#{$fa-css-prefix}-pool-8-ball::after, .fa-duotone.#{$fa-css-prefix}-pool-8-ball::after { content: "\e3c5\e3c5"; }
.fad.#{$fa-css-prefix}-poop::after, .fa-duotone.#{$fa-css-prefix}-poop::after { content: "\f619\f619"; }
.fad.#{$fa-css-prefix}-popcorn::after, .fa-duotone.#{$fa-css-prefix}-popcorn::after { content: "\f819\f819"; }
.fad.#{$fa-css-prefix}-popsicle::after, .fa-duotone.#{$fa-css-prefix}-popsicle::after { content: "\e43e\e43e"; }
.fad.#{$fa-css-prefix}-pot-food::after, .fa-duotone.#{$fa-css-prefix}-pot-food::after { content: "\e43f\e43f"; }
.fad.#{$fa-css-prefix}-potato::after, .fa-duotone.#{$fa-css-prefix}-potato::after { content: "\e440\e440"; }
.fad.#{$fa-css-prefix}-power-off::after, .fa-duotone.#{$fa-css-prefix}-power-off::after { content: "\f011\f011"; }
.fad.#{$fa-css-prefix}-prescription::after, .fa-duotone.#{$fa-css-prefix}-prescription::after { content: "\f5b1\f5b1"; }
.fad.#{$fa-css-prefix}-prescription-bottle::after, .fa-duotone.#{$fa-css-prefix}-prescription-bottle::after { content: "\f485\f485"; }
.fad.#{$fa-css-prefix}-prescription-bottle-medical::after, .fa-duotone.#{$fa-css-prefix}-prescription-bottle-medical::after { content: "\f486\f486"; }
.fad.#{$fa-css-prefix}-prescription-bottle-alt::after, .fa-duotone.#{$fa-css-prefix}-prescription-bottle-alt::after { content: "\f486\f486"; }
.fad.#{$fa-css-prefix}-presentation-screen::after, .fa-duotone.#{$fa-css-prefix}-presentation-screen::after { content: "\f685\f685"; }
.fad.#{$fa-css-prefix}-presentation::after, .fa-duotone.#{$fa-css-prefix}-presentation::after { content: "\f685\f685"; }
.fad.#{$fa-css-prefix}-pretzel::after, .fa-duotone.#{$fa-css-prefix}-pretzel::after { content: "\e441\e441"; }
.fad.#{$fa-css-prefix}-print::after, .fa-duotone.#{$fa-css-prefix}-print::after { content: "\f02f\f02f"; }
.fad.#{$fa-css-prefix}-print-magnifying-glass::after, .fa-duotone.#{$fa-css-prefix}-print-magnifying-glass::after { content: "\f81a\f81a"; }
.fad.#{$fa-css-prefix}-print-search::after, .fa-duotone.#{$fa-css-prefix}-print-search::after { content: "\f81a\f81a"; }
.fad.#{$fa-css-prefix}-print-slash::after, .fa-duotone.#{$fa-css-prefix}-print-slash::after { content: "\f686\f686"; }
.fad.#{$fa-css-prefix}-projector::after, .fa-duotone.#{$fa-css-prefix}-projector::after { content: "\f8d6\f8d6"; }
.fad.#{$fa-css-prefix}-pump::after, .fa-duotone.#{$fa-css-prefix}-pump::after { content: "\e442\e442"; }
.fad.#{$fa-css-prefix}-pump-medical::after, .fa-duotone.#{$fa-css-prefix}-pump-medical::after { content: "\e06a\e06a"; }
.fad.#{$fa-css-prefix}-pump-soap::after, .fa-duotone.#{$fa-css-prefix}-pump-soap::after { content: "\e06b\e06b"; }
.fad.#{$fa-css-prefix}-pumpkin::after, .fa-duotone.#{$fa-css-prefix}-pumpkin::after { content: "\f707\f707"; }
.fad.#{$fa-css-prefix}-puzzle::after, .fa-duotone.#{$fa-css-prefix}-puzzle::after { content: "\e443\e443"; }
.fad.#{$fa-css-prefix}-puzzle-piece::after, .fa-duotone.#{$fa-css-prefix}-puzzle-piece::after { content: "\f12e\f12e"; }
.fad.#{$fa-css-prefix}-puzzle-piece-simple::after, .fa-duotone.#{$fa-css-prefix}-puzzle-piece-simple::after { content: "\e231\e231"; }
.fad.#{$fa-css-prefix}-puzzle-piece-alt::after, .fa-duotone.#{$fa-css-prefix}-puzzle-piece-alt::after { content: "\e231\e231"; }
.fad.#{$fa-css-prefix}-q::after, .fa-duotone.#{$fa-css-prefix}-q::after { content: "\51\51"; }
.fad.#{$fa-css-prefix}-qrcode::after, .fa-duotone.#{$fa-css-prefix}-qrcode::after { content: "\f029\f029"; }
.fad.#{$fa-css-prefix}-question::after, .fa-duotone.#{$fa-css-prefix}-question::after { content: "\3f\3f"; }
.fad.#{$fa-css-prefix}-quote-left::after, .fa-duotone.#{$fa-css-prefix}-quote-left::after { content: "\f10d\f10d"; }
.fad.#{$fa-css-prefix}-quote-left-alt::after, .fa-duotone.#{$fa-css-prefix}-quote-left-alt::after { content: "\f10d\f10d"; }
.fad.#{$fa-css-prefix}-quote-right::after, .fa-duotone.#{$fa-css-prefix}-quote-right::after { content: "\f10e\f10e"; }
.fad.#{$fa-css-prefix}-quote-right-alt::after, .fa-duotone.#{$fa-css-prefix}-quote-right-alt::after { content: "\f10e\f10e"; }
.fad.#{$fa-css-prefix}-quotes::after, .fa-duotone.#{$fa-css-prefix}-quotes::after { content: "\e234\e234"; }
.fad.#{$fa-css-prefix}-r::after, .fa-duotone.#{$fa-css-prefix}-r::after { content: "\52\52"; }
.fad.#{$fa-css-prefix}-rabbit::after, .fa-duotone.#{$fa-css-prefix}-rabbit::after { content: "\f708\f708"; }
.fad.#{$fa-css-prefix}-rabbit-running::after, .fa-duotone.#{$fa-css-prefix}-rabbit-running::after { content: "\f709\f709"; }
.fad.#{$fa-css-prefix}-rabbit-fast::after, .fa-duotone.#{$fa-css-prefix}-rabbit-fast::after { content: "\f709\f709"; }
.fad.#{$fa-css-prefix}-racquet::after, .fa-duotone.#{$fa-css-prefix}-racquet::after { content: "\f45a\f45a"; }
.fad.#{$fa-css-prefix}-radar::after, .fa-duotone.#{$fa-css-prefix}-radar::after { content: "\e024\e024"; }
.fad.#{$fa-css-prefix}-radiation::after, .fa-duotone.#{$fa-css-prefix}-radiation::after { content: "\f7b9\f7b9"; }
.fad.#{$fa-css-prefix}-radio::after, .fa-duotone.#{$fa-css-prefix}-radio::after { content: "\f8d7\f8d7"; }
.fad.#{$fa-css-prefix}-radio-tuner::after, .fa-duotone.#{$fa-css-prefix}-radio-tuner::after { content: "\f8d8\f8d8"; }
.fad.#{$fa-css-prefix}-radio-alt::after, .fa-duotone.#{$fa-css-prefix}-radio-alt::after { content: "\f8d8\f8d8"; }
.fad.#{$fa-css-prefix}-rainbow::after, .fa-duotone.#{$fa-css-prefix}-rainbow::after { content: "\f75b\f75b"; }
.fad.#{$fa-css-prefix}-raindrops::after, .fa-duotone.#{$fa-css-prefix}-raindrops::after { content: "\f75c\f75c"; }
.fad.#{$fa-css-prefix}-ram::after, .fa-duotone.#{$fa-css-prefix}-ram::after { content: "\f70a\f70a"; }
.fad.#{$fa-css-prefix}-ramp-loading::after, .fa-duotone.#{$fa-css-prefix}-ramp-loading::after { content: "\f4d4\f4d4"; }
.fad.#{$fa-css-prefix}-raygun::after, .fa-duotone.#{$fa-css-prefix}-raygun::after { content: "\e025\e025"; }
.fad.#{$fa-css-prefix}-receipt::after, .fa-duotone.#{$fa-css-prefix}-receipt::after { content: "\f543\f543"; }
.fad.#{$fa-css-prefix}-record-vinyl::after, .fa-duotone.#{$fa-css-prefix}-record-vinyl::after { content: "\f8d9\f8d9"; }
.fad.#{$fa-css-prefix}-rectangle::after, .fa-duotone.#{$fa-css-prefix}-rectangle::after { content: "\f2fa\f2fa"; }
.fad.#{$fa-css-prefix}-rectangle-landscape::after, .fa-duotone.#{$fa-css-prefix}-rectangle-landscape::after { content: "\f2fa\f2fa"; }
.fad.#{$fa-css-prefix}-rectangle-ad::after, .fa-duotone.#{$fa-css-prefix}-rectangle-ad::after { content: "\f641\f641"; }
.fad.#{$fa-css-prefix}-ad::after, .fa-duotone.#{$fa-css-prefix}-ad::after { content: "\f641\f641"; }
.fad.#{$fa-css-prefix}-rectangle-barcode::after, .fa-duotone.#{$fa-css-prefix}-rectangle-barcode::after { content: "\f463\f463"; }
.fad.#{$fa-css-prefix}-barcode-alt::after, .fa-duotone.#{$fa-css-prefix}-barcode-alt::after { content: "\f463\f463"; }
.fad.#{$fa-css-prefix}-rectangle-code::after, .fa-duotone.#{$fa-css-prefix}-rectangle-code::after { content: "\e322\e322"; }
.fad.#{$fa-css-prefix}-rectangle-history::after, .fa-duotone.#{$fa-css-prefix}-rectangle-history::after { content: "\e4a2\e4a2"; }
.fad.#{$fa-css-prefix}-rectangle-history-circle-plus::after, .fa-duotone.#{$fa-css-prefix}-rectangle-history-circle-plus::after { content: "\e4a3\e4a3"; }
.fad.#{$fa-css-prefix}-rectangle-history-circle-user::after, .fa-duotone.#{$fa-css-prefix}-rectangle-history-circle-user::after { content: "\e4a4\e4a4"; }
.fad.#{$fa-css-prefix}-rectangle-list::after, .fa-duotone.#{$fa-css-prefix}-rectangle-list::after { content: "\f022\f022"; }
.fad.#{$fa-css-prefix}-list-alt::after, .fa-duotone.#{$fa-css-prefix}-list-alt::after { content: "\f022\f022"; }
.fad.#{$fa-css-prefix}-rectangle-pro::after, .fa-duotone.#{$fa-css-prefix}-rectangle-pro::after { content: "\e235\e235"; }
.fad.#{$fa-css-prefix}-pro::after, .fa-duotone.#{$fa-css-prefix}-pro::after { content: "\e235\e235"; }
.fad.#{$fa-css-prefix}-rectangle-terminal::after, .fa-duotone.#{$fa-css-prefix}-rectangle-terminal::after { content: "\e236\e236"; }
.fad.#{$fa-css-prefix}-rectangle-vertical::after, .fa-duotone.#{$fa-css-prefix}-rectangle-vertical::after { content: "\f2fb\f2fb"; }
.fad.#{$fa-css-prefix}-rectangle-portrait::after, .fa-duotone.#{$fa-css-prefix}-rectangle-portrait::after { content: "\f2fb\f2fb"; }
.fad.#{$fa-css-prefix}-rectangle-vertical-history::after, .fa-duotone.#{$fa-css-prefix}-rectangle-vertical-history::after { content: "\e237\e237"; }
.fad.#{$fa-css-prefix}-rectangle-wide::after, .fa-duotone.#{$fa-css-prefix}-rectangle-wide::after { content: "\f2fc\f2fc"; }
.fad.#{$fa-css-prefix}-rectangle-xmark::after, .fa-duotone.#{$fa-css-prefix}-rectangle-xmark::after { content: "\f410\f410"; }
.fad.#{$fa-css-prefix}-rectangle-times::after, .fa-duotone.#{$fa-css-prefix}-rectangle-times::after { content: "\f410\f410"; }
.fad.#{$fa-css-prefix}-times-rectangle::after, .fa-duotone.#{$fa-css-prefix}-times-rectangle::after { content: "\f410\f410"; }
.fad.#{$fa-css-prefix}-window-close::after, .fa-duotone.#{$fa-css-prefix}-window-close::after { content: "\f410\f410"; }
.fad.#{$fa-css-prefix}-rectangles-mixed::after, .fa-duotone.#{$fa-css-prefix}-rectangles-mixed::after { content: "\e323\e323"; }
.fad.#{$fa-css-prefix}-recycle::after, .fa-duotone.#{$fa-css-prefix}-recycle::after { content: "\f1b8\f1b8"; }
.fad.#{$fa-css-prefix}-reel::after, .fa-duotone.#{$fa-css-prefix}-reel::after { content: "\e238\e238"; }
.fad.#{$fa-css-prefix}-refrigerator::after, .fa-duotone.#{$fa-css-prefix}-refrigerator::after { content: "\e026\e026"; }
.fad.#{$fa-css-prefix}-registered::after, .fa-duotone.#{$fa-css-prefix}-registered::after { content: "\f25d\f25d"; }
.fad.#{$fa-css-prefix}-repeat::after, .fa-duotone.#{$fa-css-prefix}-repeat::after { content: "\f363\f363"; }
.fad.#{$fa-css-prefix}-repeat-1::after, .fa-duotone.#{$fa-css-prefix}-repeat-1::after { content: "\f365\f365"; }
.fad.#{$fa-css-prefix}-reply::after, .fa-duotone.#{$fa-css-prefix}-reply::after { content: "\f3e5\f3e5"; }
.fad.#{$fa-css-prefix}-mail-reply::after, .fa-duotone.#{$fa-css-prefix}-mail-reply::after { content: "\f3e5\f3e5"; }
.fad.#{$fa-css-prefix}-reply-all::after, .fa-duotone.#{$fa-css-prefix}-reply-all::after { content: "\f122\f122"; }
.fad.#{$fa-css-prefix}-mail-reply-all::after, .fa-duotone.#{$fa-css-prefix}-mail-reply-all::after { content: "\f122\f122"; }
.fad.#{$fa-css-prefix}-reply-clock::after, .fa-duotone.#{$fa-css-prefix}-reply-clock::after { content: "\e239\e239"; }
.fad.#{$fa-css-prefix}-reply-time::after, .fa-duotone.#{$fa-css-prefix}-reply-time::after { content: "\e239\e239"; }
.fad.#{$fa-css-prefix}-republican::after, .fa-duotone.#{$fa-css-prefix}-republican::after { content: "\f75e\f75e"; }
.fad.#{$fa-css-prefix}-restroom::after, .fa-duotone.#{$fa-css-prefix}-restroom::after { content: "\f7bd\f7bd"; }
.fad.#{$fa-css-prefix}-restroom-simple::after, .fa-duotone.#{$fa-css-prefix}-restroom-simple::after { content: "\e23a\e23a"; }
.fad.#{$fa-css-prefix}-retweet::after, .fa-duotone.#{$fa-css-prefix}-retweet::after { content: "\f079\f079"; }
.fad.#{$fa-css-prefix}-rhombus::after, .fa-duotone.#{$fa-css-prefix}-rhombus::after { content: "\e23b\e23b"; }
.fad.#{$fa-css-prefix}-ribbon::after, .fa-duotone.#{$fa-css-prefix}-ribbon::after { content: "\f4d6\f4d6"; }
.fad.#{$fa-css-prefix}-right::after, .fa-duotone.#{$fa-css-prefix}-right::after { content: "\f356\f356"; }
.fad.#{$fa-css-prefix}-arrow-alt-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-right::after { content: "\f356\f356"; }
.fad.#{$fa-css-prefix}-right-from-bracket::after, .fa-duotone.#{$fa-css-prefix}-right-from-bracket::after { content: "\f2f5\f2f5"; }
.fad.#{$fa-css-prefix}-sign-out-alt::after, .fa-duotone.#{$fa-css-prefix}-sign-out-alt::after { content: "\f2f5\f2f5"; }
.fad.#{$fa-css-prefix}-right-from-line::after, .fa-duotone.#{$fa-css-prefix}-right-from-line::after { content: "\f347\f347"; }
.fad.#{$fa-css-prefix}-arrow-alt-from-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-from-left::after { content: "\f347\f347"; }
.fad.#{$fa-css-prefix}-right-left::after, .fa-duotone.#{$fa-css-prefix}-right-left::after { content: "\f362\f362"; }
.fad.#{$fa-css-prefix}-exchange-alt::after, .fa-duotone.#{$fa-css-prefix}-exchange-alt::after { content: "\f362\f362"; }
.fad.#{$fa-css-prefix}-right-long::after, .fa-duotone.#{$fa-css-prefix}-right-long::after { content: "\f30b\f30b"; }
.fad.#{$fa-css-prefix}-long-arrow-alt-right::after, .fa-duotone.#{$fa-css-prefix}-long-arrow-alt-right::after { content: "\f30b\f30b"; }
.fad.#{$fa-css-prefix}-right-long-to-line::after, .fa-duotone.#{$fa-css-prefix}-right-long-to-line::after { content: "\e444\e444"; }
.fad.#{$fa-css-prefix}-right-to-bracket::after, .fa-duotone.#{$fa-css-prefix}-right-to-bracket::after { content: "\f2f6\f2f6"; }
.fad.#{$fa-css-prefix}-sign-in-alt::after, .fa-duotone.#{$fa-css-prefix}-sign-in-alt::after { content: "\f2f6\f2f6"; }
.fad.#{$fa-css-prefix}-right-to-line::after, .fa-duotone.#{$fa-css-prefix}-right-to-line::after { content: "\f34c\f34c"; }
.fad.#{$fa-css-prefix}-arrow-alt-to-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-to-right::after { content: "\f34c\f34c"; }
.fad.#{$fa-css-prefix}-ring::after, .fa-duotone.#{$fa-css-prefix}-ring::after { content: "\f70b\f70b"; }
.fad.#{$fa-css-prefix}-rings-wedding::after, .fa-duotone.#{$fa-css-prefix}-rings-wedding::after { content: "\f81b\f81b"; }
.fad.#{$fa-css-prefix}-road::after, .fa-duotone.#{$fa-css-prefix}-road::after { content: "\f018\f018"; }
.fad.#{$fa-css-prefix}-robot::after, .fa-duotone.#{$fa-css-prefix}-robot::after { content: "\f544\f544"; }
.fad.#{$fa-css-prefix}-robot-astromech::after, .fa-duotone.#{$fa-css-prefix}-robot-astromech::after { content: "\e2d2\e2d2"; }
.fad.#{$fa-css-prefix}-rocket::after, .fa-duotone.#{$fa-css-prefix}-rocket::after { content: "\f135\f135"; }
.fad.#{$fa-css-prefix}-rocket-launch::after, .fa-duotone.#{$fa-css-prefix}-rocket-launch::after { content: "\e027\e027"; }
.fad.#{$fa-css-prefix}-roller-coaster::after, .fa-duotone.#{$fa-css-prefix}-roller-coaster::after { content: "\e324\e324"; }
.fad.#{$fa-css-prefix}-rotate::after, .fa-duotone.#{$fa-css-prefix}-rotate::after { content: "\f2f1\f2f1"; }
.fad.#{$fa-css-prefix}-sync-alt::after, .fa-duotone.#{$fa-css-prefix}-sync-alt::after { content: "\f2f1\f2f1"; }
.fad.#{$fa-css-prefix}-rotate-exclamation::after, .fa-duotone.#{$fa-css-prefix}-rotate-exclamation::after { content: "\e23c\e23c"; }
.fad.#{$fa-css-prefix}-rotate-left::after, .fa-duotone.#{$fa-css-prefix}-rotate-left::after { content: "\f2ea\f2ea"; }
.fad.#{$fa-css-prefix}-rotate-back::after, .fa-duotone.#{$fa-css-prefix}-rotate-back::after { content: "\f2ea\f2ea"; }
.fad.#{$fa-css-prefix}-rotate-backward::after, .fa-duotone.#{$fa-css-prefix}-rotate-backward::after { content: "\f2ea\f2ea"; }
.fad.#{$fa-css-prefix}-undo-alt::after, .fa-duotone.#{$fa-css-prefix}-undo-alt::after { content: "\f2ea\f2ea"; }
.fad.#{$fa-css-prefix}-rotate-right::after, .fa-duotone.#{$fa-css-prefix}-rotate-right::after { content: "\f2f9\f2f9"; }
.fad.#{$fa-css-prefix}-redo-alt::after, .fa-duotone.#{$fa-css-prefix}-redo-alt::after { content: "\f2f9\f2f9"; }
.fad.#{$fa-css-prefix}-rotate-forward::after, .fa-duotone.#{$fa-css-prefix}-rotate-forward::after { content: "\f2f9\f2f9"; }
.fad.#{$fa-css-prefix}-route::after, .fa-duotone.#{$fa-css-prefix}-route::after { content: "\f4d7\f4d7"; }
.fad.#{$fa-css-prefix}-route-highway::after, .fa-duotone.#{$fa-css-prefix}-route-highway::after { content: "\f61a\f61a"; }
.fad.#{$fa-css-prefix}-route-interstate::after, .fa-duotone.#{$fa-css-prefix}-route-interstate::after { content: "\f61b\f61b"; }
.fad.#{$fa-css-prefix}-router::after, .fa-duotone.#{$fa-css-prefix}-router::after { content: "\f8da\f8da"; }
.fad.#{$fa-css-prefix}-rss::after, .fa-duotone.#{$fa-css-prefix}-rss::after { content: "\f09e\f09e"; }
.fad.#{$fa-css-prefix}-feed::after, .fa-duotone.#{$fa-css-prefix}-feed::after { content: "\f09e\f09e"; }
.fad.#{$fa-css-prefix}-ruble-sign::after, .fa-duotone.#{$fa-css-prefix}-ruble-sign::after { content: "\f158\f158"; }
.fad.#{$fa-css-prefix}-rouble::after, .fa-duotone.#{$fa-css-prefix}-rouble::after { content: "\f158\f158"; }
.fad.#{$fa-css-prefix}-rub::after, .fa-duotone.#{$fa-css-prefix}-rub::after { content: "\f158\f158"; }
.fad.#{$fa-css-prefix}-ruble::after, .fa-duotone.#{$fa-css-prefix}-ruble::after { content: "\f158\f158"; }
.fad.#{$fa-css-prefix}-rugby-ball::after, .fa-duotone.#{$fa-css-prefix}-rugby-ball::after { content: "\e3c6\e3c6"; }
.fad.#{$fa-css-prefix}-ruler::after, .fa-duotone.#{$fa-css-prefix}-ruler::after { content: "\f545\f545"; }
.fad.#{$fa-css-prefix}-ruler-combined::after, .fa-duotone.#{$fa-css-prefix}-ruler-combined::after { content: "\f546\f546"; }
.fad.#{$fa-css-prefix}-ruler-horizontal::after, .fa-duotone.#{$fa-css-prefix}-ruler-horizontal::after { content: "\f547\f547"; }
.fad.#{$fa-css-prefix}-ruler-triangle::after, .fa-duotone.#{$fa-css-prefix}-ruler-triangle::after { content: "\f61c\f61c"; }
.fad.#{$fa-css-prefix}-ruler-vertical::after, .fa-duotone.#{$fa-css-prefix}-ruler-vertical::after { content: "\f548\f548"; }
.fad.#{$fa-css-prefix}-rupee-sign::after, .fa-duotone.#{$fa-css-prefix}-rupee-sign::after { content: "\f156\f156"; }
.fad.#{$fa-css-prefix}-rupee::after, .fa-duotone.#{$fa-css-prefix}-rupee::after { content: "\f156\f156"; }
.fad.#{$fa-css-prefix}-rupiah-sign::after, .fa-duotone.#{$fa-css-prefix}-rupiah-sign::after { content: "\e23d\e23d"; }
.fad.#{$fa-css-prefix}-rv::after, .fa-duotone.#{$fa-css-prefix}-rv::after { content: "\f7be\f7be"; }
.fad.#{$fa-css-prefix}-s::after, .fa-duotone.#{$fa-css-prefix}-s::after { content: "\53\53"; }
.fad.#{$fa-css-prefix}-sack::after, .fa-duotone.#{$fa-css-prefix}-sack::after { content: "\f81c\f81c"; }
.fad.#{$fa-css-prefix}-sack-dollar::after, .fa-duotone.#{$fa-css-prefix}-sack-dollar::after { content: "\f81d\f81d"; }
.fad.#{$fa-css-prefix}-sailboat::after, .fa-duotone.#{$fa-css-prefix}-sailboat::after { content: "\e445\e445"; }
.fad.#{$fa-css-prefix}-salad::after, .fa-duotone.#{$fa-css-prefix}-salad::after { content: "\f81e\f81e"; }
.fad.#{$fa-css-prefix}-bowl-salad::after, .fa-duotone.#{$fa-css-prefix}-bowl-salad::after { content: "\f81e\f81e"; }
.fad.#{$fa-css-prefix}-salt-shaker::after, .fa-duotone.#{$fa-css-prefix}-salt-shaker::after { content: "\e446\e446"; }
.fad.#{$fa-css-prefix}-sandwich::after, .fa-duotone.#{$fa-css-prefix}-sandwich::after { content: "\f81f\f81f"; }
.fad.#{$fa-css-prefix}-satellite::after, .fa-duotone.#{$fa-css-prefix}-satellite::after { content: "\f7bf\f7bf"; }
.fad.#{$fa-css-prefix}-satellite-dish::after, .fa-duotone.#{$fa-css-prefix}-satellite-dish::after { content: "\f7c0\f7c0"; }
.fad.#{$fa-css-prefix}-sausage::after, .fa-duotone.#{$fa-css-prefix}-sausage::after { content: "\f820\f820"; }
.fad.#{$fa-css-prefix}-saxophone::after, .fa-duotone.#{$fa-css-prefix}-saxophone::after { content: "\f8dc\f8dc"; }
.fad.#{$fa-css-prefix}-saxophone-fire::after, .fa-duotone.#{$fa-css-prefix}-saxophone-fire::after { content: "\f8db\f8db"; }
.fad.#{$fa-css-prefix}-sax-hot::after, .fa-duotone.#{$fa-css-prefix}-sax-hot::after { content: "\f8db\f8db"; }
.fad.#{$fa-css-prefix}-scale-balanced::after, .fa-duotone.#{$fa-css-prefix}-scale-balanced::after { content: "\f24e\f24e"; }
.fad.#{$fa-css-prefix}-balance-scale::after, .fa-duotone.#{$fa-css-prefix}-balance-scale::after { content: "\f24e\f24e"; }
.fad.#{$fa-css-prefix}-scale-unbalanced::after, .fa-duotone.#{$fa-css-prefix}-scale-unbalanced::after { content: "\f515\f515"; }
.fad.#{$fa-css-prefix}-balance-scale-left::after, .fa-duotone.#{$fa-css-prefix}-balance-scale-left::after { content: "\f515\f515"; }
.fad.#{$fa-css-prefix}-scale-unbalanced-flip::after, .fa-duotone.#{$fa-css-prefix}-scale-unbalanced-flip::after { content: "\f516\f516"; }
.fad.#{$fa-css-prefix}-balance-scale-right::after, .fa-duotone.#{$fa-css-prefix}-balance-scale-right::after { content: "\f516\f516"; }
.fad.#{$fa-css-prefix}-scalpel::after, .fa-duotone.#{$fa-css-prefix}-scalpel::after { content: "\f61d\f61d"; }
.fad.#{$fa-css-prefix}-scalpel-line-dashed::after, .fa-duotone.#{$fa-css-prefix}-scalpel-line-dashed::after { content: "\f61e\f61e"; }
.fad.#{$fa-css-prefix}-scalpel-path::after, .fa-duotone.#{$fa-css-prefix}-scalpel-path::after { content: "\f61e\f61e"; }
.fad.#{$fa-css-prefix}-scanner::after, .fa-duotone.#{$fa-css-prefix}-scanner::after { content: "\f8f3\f8f3"; }
.fad.#{$fa-css-prefix}-scanner-image::after, .fa-duotone.#{$fa-css-prefix}-scanner-image::after { content: "\f8f3\f8f3"; }
.fad.#{$fa-css-prefix}-scanner-gun::after, .fa-duotone.#{$fa-css-prefix}-scanner-gun::after { content: "\f488\f488"; }
.fad.#{$fa-css-prefix}-scanner-keyboard::after, .fa-duotone.#{$fa-css-prefix}-scanner-keyboard::after { content: "\f489\f489"; }
.fad.#{$fa-css-prefix}-scanner-touchscreen::after, .fa-duotone.#{$fa-css-prefix}-scanner-touchscreen::after { content: "\f48a\f48a"; }
.fad.#{$fa-css-prefix}-scarecrow::after, .fa-duotone.#{$fa-css-prefix}-scarecrow::after { content: "\f70d\f70d"; }
.fad.#{$fa-css-prefix}-scarf::after, .fa-duotone.#{$fa-css-prefix}-scarf::after { content: "\f7c1\f7c1"; }
.fad.#{$fa-css-prefix}-school::after, .fa-duotone.#{$fa-css-prefix}-school::after { content: "\f549\f549"; }
.fad.#{$fa-css-prefix}-scissors::after, .fa-duotone.#{$fa-css-prefix}-scissors::after { content: "\f0c4\f0c4"; }
.fad.#{$fa-css-prefix}-cut::after, .fa-duotone.#{$fa-css-prefix}-cut::after { content: "\f0c4\f0c4"; }
.fad.#{$fa-css-prefix}-screen-users::after, .fa-duotone.#{$fa-css-prefix}-screen-users::after { content: "\f63d\f63d"; }
.fad.#{$fa-css-prefix}-users-class::after, .fa-duotone.#{$fa-css-prefix}-users-class::after { content: "\f63d\f63d"; }
.fad.#{$fa-css-prefix}-screencast::after, .fa-duotone.#{$fa-css-prefix}-screencast::after { content: "\e23e\e23e"; }
.fad.#{$fa-css-prefix}-screwdriver::after, .fa-duotone.#{$fa-css-prefix}-screwdriver::after { content: "\f54a\f54a"; }
.fad.#{$fa-css-prefix}-screwdriver-wrench::after, .fa-duotone.#{$fa-css-prefix}-screwdriver-wrench::after { content: "\f7d9\f7d9"; }
.fad.#{$fa-css-prefix}-tools::after, .fa-duotone.#{$fa-css-prefix}-tools::after { content: "\f7d9\f7d9"; }
.fad.#{$fa-css-prefix}-scribble::after, .fa-duotone.#{$fa-css-prefix}-scribble::after { content: "\e23f\e23f"; }
.fad.#{$fa-css-prefix}-scroll::after, .fa-duotone.#{$fa-css-prefix}-scroll::after { content: "\f70e\f70e"; }
.fad.#{$fa-css-prefix}-scroll-old::after, .fa-duotone.#{$fa-css-prefix}-scroll-old::after { content: "\f70f\f70f"; }
.fad.#{$fa-css-prefix}-scroll-torah::after, .fa-duotone.#{$fa-css-prefix}-scroll-torah::after { content: "\f6a0\f6a0"; }
.fad.#{$fa-css-prefix}-torah::after, .fa-duotone.#{$fa-css-prefix}-torah::after { content: "\f6a0\f6a0"; }
.fad.#{$fa-css-prefix}-scrubber::after, .fa-duotone.#{$fa-css-prefix}-scrubber::after { content: "\f2f8\f2f8"; }
.fad.#{$fa-css-prefix}-scythe::after, .fa-duotone.#{$fa-css-prefix}-scythe::after { content: "\f710\f710"; }
.fad.#{$fa-css-prefix}-sd-card::after, .fa-duotone.#{$fa-css-prefix}-sd-card::after { content: "\f7c2\f7c2"; }
.fad.#{$fa-css-prefix}-sd-cards::after, .fa-duotone.#{$fa-css-prefix}-sd-cards::after { content: "\e240\e240"; }
.fad.#{$fa-css-prefix}-seal::after, .fa-duotone.#{$fa-css-prefix}-seal::after { content: "\e241\e241"; }
.fad.#{$fa-css-prefix}-seal-exclamation::after, .fa-duotone.#{$fa-css-prefix}-seal-exclamation::after { content: "\e242\e242"; }
.fad.#{$fa-css-prefix}-seal-question::after, .fa-duotone.#{$fa-css-prefix}-seal-question::after { content: "\e243\e243"; }
.fad.#{$fa-css-prefix}-seat-airline::after, .fa-duotone.#{$fa-css-prefix}-seat-airline::after { content: "\e244\e244"; }
.fad.#{$fa-css-prefix}-section::after, .fa-duotone.#{$fa-css-prefix}-section::after { content: "\e447\e447"; }
.fad.#{$fa-css-prefix}-seedling::after, .fa-duotone.#{$fa-css-prefix}-seedling::after { content: "\f4d8\f4d8"; }
.fad.#{$fa-css-prefix}-sprout::after, .fa-duotone.#{$fa-css-prefix}-sprout::after { content: "\f4d8\f4d8"; }
.fad.#{$fa-css-prefix}-semicolon::after, .fa-duotone.#{$fa-css-prefix}-semicolon::after { content: "\3b\3b"; }
.fad.#{$fa-css-prefix}-send-back::after, .fa-duotone.#{$fa-css-prefix}-send-back::after { content: "\f87e\f87e"; }
.fad.#{$fa-css-prefix}-send-backward::after, .fa-duotone.#{$fa-css-prefix}-send-backward::after { content: "\f87f\f87f"; }
.fad.#{$fa-css-prefix}-sensor::after, .fa-duotone.#{$fa-css-prefix}-sensor::after { content: "\e028\e028"; }
.fad.#{$fa-css-prefix}-sensor-cloud::after, .fa-duotone.#{$fa-css-prefix}-sensor-cloud::after { content: "\e02c\e02c"; }
.fad.#{$fa-css-prefix}-sensor-smoke::after, .fa-duotone.#{$fa-css-prefix}-sensor-smoke::after { content: "\e02c\e02c"; }
.fad.#{$fa-css-prefix}-sensor-fire::after, .fa-duotone.#{$fa-css-prefix}-sensor-fire::after { content: "\e02a\e02a"; }
.fad.#{$fa-css-prefix}-sensor-on::after, .fa-duotone.#{$fa-css-prefix}-sensor-on::after { content: "\e02b\e02b"; }
.fad.#{$fa-css-prefix}-sensor-triangle-exclamation::after, .fa-duotone.#{$fa-css-prefix}-sensor-triangle-exclamation::after { content: "\e029\e029"; }
.fad.#{$fa-css-prefix}-sensor-alert::after, .fa-duotone.#{$fa-css-prefix}-sensor-alert::after { content: "\e029\e029"; }
.fad.#{$fa-css-prefix}-server::after, .fa-duotone.#{$fa-css-prefix}-server::after { content: "\f233\f233"; }
.fad.#{$fa-css-prefix}-shapes::after, .fa-duotone.#{$fa-css-prefix}-shapes::after { content: "\f61f\f61f"; }
.fad.#{$fa-css-prefix}-triangle-circle-square::after, .fa-duotone.#{$fa-css-prefix}-triangle-circle-square::after { content: "\f61f\f61f"; }
.fad.#{$fa-css-prefix}-share::after, .fa-duotone.#{$fa-css-prefix}-share::after { content: "\f064\f064"; }
.fad.#{$fa-css-prefix}-arrow-turn-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-turn-right::after { content: "\f064\f064"; }
.fad.#{$fa-css-prefix}-mail-forward::after, .fa-duotone.#{$fa-css-prefix}-mail-forward::after { content: "\f064\f064"; }
.fad.#{$fa-css-prefix}-share-all::after, .fa-duotone.#{$fa-css-prefix}-share-all::after { content: "\f367\f367"; }
.fad.#{$fa-css-prefix}-arrows-turn-right::after, .fa-duotone.#{$fa-css-prefix}-arrows-turn-right::after { content: "\f367\f367"; }
.fad.#{$fa-css-prefix}-share-from-square::after, .fa-duotone.#{$fa-css-prefix}-share-from-square::after { content: "\f14d\f14d"; }
.fad.#{$fa-css-prefix}-share-square::after, .fa-duotone.#{$fa-css-prefix}-share-square::after { content: "\f14d\f14d"; }
.fad.#{$fa-css-prefix}-share-nodes::after, .fa-duotone.#{$fa-css-prefix}-share-nodes::after { content: "\f1e0\f1e0"; }
.fad.#{$fa-css-prefix}-share-alt::after, .fa-duotone.#{$fa-css-prefix}-share-alt::after { content: "\f1e0\f1e0"; }
.fad.#{$fa-css-prefix}-sheep::after, .fa-duotone.#{$fa-css-prefix}-sheep::after { content: "\f711\f711"; }
.fad.#{$fa-css-prefix}-shekel-sign::after, .fa-duotone.#{$fa-css-prefix}-shekel-sign::after { content: "\f20b\f20b"; }
.fad.#{$fa-css-prefix}-ils::after, .fa-duotone.#{$fa-css-prefix}-ils::after { content: "\f20b\f20b"; }
.fad.#{$fa-css-prefix}-shekel::after, .fa-duotone.#{$fa-css-prefix}-shekel::after { content: "\f20b\f20b"; }
.fad.#{$fa-css-prefix}-sheqel::after, .fa-duotone.#{$fa-css-prefix}-sheqel::after { content: "\f20b\f20b"; }
.fad.#{$fa-css-prefix}-sheqel-sign::after, .fa-duotone.#{$fa-css-prefix}-sheqel-sign::after { content: "\f20b\f20b"; }
.fad.#{$fa-css-prefix}-shelves::after, .fa-duotone.#{$fa-css-prefix}-shelves::after { content: "\f480\f480"; }
.fad.#{$fa-css-prefix}-inventory::after, .fa-duotone.#{$fa-css-prefix}-inventory::after { content: "\f480\f480"; }
.fad.#{$fa-css-prefix}-shelves-empty::after, .fa-duotone.#{$fa-css-prefix}-shelves-empty::after { content: "\e246\e246"; }
.fad.#{$fa-css-prefix}-shield::after, .fa-duotone.#{$fa-css-prefix}-shield::after { content: "\f132\f132"; }
.fad.#{$fa-css-prefix}-shield-blank::after, .fa-duotone.#{$fa-css-prefix}-shield-blank::after { content: "\f3ed\f3ed"; }
.fad.#{$fa-css-prefix}-shield-alt::after, .fa-duotone.#{$fa-css-prefix}-shield-alt::after { content: "\f3ed\f3ed"; }
.fad.#{$fa-css-prefix}-shield-check::after, .fa-duotone.#{$fa-css-prefix}-shield-check::after { content: "\f2f7\f2f7"; }
.fad.#{$fa-css-prefix}-shield-cross::after, .fa-duotone.#{$fa-css-prefix}-shield-cross::after { content: "\f712\f712"; }
.fad.#{$fa-css-prefix}-shield-exclamation::after, .fa-duotone.#{$fa-css-prefix}-shield-exclamation::after { content: "\e247\e247"; }
.fad.#{$fa-css-prefix}-shield-keyhole::after, .fa-duotone.#{$fa-css-prefix}-shield-keyhole::after { content: "\e248\e248"; }
.fad.#{$fa-css-prefix}-shield-minus::after, .fa-duotone.#{$fa-css-prefix}-shield-minus::after { content: "\e249\e249"; }
.fad.#{$fa-css-prefix}-shield-plus::after, .fa-duotone.#{$fa-css-prefix}-shield-plus::after { content: "\e24a\e24a"; }
.fad.#{$fa-css-prefix}-shield-slash::after, .fa-duotone.#{$fa-css-prefix}-shield-slash::after { content: "\e24b\e24b"; }
.fad.#{$fa-css-prefix}-shield-virus::after, .fa-duotone.#{$fa-css-prefix}-shield-virus::after { content: "\e06c\e06c"; }
.fad.#{$fa-css-prefix}-shield-xmark::after, .fa-duotone.#{$fa-css-prefix}-shield-xmark::after { content: "\e24c\e24c"; }
.fad.#{$fa-css-prefix}-shield-times::after, .fa-duotone.#{$fa-css-prefix}-shield-times::after { content: "\e24c\e24c"; }
.fad.#{$fa-css-prefix}-ship::after, .fa-duotone.#{$fa-css-prefix}-ship::after { content: "\f21a\f21a"; }
.fad.#{$fa-css-prefix}-shirt::after, .fa-duotone.#{$fa-css-prefix}-shirt::after { content: "\f553\f553"; }
.fad.#{$fa-css-prefix}-t-shirt::after, .fa-duotone.#{$fa-css-prefix}-t-shirt::after { content: "\f553\f553"; }
.fad.#{$fa-css-prefix}-tshirt::after, .fa-duotone.#{$fa-css-prefix}-tshirt::after { content: "\f553\f553"; }
.fad.#{$fa-css-prefix}-shirt-long-sleeve::after, .fa-duotone.#{$fa-css-prefix}-shirt-long-sleeve::after { content: "\e3c7\e3c7"; }
.fad.#{$fa-css-prefix}-shirt-running::after, .fa-duotone.#{$fa-css-prefix}-shirt-running::after { content: "\e3c8\e3c8"; }
.fad.#{$fa-css-prefix}-shirt-tank-top::after, .fa-duotone.#{$fa-css-prefix}-shirt-tank-top::after { content: "\e3c9\e3c9"; }
.fad.#{$fa-css-prefix}-shish-kebab::after, .fa-duotone.#{$fa-css-prefix}-shish-kebab::after { content: "\f821\f821"; }
.fad.#{$fa-css-prefix}-shoe-prints::after, .fa-duotone.#{$fa-css-prefix}-shoe-prints::after { content: "\f54b\f54b"; }
.fad.#{$fa-css-prefix}-shop::after, .fa-duotone.#{$fa-css-prefix}-shop::after { content: "\f54f\f54f"; }
.fad.#{$fa-css-prefix}-store-alt::after, .fa-duotone.#{$fa-css-prefix}-store-alt::after { content: "\f54f\f54f"; }
.fad.#{$fa-css-prefix}-shop-lock::after, .fa-duotone.#{$fa-css-prefix}-shop-lock::after { content: "\e4a5\e4a5"; }
.fad.#{$fa-css-prefix}-shop-slash::after, .fa-duotone.#{$fa-css-prefix}-shop-slash::after { content: "\e070\e070"; }
.fad.#{$fa-css-prefix}-store-alt-slash::after, .fa-duotone.#{$fa-css-prefix}-store-alt-slash::after { content: "\e070\e070"; }
.fad.#{$fa-css-prefix}-shovel::after, .fa-duotone.#{$fa-css-prefix}-shovel::after { content: "\f713\f713"; }
.fad.#{$fa-css-prefix}-shovel-snow::after, .fa-duotone.#{$fa-css-prefix}-shovel-snow::after { content: "\f7c3\f7c3"; }
.fad.#{$fa-css-prefix}-shower::after, .fa-duotone.#{$fa-css-prefix}-shower::after { content: "\f2cc\f2cc"; }
.fad.#{$fa-css-prefix}-shower-down::after, .fa-duotone.#{$fa-css-prefix}-shower-down::after { content: "\e24d\e24d"; }
.fad.#{$fa-css-prefix}-shower-alt::after, .fa-duotone.#{$fa-css-prefix}-shower-alt::after { content: "\e24d\e24d"; }
.fad.#{$fa-css-prefix}-shredder::after, .fa-duotone.#{$fa-css-prefix}-shredder::after { content: "\f68a\f68a"; }
.fad.#{$fa-css-prefix}-shrimp::after, .fa-duotone.#{$fa-css-prefix}-shrimp::after { content: "\e448\e448"; }
.fad.#{$fa-css-prefix}-shuffle::after, .fa-duotone.#{$fa-css-prefix}-shuffle::after { content: "\f074\f074"; }
.fad.#{$fa-css-prefix}-random::after, .fa-duotone.#{$fa-css-prefix}-random::after { content: "\f074\f074"; }
.fad.#{$fa-css-prefix}-shutters::after, .fa-duotone.#{$fa-css-prefix}-shutters::after { content: "\e449\e449"; }
.fad.#{$fa-css-prefix}-shuttle-space::after, .fa-duotone.#{$fa-css-prefix}-shuttle-space::after { content: "\f197\f197"; }
.fad.#{$fa-css-prefix}-space-shuttle::after, .fa-duotone.#{$fa-css-prefix}-space-shuttle::after { content: "\f197\f197"; }
.fad.#{$fa-css-prefix}-shuttlecock::after, .fa-duotone.#{$fa-css-prefix}-shuttlecock::after { content: "\f45b\f45b"; }
.fad.#{$fa-css-prefix}-sickle::after, .fa-duotone.#{$fa-css-prefix}-sickle::after { content: "\f822\f822"; }
.fad.#{$fa-css-prefix}-sidebar::after, .fa-duotone.#{$fa-css-prefix}-sidebar::after { content: "\e24e\e24e"; }
.fad.#{$fa-css-prefix}-sidebar-flip::after, .fa-duotone.#{$fa-css-prefix}-sidebar-flip::after { content: "\e24f\e24f"; }
.fad.#{$fa-css-prefix}-sigma::after, .fa-duotone.#{$fa-css-prefix}-sigma::after { content: "\f68b\f68b"; }
.fad.#{$fa-css-prefix}-sign-hanging::after, .fa-duotone.#{$fa-css-prefix}-sign-hanging::after { content: "\f4d9\f4d9"; }
.fad.#{$fa-css-prefix}-sign::after, .fa-duotone.#{$fa-css-prefix}-sign::after { content: "\f4d9\f4d9"; }
.fad.#{$fa-css-prefix}-signal::after, .fa-duotone.#{$fa-css-prefix}-signal::after { content: "\f012\f012"; }
.fad.#{$fa-css-prefix}-signal-5::after, .fa-duotone.#{$fa-css-prefix}-signal-5::after { content: "\f012\f012"; }
.fad.#{$fa-css-prefix}-signal-perfect::after, .fa-duotone.#{$fa-css-prefix}-signal-perfect::after { content: "\f012\f012"; }
.fad.#{$fa-css-prefix}-signal-bars::after, .fa-duotone.#{$fa-css-prefix}-signal-bars::after { content: "\f690\f690"; }
.fad.#{$fa-css-prefix}-signal-alt::after, .fa-duotone.#{$fa-css-prefix}-signal-alt::after { content: "\f690\f690"; }
.fad.#{$fa-css-prefix}-signal-alt-4::after, .fa-duotone.#{$fa-css-prefix}-signal-alt-4::after { content: "\f690\f690"; }
.fad.#{$fa-css-prefix}-signal-bars-strong::after, .fa-duotone.#{$fa-css-prefix}-signal-bars-strong::after { content: "\f690\f690"; }
.fad.#{$fa-css-prefix}-signal-bars-fair::after, .fa-duotone.#{$fa-css-prefix}-signal-bars-fair::after { content: "\f692\f692"; }
.fad.#{$fa-css-prefix}-signal-alt-2::after, .fa-duotone.#{$fa-css-prefix}-signal-alt-2::after { content: "\f692\f692"; }
.fad.#{$fa-css-prefix}-signal-bars-good::after, .fa-duotone.#{$fa-css-prefix}-signal-bars-good::after { content: "\f693\f693"; }
.fad.#{$fa-css-prefix}-signal-alt-3::after, .fa-duotone.#{$fa-css-prefix}-signal-alt-3::after { content: "\f693\f693"; }
.fad.#{$fa-css-prefix}-signal-bars-slash::after, .fa-duotone.#{$fa-css-prefix}-signal-bars-slash::after { content: "\f694\f694"; }
.fad.#{$fa-css-prefix}-signal-alt-slash::after, .fa-duotone.#{$fa-css-prefix}-signal-alt-slash::after { content: "\f694\f694"; }
.fad.#{$fa-css-prefix}-signal-bars-weak::after, .fa-duotone.#{$fa-css-prefix}-signal-bars-weak::after { content: "\f691\f691"; }
.fad.#{$fa-css-prefix}-signal-alt-1::after, .fa-duotone.#{$fa-css-prefix}-signal-alt-1::after { content: "\f691\f691"; }
.fad.#{$fa-css-prefix}-signal-fair::after, .fa-duotone.#{$fa-css-prefix}-signal-fair::after { content: "\f68d\f68d"; }
.fad.#{$fa-css-prefix}-signal-2::after, .fa-duotone.#{$fa-css-prefix}-signal-2::after { content: "\f68d\f68d"; }
.fad.#{$fa-css-prefix}-signal-good::after, .fa-duotone.#{$fa-css-prefix}-signal-good::after { content: "\f68e\f68e"; }
.fad.#{$fa-css-prefix}-signal-3::after, .fa-duotone.#{$fa-css-prefix}-signal-3::after { content: "\f68e\f68e"; }
.fad.#{$fa-css-prefix}-signal-slash::after, .fa-duotone.#{$fa-css-prefix}-signal-slash::after { content: "\f695\f695"; }
.fad.#{$fa-css-prefix}-signal-stream::after, .fa-duotone.#{$fa-css-prefix}-signal-stream::after { content: "\f8dd\f8dd"; }
.fad.#{$fa-css-prefix}-signal-stream-slash::after, .fa-duotone.#{$fa-css-prefix}-signal-stream-slash::after { content: "\e250\e250"; }
.fad.#{$fa-css-prefix}-signal-strong::after, .fa-duotone.#{$fa-css-prefix}-signal-strong::after { content: "\f68f\f68f"; }
.fad.#{$fa-css-prefix}-signal-4::after, .fa-duotone.#{$fa-css-prefix}-signal-4::after { content: "\f68f\f68f"; }
.fad.#{$fa-css-prefix}-signal-weak::after, .fa-duotone.#{$fa-css-prefix}-signal-weak::after { content: "\f68c\f68c"; }
.fad.#{$fa-css-prefix}-signal-1::after, .fa-duotone.#{$fa-css-prefix}-signal-1::after { content: "\f68c\f68c"; }
.fad.#{$fa-css-prefix}-signature::after, .fa-duotone.#{$fa-css-prefix}-signature::after { content: "\f5b7\f5b7"; }
.fad.#{$fa-css-prefix}-signature-lock::after, .fa-duotone.#{$fa-css-prefix}-signature-lock::after { content: "\e3ca\e3ca"; }
.fad.#{$fa-css-prefix}-signature-slash::after, .fa-duotone.#{$fa-css-prefix}-signature-slash::after { content: "\e3cb\e3cb"; }
.fad.#{$fa-css-prefix}-signs-post::after, .fa-duotone.#{$fa-css-prefix}-signs-post::after { content: "\f277\f277"; }
.fad.#{$fa-css-prefix}-map-signs::after, .fa-duotone.#{$fa-css-prefix}-map-signs::after { content: "\f277\f277"; }
.fad.#{$fa-css-prefix}-sim-card::after, .fa-duotone.#{$fa-css-prefix}-sim-card::after { content: "\f7c4\f7c4"; }
.fad.#{$fa-css-prefix}-sim-cards::after, .fa-duotone.#{$fa-css-prefix}-sim-cards::after { content: "\e251\e251"; }
.fad.#{$fa-css-prefix}-sink::after, .fa-duotone.#{$fa-css-prefix}-sink::after { content: "\e06d\e06d"; }
.fad.#{$fa-css-prefix}-siren::after, .fa-duotone.#{$fa-css-prefix}-siren::after { content: "\e02d\e02d"; }
.fad.#{$fa-css-prefix}-siren-on::after, .fa-duotone.#{$fa-css-prefix}-siren-on::after { content: "\e02e\e02e"; }
.fad.#{$fa-css-prefix}-sitemap::after, .fa-duotone.#{$fa-css-prefix}-sitemap::after { content: "\f0e8\f0e8"; }
.fad.#{$fa-css-prefix}-skeleton::after, .fa-duotone.#{$fa-css-prefix}-skeleton::after { content: "\f620\f620"; }
.fad.#{$fa-css-prefix}-ski-boot::after, .fa-duotone.#{$fa-css-prefix}-ski-boot::after { content: "\e3cc\e3cc"; }
.fad.#{$fa-css-prefix}-ski-boot-ski::after, .fa-duotone.#{$fa-css-prefix}-ski-boot-ski::after { content: "\e3cd\e3cd"; }
.fad.#{$fa-css-prefix}-skull::after, .fa-duotone.#{$fa-css-prefix}-skull::after { content: "\f54c\f54c"; }
.fad.#{$fa-css-prefix}-skull-cow::after, .fa-duotone.#{$fa-css-prefix}-skull-cow::after { content: "\f8de\f8de"; }
.fad.#{$fa-css-prefix}-skull-crossbones::after, .fa-duotone.#{$fa-css-prefix}-skull-crossbones::after { content: "\f714\f714"; }
.fad.#{$fa-css-prefix}-slash::after, .fa-duotone.#{$fa-css-prefix}-slash::after { content: "\f715\f715"; }
.fad.#{$fa-css-prefix}-slash-back::after, .fa-duotone.#{$fa-css-prefix}-slash-back::after { content: "\5c\5c"; }
.fad.#{$fa-css-prefix}-slash-forward::after, .fa-duotone.#{$fa-css-prefix}-slash-forward::after { content: "\2f\2f"; }
.fad.#{$fa-css-prefix}-sleigh::after, .fa-duotone.#{$fa-css-prefix}-sleigh::after { content: "\f7cc\f7cc"; }
.fad.#{$fa-css-prefix}-slider::after, .fa-duotone.#{$fa-css-prefix}-slider::after { content: "\e252\e252"; }
.fad.#{$fa-css-prefix}-sliders::after, .fa-duotone.#{$fa-css-prefix}-sliders::after { content: "\f1de\f1de"; }
.fad.#{$fa-css-prefix}-sliders-h::after, .fa-duotone.#{$fa-css-prefix}-sliders-h::after { content: "\f1de\f1de"; }
.fad.#{$fa-css-prefix}-sliders-simple::after, .fa-duotone.#{$fa-css-prefix}-sliders-simple::after { content: "\e253\e253"; }
.fad.#{$fa-css-prefix}-sliders-up::after, .fa-duotone.#{$fa-css-prefix}-sliders-up::after { content: "\f3f1\f3f1"; }
.fad.#{$fa-css-prefix}-sliders-v::after, .fa-duotone.#{$fa-css-prefix}-sliders-v::after { content: "\f3f1\f3f1"; }
.fad.#{$fa-css-prefix}-slot-machine::after, .fa-duotone.#{$fa-css-prefix}-slot-machine::after { content: "\e3ce\e3ce"; }
.fad.#{$fa-css-prefix}-smog::after, .fa-duotone.#{$fa-css-prefix}-smog::after { content: "\f75f\f75f"; }
.fad.#{$fa-css-prefix}-smoke::after, .fa-duotone.#{$fa-css-prefix}-smoke::after { content: "\f760\f760"; }
.fad.#{$fa-css-prefix}-smoking::after, .fa-duotone.#{$fa-css-prefix}-smoking::after { content: "\f48d\f48d"; }
.fad.#{$fa-css-prefix}-snake::after, .fa-duotone.#{$fa-css-prefix}-snake::after { content: "\f716\f716"; }
.fad.#{$fa-css-prefix}-snooze::after, .fa-duotone.#{$fa-css-prefix}-snooze::after { content: "\f880\f880"; }
.fad.#{$fa-css-prefix}-zzz::after, .fa-duotone.#{$fa-css-prefix}-zzz::after { content: "\f880\f880"; }
.fad.#{$fa-css-prefix}-snow-blowing::after, .fa-duotone.#{$fa-css-prefix}-snow-blowing::after { content: "\f761\f761"; }
.fad.#{$fa-css-prefix}-snowflake::after, .fa-duotone.#{$fa-css-prefix}-snowflake::after { content: "\f2dc\f2dc"; }
.fad.#{$fa-css-prefix}-snowflakes::after, .fa-duotone.#{$fa-css-prefix}-snowflakes::after { content: "\f7cf\f7cf"; }
.fad.#{$fa-css-prefix}-snowman::after, .fa-duotone.#{$fa-css-prefix}-snowman::after { content: "\f7d0\f7d0"; }
.fad.#{$fa-css-prefix}-snowman-head::after, .fa-duotone.#{$fa-css-prefix}-snowman-head::after { content: "\f79b\f79b"; }
.fad.#{$fa-css-prefix}-frosty-head::after, .fa-duotone.#{$fa-css-prefix}-frosty-head::after { content: "\f79b\f79b"; }
.fad.#{$fa-css-prefix}-snowplow::after, .fa-duotone.#{$fa-css-prefix}-snowplow::after { content: "\f7d2\f7d2"; }
.fad.#{$fa-css-prefix}-soap::after, .fa-duotone.#{$fa-css-prefix}-soap::after { content: "\e06e\e06e"; }
.fad.#{$fa-css-prefix}-socks::after, .fa-duotone.#{$fa-css-prefix}-socks::after { content: "\f696\f696"; }
.fad.#{$fa-css-prefix}-soft-serve::after, .fa-duotone.#{$fa-css-prefix}-soft-serve::after { content: "\e400\e400"; }
.fad.#{$fa-css-prefix}-creemee::after, .fa-duotone.#{$fa-css-prefix}-creemee::after { content: "\e400\e400"; }
.fad.#{$fa-css-prefix}-solar-panel::after, .fa-duotone.#{$fa-css-prefix}-solar-panel::after { content: "\f5ba\f5ba"; }
.fad.#{$fa-css-prefix}-solar-system::after, .fa-duotone.#{$fa-css-prefix}-solar-system::after { content: "\e02f\e02f"; }
.fad.#{$fa-css-prefix}-sort::after, .fa-duotone.#{$fa-css-prefix}-sort::after { content: "\f0dc\f0dc"; }
.fad.#{$fa-css-prefix}-unsorted::after, .fa-duotone.#{$fa-css-prefix}-unsorted::after { content: "\f0dc\f0dc"; }
.fad.#{$fa-css-prefix}-sort-down::after, .fa-duotone.#{$fa-css-prefix}-sort-down::after { content: "\f0dd\f0dd"; }
.fad.#{$fa-css-prefix}-sort-desc::after, .fa-duotone.#{$fa-css-prefix}-sort-desc::after { content: "\f0dd\f0dd"; }
.fad.#{$fa-css-prefix}-sort-up::after, .fa-duotone.#{$fa-css-prefix}-sort-up::after { content: "\f0de\f0de"; }
.fad.#{$fa-css-prefix}-sort-asc::after, .fa-duotone.#{$fa-css-prefix}-sort-asc::after { content: "\f0de\f0de"; }
.fad.#{$fa-css-prefix}-spa::after, .fa-duotone.#{$fa-css-prefix}-spa::after { content: "\f5bb\f5bb"; }
.fad.#{$fa-css-prefix}-space-station-moon::after, .fa-duotone.#{$fa-css-prefix}-space-station-moon::after { content: "\e033\e033"; }
.fad.#{$fa-css-prefix}-space-station-moon-construction::after, .fa-duotone.#{$fa-css-prefix}-space-station-moon-construction::after { content: "\e034\e034"; }
.fad.#{$fa-css-prefix}-space-station-moon-alt::after, .fa-duotone.#{$fa-css-prefix}-space-station-moon-alt::after { content: "\e034\e034"; }
.fad.#{$fa-css-prefix}-spade::after, .fa-duotone.#{$fa-css-prefix}-spade::after { content: "\f2f4\f2f4"; }
.fad.#{$fa-css-prefix}-spaghetti-monster-flying::after, .fa-duotone.#{$fa-css-prefix}-spaghetti-monster-flying::after { content: "\f67b\f67b"; }
.fad.#{$fa-css-prefix}-pastafarianism::after, .fa-duotone.#{$fa-css-prefix}-pastafarianism::after { content: "\f67b\f67b"; }
.fad.#{$fa-css-prefix}-sparkles::after, .fa-duotone.#{$fa-css-prefix}-sparkles::after { content: "\f890\f890"; }
.fad.#{$fa-css-prefix}-speaker::after, .fa-duotone.#{$fa-css-prefix}-speaker::after { content: "\f8df\f8df"; }
.fad.#{$fa-css-prefix}-speakers::after, .fa-duotone.#{$fa-css-prefix}-speakers::after { content: "\f8e0\f8e0"; }
.fad.#{$fa-css-prefix}-spell-check::after, .fa-duotone.#{$fa-css-prefix}-spell-check::after { content: "\f891\f891"; }
.fad.#{$fa-css-prefix}-spider::after, .fa-duotone.#{$fa-css-prefix}-spider::after { content: "\f717\f717"; }
.fad.#{$fa-css-prefix}-spider-black-widow::after, .fa-duotone.#{$fa-css-prefix}-spider-black-widow::after { content: "\f718\f718"; }
.fad.#{$fa-css-prefix}-spider-web::after, .fa-duotone.#{$fa-css-prefix}-spider-web::after { content: "\f719\f719"; }
.fad.#{$fa-css-prefix}-spinner::after, .fa-duotone.#{$fa-css-prefix}-spinner::after { content: "\f110\f110"; }
.fad.#{$fa-css-prefix}-spinner-third::after, .fa-duotone.#{$fa-css-prefix}-spinner-third::after { content: "\f3f4\f3f4"; }
.fad.#{$fa-css-prefix}-split::after, .fa-duotone.#{$fa-css-prefix}-split::after { content: "\e254\e254"; }
.fad.#{$fa-css-prefix}-splotch::after, .fa-duotone.#{$fa-css-prefix}-splotch::after { content: "\f5bc\f5bc"; }
.fad.#{$fa-css-prefix}-spoon::after, .fa-duotone.#{$fa-css-prefix}-spoon::after { content: "\f2e5\f2e5"; }
.fad.#{$fa-css-prefix}-utensil-spoon::after, .fa-duotone.#{$fa-css-prefix}-utensil-spoon::after { content: "\f2e5\f2e5"; }
.fad.#{$fa-css-prefix}-sportsball::after, .fa-duotone.#{$fa-css-prefix}-sportsball::after { content: "\e44b\e44b"; }
.fad.#{$fa-css-prefix}-spray-can::after, .fa-duotone.#{$fa-css-prefix}-spray-can::after { content: "\f5bd\f5bd"; }
.fad.#{$fa-css-prefix}-spray-can-sparkles::after, .fa-duotone.#{$fa-css-prefix}-spray-can-sparkles::after { content: "\f5d0\f5d0"; }
.fad.#{$fa-css-prefix}-air-freshener::after, .fa-duotone.#{$fa-css-prefix}-air-freshener::after { content: "\f5d0\f5d0"; }
.fad.#{$fa-css-prefix}-sprinkler::after, .fa-duotone.#{$fa-css-prefix}-sprinkler::after { content: "\e035\e035"; }
.fad.#{$fa-css-prefix}-sprinkler-ceiling::after, .fa-duotone.#{$fa-css-prefix}-sprinkler-ceiling::after { content: "\e44c\e44c"; }
.fad.#{$fa-css-prefix}-square::after, .fa-duotone.#{$fa-css-prefix}-square::after { content: "\f0c8\f0c8"; }
.fad.#{$fa-css-prefix}-square-0::after, .fa-duotone.#{$fa-css-prefix}-square-0::after { content: "\e255\e255"; }
.fad.#{$fa-css-prefix}-square-1::after, .fa-duotone.#{$fa-css-prefix}-square-1::after { content: "\e256\e256"; }
.fad.#{$fa-css-prefix}-square-2::after, .fa-duotone.#{$fa-css-prefix}-square-2::after { content: "\e257\e257"; }
.fad.#{$fa-css-prefix}-square-3::after, .fa-duotone.#{$fa-css-prefix}-square-3::after { content: "\e258\e258"; }
.fad.#{$fa-css-prefix}-square-4::after, .fa-duotone.#{$fa-css-prefix}-square-4::after { content: "\e259\e259"; }
.fad.#{$fa-css-prefix}-square-5::after, .fa-duotone.#{$fa-css-prefix}-square-5::after { content: "\e25a\e25a"; }
.fad.#{$fa-css-prefix}-square-6::after, .fa-duotone.#{$fa-css-prefix}-square-6::after { content: "\e25b\e25b"; }
.fad.#{$fa-css-prefix}-square-7::after, .fa-duotone.#{$fa-css-prefix}-square-7::after { content: "\e25c\e25c"; }
.fad.#{$fa-css-prefix}-square-8::after, .fa-duotone.#{$fa-css-prefix}-square-8::after { content: "\e25d\e25d"; }
.fad.#{$fa-css-prefix}-square-9::after, .fa-duotone.#{$fa-css-prefix}-square-9::after { content: "\e25e\e25e"; }
.fad.#{$fa-css-prefix}-square-a::after, .fa-duotone.#{$fa-css-prefix}-square-a::after { content: "\e25f\e25f"; }
.fad.#{$fa-css-prefix}-square-a-lock::after, .fa-duotone.#{$fa-css-prefix}-square-a-lock::after { content: "\e44d\e44d"; }
.fad.#{$fa-css-prefix}-square-ampersand::after, .fa-duotone.#{$fa-css-prefix}-square-ampersand::after { content: "\e260\e260"; }
.fad.#{$fa-css-prefix}-square-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-square-arrow-down::after { content: "\f339\f339"; }
.fad.#{$fa-css-prefix}-arrow-square-down::after, .fa-duotone.#{$fa-css-prefix}-arrow-square-down::after { content: "\f339\f339"; }
.fad.#{$fa-css-prefix}-square-arrow-down-left::after, .fa-duotone.#{$fa-css-prefix}-square-arrow-down-left::after { content: "\e261\e261"; }
.fad.#{$fa-css-prefix}-square-arrow-down-right::after, .fa-duotone.#{$fa-css-prefix}-square-arrow-down-right::after { content: "\e262\e262"; }
.fad.#{$fa-css-prefix}-square-arrow-left::after, .fa-duotone.#{$fa-css-prefix}-square-arrow-left::after { content: "\f33a\f33a"; }
.fad.#{$fa-css-prefix}-arrow-square-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-square-left::after { content: "\f33a\f33a"; }
.fad.#{$fa-css-prefix}-square-arrow-right::after, .fa-duotone.#{$fa-css-prefix}-square-arrow-right::after { content: "\f33b\f33b"; }
.fad.#{$fa-css-prefix}-arrow-square-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-square-right::after { content: "\f33b\f33b"; }
.fad.#{$fa-css-prefix}-square-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-square-arrow-up::after { content: "\f33c\f33c"; }
.fad.#{$fa-css-prefix}-arrow-square-up::after, .fa-duotone.#{$fa-css-prefix}-arrow-square-up::after { content: "\f33c\f33c"; }
.fad.#{$fa-css-prefix}-square-arrow-up-left::after, .fa-duotone.#{$fa-css-prefix}-square-arrow-up-left::after { content: "\e263\e263"; }
.fad.#{$fa-css-prefix}-square-arrow-up-right::after, .fa-duotone.#{$fa-css-prefix}-square-arrow-up-right::after { content: "\f14c\f14c"; }
.fad.#{$fa-css-prefix}-external-link-square::after, .fa-duotone.#{$fa-css-prefix}-external-link-square::after { content: "\f14c\f14c"; }
.fad.#{$fa-css-prefix}-square-b::after, .fa-duotone.#{$fa-css-prefix}-square-b::after { content: "\e264\e264"; }
.fad.#{$fa-css-prefix}-square-bolt::after, .fa-duotone.#{$fa-css-prefix}-square-bolt::after { content: "\e265\e265"; }
.fad.#{$fa-css-prefix}-square-c::after, .fa-duotone.#{$fa-css-prefix}-square-c::after { content: "\e266\e266"; }
.fad.#{$fa-css-prefix}-square-caret-down::after, .fa-duotone.#{$fa-css-prefix}-square-caret-down::after { content: "\f150\f150"; }
.fad.#{$fa-css-prefix}-caret-square-down::after, .fa-duotone.#{$fa-css-prefix}-caret-square-down::after { content: "\f150\f150"; }
.fad.#{$fa-css-prefix}-square-caret-left::after, .fa-duotone.#{$fa-css-prefix}-square-caret-left::after { content: "\f191\f191"; }
.fad.#{$fa-css-prefix}-caret-square-left::after, .fa-duotone.#{$fa-css-prefix}-caret-square-left::after { content: "\f191\f191"; }
.fad.#{$fa-css-prefix}-square-caret-right::after, .fa-duotone.#{$fa-css-prefix}-square-caret-right::after { content: "\f152\f152"; }
.fad.#{$fa-css-prefix}-caret-square-right::after, .fa-duotone.#{$fa-css-prefix}-caret-square-right::after { content: "\f152\f152"; }
.fad.#{$fa-css-prefix}-square-caret-up::after, .fa-duotone.#{$fa-css-prefix}-square-caret-up::after { content: "\f151\f151"; }
.fad.#{$fa-css-prefix}-caret-square-up::after, .fa-duotone.#{$fa-css-prefix}-caret-square-up::after { content: "\f151\f151"; }
.fad.#{$fa-css-prefix}-square-check::after, .fa-duotone.#{$fa-css-prefix}-square-check::after { content: "\f14a\f14a"; }
.fad.#{$fa-css-prefix}-check-square::after, .fa-duotone.#{$fa-css-prefix}-check-square::after { content: "\f14a\f14a"; }
.fad.#{$fa-css-prefix}-square-chevron-down::after, .fa-duotone.#{$fa-css-prefix}-square-chevron-down::after { content: "\f329\f329"; }
.fad.#{$fa-css-prefix}-chevron-square-down::after, .fa-duotone.#{$fa-css-prefix}-chevron-square-down::after { content: "\f329\f329"; }
.fad.#{$fa-css-prefix}-square-chevron-left::after, .fa-duotone.#{$fa-css-prefix}-square-chevron-left::after { content: "\f32a\f32a"; }
.fad.#{$fa-css-prefix}-chevron-square-left::after, .fa-duotone.#{$fa-css-prefix}-chevron-square-left::after { content: "\f32a\f32a"; }
.fad.#{$fa-css-prefix}-square-chevron-right::after, .fa-duotone.#{$fa-css-prefix}-square-chevron-right::after { content: "\f32b\f32b"; }
.fad.#{$fa-css-prefix}-chevron-square-right::after, .fa-duotone.#{$fa-css-prefix}-chevron-square-right::after { content: "\f32b\f32b"; }
.fad.#{$fa-css-prefix}-square-chevron-up::after, .fa-duotone.#{$fa-css-prefix}-square-chevron-up::after { content: "\f32c\f32c"; }
.fad.#{$fa-css-prefix}-chevron-square-up::after, .fa-duotone.#{$fa-css-prefix}-chevron-square-up::after { content: "\f32c\f32c"; }
.fad.#{$fa-css-prefix}-square-code::after, .fa-duotone.#{$fa-css-prefix}-square-code::after { content: "\e267\e267"; }
.fad.#{$fa-css-prefix}-square-d::after, .fa-duotone.#{$fa-css-prefix}-square-d::after { content: "\e268\e268"; }
.fad.#{$fa-css-prefix}-square-dashed::after, .fa-duotone.#{$fa-css-prefix}-square-dashed::after { content: "\e269\e269"; }
.fad.#{$fa-css-prefix}-square-divide::after, .fa-duotone.#{$fa-css-prefix}-square-divide::after { content: "\e26a\e26a"; }
.fad.#{$fa-css-prefix}-square-dollar::after, .fa-duotone.#{$fa-css-prefix}-square-dollar::after { content: "\f2e9\f2e9"; }
.fad.#{$fa-css-prefix}-dollar-square::after, .fa-duotone.#{$fa-css-prefix}-dollar-square::after { content: "\f2e9\f2e9"; }
.fad.#{$fa-css-prefix}-usd-square::after, .fa-duotone.#{$fa-css-prefix}-usd-square::after { content: "\f2e9\f2e9"; }
.fad.#{$fa-css-prefix}-square-down::after, .fa-duotone.#{$fa-css-prefix}-square-down::after { content: "\f350\f350"; }
.fad.#{$fa-css-prefix}-arrow-alt-square-down::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-square-down::after { content: "\f350\f350"; }
.fad.#{$fa-css-prefix}-square-down-left::after, .fa-duotone.#{$fa-css-prefix}-square-down-left::after { content: "\e26b\e26b"; }
.fad.#{$fa-css-prefix}-square-down-right::after, .fa-duotone.#{$fa-css-prefix}-square-down-right::after { content: "\e26c\e26c"; }
.fad.#{$fa-css-prefix}-square-e::after, .fa-duotone.#{$fa-css-prefix}-square-e::after { content: "\e26d\e26d"; }
.fad.#{$fa-css-prefix}-square-ellipsis::after, .fa-duotone.#{$fa-css-prefix}-square-ellipsis::after { content: "\e26e\e26e"; }
.fad.#{$fa-css-prefix}-square-ellipsis-vertical::after, .fa-duotone.#{$fa-css-prefix}-square-ellipsis-vertical::after { content: "\e26f\e26f"; }
.fad.#{$fa-css-prefix}-square-envelope::after, .fa-duotone.#{$fa-css-prefix}-square-envelope::after { content: "\f199\f199"; }
.fad.#{$fa-css-prefix}-envelope-square::after, .fa-duotone.#{$fa-css-prefix}-envelope-square::after { content: "\f199\f199"; }
.fad.#{$fa-css-prefix}-square-exclamation::after, .fa-duotone.#{$fa-css-prefix}-square-exclamation::after { content: "\f321\f321"; }
.fad.#{$fa-css-prefix}-exclamation-square::after, .fa-duotone.#{$fa-css-prefix}-exclamation-square::after { content: "\f321\f321"; }
.fad.#{$fa-css-prefix}-square-f::after, .fa-duotone.#{$fa-css-prefix}-square-f::after { content: "\e270\e270"; }
.fad.#{$fa-css-prefix}-square-fragile::after, .fa-duotone.#{$fa-css-prefix}-square-fragile::after { content: "\f49b\f49b"; }
.fad.#{$fa-css-prefix}-box-fragile::after, .fa-duotone.#{$fa-css-prefix}-box-fragile::after { content: "\f49b\f49b"; }
.fad.#{$fa-css-prefix}-square-wine-glass-crack::after, .fa-duotone.#{$fa-css-prefix}-square-wine-glass-crack::after { content: "\f49b\f49b"; }
.fad.#{$fa-css-prefix}-square-full::after, .fa-duotone.#{$fa-css-prefix}-square-full::after { content: "\f45c\f45c"; }
.fad.#{$fa-css-prefix}-square-g::after, .fa-duotone.#{$fa-css-prefix}-square-g::after { content: "\e271\e271"; }
.fad.#{$fa-css-prefix}-square-h::after, .fa-duotone.#{$fa-css-prefix}-square-h::after { content: "\f0fd\f0fd"; }
.fad.#{$fa-css-prefix}-h-square::after, .fa-duotone.#{$fa-css-prefix}-h-square::after { content: "\f0fd\f0fd"; }
.fad.#{$fa-css-prefix}-square-heart::after, .fa-duotone.#{$fa-css-prefix}-square-heart::after { content: "\f4c8\f4c8"; }
.fad.#{$fa-css-prefix}-heart-square::after, .fa-duotone.#{$fa-css-prefix}-heart-square::after { content: "\f4c8\f4c8"; }
.fad.#{$fa-css-prefix}-square-i::after, .fa-duotone.#{$fa-css-prefix}-square-i::after { content: "\e272\e272"; }
.fad.#{$fa-css-prefix}-square-info::after, .fa-duotone.#{$fa-css-prefix}-square-info::after { content: "\f30f\f30f"; }
.fad.#{$fa-css-prefix}-info-square::after, .fa-duotone.#{$fa-css-prefix}-info-square::after { content: "\f30f\f30f"; }
.fad.#{$fa-css-prefix}-square-j::after, .fa-duotone.#{$fa-css-prefix}-square-j::after { content: "\e273\e273"; }
.fad.#{$fa-css-prefix}-square-k::after, .fa-duotone.#{$fa-css-prefix}-square-k::after { content: "\e274\e274"; }
.fad.#{$fa-css-prefix}-square-kanban::after, .fa-duotone.#{$fa-css-prefix}-square-kanban::after { content: "\e488\e488"; }
.fad.#{$fa-css-prefix}-square-l::after, .fa-duotone.#{$fa-css-prefix}-square-l::after { content: "\e275\e275"; }
.fad.#{$fa-css-prefix}-square-left::after, .fa-duotone.#{$fa-css-prefix}-square-left::after { content: "\f351\f351"; }
.fad.#{$fa-css-prefix}-arrow-alt-square-left::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-square-left::after { content: "\f351\f351"; }
.fad.#{$fa-css-prefix}-square-list::after, .fa-duotone.#{$fa-css-prefix}-square-list::after { content: "\e489\e489"; }
.fad.#{$fa-css-prefix}-square-m::after, .fa-duotone.#{$fa-css-prefix}-square-m::after { content: "\e276\e276"; }
.fad.#{$fa-css-prefix}-square-minus::after, .fa-duotone.#{$fa-css-prefix}-square-minus::after { content: "\f146\f146"; }
.fad.#{$fa-css-prefix}-minus-square::after, .fa-duotone.#{$fa-css-prefix}-minus-square::after { content: "\f146\f146"; }
.fad.#{$fa-css-prefix}-square-n::after, .fa-duotone.#{$fa-css-prefix}-square-n::after { content: "\e277\e277"; }
.fad.#{$fa-css-prefix}-square-o::after, .fa-duotone.#{$fa-css-prefix}-square-o::after { content: "\e278\e278"; }
.fad.#{$fa-css-prefix}-square-p::after, .fa-duotone.#{$fa-css-prefix}-square-p::after { content: "\e279\e279"; }
.fad.#{$fa-css-prefix}-square-parking::after, .fa-duotone.#{$fa-css-prefix}-square-parking::after { content: "\f540\f540"; }
.fad.#{$fa-css-prefix}-parking::after, .fa-duotone.#{$fa-css-prefix}-parking::after { content: "\f540\f540"; }
.fad.#{$fa-css-prefix}-square-parking-slash::after, .fa-duotone.#{$fa-css-prefix}-square-parking-slash::after { content: "\f617\f617"; }
.fad.#{$fa-css-prefix}-parking-slash::after, .fa-duotone.#{$fa-css-prefix}-parking-slash::after { content: "\f617\f617"; }
.fad.#{$fa-css-prefix}-square-pen::after, .fa-duotone.#{$fa-css-prefix}-square-pen::after { content: "\f14b\f14b"; }
.fad.#{$fa-css-prefix}-pen-square::after, .fa-duotone.#{$fa-css-prefix}-pen-square::after { content: "\f14b\f14b"; }
.fad.#{$fa-css-prefix}-pencil-square::after, .fa-duotone.#{$fa-css-prefix}-pencil-square::after { content: "\f14b\f14b"; }
.fad.#{$fa-css-prefix}-square-phone::after, .fa-duotone.#{$fa-css-prefix}-square-phone::after { content: "\f098\f098"; }
.fad.#{$fa-css-prefix}-phone-square::after, .fa-duotone.#{$fa-css-prefix}-phone-square::after { content: "\f098\f098"; }
.fad.#{$fa-css-prefix}-square-phone-flip::after, .fa-duotone.#{$fa-css-prefix}-square-phone-flip::after { content: "\f87b\f87b"; }
.fad.#{$fa-css-prefix}-phone-square-alt::after, .fa-duotone.#{$fa-css-prefix}-phone-square-alt::after { content: "\f87b\f87b"; }
.fad.#{$fa-css-prefix}-square-phone-hangup::after, .fa-duotone.#{$fa-css-prefix}-square-phone-hangup::after { content: "\e27a\e27a"; }
.fad.#{$fa-css-prefix}-phone-square-down::after, .fa-duotone.#{$fa-css-prefix}-phone-square-down::after { content: "\e27a\e27a"; }
.fad.#{$fa-css-prefix}-square-plus::after, .fa-duotone.#{$fa-css-prefix}-square-plus::after { content: "\f0fe\f0fe"; }
.fad.#{$fa-css-prefix}-plus-square::after, .fa-duotone.#{$fa-css-prefix}-plus-square::after { content: "\f0fe\f0fe"; }
.fad.#{$fa-css-prefix}-square-poll-horizontal::after, .fa-duotone.#{$fa-css-prefix}-square-poll-horizontal::after { content: "\f682\f682"; }
.fad.#{$fa-css-prefix}-poll-h::after, .fa-duotone.#{$fa-css-prefix}-poll-h::after { content: "\f682\f682"; }
.fad.#{$fa-css-prefix}-square-poll-vertical::after, .fa-duotone.#{$fa-css-prefix}-square-poll-vertical::after { content: "\f681\f681"; }
.fad.#{$fa-css-prefix}-poll::after, .fa-duotone.#{$fa-css-prefix}-poll::after { content: "\f681\f681"; }
.fad.#{$fa-css-prefix}-square-q::after, .fa-duotone.#{$fa-css-prefix}-square-q::after { content: "\e27b\e27b"; }
.fad.#{$fa-css-prefix}-square-quarters::after, .fa-duotone.#{$fa-css-prefix}-square-quarters::after { content: "\e44e\e44e"; }
.fad.#{$fa-css-prefix}-square-question::after, .fa-duotone.#{$fa-css-prefix}-square-question::after { content: "\f2fd\f2fd"; }
.fad.#{$fa-css-prefix}-question-square::after, .fa-duotone.#{$fa-css-prefix}-question-square::after { content: "\f2fd\f2fd"; }
.fad.#{$fa-css-prefix}-square-quote::after, .fa-duotone.#{$fa-css-prefix}-square-quote::after { content: "\e329\e329"; }
.fad.#{$fa-css-prefix}-square-r::after, .fa-duotone.#{$fa-css-prefix}-square-r::after { content: "\e27c\e27c"; }
.fad.#{$fa-css-prefix}-square-right::after, .fa-duotone.#{$fa-css-prefix}-square-right::after { content: "\f352\f352"; }
.fad.#{$fa-css-prefix}-arrow-alt-square-right::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-square-right::after { content: "\f352\f352"; }
.fad.#{$fa-css-prefix}-square-ring::after, .fa-duotone.#{$fa-css-prefix}-square-ring::after { content: "\e44f\e44f"; }
.fad.#{$fa-css-prefix}-square-root::after, .fa-duotone.#{$fa-css-prefix}-square-root::after { content: "\f697\f697"; }
.fad.#{$fa-css-prefix}-square-root-variable::after, .fa-duotone.#{$fa-css-prefix}-square-root-variable::after { content: "\f698\f698"; }
.fad.#{$fa-css-prefix}-square-root-alt::after, .fa-duotone.#{$fa-css-prefix}-square-root-alt::after { content: "\f698\f698"; }
.fad.#{$fa-css-prefix}-square-rss::after, .fa-duotone.#{$fa-css-prefix}-square-rss::after { content: "\f143\f143"; }
.fad.#{$fa-css-prefix}-rss-square::after, .fa-duotone.#{$fa-css-prefix}-rss-square::after { content: "\f143\f143"; }
.fad.#{$fa-css-prefix}-square-s::after, .fa-duotone.#{$fa-css-prefix}-square-s::after { content: "\e27d\e27d"; }
.fad.#{$fa-css-prefix}-square-share-nodes::after, .fa-duotone.#{$fa-css-prefix}-square-share-nodes::after { content: "\f1e1\f1e1"; }
.fad.#{$fa-css-prefix}-share-alt-square::after, .fa-duotone.#{$fa-css-prefix}-share-alt-square::after { content: "\f1e1\f1e1"; }
.fad.#{$fa-css-prefix}-square-sliders::after, .fa-duotone.#{$fa-css-prefix}-square-sliders::after { content: "\f3f0\f3f0"; }
.fad.#{$fa-css-prefix}-sliders-h-square::after, .fa-duotone.#{$fa-css-prefix}-sliders-h-square::after { content: "\f3f0\f3f0"; }
.fad.#{$fa-css-prefix}-square-sliders-vertical::after, .fa-duotone.#{$fa-css-prefix}-square-sliders-vertical::after { content: "\f3f2\f3f2"; }
.fad.#{$fa-css-prefix}-sliders-v-square::after, .fa-duotone.#{$fa-css-prefix}-sliders-v-square::after { content: "\f3f2\f3f2"; }
.fad.#{$fa-css-prefix}-square-small::after, .fa-duotone.#{$fa-css-prefix}-square-small::after { content: "\e27e\e27e"; }
.fad.#{$fa-css-prefix}-square-star::after, .fa-duotone.#{$fa-css-prefix}-square-star::after { content: "\e27f\e27f"; }
.fad.#{$fa-css-prefix}-square-t::after, .fa-duotone.#{$fa-css-prefix}-square-t::after { content: "\e280\e280"; }
.fad.#{$fa-css-prefix}-square-terminal::after, .fa-duotone.#{$fa-css-prefix}-square-terminal::after { content: "\e32a\e32a"; }
.fad.#{$fa-css-prefix}-square-this-way-up::after, .fa-duotone.#{$fa-css-prefix}-square-this-way-up::after { content: "\f49f\f49f"; }
.fad.#{$fa-css-prefix}-box-up::after, .fa-duotone.#{$fa-css-prefix}-box-up::after { content: "\f49f\f49f"; }
.fad.#{$fa-css-prefix}-square-u::after, .fa-duotone.#{$fa-css-prefix}-square-u::after { content: "\e281\e281"; }
.fad.#{$fa-css-prefix}-square-up::after, .fa-duotone.#{$fa-css-prefix}-square-up::after { content: "\f353\f353"; }
.fad.#{$fa-css-prefix}-arrow-alt-square-up::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-square-up::after { content: "\f353\f353"; }
.fad.#{$fa-css-prefix}-square-up-left::after, .fa-duotone.#{$fa-css-prefix}-square-up-left::after { content: "\e282\e282"; }
.fad.#{$fa-css-prefix}-square-up-right::after, .fa-duotone.#{$fa-css-prefix}-square-up-right::after { content: "\f360\f360"; }
.fad.#{$fa-css-prefix}-external-link-square-alt::after, .fa-duotone.#{$fa-css-prefix}-external-link-square-alt::after { content: "\f360\f360"; }
.fad.#{$fa-css-prefix}-square-user::after, .fa-duotone.#{$fa-css-prefix}-square-user::after { content: "\e283\e283"; }
.fad.#{$fa-css-prefix}-square-v::after, .fa-duotone.#{$fa-css-prefix}-square-v::after { content: "\e284\e284"; }
.fad.#{$fa-css-prefix}-square-w::after, .fa-duotone.#{$fa-css-prefix}-square-w::after { content: "\e285\e285"; }
.fad.#{$fa-css-prefix}-square-x::after, .fa-duotone.#{$fa-css-prefix}-square-x::after { content: "\e286\e286"; }
.fad.#{$fa-css-prefix}-square-xmark::after, .fa-duotone.#{$fa-css-prefix}-square-xmark::after { content: "\f2d3\f2d3"; }
.fad.#{$fa-css-prefix}-times-square::after, .fa-duotone.#{$fa-css-prefix}-times-square::after { content: "\f2d3\f2d3"; }
.fad.#{$fa-css-prefix}-xmark-square::after, .fa-duotone.#{$fa-css-prefix}-xmark-square::after { content: "\f2d3\f2d3"; }
.fad.#{$fa-css-prefix}-square-y::after, .fa-duotone.#{$fa-css-prefix}-square-y::after { content: "\e287\e287"; }
.fad.#{$fa-css-prefix}-square-z::after, .fa-duotone.#{$fa-css-prefix}-square-z::after { content: "\e288\e288"; }
.fad.#{$fa-css-prefix}-squid::after, .fa-duotone.#{$fa-css-prefix}-squid::after { content: "\e450\e450"; }
.fad.#{$fa-css-prefix}-squirrel::after, .fa-duotone.#{$fa-css-prefix}-squirrel::after { content: "\f71a\f71a"; }
.fad.#{$fa-css-prefix}-staff::after, .fa-duotone.#{$fa-css-prefix}-staff::after { content: "\f71b\f71b"; }
.fad.#{$fa-css-prefix}-stairs::after, .fa-duotone.#{$fa-css-prefix}-stairs::after { content: "\e289\e289"; }
.fad.#{$fa-css-prefix}-stamp::after, .fa-duotone.#{$fa-css-prefix}-stamp::after { content: "\f5bf\f5bf"; }
.fad.#{$fa-css-prefix}-standard-definition::after, .fa-duotone.#{$fa-css-prefix}-standard-definition::after { content: "\e28a\e28a"; }
.fad.#{$fa-css-prefix}-rectangle-sd::after, .fa-duotone.#{$fa-css-prefix}-rectangle-sd::after { content: "\e28a\e28a"; }
.fad.#{$fa-css-prefix}-star::after, .fa-duotone.#{$fa-css-prefix}-star::after { content: "\f005\f005"; }
.fad.#{$fa-css-prefix}-star-and-crescent::after, .fa-duotone.#{$fa-css-prefix}-star-and-crescent::after { content: "\f699\f699"; }
.fad.#{$fa-css-prefix}-star-christmas::after, .fa-duotone.#{$fa-css-prefix}-star-christmas::after { content: "\f7d4\f7d4"; }
.fad.#{$fa-css-prefix}-star-exclamation::after, .fa-duotone.#{$fa-css-prefix}-star-exclamation::after { content: "\f2f3\f2f3"; }
.fad.#{$fa-css-prefix}-star-half::after, .fa-duotone.#{$fa-css-prefix}-star-half::after { content: "\f089\f089"; }
.fad.#{$fa-css-prefix}-star-half-stroke::after, .fa-duotone.#{$fa-css-prefix}-star-half-stroke::after { content: "\f5c0\f5c0"; }
.fad.#{$fa-css-prefix}-star-half-alt::after, .fa-duotone.#{$fa-css-prefix}-star-half-alt::after { content: "\f5c0\f5c0"; }
.fad.#{$fa-css-prefix}-star-of-david::after, .fa-duotone.#{$fa-css-prefix}-star-of-david::after { content: "\f69a\f69a"; }
.fad.#{$fa-css-prefix}-star-of-life::after, .fa-duotone.#{$fa-css-prefix}-star-of-life::after { content: "\f621\f621"; }
.fad.#{$fa-css-prefix}-star-sharp::after, .fa-duotone.#{$fa-css-prefix}-star-sharp::after { content: "\e28b\e28b"; }
.fad.#{$fa-css-prefix}-star-sharp-half::after, .fa-duotone.#{$fa-css-prefix}-star-sharp-half::after { content: "\e28c\e28c"; }
.fad.#{$fa-css-prefix}-star-sharp-half-stroke::after, .fa-duotone.#{$fa-css-prefix}-star-sharp-half-stroke::after { content: "\e28d\e28d"; }
.fad.#{$fa-css-prefix}-star-sharp-half-alt::after, .fa-duotone.#{$fa-css-prefix}-star-sharp-half-alt::after { content: "\e28d\e28d"; }
.fad.#{$fa-css-prefix}-star-shooting::after, .fa-duotone.#{$fa-css-prefix}-star-shooting::after { content: "\e036\e036"; }
.fad.#{$fa-css-prefix}-starfighter::after, .fa-duotone.#{$fa-css-prefix}-starfighter::after { content: "\e037\e037"; }
.fad.#{$fa-css-prefix}-starfighter-twin-ion-engine::after, .fa-duotone.#{$fa-css-prefix}-starfighter-twin-ion-engine::after { content: "\e038\e038"; }
.fad.#{$fa-css-prefix}-starfighter-alt::after, .fa-duotone.#{$fa-css-prefix}-starfighter-alt::after { content: "\e038\e038"; }
.fad.#{$fa-css-prefix}-starfighter-twin-ion-engine-advanced::after, .fa-duotone.#{$fa-css-prefix}-starfighter-twin-ion-engine-advanced::after { content: "\e28e\e28e"; }
.fad.#{$fa-css-prefix}-starfighter-alt-advanced::after, .fa-duotone.#{$fa-css-prefix}-starfighter-alt-advanced::after { content: "\e28e\e28e"; }
.fad.#{$fa-css-prefix}-stars::after, .fa-duotone.#{$fa-css-prefix}-stars::after { content: "\f762\f762"; }
.fad.#{$fa-css-prefix}-starship::after, .fa-duotone.#{$fa-css-prefix}-starship::after { content: "\e039\e039"; }
.fad.#{$fa-css-prefix}-starship-freighter::after, .fa-duotone.#{$fa-css-prefix}-starship-freighter::after { content: "\e03a\e03a"; }
.fad.#{$fa-css-prefix}-steak::after, .fa-duotone.#{$fa-css-prefix}-steak::after { content: "\f824\f824"; }
.fad.#{$fa-css-prefix}-steering-wheel::after, .fa-duotone.#{$fa-css-prefix}-steering-wheel::after { content: "\f622\f622"; }
.fad.#{$fa-css-prefix}-sterling-sign::after, .fa-duotone.#{$fa-css-prefix}-sterling-sign::after { content: "\f154\f154"; }
.fad.#{$fa-css-prefix}-gbp::after, .fa-duotone.#{$fa-css-prefix}-gbp::after { content: "\f154\f154"; }
.fad.#{$fa-css-prefix}-pound-sign::after, .fa-duotone.#{$fa-css-prefix}-pound-sign::after { content: "\f154\f154"; }
.fad.#{$fa-css-prefix}-stethoscope::after, .fa-duotone.#{$fa-css-prefix}-stethoscope::after { content: "\f0f1\f0f1"; }
.fad.#{$fa-css-prefix}-stocking::after, .fa-duotone.#{$fa-css-prefix}-stocking::after { content: "\f7d5\f7d5"; }
.fad.#{$fa-css-prefix}-stomach::after, .fa-duotone.#{$fa-css-prefix}-stomach::after { content: "\f623\f623"; }
.fad.#{$fa-css-prefix}-stop::after, .fa-duotone.#{$fa-css-prefix}-stop::after { content: "\f04d\f04d"; }
.fad.#{$fa-css-prefix}-stopwatch::after, .fa-duotone.#{$fa-css-prefix}-stopwatch::after { content: "\f2f2\f2f2"; }
.fad.#{$fa-css-prefix}-stopwatch-20::after, .fa-duotone.#{$fa-css-prefix}-stopwatch-20::after { content: "\e06f\e06f"; }
.fad.#{$fa-css-prefix}-store::after, .fa-duotone.#{$fa-css-prefix}-store::after { content: "\f54e\f54e"; }
.fad.#{$fa-css-prefix}-store-lock::after, .fa-duotone.#{$fa-css-prefix}-store-lock::after { content: "\e4a6\e4a6"; }
.fad.#{$fa-css-prefix}-store-slash::after, .fa-duotone.#{$fa-css-prefix}-store-slash::after { content: "\e071\e071"; }
.fad.#{$fa-css-prefix}-strawberry::after, .fa-duotone.#{$fa-css-prefix}-strawberry::after { content: "\e32b\e32b"; }
.fad.#{$fa-css-prefix}-street-view::after, .fa-duotone.#{$fa-css-prefix}-street-view::after { content: "\f21d\f21d"; }
.fad.#{$fa-css-prefix}-stretcher::after, .fa-duotone.#{$fa-css-prefix}-stretcher::after { content: "\f825\f825"; }
.fad.#{$fa-css-prefix}-strikethrough::after, .fa-duotone.#{$fa-css-prefix}-strikethrough::after { content: "\f0cc\f0cc"; }
.fad.#{$fa-css-prefix}-stroopwafel::after, .fa-duotone.#{$fa-css-prefix}-stroopwafel::after { content: "\f551\f551"; }
.fad.#{$fa-css-prefix}-subscript::after, .fa-duotone.#{$fa-css-prefix}-subscript::after { content: "\f12c\f12c"; }
.fad.#{$fa-css-prefix}-suitcase::after, .fa-duotone.#{$fa-css-prefix}-suitcase::after { content: "\f0f2\f0f2"; }
.fad.#{$fa-css-prefix}-suitcase-medical::after, .fa-duotone.#{$fa-css-prefix}-suitcase-medical::after { content: "\f0fa\f0fa"; }
.fad.#{$fa-css-prefix}-medkit::after, .fa-duotone.#{$fa-css-prefix}-medkit::after { content: "\f0fa\f0fa"; }
.fad.#{$fa-css-prefix}-suitcase-rolling::after, .fa-duotone.#{$fa-css-prefix}-suitcase-rolling::after { content: "\f5c1\f5c1"; }
.fad.#{$fa-css-prefix}-sun::after, .fa-duotone.#{$fa-css-prefix}-sun::after { content: "\f185\f185"; }
.fad.#{$fa-css-prefix}-sun-bright::after, .fa-duotone.#{$fa-css-prefix}-sun-bright::after { content: "\e28f\e28f"; }
.fad.#{$fa-css-prefix}-sun-alt::after, .fa-duotone.#{$fa-css-prefix}-sun-alt::after { content: "\e28f\e28f"; }
.fad.#{$fa-css-prefix}-sun-cloud::after, .fa-duotone.#{$fa-css-prefix}-sun-cloud::after { content: "\f763\f763"; }
.fad.#{$fa-css-prefix}-sun-dust::after, .fa-duotone.#{$fa-css-prefix}-sun-dust::after { content: "\f764\f764"; }
.fad.#{$fa-css-prefix}-sun-haze::after, .fa-duotone.#{$fa-css-prefix}-sun-haze::after { content: "\f765\f765"; }
.fad.#{$fa-css-prefix}-sunglasses::after, .fa-duotone.#{$fa-css-prefix}-sunglasses::after { content: "\f892\f892"; }
.fad.#{$fa-css-prefix}-sunrise::after, .fa-duotone.#{$fa-css-prefix}-sunrise::after { content: "\f766\f766"; }
.fad.#{$fa-css-prefix}-sunset::after, .fa-duotone.#{$fa-css-prefix}-sunset::after { content: "\f767\f767"; }
.fad.#{$fa-css-prefix}-superscript::after, .fa-duotone.#{$fa-css-prefix}-superscript::after { content: "\f12b\f12b"; }
.fad.#{$fa-css-prefix}-sushi::after, .fa-duotone.#{$fa-css-prefix}-sushi::after { content: "\e48a\e48a"; }
.fad.#{$fa-css-prefix}-nigiri::after, .fa-duotone.#{$fa-css-prefix}-nigiri::after { content: "\e48a\e48a"; }
.fad.#{$fa-css-prefix}-sushi-roll::after, .fa-duotone.#{$fa-css-prefix}-sushi-roll::after { content: "\e48b\e48b"; }
.fad.#{$fa-css-prefix}-maki-roll::after, .fa-duotone.#{$fa-css-prefix}-maki-roll::after { content: "\e48b\e48b"; }
.fad.#{$fa-css-prefix}-makizushi::after, .fa-duotone.#{$fa-css-prefix}-makizushi::after { content: "\e48b\e48b"; }
.fad.#{$fa-css-prefix}-swatchbook::after, .fa-duotone.#{$fa-css-prefix}-swatchbook::after { content: "\f5c3\f5c3"; }
.fad.#{$fa-css-prefix}-sword::after, .fa-duotone.#{$fa-css-prefix}-sword::after { content: "\f71c\f71c"; }
.fad.#{$fa-css-prefix}-sword-laser::after, .fa-duotone.#{$fa-css-prefix}-sword-laser::after { content: "\e03b\e03b"; }
.fad.#{$fa-css-prefix}-sword-laser-alt::after, .fa-duotone.#{$fa-css-prefix}-sword-laser-alt::after { content: "\e03c\e03c"; }
.fad.#{$fa-css-prefix}-swords::after, .fa-duotone.#{$fa-css-prefix}-swords::after { content: "\f71d\f71d"; }
.fad.#{$fa-css-prefix}-swords-laser::after, .fa-duotone.#{$fa-css-prefix}-swords-laser::after { content: "\e03d\e03d"; }
.fad.#{$fa-css-prefix}-symbols::after, .fa-duotone.#{$fa-css-prefix}-symbols::after { content: "\f86e\f86e"; }
.fad.#{$fa-css-prefix}-icons-alt::after, .fa-duotone.#{$fa-css-prefix}-icons-alt::after { content: "\f86e\f86e"; }
.fad.#{$fa-css-prefix}-synagogue::after, .fa-duotone.#{$fa-css-prefix}-synagogue::after { content: "\f69b\f69b"; }
.fad.#{$fa-css-prefix}-syringe::after, .fa-duotone.#{$fa-css-prefix}-syringe::after { content: "\f48e\f48e"; }
.fad.#{$fa-css-prefix}-t::after, .fa-duotone.#{$fa-css-prefix}-t::after { content: "\54\54"; }
.fad.#{$fa-css-prefix}-table::after, .fa-duotone.#{$fa-css-prefix}-table::after { content: "\f0ce\f0ce"; }
.fad.#{$fa-css-prefix}-table-cells::after, .fa-duotone.#{$fa-css-prefix}-table-cells::after { content: "\f00a\f00a"; }
.fad.#{$fa-css-prefix}-th::after, .fa-duotone.#{$fa-css-prefix}-th::after { content: "\f00a\f00a"; }
.fad.#{$fa-css-prefix}-table-cells-large::after, .fa-duotone.#{$fa-css-prefix}-table-cells-large::after { content: "\f009\f009"; }
.fad.#{$fa-css-prefix}-th-large::after, .fa-duotone.#{$fa-css-prefix}-th-large::after { content: "\f009\f009"; }
.fad.#{$fa-css-prefix}-table-columns::after, .fa-duotone.#{$fa-css-prefix}-table-columns::after { content: "\f0db\f0db"; }
.fad.#{$fa-css-prefix}-columns::after, .fa-duotone.#{$fa-css-prefix}-columns::after { content: "\f0db\f0db"; }
.fad.#{$fa-css-prefix}-table-layout::after, .fa-duotone.#{$fa-css-prefix}-table-layout::after { content: "\e290\e290"; }
.fad.#{$fa-css-prefix}-table-list::after, .fa-duotone.#{$fa-css-prefix}-table-list::after { content: "\f00b\f00b"; }
.fad.#{$fa-css-prefix}-th-list::after, .fa-duotone.#{$fa-css-prefix}-th-list::after { content: "\f00b\f00b"; }
.fad.#{$fa-css-prefix}-table-picnic::after, .fa-duotone.#{$fa-css-prefix}-table-picnic::after { content: "\e32d\e32d"; }
.fad.#{$fa-css-prefix}-table-pivot::after, .fa-duotone.#{$fa-css-prefix}-table-pivot::after { content: "\e291\e291"; }
.fad.#{$fa-css-prefix}-table-rows::after, .fa-duotone.#{$fa-css-prefix}-table-rows::after { content: "\e292\e292"; }
.fad.#{$fa-css-prefix}-rows::after, .fa-duotone.#{$fa-css-prefix}-rows::after { content: "\e292\e292"; }
.fad.#{$fa-css-prefix}-table-tennis-paddle-ball::after, .fa-duotone.#{$fa-css-prefix}-table-tennis-paddle-ball::after { content: "\f45d\f45d"; }
.fad.#{$fa-css-prefix}-ping-pong-paddle-ball::after, .fa-duotone.#{$fa-css-prefix}-ping-pong-paddle-ball::after { content: "\f45d\f45d"; }
.fad.#{$fa-css-prefix}-table-tennis::after, .fa-duotone.#{$fa-css-prefix}-table-tennis::after { content: "\f45d\f45d"; }
.fad.#{$fa-css-prefix}-table-tree::after, .fa-duotone.#{$fa-css-prefix}-table-tree::after { content: "\e293\e293"; }
.fad.#{$fa-css-prefix}-tablet::after, .fa-duotone.#{$fa-css-prefix}-tablet::after { content: "\f3fb\f3fb"; }
.fad.#{$fa-css-prefix}-tablet-android::after, .fa-duotone.#{$fa-css-prefix}-tablet-android::after { content: "\f3fb\f3fb"; }
.fad.#{$fa-css-prefix}-tablet-button::after, .fa-duotone.#{$fa-css-prefix}-tablet-button::after { content: "\f10a\f10a"; }
.fad.#{$fa-css-prefix}-tablet-rugged::after, .fa-duotone.#{$fa-css-prefix}-tablet-rugged::after { content: "\f48f\f48f"; }
.fad.#{$fa-css-prefix}-tablet-screen::after, .fa-duotone.#{$fa-css-prefix}-tablet-screen::after { content: "\f3fc\f3fc"; }
.fad.#{$fa-css-prefix}-tablet-android-alt::after, .fa-duotone.#{$fa-css-prefix}-tablet-android-alt::after { content: "\f3fc\f3fc"; }
.fad.#{$fa-css-prefix}-tablet-screen-button::after, .fa-duotone.#{$fa-css-prefix}-tablet-screen-button::after { content: "\f3fa\f3fa"; }
.fad.#{$fa-css-prefix}-tablet-alt::after, .fa-duotone.#{$fa-css-prefix}-tablet-alt::after { content: "\f3fa\f3fa"; }
.fad.#{$fa-css-prefix}-tablets::after, .fa-duotone.#{$fa-css-prefix}-tablets::after { content: "\f490\f490"; }
.fad.#{$fa-css-prefix}-tachograph-digital::after, .fa-duotone.#{$fa-css-prefix}-tachograph-digital::after { content: "\f566\f566"; }
.fad.#{$fa-css-prefix}-digital-tachograph::after, .fa-duotone.#{$fa-css-prefix}-digital-tachograph::after { content: "\f566\f566"; }
.fad.#{$fa-css-prefix}-taco::after, .fa-duotone.#{$fa-css-prefix}-taco::after { content: "\f826\f826"; }
.fad.#{$fa-css-prefix}-tag::after, .fa-duotone.#{$fa-css-prefix}-tag::after { content: "\f02b\f02b"; }
.fad.#{$fa-css-prefix}-tags::after, .fa-duotone.#{$fa-css-prefix}-tags::after { content: "\f02c\f02c"; }
.fad.#{$fa-css-prefix}-tally::after, .fa-duotone.#{$fa-css-prefix}-tally::after { content: "\f69c\f69c"; }
.fad.#{$fa-css-prefix}-tally-5::after, .fa-duotone.#{$fa-css-prefix}-tally-5::after { content: "\f69c\f69c"; }
.fad.#{$fa-css-prefix}-tally-1::after, .fa-duotone.#{$fa-css-prefix}-tally-1::after { content: "\e294\e294"; }
.fad.#{$fa-css-prefix}-tally-2::after, .fa-duotone.#{$fa-css-prefix}-tally-2::after { content: "\e295\e295"; }
.fad.#{$fa-css-prefix}-tally-3::after, .fa-duotone.#{$fa-css-prefix}-tally-3::after { content: "\e296\e296"; }
.fad.#{$fa-css-prefix}-tally-4::after, .fa-duotone.#{$fa-css-prefix}-tally-4::after { content: "\e297\e297"; }
.fad.#{$fa-css-prefix}-tamale::after, .fa-duotone.#{$fa-css-prefix}-tamale::after { content: "\e451\e451"; }
.fad.#{$fa-css-prefix}-tank-water::after, .fa-duotone.#{$fa-css-prefix}-tank-water::after { content: "\e452\e452"; }
.fad.#{$fa-css-prefix}-tape::after, .fa-duotone.#{$fa-css-prefix}-tape::after { content: "\f4db\f4db"; }
.fad.#{$fa-css-prefix}-taxi::after, .fa-duotone.#{$fa-css-prefix}-taxi::after { content: "\f1ba\f1ba"; }
.fad.#{$fa-css-prefix}-cab::after, .fa-duotone.#{$fa-css-prefix}-cab::after { content: "\f1ba\f1ba"; }
.fad.#{$fa-css-prefix}-taxi-bus::after, .fa-duotone.#{$fa-css-prefix}-taxi-bus::after { content: "\e298\e298"; }
.fad.#{$fa-css-prefix}-teddy-bear::after, .fa-duotone.#{$fa-css-prefix}-teddy-bear::after { content: "\e3cf\e3cf"; }
.fad.#{$fa-css-prefix}-teeth::after, .fa-duotone.#{$fa-css-prefix}-teeth::after { content: "\f62e\f62e"; }
.fad.#{$fa-css-prefix}-teeth-open::after, .fa-duotone.#{$fa-css-prefix}-teeth-open::after { content: "\f62f\f62f"; }
.fad.#{$fa-css-prefix}-telescope::after, .fa-duotone.#{$fa-css-prefix}-telescope::after { content: "\e03e\e03e"; }
.fad.#{$fa-css-prefix}-temperature-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-temperature-arrow-down::after { content: "\e03f\e03f"; }
.fad.#{$fa-css-prefix}-temperature-down::after, .fa-duotone.#{$fa-css-prefix}-temperature-down::after { content: "\e03f\e03f"; }
.fad.#{$fa-css-prefix}-temperature-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-temperature-arrow-up::after { content: "\e040\e040"; }
.fad.#{$fa-css-prefix}-temperature-up::after, .fa-duotone.#{$fa-css-prefix}-temperature-up::after { content: "\e040\e040"; }
.fad.#{$fa-css-prefix}-temperature-empty::after, .fa-duotone.#{$fa-css-prefix}-temperature-empty::after { content: "\f2cb\f2cb"; }
.fad.#{$fa-css-prefix}-temperature-0::after, .fa-duotone.#{$fa-css-prefix}-temperature-0::after { content: "\f2cb\f2cb"; }
.fad.#{$fa-css-prefix}-thermometer-0::after, .fa-duotone.#{$fa-css-prefix}-thermometer-0::after { content: "\f2cb\f2cb"; }
.fad.#{$fa-css-prefix}-thermometer-empty::after, .fa-duotone.#{$fa-css-prefix}-thermometer-empty::after { content: "\f2cb\f2cb"; }
.fad.#{$fa-css-prefix}-temperature-full::after, .fa-duotone.#{$fa-css-prefix}-temperature-full::after { content: "\f2c7\f2c7"; }
.fad.#{$fa-css-prefix}-temperature-4::after, .fa-duotone.#{$fa-css-prefix}-temperature-4::after { content: "\f2c7\f2c7"; }
.fad.#{$fa-css-prefix}-thermometer-4::after, .fa-duotone.#{$fa-css-prefix}-thermometer-4::after { content: "\f2c7\f2c7"; }
.fad.#{$fa-css-prefix}-thermometer-full::after, .fa-duotone.#{$fa-css-prefix}-thermometer-full::after { content: "\f2c7\f2c7"; }
.fad.#{$fa-css-prefix}-temperature-half::after, .fa-duotone.#{$fa-css-prefix}-temperature-half::after { content: "\f2c9\f2c9"; }
.fad.#{$fa-css-prefix}-temperature-2::after, .fa-duotone.#{$fa-css-prefix}-temperature-2::after { content: "\f2c9\f2c9"; }
.fad.#{$fa-css-prefix}-thermometer-2::after, .fa-duotone.#{$fa-css-prefix}-thermometer-2::after { content: "\f2c9\f2c9"; }
.fad.#{$fa-css-prefix}-thermometer-half::after, .fa-duotone.#{$fa-css-prefix}-thermometer-half::after { content: "\f2c9\f2c9"; }
.fad.#{$fa-css-prefix}-temperature-high::after, .fa-duotone.#{$fa-css-prefix}-temperature-high::after { content: "\f769\f769"; }
.fad.#{$fa-css-prefix}-temperature-list::after, .fa-duotone.#{$fa-css-prefix}-temperature-list::after { content: "\e299\e299"; }
.fad.#{$fa-css-prefix}-temperature-low::after, .fa-duotone.#{$fa-css-prefix}-temperature-low::after { content: "\f76b\f76b"; }
.fad.#{$fa-css-prefix}-temperature-quarter::after, .fa-duotone.#{$fa-css-prefix}-temperature-quarter::after { content: "\f2ca\f2ca"; }
.fad.#{$fa-css-prefix}-temperature-1::after, .fa-duotone.#{$fa-css-prefix}-temperature-1::after { content: "\f2ca\f2ca"; }
.fad.#{$fa-css-prefix}-thermometer-1::after, .fa-duotone.#{$fa-css-prefix}-thermometer-1::after { content: "\f2ca\f2ca"; }
.fad.#{$fa-css-prefix}-thermometer-quarter::after, .fa-duotone.#{$fa-css-prefix}-thermometer-quarter::after { content: "\f2ca\f2ca"; }
.fad.#{$fa-css-prefix}-temperature-snow::after, .fa-duotone.#{$fa-css-prefix}-temperature-snow::after { content: "\f768\f768"; }
.fad.#{$fa-css-prefix}-temperature-frigid::after, .fa-duotone.#{$fa-css-prefix}-temperature-frigid::after { content: "\f768\f768"; }
.fad.#{$fa-css-prefix}-temperature-sun::after, .fa-duotone.#{$fa-css-prefix}-temperature-sun::after { content: "\f76a\f76a"; }
.fad.#{$fa-css-prefix}-temperature-hot::after, .fa-duotone.#{$fa-css-prefix}-temperature-hot::after { content: "\f76a\f76a"; }
.fad.#{$fa-css-prefix}-temperature-three-quarters::after, .fa-duotone.#{$fa-css-prefix}-temperature-three-quarters::after { content: "\f2c8\f2c8"; }
.fad.#{$fa-css-prefix}-temperature-3::after, .fa-duotone.#{$fa-css-prefix}-temperature-3::after { content: "\f2c8\f2c8"; }
.fad.#{$fa-css-prefix}-thermometer-3::after, .fa-duotone.#{$fa-css-prefix}-thermometer-3::after { content: "\f2c8\f2c8"; }
.fad.#{$fa-css-prefix}-thermometer-three-quarters::after, .fa-duotone.#{$fa-css-prefix}-thermometer-three-quarters::after { content: "\f2c8\f2c8"; }
.fad.#{$fa-css-prefix}-tenge-sign::after, .fa-duotone.#{$fa-css-prefix}-tenge-sign::after { content: "\f7d7\f7d7"; }
.fad.#{$fa-css-prefix}-tenge::after, .fa-duotone.#{$fa-css-prefix}-tenge::after { content: "\f7d7\f7d7"; }
.fad.#{$fa-css-prefix}-tennis-ball::after, .fa-duotone.#{$fa-css-prefix}-tennis-ball::after { content: "\f45e\f45e"; }
.fad.#{$fa-css-prefix}-terminal::after, .fa-duotone.#{$fa-css-prefix}-terminal::after { content: "\f120\f120"; }
.fad.#{$fa-css-prefix}-text::after, .fa-duotone.#{$fa-css-prefix}-text::after { content: "\f893\f893"; }
.fad.#{$fa-css-prefix}-text-height::after, .fa-duotone.#{$fa-css-prefix}-text-height::after { content: "\f034\f034"; }
.fad.#{$fa-css-prefix}-text-size::after, .fa-duotone.#{$fa-css-prefix}-text-size::after { content: "\f894\f894"; }
.fad.#{$fa-css-prefix}-text-slash::after, .fa-duotone.#{$fa-css-prefix}-text-slash::after { content: "\f87d\f87d"; }
.fad.#{$fa-css-prefix}-remove-format::after, .fa-duotone.#{$fa-css-prefix}-remove-format::after { content: "\f87d\f87d"; }
.fad.#{$fa-css-prefix}-text-width::after, .fa-duotone.#{$fa-css-prefix}-text-width::after { content: "\f035\f035"; }
.fad.#{$fa-css-prefix}-thermometer::after, .fa-duotone.#{$fa-css-prefix}-thermometer::after { content: "\f491\f491"; }
.fad.#{$fa-css-prefix}-theta::after, .fa-duotone.#{$fa-css-prefix}-theta::after { content: "\f69e\f69e"; }
.fad.#{$fa-css-prefix}-thought-bubble::after, .fa-duotone.#{$fa-css-prefix}-thought-bubble::after { content: "\e32e\e32e"; }
.fad.#{$fa-css-prefix}-thumbs-down::after, .fa-duotone.#{$fa-css-prefix}-thumbs-down::after { content: "\f165\f165"; }
.fad.#{$fa-css-prefix}-thumbs-up::after, .fa-duotone.#{$fa-css-prefix}-thumbs-up::after { content: "\f164\f164"; }
.fad.#{$fa-css-prefix}-thumbtack::after, .fa-duotone.#{$fa-css-prefix}-thumbtack::after { content: "\f08d\f08d"; }
.fad.#{$fa-css-prefix}-thumb-tack::after, .fa-duotone.#{$fa-css-prefix}-thumb-tack::after { content: "\f08d\f08d"; }
.fad.#{$fa-css-prefix}-tick::after, .fa-duotone.#{$fa-css-prefix}-tick::after { content: "\e32f\e32f"; }
.fad.#{$fa-css-prefix}-ticket::after, .fa-duotone.#{$fa-css-prefix}-ticket::after { content: "\f145\f145"; }
.fad.#{$fa-css-prefix}-ticket-airline::after, .fa-duotone.#{$fa-css-prefix}-ticket-airline::after { content: "\e29a\e29a"; }
.fad.#{$fa-css-prefix}-ticket-simple::after, .fa-duotone.#{$fa-css-prefix}-ticket-simple::after { content: "\f3ff\f3ff"; }
.fad.#{$fa-css-prefix}-ticket-alt::after, .fa-duotone.#{$fa-css-prefix}-ticket-alt::after { content: "\f3ff\f3ff"; }
.fad.#{$fa-css-prefix}-tickets-airline::after, .fa-duotone.#{$fa-css-prefix}-tickets-airline::after { content: "\e29b\e29b"; }
.fad.#{$fa-css-prefix}-tilde::after, .fa-duotone.#{$fa-css-prefix}-tilde::after { content: "\7e\7e"; }
.fad.#{$fa-css-prefix}-timeline::after, .fa-duotone.#{$fa-css-prefix}-timeline::after { content: "\e29c\e29c"; }
.fad.#{$fa-css-prefix}-timeline-arrow::after, .fa-duotone.#{$fa-css-prefix}-timeline-arrow::after { content: "\e29d\e29d"; }
.fad.#{$fa-css-prefix}-timer::after, .fa-duotone.#{$fa-css-prefix}-timer::after { content: "\e29e\e29e"; }
.fad.#{$fa-css-prefix}-tire::after, .fa-duotone.#{$fa-css-prefix}-tire::after { content: "\f631\f631"; }
.fad.#{$fa-css-prefix}-tire-flat::after, .fa-duotone.#{$fa-css-prefix}-tire-flat::after { content: "\f632\f632"; }
.fad.#{$fa-css-prefix}-tire-pressure-warning::after, .fa-duotone.#{$fa-css-prefix}-tire-pressure-warning::after { content: "\f633\f633"; }
.fad.#{$fa-css-prefix}-tire-rugged::after, .fa-duotone.#{$fa-css-prefix}-tire-rugged::after { content: "\f634\f634"; }
.fad.#{$fa-css-prefix}-toggle-off::after, .fa-duotone.#{$fa-css-prefix}-toggle-off::after { content: "\f204\f204"; }
.fad.#{$fa-css-prefix}-toggle-on::after, .fa-duotone.#{$fa-css-prefix}-toggle-on::after { content: "\f205\f205"; }
.fad.#{$fa-css-prefix}-toilet::after, .fa-duotone.#{$fa-css-prefix}-toilet::after { content: "\f7d8\f7d8"; }
.fad.#{$fa-css-prefix}-toilet-paper::after, .fa-duotone.#{$fa-css-prefix}-toilet-paper::after { content: "\f71e\f71e"; }
.fad.#{$fa-css-prefix}-toilet-paper-blank::after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-blank::after { content: "\f71f\f71f"; }
.fad.#{$fa-css-prefix}-toilet-paper-alt::after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-alt::after { content: "\f71f\f71f"; }
.fad.#{$fa-css-prefix}-toilet-paper-blank-under::after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-blank-under::after { content: "\e29f\e29f"; }
.fad.#{$fa-css-prefix}-toilet-paper-reverse-alt::after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-reverse-alt::after { content: "\e29f\e29f"; }
.fad.#{$fa-css-prefix}-toilet-paper-slash::after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-slash::after { content: "\e072\e072"; }
.fad.#{$fa-css-prefix}-toilet-paper-under::after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-under::after { content: "\e2a0\e2a0"; }
.fad.#{$fa-css-prefix}-toilet-paper-reverse::after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-reverse::after { content: "\e2a0\e2a0"; }
.fad.#{$fa-css-prefix}-toilet-paper-under-slash::after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-under-slash::after { content: "\e2a1\e2a1"; }
.fad.#{$fa-css-prefix}-toilet-paper-reverse-slash::after, .fa-duotone.#{$fa-css-prefix}-toilet-paper-reverse-slash::after { content: "\e2a1\e2a1"; }
.fad.#{$fa-css-prefix}-tomato::after, .fa-duotone.#{$fa-css-prefix}-tomato::after { content: "\e330\e330"; }
.fad.#{$fa-css-prefix}-tombstone::after, .fa-duotone.#{$fa-css-prefix}-tombstone::after { content: "\f720\f720"; }
.fad.#{$fa-css-prefix}-tombstone-blank::after, .fa-duotone.#{$fa-css-prefix}-tombstone-blank::after { content: "\f721\f721"; }
.fad.#{$fa-css-prefix}-tombstone-alt::after, .fa-duotone.#{$fa-css-prefix}-tombstone-alt::after { content: "\f721\f721"; }
.fad.#{$fa-css-prefix}-toolbox::after, .fa-duotone.#{$fa-css-prefix}-toolbox::after { content: "\f552\f552"; }
.fad.#{$fa-css-prefix}-tooth::after, .fa-duotone.#{$fa-css-prefix}-tooth::after { content: "\f5c9\f5c9"; }
.fad.#{$fa-css-prefix}-toothbrush::after, .fa-duotone.#{$fa-css-prefix}-toothbrush::after { content: "\f635\f635"; }
.fad.#{$fa-css-prefix}-torii-gate::after, .fa-duotone.#{$fa-css-prefix}-torii-gate::after { content: "\f6a1\f6a1"; }
.fad.#{$fa-css-prefix}-tornado::after, .fa-duotone.#{$fa-css-prefix}-tornado::after { content: "\f76f\f76f"; }
.fad.#{$fa-css-prefix}-tower-broadcast::after, .fa-duotone.#{$fa-css-prefix}-tower-broadcast::after { content: "\f519\f519"; }
.fad.#{$fa-css-prefix}-broadcast-tower::after, .fa-duotone.#{$fa-css-prefix}-broadcast-tower::after { content: "\f519\f519"; }
.fad.#{$fa-css-prefix}-tower-control::after, .fa-duotone.#{$fa-css-prefix}-tower-control::after { content: "\e2a2\e2a2"; }
.fad.#{$fa-css-prefix}-tractor::after, .fa-duotone.#{$fa-css-prefix}-tractor::after { content: "\f722\f722"; }
.fad.#{$fa-css-prefix}-trademark::after, .fa-duotone.#{$fa-css-prefix}-trademark::after { content: "\f25c\f25c"; }
.fad.#{$fa-css-prefix}-traffic-cone::after, .fa-duotone.#{$fa-css-prefix}-traffic-cone::after { content: "\f636\f636"; }
.fad.#{$fa-css-prefix}-traffic-light::after, .fa-duotone.#{$fa-css-prefix}-traffic-light::after { content: "\f637\f637"; }
.fad.#{$fa-css-prefix}-traffic-light-go::after, .fa-duotone.#{$fa-css-prefix}-traffic-light-go::after { content: "\f638\f638"; }
.fad.#{$fa-css-prefix}-traffic-light-slow::after, .fa-duotone.#{$fa-css-prefix}-traffic-light-slow::after { content: "\f639\f639"; }
.fad.#{$fa-css-prefix}-traffic-light-stop::after, .fa-duotone.#{$fa-css-prefix}-traffic-light-stop::after { content: "\f63a\f63a"; }
.fad.#{$fa-css-prefix}-trailer::after, .fa-duotone.#{$fa-css-prefix}-trailer::after { content: "\e041\e041"; }
.fad.#{$fa-css-prefix}-train::after, .fa-duotone.#{$fa-css-prefix}-train::after { content: "\f238\f238"; }
.fad.#{$fa-css-prefix}-train-subway::after, .fa-duotone.#{$fa-css-prefix}-train-subway::after { content: "\f239\f239"; }
.fad.#{$fa-css-prefix}-subway::after, .fa-duotone.#{$fa-css-prefix}-subway::after { content: "\f239\f239"; }
.fad.#{$fa-css-prefix}-train-subway-tunnel::after, .fa-duotone.#{$fa-css-prefix}-train-subway-tunnel::after { content: "\e2a3\e2a3"; }
.fad.#{$fa-css-prefix}-subway-tunnel::after, .fa-duotone.#{$fa-css-prefix}-subway-tunnel::after { content: "\e2a3\e2a3"; }
.fad.#{$fa-css-prefix}-train-track::after, .fa-duotone.#{$fa-css-prefix}-train-track::after { content: "\e453\e453"; }
.fad.#{$fa-css-prefix}-train-tram::after, .fa-duotone.#{$fa-css-prefix}-train-tram::after { content: "\f7da\f7da"; }
.fad.#{$fa-css-prefix}-tram::after, .fa-duotone.#{$fa-css-prefix}-tram::after { content: "\f7da\f7da"; }
.fad.#{$fa-css-prefix}-train-tunnel::after, .fa-duotone.#{$fa-css-prefix}-train-tunnel::after { content: "\e454\e454"; }
.fad.#{$fa-css-prefix}-transformer-bolt::after, .fa-duotone.#{$fa-css-prefix}-transformer-bolt::after { content: "\e2a4\e2a4"; }
.fad.#{$fa-css-prefix}-transgender::after, .fa-duotone.#{$fa-css-prefix}-transgender::after { content: "\f225\f225"; }
.fad.#{$fa-css-prefix}-transgender-alt::after, .fa-duotone.#{$fa-css-prefix}-transgender-alt::after { content: "\f225\f225"; }
.fad.#{$fa-css-prefix}-transporter::after, .fa-duotone.#{$fa-css-prefix}-transporter::after { content: "\e042\e042"; }
.fad.#{$fa-css-prefix}-transporter-1::after, .fa-duotone.#{$fa-css-prefix}-transporter-1::after { content: "\e043\e043"; }
.fad.#{$fa-css-prefix}-transporter-2::after, .fa-duotone.#{$fa-css-prefix}-transporter-2::after { content: "\e044\e044"; }
.fad.#{$fa-css-prefix}-transporter-3::after, .fa-duotone.#{$fa-css-prefix}-transporter-3::after { content: "\e045\e045"; }
.fad.#{$fa-css-prefix}-transporter-4::after, .fa-duotone.#{$fa-css-prefix}-transporter-4::after { content: "\e2a5\e2a5"; }
.fad.#{$fa-css-prefix}-transporter-5::after, .fa-duotone.#{$fa-css-prefix}-transporter-5::after { content: "\e2a6\e2a6"; }
.fad.#{$fa-css-prefix}-transporter-6::after, .fa-duotone.#{$fa-css-prefix}-transporter-6::after { content: "\e2a7\e2a7"; }
.fad.#{$fa-css-prefix}-transporter-7::after, .fa-duotone.#{$fa-css-prefix}-transporter-7::after { content: "\e2a8\e2a8"; }
.fad.#{$fa-css-prefix}-transporter-empty::after, .fa-duotone.#{$fa-css-prefix}-transporter-empty::after { content: "\e046\e046"; }
.fad.#{$fa-css-prefix}-trash::after, .fa-duotone.#{$fa-css-prefix}-trash::after { content: "\f1f8\f1f8"; }
.fad.#{$fa-css-prefix}-trash-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-trash-arrow-up::after { content: "\f829\f829"; }
.fad.#{$fa-css-prefix}-trash-restore::after, .fa-duotone.#{$fa-css-prefix}-trash-restore::after { content: "\f829\f829"; }
.fad.#{$fa-css-prefix}-trash-can::after, .fa-duotone.#{$fa-css-prefix}-trash-can::after { content: "\f2ed\f2ed"; }
.fad.#{$fa-css-prefix}-trash-alt::after, .fa-duotone.#{$fa-css-prefix}-trash-alt::after { content: "\f2ed\f2ed"; }
.fad.#{$fa-css-prefix}-trash-can-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-trash-can-arrow-up::after { content: "\f82a\f82a"; }
.fad.#{$fa-css-prefix}-trash-restore-alt::after, .fa-duotone.#{$fa-css-prefix}-trash-restore-alt::after { content: "\f82a\f82a"; }
.fad.#{$fa-css-prefix}-trash-can-check::after, .fa-duotone.#{$fa-css-prefix}-trash-can-check::after { content: "\e2a9\e2a9"; }
.fad.#{$fa-css-prefix}-trash-can-clock::after, .fa-duotone.#{$fa-css-prefix}-trash-can-clock::after { content: "\e2aa\e2aa"; }
.fad.#{$fa-css-prefix}-trash-can-list::after, .fa-duotone.#{$fa-css-prefix}-trash-can-list::after { content: "\e2ab\e2ab"; }
.fad.#{$fa-css-prefix}-trash-can-plus::after, .fa-duotone.#{$fa-css-prefix}-trash-can-plus::after { content: "\e2ac\e2ac"; }
.fad.#{$fa-css-prefix}-trash-can-slash::after, .fa-duotone.#{$fa-css-prefix}-trash-can-slash::after { content: "\e2ad\e2ad"; }
.fad.#{$fa-css-prefix}-trash-alt-slash::after, .fa-duotone.#{$fa-css-prefix}-trash-alt-slash::after { content: "\e2ad\e2ad"; }
.fad.#{$fa-css-prefix}-trash-can-undo::after, .fa-duotone.#{$fa-css-prefix}-trash-can-undo::after { content: "\f896\f896"; }
.fad.#{$fa-css-prefix}-trash-can-arrow-turn-left::after, .fa-duotone.#{$fa-css-prefix}-trash-can-arrow-turn-left::after { content: "\f896\f896"; }
.fad.#{$fa-css-prefix}-trash-undo-alt::after, .fa-duotone.#{$fa-css-prefix}-trash-undo-alt::after { content: "\f896\f896"; }
.fad.#{$fa-css-prefix}-trash-can-xmark::after, .fa-duotone.#{$fa-css-prefix}-trash-can-xmark::after { content: "\e2ae\e2ae"; }
.fad.#{$fa-css-prefix}-trash-check::after, .fa-duotone.#{$fa-css-prefix}-trash-check::after { content: "\e2af\e2af"; }
.fad.#{$fa-css-prefix}-trash-clock::after, .fa-duotone.#{$fa-css-prefix}-trash-clock::after { content: "\e2b0\e2b0"; }
.fad.#{$fa-css-prefix}-trash-list::after, .fa-duotone.#{$fa-css-prefix}-trash-list::after { content: "\e2b1\e2b1"; }
.fad.#{$fa-css-prefix}-trash-plus::after, .fa-duotone.#{$fa-css-prefix}-trash-plus::after { content: "\e2b2\e2b2"; }
.fad.#{$fa-css-prefix}-trash-slash::after, .fa-duotone.#{$fa-css-prefix}-trash-slash::after { content: "\e2b3\e2b3"; }
.fad.#{$fa-css-prefix}-trash-undo::after, .fa-duotone.#{$fa-css-prefix}-trash-undo::after { content: "\f895\f895"; }
.fad.#{$fa-css-prefix}-trash-arrow-turn-left::after, .fa-duotone.#{$fa-css-prefix}-trash-arrow-turn-left::after { content: "\f895\f895"; }
.fad.#{$fa-css-prefix}-trash-xmark::after, .fa-duotone.#{$fa-css-prefix}-trash-xmark::after { content: "\e2b4\e2b4"; }
.fad.#{$fa-css-prefix}-treasure-chest::after, .fa-duotone.#{$fa-css-prefix}-treasure-chest::after { content: "\f723\f723"; }
.fad.#{$fa-css-prefix}-tree::after, .fa-duotone.#{$fa-css-prefix}-tree::after { content: "\f1bb\f1bb"; }
.fad.#{$fa-css-prefix}-tree-christmas::after, .fa-duotone.#{$fa-css-prefix}-tree-christmas::after { content: "\f7db\f7db"; }
.fad.#{$fa-css-prefix}-tree-deciduous::after, .fa-duotone.#{$fa-css-prefix}-tree-deciduous::after { content: "\f400\f400"; }
.fad.#{$fa-css-prefix}-tree-alt::after, .fa-duotone.#{$fa-css-prefix}-tree-alt::after { content: "\f400\f400"; }
.fad.#{$fa-css-prefix}-tree-decorated::after, .fa-duotone.#{$fa-css-prefix}-tree-decorated::after { content: "\f7dc\f7dc"; }
.fad.#{$fa-css-prefix}-tree-large::after, .fa-duotone.#{$fa-css-prefix}-tree-large::after { content: "\f7dd\f7dd"; }
.fad.#{$fa-css-prefix}-tree-palm::after, .fa-duotone.#{$fa-css-prefix}-tree-palm::after { content: "\f82b\f82b"; }
.fad.#{$fa-css-prefix}-trees::after, .fa-duotone.#{$fa-css-prefix}-trees::after { content: "\f724\f724"; }
.fad.#{$fa-css-prefix}-triangle::after, .fa-duotone.#{$fa-css-prefix}-triangle::after { content: "\f2ec\f2ec"; }
.fad.#{$fa-css-prefix}-triangle-exclamation::after, .fa-duotone.#{$fa-css-prefix}-triangle-exclamation::after { content: "\f071\f071"; }
.fad.#{$fa-css-prefix}-exclamation-triangle::after, .fa-duotone.#{$fa-css-prefix}-exclamation-triangle::after { content: "\f071\f071"; }
.fad.#{$fa-css-prefix}-warning::after, .fa-duotone.#{$fa-css-prefix}-warning::after { content: "\f071\f071"; }
.fad.#{$fa-css-prefix}-triangle-instrument::after, .fa-duotone.#{$fa-css-prefix}-triangle-instrument::after { content: "\f8e2\f8e2"; }
.fad.#{$fa-css-prefix}-triangle-music::after, .fa-duotone.#{$fa-css-prefix}-triangle-music::after { content: "\f8e2\f8e2"; }
.fad.#{$fa-css-prefix}-triangle-person-digging::after, .fa-duotone.#{$fa-css-prefix}-triangle-person-digging::after { content: "\f85d\f85d"; }
.fad.#{$fa-css-prefix}-construction::after, .fa-duotone.#{$fa-css-prefix}-construction::after { content: "\f85d\f85d"; }
.fad.#{$fa-css-prefix}-trophy::after, .fa-duotone.#{$fa-css-prefix}-trophy::after { content: "\f091\f091"; }
.fad.#{$fa-css-prefix}-trophy-star::after, .fa-duotone.#{$fa-css-prefix}-trophy-star::after { content: "\f2eb\f2eb"; }
.fad.#{$fa-css-prefix}-trophy-alt::after, .fa-duotone.#{$fa-css-prefix}-trophy-alt::after { content: "\f2eb\f2eb"; }
.fad.#{$fa-css-prefix}-truck::after, .fa-duotone.#{$fa-css-prefix}-truck::after { content: "\f0d1\f0d1"; }
.fad.#{$fa-css-prefix}-truck-bolt::after, .fa-duotone.#{$fa-css-prefix}-truck-bolt::after { content: "\e3d0\e3d0"; }
.fad.#{$fa-css-prefix}-truck-clock::after, .fa-duotone.#{$fa-css-prefix}-truck-clock::after { content: "\f48c\f48c"; }
.fad.#{$fa-css-prefix}-shipping-timed::after, .fa-duotone.#{$fa-css-prefix}-shipping-timed::after { content: "\f48c\f48c"; }
.fad.#{$fa-css-prefix}-truck-container::after, .fa-duotone.#{$fa-css-prefix}-truck-container::after { content: "\f4dc\f4dc"; }
.fad.#{$fa-css-prefix}-truck-container-empty::after, .fa-duotone.#{$fa-css-prefix}-truck-container-empty::after { content: "\e2b5\e2b5"; }
.fad.#{$fa-css-prefix}-truck-fast::after, .fa-duotone.#{$fa-css-prefix}-truck-fast::after { content: "\f48b\f48b"; }
.fad.#{$fa-css-prefix}-shipping-fast::after, .fa-duotone.#{$fa-css-prefix}-shipping-fast::after { content: "\f48b\f48b"; }
.fad.#{$fa-css-prefix}-truck-flatbed::after, .fa-duotone.#{$fa-css-prefix}-truck-flatbed::after { content: "\e2b6\e2b6"; }
.fad.#{$fa-css-prefix}-truck-front::after, .fa-duotone.#{$fa-css-prefix}-truck-front::after { content: "\e2b7\e2b7"; }
.fad.#{$fa-css-prefix}-truck-medical::after, .fa-duotone.#{$fa-css-prefix}-truck-medical::after { content: "\f0f9\f0f9"; }
.fad.#{$fa-css-prefix}-ambulance::after, .fa-duotone.#{$fa-css-prefix}-ambulance::after { content: "\f0f9\f0f9"; }
.fad.#{$fa-css-prefix}-truck-monster::after, .fa-duotone.#{$fa-css-prefix}-truck-monster::after { content: "\f63b\f63b"; }
.fad.#{$fa-css-prefix}-truck-moving::after, .fa-duotone.#{$fa-css-prefix}-truck-moving::after { content: "\f4df\f4df"; }
.fad.#{$fa-css-prefix}-truck-pickup::after, .fa-duotone.#{$fa-css-prefix}-truck-pickup::after { content: "\f63c\f63c"; }
.fad.#{$fa-css-prefix}-truck-plow::after, .fa-duotone.#{$fa-css-prefix}-truck-plow::after { content: "\f7de\f7de"; }
.fad.#{$fa-css-prefix}-truck-ramp::after, .fa-duotone.#{$fa-css-prefix}-truck-ramp::after { content: "\f4e0\f4e0"; }
.fad.#{$fa-css-prefix}-truck-ramp-box::after, .fa-duotone.#{$fa-css-prefix}-truck-ramp-box::after { content: "\f4de\f4de"; }
.fad.#{$fa-css-prefix}-truck-loading::after, .fa-duotone.#{$fa-css-prefix}-truck-loading::after { content: "\f4de\f4de"; }
.fad.#{$fa-css-prefix}-truck-ramp-couch::after, .fa-duotone.#{$fa-css-prefix}-truck-ramp-couch::after { content: "\f4dd\f4dd"; }
.fad.#{$fa-css-prefix}-truck-couch::after, .fa-duotone.#{$fa-css-prefix}-truck-couch::after { content: "\f4dd\f4dd"; }
.fad.#{$fa-css-prefix}-truck-tow::after, .fa-duotone.#{$fa-css-prefix}-truck-tow::after { content: "\e2b8\e2b8"; }
.fad.#{$fa-css-prefix}-trumpet::after, .fa-duotone.#{$fa-css-prefix}-trumpet::after { content: "\f8e3\f8e3"; }
.fad.#{$fa-css-prefix}-tty::after, .fa-duotone.#{$fa-css-prefix}-tty::after { content: "\f1e4\f1e4"; }
.fad.#{$fa-css-prefix}-teletype::after, .fa-duotone.#{$fa-css-prefix}-teletype::after { content: "\f1e4\f1e4"; }
.fad.#{$fa-css-prefix}-tty-answer::after, .fa-duotone.#{$fa-css-prefix}-tty-answer::after { content: "\e2b9\e2b9"; }
.fad.#{$fa-css-prefix}-teletype-answer::after, .fa-duotone.#{$fa-css-prefix}-teletype-answer::after { content: "\e2b9\e2b9"; }
.fad.#{$fa-css-prefix}-tugrik-sign::after, .fa-duotone.#{$fa-css-prefix}-tugrik-sign::after { content: "\e2ba\e2ba"; }
.fad.#{$fa-css-prefix}-turkey::after, .fa-duotone.#{$fa-css-prefix}-turkey::after { content: "\f725\f725"; }
.fad.#{$fa-css-prefix}-turkish-lira-sign::after, .fa-duotone.#{$fa-css-prefix}-turkish-lira-sign::after { content: "\e2bb\e2bb"; }
.fad.#{$fa-css-prefix}-try::after, .fa-duotone.#{$fa-css-prefix}-try::after { content: "\e2bb\e2bb"; }
.fad.#{$fa-css-prefix}-turkish-lira::after, .fa-duotone.#{$fa-css-prefix}-turkish-lira::after { content: "\e2bb\e2bb"; }
.fad.#{$fa-css-prefix}-turn-down::after, .fa-duotone.#{$fa-css-prefix}-turn-down::after { content: "\f3be\f3be"; }
.fad.#{$fa-css-prefix}-level-down-alt::after, .fa-duotone.#{$fa-css-prefix}-level-down-alt::after { content: "\f3be\f3be"; }
.fad.#{$fa-css-prefix}-turn-down-left::after, .fa-duotone.#{$fa-css-prefix}-turn-down-left::after { content: "\e331\e331"; }
.fad.#{$fa-css-prefix}-turn-down-right::after, .fa-duotone.#{$fa-css-prefix}-turn-down-right::after { content: "\e455\e455"; }
.fad.#{$fa-css-prefix}-turn-up::after, .fa-duotone.#{$fa-css-prefix}-turn-up::after { content: "\f3bf\f3bf"; }
.fad.#{$fa-css-prefix}-level-up-alt::after, .fa-duotone.#{$fa-css-prefix}-level-up-alt::after { content: "\f3bf\f3bf"; }
.fad.#{$fa-css-prefix}-turntable::after, .fa-duotone.#{$fa-css-prefix}-turntable::after { content: "\f8e4\f8e4"; }
.fad.#{$fa-css-prefix}-turtle::after, .fa-duotone.#{$fa-css-prefix}-turtle::after { content: "\f726\f726"; }
.fad.#{$fa-css-prefix}-tv::after, .fa-duotone.#{$fa-css-prefix}-tv::after { content: "\f26c\f26c"; }
.fad.#{$fa-css-prefix}-television::after, .fa-duotone.#{$fa-css-prefix}-television::after { content: "\f26c\f26c"; }
.fad.#{$fa-css-prefix}-tv-alt::after, .fa-duotone.#{$fa-css-prefix}-tv-alt::after { content: "\f26c\f26c"; }
.fad.#{$fa-css-prefix}-tv-music::after, .fa-duotone.#{$fa-css-prefix}-tv-music::after { content: "\f8e6\f8e6"; }
.fad.#{$fa-css-prefix}-tv-retro::after, .fa-duotone.#{$fa-css-prefix}-tv-retro::after { content: "\f401\f401"; }
.fad.#{$fa-css-prefix}-typewriter::after, .fa-duotone.#{$fa-css-prefix}-typewriter::after { content: "\f8e7\f8e7"; }
.fad.#{$fa-css-prefix}-u::after, .fa-duotone.#{$fa-css-prefix}-u::after { content: "\55\55"; }
.fad.#{$fa-css-prefix}-ufo::after, .fa-duotone.#{$fa-css-prefix}-ufo::after { content: "\e047\e047"; }
.fad.#{$fa-css-prefix}-ufo-beam::after, .fa-duotone.#{$fa-css-prefix}-ufo-beam::after { content: "\e048\e048"; }
.fad.#{$fa-css-prefix}-umbrella::after, .fa-duotone.#{$fa-css-prefix}-umbrella::after { content: "\f0e9\f0e9"; }
.fad.#{$fa-css-prefix}-umbrella-beach::after, .fa-duotone.#{$fa-css-prefix}-umbrella-beach::after { content: "\f5ca\f5ca"; }
.fad.#{$fa-css-prefix}-umbrella-simple::after, .fa-duotone.#{$fa-css-prefix}-umbrella-simple::after { content: "\e2bc\e2bc"; }
.fad.#{$fa-css-prefix}-umbrella-alt::after, .fa-duotone.#{$fa-css-prefix}-umbrella-alt::after { content: "\e2bc\e2bc"; }
.fad.#{$fa-css-prefix}-underline::after, .fa-duotone.#{$fa-css-prefix}-underline::after { content: "\f0cd\f0cd"; }
.fad.#{$fa-css-prefix}-unicorn::after, .fa-duotone.#{$fa-css-prefix}-unicorn::after { content: "\f727\f727"; }
.fad.#{$fa-css-prefix}-uniform-martial-arts::after, .fa-duotone.#{$fa-css-prefix}-uniform-martial-arts::after { content: "\e3d1\e3d1"; }
.fad.#{$fa-css-prefix}-union::after, .fa-duotone.#{$fa-css-prefix}-union::after { content: "\f6a2\f6a2"; }
.fad.#{$fa-css-prefix}-universal-access::after, .fa-duotone.#{$fa-css-prefix}-universal-access::after { content: "\f29a\f29a"; }
.fad.#{$fa-css-prefix}-unlock::after, .fa-duotone.#{$fa-css-prefix}-unlock::after { content: "\f09c\f09c"; }
.fad.#{$fa-css-prefix}-unlock-keyhole::after, .fa-duotone.#{$fa-css-prefix}-unlock-keyhole::after { content: "\f13e\f13e"; }
.fad.#{$fa-css-prefix}-unlock-alt::after, .fa-duotone.#{$fa-css-prefix}-unlock-alt::after { content: "\f13e\f13e"; }
.fad.#{$fa-css-prefix}-up::after, .fa-duotone.#{$fa-css-prefix}-up::after { content: "\f357\f357"; }
.fad.#{$fa-css-prefix}-arrow-alt-up::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-up::after { content: "\f357\f357"; }
.fad.#{$fa-css-prefix}-up-down::after, .fa-duotone.#{$fa-css-prefix}-up-down::after { content: "\f338\f338"; }
.fad.#{$fa-css-prefix}-arrows-alt-v::after, .fa-duotone.#{$fa-css-prefix}-arrows-alt-v::after { content: "\f338\f338"; }
.fad.#{$fa-css-prefix}-up-down-left-right::after, .fa-duotone.#{$fa-css-prefix}-up-down-left-right::after { content: "\f0b2\f0b2"; }
.fad.#{$fa-css-prefix}-arrows-alt::after, .fa-duotone.#{$fa-css-prefix}-arrows-alt::after { content: "\f0b2\f0b2"; }
.fad.#{$fa-css-prefix}-up-from-dotted-line::after, .fa-duotone.#{$fa-css-prefix}-up-from-dotted-line::after { content: "\e456\e456"; }
.fad.#{$fa-css-prefix}-up-from-line::after, .fa-duotone.#{$fa-css-prefix}-up-from-line::after { content: "\f346\f346"; }
.fad.#{$fa-css-prefix}-arrow-alt-from-bottom::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-from-bottom::after { content: "\f346\f346"; }
.fad.#{$fa-css-prefix}-up-left::after, .fa-duotone.#{$fa-css-prefix}-up-left::after { content: "\e2bd\e2bd"; }
.fad.#{$fa-css-prefix}-up-long::after, .fa-duotone.#{$fa-css-prefix}-up-long::after { content: "\f30c\f30c"; }
.fad.#{$fa-css-prefix}-long-arrow-alt-up::after, .fa-duotone.#{$fa-css-prefix}-long-arrow-alt-up::after { content: "\f30c\f30c"; }
.fad.#{$fa-css-prefix}-up-right::after, .fa-duotone.#{$fa-css-prefix}-up-right::after { content: "\e2be\e2be"; }
.fad.#{$fa-css-prefix}-up-right-and-down-left-from-center::after, .fa-duotone.#{$fa-css-prefix}-up-right-and-down-left-from-center::after { content: "\f424\f424"; }
.fad.#{$fa-css-prefix}-expand-alt::after, .fa-duotone.#{$fa-css-prefix}-expand-alt::after { content: "\f424\f424"; }
.fad.#{$fa-css-prefix}-up-right-from-square::after, .fa-duotone.#{$fa-css-prefix}-up-right-from-square::after { content: "\f35d\f35d"; }
.fad.#{$fa-css-prefix}-external-link-alt::after, .fa-duotone.#{$fa-css-prefix}-external-link-alt::after { content: "\f35d\f35d"; }
.fad.#{$fa-css-prefix}-up-to-dotted-line::after, .fa-duotone.#{$fa-css-prefix}-up-to-dotted-line::after { content: "\e457\e457"; }
.fad.#{$fa-css-prefix}-up-to-line::after, .fa-duotone.#{$fa-css-prefix}-up-to-line::after { content: "\f34d\f34d"; }
.fad.#{$fa-css-prefix}-arrow-alt-to-top::after, .fa-duotone.#{$fa-css-prefix}-arrow-alt-to-top::after { content: "\f34d\f34d"; }
.fad.#{$fa-css-prefix}-upload::after, .fa-duotone.#{$fa-css-prefix}-upload::after { content: "\f093\f093"; }
.fad.#{$fa-css-prefix}-usb-drive::after, .fa-duotone.#{$fa-css-prefix}-usb-drive::after { content: "\f8e9\f8e9"; }
.fad.#{$fa-css-prefix}-user::after, .fa-duotone.#{$fa-css-prefix}-user::after { content: "\f007\f007"; }
.fad.#{$fa-css-prefix}-user-alien::after, .fa-duotone.#{$fa-css-prefix}-user-alien::after { content: "\e04a\e04a"; }
.fad.#{$fa-css-prefix}-user-astronaut::after, .fa-duotone.#{$fa-css-prefix}-user-astronaut::after { content: "\f4fb\f4fb"; }
.fad.#{$fa-css-prefix}-user-bounty-hunter::after, .fa-duotone.#{$fa-css-prefix}-user-bounty-hunter::after { content: "\e2bf\e2bf"; }
.fad.#{$fa-css-prefix}-user-check::after, .fa-duotone.#{$fa-css-prefix}-user-check::after { content: "\f4fc\f4fc"; }
.fad.#{$fa-css-prefix}-user-chef::after, .fa-duotone.#{$fa-css-prefix}-user-chef::after { content: "\e3d2\e3d2"; }
.fad.#{$fa-css-prefix}-user-clock::after, .fa-duotone.#{$fa-css-prefix}-user-clock::after { content: "\f4fd\f4fd"; }
.fad.#{$fa-css-prefix}-user-cowboy::after, .fa-duotone.#{$fa-css-prefix}-user-cowboy::after { content: "\f8ea\f8ea"; }
.fad.#{$fa-css-prefix}-user-crown::after, .fa-duotone.#{$fa-css-prefix}-user-crown::after { content: "\f6a4\f6a4"; }
.fad.#{$fa-css-prefix}-user-doctor::after, .fa-duotone.#{$fa-css-prefix}-user-doctor::after { content: "\f0f0\f0f0"; }
.fad.#{$fa-css-prefix}-user-md::after, .fa-duotone.#{$fa-css-prefix}-user-md::after { content: "\f0f0\f0f0"; }
.fad.#{$fa-css-prefix}-user-doctor-hair::after, .fa-duotone.#{$fa-css-prefix}-user-doctor-hair::after { content: "\e458\e458"; }
.fad.#{$fa-css-prefix}-user-doctor-hair-long::after, .fa-duotone.#{$fa-css-prefix}-user-doctor-hair-long::after { content: "\e459\e459"; }
.fad.#{$fa-css-prefix}-user-doctor-message::after, .fa-duotone.#{$fa-css-prefix}-user-doctor-message::after { content: "\f82e\f82e"; }
.fad.#{$fa-css-prefix}-user-md-chat::after, .fa-duotone.#{$fa-css-prefix}-user-md-chat::after { content: "\f82e\f82e"; }
.fad.#{$fa-css-prefix}-user-gear::after, .fa-duotone.#{$fa-css-prefix}-user-gear::after { content: "\f4fe\f4fe"; }
.fad.#{$fa-css-prefix}-user-cog::after, .fa-duotone.#{$fa-css-prefix}-user-cog::after { content: "\f4fe\f4fe"; }
.fad.#{$fa-css-prefix}-user-graduate::after, .fa-duotone.#{$fa-css-prefix}-user-graduate::after { content: "\f501\f501"; }
.fad.#{$fa-css-prefix}-user-group::after, .fa-duotone.#{$fa-css-prefix}-user-group::after { content: "\f500\f500"; }
.fad.#{$fa-css-prefix}-user-friends::after, .fa-duotone.#{$fa-css-prefix}-user-friends::after { content: "\f500\f500"; }
.fad.#{$fa-css-prefix}-user-group-crown::after, .fa-duotone.#{$fa-css-prefix}-user-group-crown::after { content: "\f6a5\f6a5"; }
.fad.#{$fa-css-prefix}-users-crown::after, .fa-duotone.#{$fa-css-prefix}-users-crown::after { content: "\f6a5\f6a5"; }
.fad.#{$fa-css-prefix}-user-hair::after, .fa-duotone.#{$fa-css-prefix}-user-hair::after { content: "\e45a\e45a"; }
.fad.#{$fa-css-prefix}-user-hair-buns::after, .fa-duotone.#{$fa-css-prefix}-user-hair-buns::after { content: "\e3d3\e3d3"; }
.fad.#{$fa-css-prefix}-user-hair-long::after, .fa-duotone.#{$fa-css-prefix}-user-hair-long::after { content: "\e45b\e45b"; }
.fad.#{$fa-css-prefix}-user-hair-mullet::after, .fa-duotone.#{$fa-css-prefix}-user-hair-mullet::after { content: "\e45c\e45c"; }
.fad.#{$fa-css-prefix}-business-front::after, .fa-duotone.#{$fa-css-prefix}-business-front::after { content: "\e45c\e45c"; }
.fad.#{$fa-css-prefix}-party-back::after, .fa-duotone.#{$fa-css-prefix}-party-back::after { content: "\e45c\e45c"; }
.fad.#{$fa-css-prefix}-trian-balbot::after, .fa-duotone.#{$fa-css-prefix}-trian-balbot::after { content: "\e45c\e45c"; }
.fad.#{$fa-css-prefix}-user-headset::after, .fa-duotone.#{$fa-css-prefix}-user-headset::after { content: "\f82d\f82d"; }
.fad.#{$fa-css-prefix}-user-helmet-safety::after, .fa-duotone.#{$fa-css-prefix}-user-helmet-safety::after { content: "\f82c\f82c"; }
.fad.#{$fa-css-prefix}-user-construction::after, .fa-duotone.#{$fa-css-prefix}-user-construction::after { content: "\f82c\f82c"; }
.fad.#{$fa-css-prefix}-user-hard-hat::after, .fa-duotone.#{$fa-css-prefix}-user-hard-hat::after { content: "\f82c\f82c"; }
.fad.#{$fa-css-prefix}-user-injured::after, .fa-duotone.#{$fa-css-prefix}-user-injured::after { content: "\f728\f728"; }
.fad.#{$fa-css-prefix}-user-large::after, .fa-duotone.#{$fa-css-prefix}-user-large::after { content: "\f406\f406"; }
.fad.#{$fa-css-prefix}-user-alt::after, .fa-duotone.#{$fa-css-prefix}-user-alt::after { content: "\f406\f406"; }
.fad.#{$fa-css-prefix}-user-large-slash::after, .fa-duotone.#{$fa-css-prefix}-user-large-slash::after { content: "\f4fa\f4fa"; }
.fad.#{$fa-css-prefix}-user-alt-slash::after, .fa-duotone.#{$fa-css-prefix}-user-alt-slash::after { content: "\f4fa\f4fa"; }
.fad.#{$fa-css-prefix}-user-lock::after, .fa-duotone.#{$fa-css-prefix}-user-lock::after { content: "\f502\f502"; }
.fad.#{$fa-css-prefix}-user-minus::after, .fa-duotone.#{$fa-css-prefix}-user-minus::after { content: "\f503\f503"; }
.fad.#{$fa-css-prefix}-user-music::after, .fa-duotone.#{$fa-css-prefix}-user-music::after { content: "\f8eb\f8eb"; }
.fad.#{$fa-css-prefix}-user-ninja::after, .fa-duotone.#{$fa-css-prefix}-user-ninja::after { content: "\f504\f504"; }
.fad.#{$fa-css-prefix}-user-nurse::after, .fa-duotone.#{$fa-css-prefix}-user-nurse::after { content: "\f82f\f82f"; }
.fad.#{$fa-css-prefix}-user-nurse-hair::after, .fa-duotone.#{$fa-css-prefix}-user-nurse-hair::after { content: "\e45d\e45d"; }
.fad.#{$fa-css-prefix}-user-nurse-hair-long::after, .fa-duotone.#{$fa-css-prefix}-user-nurse-hair-long::after { content: "\e45e\e45e"; }
.fad.#{$fa-css-prefix}-user-pen::after, .fa-duotone.#{$fa-css-prefix}-user-pen::after { content: "\f4ff\f4ff"; }
.fad.#{$fa-css-prefix}-user-edit::after, .fa-duotone.#{$fa-css-prefix}-user-edit::after { content: "\f4ff\f4ff"; }
.fad.#{$fa-css-prefix}-user-pilot::after, .fa-duotone.#{$fa-css-prefix}-user-pilot::after { content: "\e2c0\e2c0"; }
.fad.#{$fa-css-prefix}-user-pilot-tie::after, .fa-duotone.#{$fa-css-prefix}-user-pilot-tie::after { content: "\e2c1\e2c1"; }
.fad.#{$fa-css-prefix}-user-plus::after, .fa-duotone.#{$fa-css-prefix}-user-plus::after { content: "\f234\f234"; }
.fad.#{$fa-css-prefix}-user-police::after, .fa-duotone.#{$fa-css-prefix}-user-police::after { content: "\e333\e333"; }
.fad.#{$fa-css-prefix}-user-police-tie::after, .fa-duotone.#{$fa-css-prefix}-user-police-tie::after { content: "\e334\e334"; }
.fad.#{$fa-css-prefix}-user-robot::after, .fa-duotone.#{$fa-css-prefix}-user-robot::after { content: "\e04b\e04b"; }
.fad.#{$fa-css-prefix}-user-robot-xmarks::after, .fa-duotone.#{$fa-css-prefix}-user-robot-xmarks::after { content: "\e4a7\e4a7"; }
.fad.#{$fa-css-prefix}-user-secret::after, .fa-duotone.#{$fa-css-prefix}-user-secret::after { content: "\f21b\f21b"; }
.fad.#{$fa-css-prefix}-user-shakespeare::after, .fa-duotone.#{$fa-css-prefix}-user-shakespeare::after { content: "\e2c2\e2c2"; }
.fad.#{$fa-css-prefix}-user-shield::after, .fa-duotone.#{$fa-css-prefix}-user-shield::after { content: "\f505\f505"; }
.fad.#{$fa-css-prefix}-user-slash::after, .fa-duotone.#{$fa-css-prefix}-user-slash::after { content: "\f506\f506"; }
.fad.#{$fa-css-prefix}-user-tag::after, .fa-duotone.#{$fa-css-prefix}-user-tag::after { content: "\f507\f507"; }
.fad.#{$fa-css-prefix}-user-tie::after, .fa-duotone.#{$fa-css-prefix}-user-tie::after { content: "\f508\f508"; }
.fad.#{$fa-css-prefix}-user-tie-hair::after, .fa-duotone.#{$fa-css-prefix}-user-tie-hair::after { content: "\e45f\e45f"; }
.fad.#{$fa-css-prefix}-user-tie-hair-long::after, .fa-duotone.#{$fa-css-prefix}-user-tie-hair-long::after { content: "\e460\e460"; }
.fad.#{$fa-css-prefix}-user-unlock::after, .fa-duotone.#{$fa-css-prefix}-user-unlock::after { content: "\e058\e058"; }
.fad.#{$fa-css-prefix}-user-visor::after, .fa-duotone.#{$fa-css-prefix}-user-visor::after { content: "\e04c\e04c"; }
.fad.#{$fa-css-prefix}-user-vneck::after, .fa-duotone.#{$fa-css-prefix}-user-vneck::after { content: "\e461\e461"; }
.fad.#{$fa-css-prefix}-user-vneck-hair::after, .fa-duotone.#{$fa-css-prefix}-user-vneck-hair::after { content: "\e462\e462"; }
.fad.#{$fa-css-prefix}-user-vneck-hair-long::after, .fa-duotone.#{$fa-css-prefix}-user-vneck-hair-long::after { content: "\e463\e463"; }
.fad.#{$fa-css-prefix}-user-xmark::after, .fa-duotone.#{$fa-css-prefix}-user-xmark::after { content: "\f235\f235"; }
.fad.#{$fa-css-prefix}-user-times::after, .fa-duotone.#{$fa-css-prefix}-user-times::after { content: "\f235\f235"; }
.fad.#{$fa-css-prefix}-users::after, .fa-duotone.#{$fa-css-prefix}-users::after { content: "\f0c0\f0c0"; }
.fad.#{$fa-css-prefix}-users-gear::after, .fa-duotone.#{$fa-css-prefix}-users-gear::after { content: "\f509\f509"; }
.fad.#{$fa-css-prefix}-users-cog::after, .fa-duotone.#{$fa-css-prefix}-users-cog::after { content: "\f509\f509"; }
.fad.#{$fa-css-prefix}-users-medical::after, .fa-duotone.#{$fa-css-prefix}-users-medical::after { content: "\f830\f830"; }
.fad.#{$fa-css-prefix}-users-slash::after, .fa-duotone.#{$fa-css-prefix}-users-slash::after { content: "\e073\e073"; }
.fad.#{$fa-css-prefix}-utensils::after, .fa-duotone.#{$fa-css-prefix}-utensils::after { content: "\f2e7\f2e7"; }
.fad.#{$fa-css-prefix}-cutlery::after, .fa-duotone.#{$fa-css-prefix}-cutlery::after { content: "\f2e7\f2e7"; }
.fad.#{$fa-css-prefix}-utensils-slash::after, .fa-duotone.#{$fa-css-prefix}-utensils-slash::after { content: "\e464\e464"; }
.fad.#{$fa-css-prefix}-utility-pole::after, .fa-duotone.#{$fa-css-prefix}-utility-pole::after { content: "\e2c3\e2c3"; }
.fad.#{$fa-css-prefix}-utility-pole-double::after, .fa-duotone.#{$fa-css-prefix}-utility-pole-double::after { content: "\e2c4\e2c4"; }
.fad.#{$fa-css-prefix}-v::after, .fa-duotone.#{$fa-css-prefix}-v::after { content: "\56\56"; }
.fad.#{$fa-css-prefix}-vacuum::after, .fa-duotone.#{$fa-css-prefix}-vacuum::after { content: "\e04d\e04d"; }
.fad.#{$fa-css-prefix}-vacuum-robot::after, .fa-duotone.#{$fa-css-prefix}-vacuum-robot::after { content: "\e04e\e04e"; }
.fad.#{$fa-css-prefix}-value-absolute::after, .fa-duotone.#{$fa-css-prefix}-value-absolute::after { content: "\f6a6\f6a6"; }
.fad.#{$fa-css-prefix}-van-shuttle::after, .fa-duotone.#{$fa-css-prefix}-van-shuttle::after { content: "\f5b6\f5b6"; }
.fad.#{$fa-css-prefix}-shuttle-van::after, .fa-duotone.#{$fa-css-prefix}-shuttle-van::after { content: "\f5b6\f5b6"; }
.fad.#{$fa-css-prefix}-vault::after, .fa-duotone.#{$fa-css-prefix}-vault::after { content: "\e2c5\e2c5"; }
.fad.#{$fa-css-prefix}-vector-circle::after, .fa-duotone.#{$fa-css-prefix}-vector-circle::after { content: "\e2c6\e2c6"; }
.fad.#{$fa-css-prefix}-vector-polygon::after, .fa-duotone.#{$fa-css-prefix}-vector-polygon::after { content: "\e2c7\e2c7"; }
.fad.#{$fa-css-prefix}-vector-square::after, .fa-duotone.#{$fa-css-prefix}-vector-square::after { content: "\f5cb\f5cb"; }
.fad.#{$fa-css-prefix}-vent-damper::after, .fa-duotone.#{$fa-css-prefix}-vent-damper::after { content: "\e465\e465"; }
.fad.#{$fa-css-prefix}-venus::after, .fa-duotone.#{$fa-css-prefix}-venus::after { content: "\f221\f221"; }
.fad.#{$fa-css-prefix}-venus-double::after, .fa-duotone.#{$fa-css-prefix}-venus-double::after { content: "\f226\f226"; }
.fad.#{$fa-css-prefix}-venus-mars::after, .fa-duotone.#{$fa-css-prefix}-venus-mars::after { content: "\f228\f228"; }
.fad.#{$fa-css-prefix}-vest::after, .fa-duotone.#{$fa-css-prefix}-vest::after { content: "\e085\e085"; }
.fad.#{$fa-css-prefix}-vest-patches::after, .fa-duotone.#{$fa-css-prefix}-vest-patches::after { content: "\e086\e086"; }
.fad.#{$fa-css-prefix}-vial::after, .fa-duotone.#{$fa-css-prefix}-vial::after { content: "\f492\f492"; }
.fad.#{$fa-css-prefix}-vials::after, .fa-duotone.#{$fa-css-prefix}-vials::after { content: "\f493\f493"; }
.fad.#{$fa-css-prefix}-video::after, .fa-duotone.#{$fa-css-prefix}-video::after { content: "\f03d\f03d"; }
.fad.#{$fa-css-prefix}-video-camera::after, .fa-duotone.#{$fa-css-prefix}-video-camera::after { content: "\f03d\f03d"; }
.fad.#{$fa-css-prefix}-video-arrow-down-left::after, .fa-duotone.#{$fa-css-prefix}-video-arrow-down-left::after { content: "\e2c8\e2c8"; }
.fad.#{$fa-css-prefix}-video-arrow-up-right::after, .fa-duotone.#{$fa-css-prefix}-video-arrow-up-right::after { content: "\e2c9\e2c9"; }
.fad.#{$fa-css-prefix}-video-plus::after, .fa-duotone.#{$fa-css-prefix}-video-plus::after { content: "\f4e1\f4e1"; }
.fad.#{$fa-css-prefix}-video-slash::after, .fa-duotone.#{$fa-css-prefix}-video-slash::after { content: "\f4e2\f4e2"; }
.fad.#{$fa-css-prefix}-vihara::after, .fa-duotone.#{$fa-css-prefix}-vihara::after { content: "\f6a7\f6a7"; }
.fad.#{$fa-css-prefix}-violin::after, .fa-duotone.#{$fa-css-prefix}-violin::after { content: "\f8ed\f8ed"; }
.fad.#{$fa-css-prefix}-virus::after, .fa-duotone.#{$fa-css-prefix}-virus::after { content: "\e074\e074"; }
.fad.#{$fa-css-prefix}-virus-covid::after, .fa-duotone.#{$fa-css-prefix}-virus-covid::after { content: "\e4a8\e4a8"; }
.fad.#{$fa-css-prefix}-virus-covid-slash::after, .fa-duotone.#{$fa-css-prefix}-virus-covid-slash::after { content: "\e4a9\e4a9"; }
.fad.#{$fa-css-prefix}-virus-slash::after, .fa-duotone.#{$fa-css-prefix}-virus-slash::after { content: "\e075\e075"; }
.fad.#{$fa-css-prefix}-viruses::after, .fa-duotone.#{$fa-css-prefix}-viruses::after { content: "\e076\e076"; }
.fad.#{$fa-css-prefix}-voicemail::after, .fa-duotone.#{$fa-css-prefix}-voicemail::after { content: "\f897\f897"; }
.fad.#{$fa-css-prefix}-volcano::after, .fa-duotone.#{$fa-css-prefix}-volcano::after { content: "\f770\f770"; }
.fad.#{$fa-css-prefix}-volleyball::after, .fa-duotone.#{$fa-css-prefix}-volleyball::after { content: "\f45f\f45f"; }
.fad.#{$fa-css-prefix}-volleyball-ball::after, .fa-duotone.#{$fa-css-prefix}-volleyball-ball::after { content: "\f45f\f45f"; }
.fad.#{$fa-css-prefix}-volume::after, .fa-duotone.#{$fa-css-prefix}-volume::after { content: "\f6a8\f6a8"; }
.fad.#{$fa-css-prefix}-volume-medium::after, .fa-duotone.#{$fa-css-prefix}-volume-medium::after { content: "\f6a8\f6a8"; }
.fad.#{$fa-css-prefix}-volume-high::after, .fa-duotone.#{$fa-css-prefix}-volume-high::after { content: "\f028\f028"; }
.fad.#{$fa-css-prefix}-volume-up::after, .fa-duotone.#{$fa-css-prefix}-volume-up::after { content: "\f028\f028"; }
.fad.#{$fa-css-prefix}-volume-low::after, .fa-duotone.#{$fa-css-prefix}-volume-low::after { content: "\f027\f027"; }
.fad.#{$fa-css-prefix}-volume-down::after, .fa-duotone.#{$fa-css-prefix}-volume-down::after { content: "\f027\f027"; }
.fad.#{$fa-css-prefix}-volume-off::after, .fa-duotone.#{$fa-css-prefix}-volume-off::after { content: "\f026\f026"; }
.fad.#{$fa-css-prefix}-volume-slash::after, .fa-duotone.#{$fa-css-prefix}-volume-slash::after { content: "\f2e2\f2e2"; }
.fad.#{$fa-css-prefix}-volume-xmark::after, .fa-duotone.#{$fa-css-prefix}-volume-xmark::after { content: "\f6a9\f6a9"; }
.fad.#{$fa-css-prefix}-volume-mute::after, .fa-duotone.#{$fa-css-prefix}-volume-mute::after { content: "\f6a9\f6a9"; }
.fad.#{$fa-css-prefix}-volume-times::after, .fa-duotone.#{$fa-css-prefix}-volume-times::after { content: "\f6a9\f6a9"; }
.fad.#{$fa-css-prefix}-vr-cardboard::after, .fa-duotone.#{$fa-css-prefix}-vr-cardboard::after { content: "\f729\f729"; }
.fad.#{$fa-css-prefix}-w::after, .fa-duotone.#{$fa-css-prefix}-w::after { content: "\57\57"; }
.fad.#{$fa-css-prefix}-waffle::after, .fa-duotone.#{$fa-css-prefix}-waffle::after { content: "\e466\e466"; }
.fad.#{$fa-css-prefix}-wagon-covered::after, .fa-duotone.#{$fa-css-prefix}-wagon-covered::after { content: "\f8ee\f8ee"; }
.fad.#{$fa-css-prefix}-walker::after, .fa-duotone.#{$fa-css-prefix}-walker::after { content: "\f831\f831"; }
.fad.#{$fa-css-prefix}-walkie-talkie::after, .fa-duotone.#{$fa-css-prefix}-walkie-talkie::after { content: "\f8ef\f8ef"; }
.fad.#{$fa-css-prefix}-wallet::after, .fa-duotone.#{$fa-css-prefix}-wallet::after { content: "\f555\f555"; }
.fad.#{$fa-css-prefix}-wand::after, .fa-duotone.#{$fa-css-prefix}-wand::after { content: "\f72a\f72a"; }
.fad.#{$fa-css-prefix}-wand-magic::after, .fa-duotone.#{$fa-css-prefix}-wand-magic::after { content: "\f0d0\f0d0"; }
.fad.#{$fa-css-prefix}-magic::after, .fa-duotone.#{$fa-css-prefix}-magic::after { content: "\f0d0\f0d0"; }
.fad.#{$fa-css-prefix}-wand-magic-sparkles::after, .fa-duotone.#{$fa-css-prefix}-wand-magic-sparkles::after { content: "\e2ca\e2ca"; }
.fad.#{$fa-css-prefix}-magic-wand-sparkles::after, .fa-duotone.#{$fa-css-prefix}-magic-wand-sparkles::after { content: "\e2ca\e2ca"; }
.fad.#{$fa-css-prefix}-wand-sparkles::after, .fa-duotone.#{$fa-css-prefix}-wand-sparkles::after { content: "\f72b\f72b"; }
.fad.#{$fa-css-prefix}-warehouse::after, .fa-duotone.#{$fa-css-prefix}-warehouse::after { content: "\f494\f494"; }
.fad.#{$fa-css-prefix}-warehouse-full::after, .fa-duotone.#{$fa-css-prefix}-warehouse-full::after { content: "\f495\f495"; }
.fad.#{$fa-css-prefix}-warehouse-alt::after, .fa-duotone.#{$fa-css-prefix}-warehouse-alt::after { content: "\f495\f495"; }
.fad.#{$fa-css-prefix}-washing-machine::after, .fa-duotone.#{$fa-css-prefix}-washing-machine::after { content: "\f898\f898"; }
.fad.#{$fa-css-prefix}-washer::after, .fa-duotone.#{$fa-css-prefix}-washer::after { content: "\f898\f898"; }
.fad.#{$fa-css-prefix}-watch::after, .fa-duotone.#{$fa-css-prefix}-watch::after { content: "\f2e1\f2e1"; }
.fad.#{$fa-css-prefix}-watch-apple::after, .fa-duotone.#{$fa-css-prefix}-watch-apple::after { content: "\e2cb\e2cb"; }
.fad.#{$fa-css-prefix}-watch-calculator::after, .fa-duotone.#{$fa-css-prefix}-watch-calculator::after { content: "\f8f0\f8f0"; }
.fad.#{$fa-css-prefix}-watch-fitness::after, .fa-duotone.#{$fa-css-prefix}-watch-fitness::after { content: "\f63e\f63e"; }
.fad.#{$fa-css-prefix}-watch-smart::after, .fa-duotone.#{$fa-css-prefix}-watch-smart::after { content: "\e2cc\e2cc"; }
.fad.#{$fa-css-prefix}-water::after, .fa-duotone.#{$fa-css-prefix}-water::after { content: "\f773\f773"; }
.fad.#{$fa-css-prefix}-water-arrow-down::after, .fa-duotone.#{$fa-css-prefix}-water-arrow-down::after { content: "\f774\f774"; }
.fad.#{$fa-css-prefix}-water-lower::after, .fa-duotone.#{$fa-css-prefix}-water-lower::after { content: "\f774\f774"; }
.fad.#{$fa-css-prefix}-water-arrow-up::after, .fa-duotone.#{$fa-css-prefix}-water-arrow-up::after { content: "\f775\f775"; }
.fad.#{$fa-css-prefix}-water-rise::after, .fa-duotone.#{$fa-css-prefix}-water-rise::after { content: "\f775\f775"; }
.fad.#{$fa-css-prefix}-water-ladder::after, .fa-duotone.#{$fa-css-prefix}-water-ladder::after { content: "\f5c5\f5c5"; }
.fad.#{$fa-css-prefix}-ladder-water::after, .fa-duotone.#{$fa-css-prefix}-ladder-water::after { content: "\f5c5\f5c5"; }
.fad.#{$fa-css-prefix}-swimming-pool::after, .fa-duotone.#{$fa-css-prefix}-swimming-pool::after { content: "\f5c5\f5c5"; }
.fad.#{$fa-css-prefix}-watermelon-slice::after, .fa-duotone.#{$fa-css-prefix}-watermelon-slice::after { content: "\e337\e337"; }
.fad.#{$fa-css-prefix}-wave-pulse::after, .fa-duotone.#{$fa-css-prefix}-wave-pulse::after { content: "\f5f8\f5f8"; }
.fad.#{$fa-css-prefix}-heart-rate::after, .fa-duotone.#{$fa-css-prefix}-heart-rate::after { content: "\f5f8\f5f8"; }
.fad.#{$fa-css-prefix}-wave-sine::after, .fa-duotone.#{$fa-css-prefix}-wave-sine::after { content: "\f899\f899"; }
.fad.#{$fa-css-prefix}-wave-square::after, .fa-duotone.#{$fa-css-prefix}-wave-square::after { content: "\f83e\f83e"; }
.fad.#{$fa-css-prefix}-wave-triangle::after, .fa-duotone.#{$fa-css-prefix}-wave-triangle::after { content: "\f89a\f89a"; }
.fad.#{$fa-css-prefix}-waveform::after, .fa-duotone.#{$fa-css-prefix}-waveform::after { content: "\f8f1\f8f1"; }
.fad.#{$fa-css-prefix}-waveform-lines::after, .fa-duotone.#{$fa-css-prefix}-waveform-lines::after { content: "\f8f2\f8f2"; }
.fad.#{$fa-css-prefix}-waveform-path::after, .fa-duotone.#{$fa-css-prefix}-waveform-path::after { content: "\f8f2\f8f2"; }
.fad.#{$fa-css-prefix}-weight-hanging::after, .fa-duotone.#{$fa-css-prefix}-weight-hanging::after { content: "\f5cd\f5cd"; }
.fad.#{$fa-css-prefix}-weight-scale::after, .fa-duotone.#{$fa-css-prefix}-weight-scale::after { content: "\f496\f496"; }
.fad.#{$fa-css-prefix}-weight::after, .fa-duotone.#{$fa-css-prefix}-weight::after { content: "\f496\f496"; }
.fad.#{$fa-css-prefix}-whale::after, .fa-duotone.#{$fa-css-prefix}-whale::after { content: "\f72c\f72c"; }
.fad.#{$fa-css-prefix}-wheat::after, .fa-duotone.#{$fa-css-prefix}-wheat::after { content: "\f72d\f72d"; }
.fad.#{$fa-css-prefix}-wheat-awn::after, .fa-duotone.#{$fa-css-prefix}-wheat-awn::after { content: "\e2cd\e2cd"; }
.fad.#{$fa-css-prefix}-wheat-alt::after, .fa-duotone.#{$fa-css-prefix}-wheat-alt::after { content: "\e2cd\e2cd"; }
.fad.#{$fa-css-prefix}-wheat-awn-slash::after, .fa-duotone.#{$fa-css-prefix}-wheat-awn-slash::after { content: "\e338\e338"; }
.fad.#{$fa-css-prefix}-wheat-slash::after, .fa-duotone.#{$fa-css-prefix}-wheat-slash::after { content: "\e339\e339"; }
.fad.#{$fa-css-prefix}-wheelchair::after, .fa-duotone.#{$fa-css-prefix}-wheelchair::after { content: "\f193\f193"; }
.fad.#{$fa-css-prefix}-wheelchair-move::after, .fa-duotone.#{$fa-css-prefix}-wheelchair-move::after { content: "\e2ce\e2ce"; }
.fad.#{$fa-css-prefix}-wheelchair-alt::after, .fa-duotone.#{$fa-css-prefix}-wheelchair-alt::after { content: "\e2ce\e2ce"; }
.fad.#{$fa-css-prefix}-whiskey-glass::after, .fa-duotone.#{$fa-css-prefix}-whiskey-glass::after { content: "\f7a0\f7a0"; }
.fad.#{$fa-css-prefix}-glass-whiskey::after, .fa-duotone.#{$fa-css-prefix}-glass-whiskey::after { content: "\f7a0\f7a0"; }
.fad.#{$fa-css-prefix}-whiskey-glass-ice::after, .fa-duotone.#{$fa-css-prefix}-whiskey-glass-ice::after { content: "\f7a1\f7a1"; }
.fad.#{$fa-css-prefix}-glass-whiskey-rocks::after, .fa-duotone.#{$fa-css-prefix}-glass-whiskey-rocks::after { content: "\f7a1\f7a1"; }
.fad.#{$fa-css-prefix}-whistle::after, .fa-duotone.#{$fa-css-prefix}-whistle::after { content: "\f460\f460"; }
.fad.#{$fa-css-prefix}-wifi::after, .fa-duotone.#{$fa-css-prefix}-wifi::after { content: "\f1eb\f1eb"; }
.fad.#{$fa-css-prefix}-wifi-3::after, .fa-duotone.#{$fa-css-prefix}-wifi-3::after { content: "\f1eb\f1eb"; }
.fad.#{$fa-css-prefix}-wifi-strong::after, .fa-duotone.#{$fa-css-prefix}-wifi-strong::after { content: "\f1eb\f1eb"; }
.fad.#{$fa-css-prefix}-wifi-exclamation::after, .fa-duotone.#{$fa-css-prefix}-wifi-exclamation::after { content: "\e2cf\e2cf"; }
.fad.#{$fa-css-prefix}-wifi-fair::after, .fa-duotone.#{$fa-css-prefix}-wifi-fair::after { content: "\f6ab\f6ab"; }
.fad.#{$fa-css-prefix}-wifi-2::after, .fa-duotone.#{$fa-css-prefix}-wifi-2::after { content: "\f6ab\f6ab"; }
.fad.#{$fa-css-prefix}-wifi-slash::after, .fa-duotone.#{$fa-css-prefix}-wifi-slash::after { content: "\f6ac\f6ac"; }
.fad.#{$fa-css-prefix}-wifi-weak::after, .fa-duotone.#{$fa-css-prefix}-wifi-weak::after { content: "\f6aa\f6aa"; }
.fad.#{$fa-css-prefix}-wifi-1::after, .fa-duotone.#{$fa-css-prefix}-wifi-1::after { content: "\f6aa\f6aa"; }
.fad.#{$fa-css-prefix}-wind::after, .fa-duotone.#{$fa-css-prefix}-wind::after { content: "\f72e\f72e"; }
.fad.#{$fa-css-prefix}-wind-turbine::after, .fa-duotone.#{$fa-css-prefix}-wind-turbine::after { content: "\f89b\f89b"; }
.fad.#{$fa-css-prefix}-wind-warning::after, .fa-duotone.#{$fa-css-prefix}-wind-warning::after { content: "\f776\f776"; }
.fad.#{$fa-css-prefix}-wind-circle-exclamation::after, .fa-duotone.#{$fa-css-prefix}-wind-circle-exclamation::after { content: "\f776\f776"; }
.fad.#{$fa-css-prefix}-window::after, .fa-duotone.#{$fa-css-prefix}-window::after { content: "\f40e\f40e"; }
.fad.#{$fa-css-prefix}-window-flip::after, .fa-duotone.#{$fa-css-prefix}-window-flip::after { content: "\f40f\f40f"; }
.fad.#{$fa-css-prefix}-window-alt::after, .fa-duotone.#{$fa-css-prefix}-window-alt::after { content: "\f40f\f40f"; }
.fad.#{$fa-css-prefix}-window-frame::after, .fa-duotone.#{$fa-css-prefix}-window-frame::after { content: "\e04f\e04f"; }
.fad.#{$fa-css-prefix}-window-frame-open::after, .fa-duotone.#{$fa-css-prefix}-window-frame-open::after { content: "\e050\e050"; }
.fad.#{$fa-css-prefix}-window-maximize::after, .fa-duotone.#{$fa-css-prefix}-window-maximize::after { content: "\f2d0\f2d0"; }
.fad.#{$fa-css-prefix}-window-minimize::after, .fa-duotone.#{$fa-css-prefix}-window-minimize::after { content: "\f2d1\f2d1"; }
.fad.#{$fa-css-prefix}-window-restore::after, .fa-duotone.#{$fa-css-prefix}-window-restore::after { content: "\f2d2\f2d2"; }
.fad.#{$fa-css-prefix}-windsock::after, .fa-duotone.#{$fa-css-prefix}-windsock::after { content: "\f777\f777"; }
.fad.#{$fa-css-prefix}-wine-bottle::after, .fa-duotone.#{$fa-css-prefix}-wine-bottle::after { content: "\f72f\f72f"; }
.fad.#{$fa-css-prefix}-wine-glass::after, .fa-duotone.#{$fa-css-prefix}-wine-glass::after { content: "\f4e3\f4e3"; }
.fad.#{$fa-css-prefix}-wine-glass-crack::after, .fa-duotone.#{$fa-css-prefix}-wine-glass-crack::after { content: "\f4bb\f4bb"; }
.fad.#{$fa-css-prefix}-fragile::after, .fa-duotone.#{$fa-css-prefix}-fragile::after { content: "\f4bb\f4bb"; }
.fad.#{$fa-css-prefix}-wine-glass-empty::after, .fa-duotone.#{$fa-css-prefix}-wine-glass-empty::after { content: "\f5ce\f5ce"; }
.fad.#{$fa-css-prefix}-wine-glass-alt::after, .fa-duotone.#{$fa-css-prefix}-wine-glass-alt::after { content: "\f5ce\f5ce"; }
.fad.#{$fa-css-prefix}-won-sign::after, .fa-duotone.#{$fa-css-prefix}-won-sign::after { content: "\f159\f159"; }
.fad.#{$fa-css-prefix}-krw::after, .fa-duotone.#{$fa-css-prefix}-krw::after { content: "\f159\f159"; }
.fad.#{$fa-css-prefix}-won::after, .fa-duotone.#{$fa-css-prefix}-won::after { content: "\f159\f159"; }
.fad.#{$fa-css-prefix}-wreath::after, .fa-duotone.#{$fa-css-prefix}-wreath::after { content: "\f7e2\f7e2"; }
.fad.#{$fa-css-prefix}-wrench::after, .fa-duotone.#{$fa-css-prefix}-wrench::after { content: "\f0ad\f0ad"; }
.fad.#{$fa-css-prefix}-wrench-simple::after, .fa-duotone.#{$fa-css-prefix}-wrench-simple::after { content: "\e2d1\e2d1"; }
.fad.#{$fa-css-prefix}-x::after, .fa-duotone.#{$fa-css-prefix}-x::after { content: "\58\58"; }
.fad.#{$fa-css-prefix}-x-ray::after, .fa-duotone.#{$fa-css-prefix}-x-ray::after { content: "\f497\f497"; }
.fad.#{$fa-css-prefix}-xmark::after, .fa-duotone.#{$fa-css-prefix}-xmark::after { content: "\f00d\f00d"; }
.fad.#{$fa-css-prefix}-close::after, .fa-duotone.#{$fa-css-prefix}-close::after { content: "\f00d\f00d"; }
.fad.#{$fa-css-prefix}-multiply::after, .fa-duotone.#{$fa-css-prefix}-multiply::after { content: "\f00d\f00d"; }
.fad.#{$fa-css-prefix}-remove::after, .fa-duotone.#{$fa-css-prefix}-remove::after { content: "\f00d\f00d"; }
.fad.#{$fa-css-prefix}-times::after, .fa-duotone.#{$fa-css-prefix}-times::after { content: "\f00d\f00d"; }
.fad.#{$fa-css-prefix}-xmark-to-slot::after, .fa-duotone.#{$fa-css-prefix}-xmark-to-slot::after { content: "\f771\f771"; }
.fad.#{$fa-css-prefix}-times-to-slot::after, .fa-duotone.#{$fa-css-prefix}-times-to-slot::after { content: "\f771\f771"; }
.fad.#{$fa-css-prefix}-vote-nay::after, .fa-duotone.#{$fa-css-prefix}-vote-nay::after { content: "\f771\f771"; }
.fad.#{$fa-css-prefix}-y::after, .fa-duotone.#{$fa-css-prefix}-y::after { content: "\59\59"; }
.fad.#{$fa-css-prefix}-yen-sign::after, .fa-duotone.#{$fa-css-prefix}-yen-sign::after { content: "\f157\f157"; }
.fad.#{$fa-css-prefix}-cny::after, .fa-duotone.#{$fa-css-prefix}-cny::after { content: "\f157\f157"; }
.fad.#{$fa-css-prefix}-jpy::after, .fa-duotone.#{$fa-css-prefix}-jpy::after { content: "\f157\f157"; }
.fad.#{$fa-css-prefix}-rmb::after, .fa-duotone.#{$fa-css-prefix}-rmb::after { content: "\f157\f157"; }
.fad.#{$fa-css-prefix}-yen::after, .fa-duotone.#{$fa-css-prefix}-yen::after { content: "\f157\f157"; }
.fad.#{$fa-css-prefix}-yin-yang::after, .fa-duotone.#{$fa-css-prefix}-yin-yang::after { content: "\f6ad\f6ad"; }
.fad.#{$fa-css-prefix}-z::after, .fa-duotone.#{$fa-css-prefix}-z::after { content: "\5a\5a"; }
