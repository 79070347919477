html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a,
abbr, acronym, address, code, del, dfn, em,
img, q, dl, dt, dd, ol, ul, li, fieldset, form,
label, legend, input, select,
table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    max-width: 100%;
    max-height: 100%;
}

html, body {
	display: block;
	border: 0;
	
	min-height: 100%;
}

p, h1, h2, h3, h4, h5, h6, label {
	display: block;
	border: 0;
	font-weight: normal;
}

a img {
	border: none;
}

table, tbody, tfoot, thead, tr, th, td {
	border: 0;
}
thead, tbody, tfoot {
	width: 100%;
}

strong, b {
	font-weight: bold;
}

em, i {
	font-style: italic;
}

.nobr {
	white-space: nowrap;
}


/*** MENU ***/
nav ul {
	margin: 0;
	padding: 0;
	list-style-type: none; 
}

nav ul li { 
	margin: 0;
	padding: 0;
}
