// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';


$fa-font-path: '../webfonts' !default;
@import '../assets/fonts/fa/scss/fontawesome.scss';
@import '../assets/fonts/fa/scss/duotone.scss';
@import '../assets/fonts/fa/scss/_duotone-icons.scss';
@import '../assets/fonts/fa/scss/solid.scss';
@import '../assets/fonts/fa/scss/thin.scss';

@import './reset.scss';
@import './flex.scss';
@import './global.dark.scss';
@import './global.light.scss';


@font-face {
  font-family: SourceSansPro-Light;
  src: url('../assets/fonts/SourceSansPro-Light.eot'),
       url('../assets/fonts/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
       url('../assets/fonts/SourceSansPro-Light.woff') format('woff'),
       url('../assets/fonts/SourceSansPro-Light.ttf') format('truetype'),
       url('../assets/fonts/SourceSansPro-Light.svg#SourceSansPro-light') format('svg');
  font-weight: normal;
  font-style: normal;
}

.font-smaller {
  font-size: 12px;
}

.font-small {
  font-size: 14px;
}

.font-medium {
  font-size: 16px;
}

.font-big {
  font-size: 18px;
}

.font-huge {
  font-size: 20px;
}

.uppercase {
  text-transform: uppercase;
}

.width-100 {
  width: 100%;
}
.height-100 {
  height: 100%;
}

// this gets rid of the right side scrollbar in the browser
::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
}

* {
  --ion-item-background: transparent;
  --keyboard-offset: 0 !important;
  --fa-secondary-opacity: 0.4;
}

ion-backdrop {
  opacity: .3;
}

ion-menu[type=overlay] {
  z-index: 2000;
  --width: 19rem;
}

.ion-page {
  overflow: unset;
}

ion-toolbar {
  --background: var(--lc-primary-color);
  --color: var(--lc-white-color);
  --min-height: 3.5rem !important;
  --padding-bottom: 0 !important;
  --ion-toolbar-color: white;

  ion-menu-button,
  ion-menu-toggle,
  .button-clear {
    --color: var(--lc-white-color);
  }
}

ion-toast {
  --max-width: 220px;
  text-align: center;
}

main {
  height: 100%;
  width: 100vw;
  .main-background {
    height: 100%;
    width: 100%;
  }
}

h1 {
  font-size: 2.25rem;
}
h2 {
  font-size: 1.88rem;
}
h3 {
  font-size: 1.5rem;
  font-weight: 600;
}
h4 {
  font-size: 1.1rem;
}
h5 {
  font-size: 1rem;
}
h6 {
  font-size: .9rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0px 0px 15px 0px;
  font-weight: normal;
}

body,
p,
li,
td,
th {
  font-size: 1.1rem;
  font-weight: 600;
}

a {
  text-decoration: none;
  font-weight: inherit;
}

.control-input1 {
  .input {
    padding-left: 50px;
    padding-right: 5px;
    border: 0;
    &.barcode,
    &.nfc {
      padding-left: 80px;
    }
    &.keyboard {
      padding-right: 50px;
    }
  }
}

.alert-message {
  overflow: auto;
  display: block;
  white-space: pre-wrap;
  max-height: 100% !important;
}

.run-device-debug {
  &::after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    border: 2px solid var(--lc-danger-color);
    z-index: 999;
    pointer-events: none;
  }
}

#keyboard-main .keyboard-row .keyboard-key-wrapper .keyboard-key {
  background-color: var(--lc-dark-color);
}

ion-popover { // this is only really used on chrome 53 (SATO Printers). Newer browsers use the version below
  --min-width: 250px;
  --width: auto;
}
ion-popover::part(content) {
  top: unset !important;
  left: unset !important;
  position: relative !important;
}

.popover-action { // this is only really used on chrome 53 (SATO Printers). Newer browsers use the version below
  --offset-y: -200px;
}
.popover-bluetooth-type { // this is only really used on chrome 53 (SATO Printers). Newer browsers use the version below
  --offset-y: -300px;
}

.popover-confirm {
  --width: 320px;
  --max-width: 320px;
}

.popover-action::part(content),
.popover-bluetooth-menu::part(content),
.popover-bluetooth-type::part(content),
.popover-checklist-free-text::part(content),
.popover-checklist-menu::part(content),
.popover-enroll-menu::part(content),
.popover-select-printer::part(content),
.popover-test-printer::part(content) {
  width: 80vw;
  max-width: 640px;
}

.popover-load .popover-content, // this is only really used on chrome 53 (SATO Printers). Newer browsers use the version below
.popover-load::part(content) {
  pointer-events: all;
  width: 60vw;
  max-width: 640px;
}

.cordova-camera-capture {
  height: 100%;
  overflow: hidden;
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: transparent;
  }
}

// Is this needed? this came from Andre's design...
.no_alert {
  animation-play-state: paused;
  background-color: none;
}




// currently not being used...

hr {
  display: block;
  border: none;
  background-color: #0072bc;
  height: 1px;
  margin: 0 15px;
}

.focus {
  opacity: 1;
  transition: opacity 0.4s ease-out;
}

.unfocus {
  opacity: 0.3;
  transition: opacity 0.4s ease-out;
}

